import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Modal, Box, Typography, Button, Link, Stack } from '@mui/material';
import mixpanel from 'mixpanel-browser';

const windowGa =
  process.env.NODE_ENV === 'development'
    ? 'ga-disable-G-VKSFE3ESK8'
    : 'ga-disable-G-1BZRDDCMGF';

const gaConfig =
  process.env.NODE_ENV === 'development' ? 'G-VKSFE3ESK8' : 'G-1BZRDDCMGF';

const CookieConsentModal = () => {
  const [cookies, setCookie] = useCookies(['userConsent']);
  const [open, setOpen] = useState(false);

  // Show modal if user hasn't made a choice
  useEffect(() => {
    if (!cookies.userConsent) {
      setOpen(true);
    }
  }, [cookies]);

  // Load GA and Mixpanel scripts if consent has been given
  useEffect(() => {
    if (cookies.userConsent === 'accepted') {
      initializeGtag();
      initializeMixpanel();
    }
  }, [cookies]);

  // Function to dynamically load the Google Analytics script
  // const initializeGtag = () => {
  //   const script = document.createElement('script');
  //   script.src = `https://www.googletagmanager.com/gtag/js?id=${gaConfig}`;
  //   script.async = true;
  //   document.head.appendChild(script);
  //   script.onload = () => {
  //     // Declare gtag and dataLayer on window
  //     interface Window {
  //       dataLayer: any[];
  //       gtag: (...args: any[]) => void;
  //     }

  //     (window as any).dataLayer = (window as any).dataLayer || [];
  //     (window as any).gtag = function (...args: any[]) {
  //       (window as any).dataLayer.push(args);
  //     };
  //     (window as any).gtag('js', new Date());
  //   };

  //   window[windowGa] = false; // You can adjust this if you need to change a global variable
  //   gtag('config', gaConfig);
  // };
  const initializeGtag = () => {
    console.log('Loading Google Analytics with ID:', gaConfig); // Debug log
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaConfig}`;
    script.async = true;
    document.head.appendChild(script);
    // script.onload = () => {
    //   // Declare gtag and dataLayer on window
    //   interface Window {
    //     dataLayer: any[];
    //     gtag: (...args: any[]) => void;
    //   }

    //   (window as any).dataLayer = (window as any).dataLayer || [];
    //   (window as any).gtag = function (...args: any[]) {
    //     (window as any).dataLayer.push(args);
    //   };
    //   (window as any).gtag('js', new Date());

    //   window[windowGa] = false; // You can adjust this if you need to change a global variable
    //   gtag('config', gaConfig);
    // };
    script.onload = () => {
      if (typeof window.gtag !== 'function') {
        return;
      }

      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).gtag = function (...args) {
        (window as any).dataLayer.push(args);
      };
      window.gtag('js', new Date());
      // Initialize with your GA ID
    };

    window[windowGa] = false;
    window.gtag('config', gaConfig);
  };

  const initializeMixpanel = () => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: true,
      persistence: 'localStorage',
    });
  };

  // Accept Cookies & Close Modal
  const handleAccept = () => {
    setCookie('userConsent', 'accepted', {
      path: '/',
      maxAge: 60 * 60 * 24 * 365 * 10,
    });

    // Call the Google Analytics configuration (or any other custom action you need)
    // This triggers the Google Analytics setup
    initializeGtag();
    initializeMixpanel();
    setOpen(false);
  };

  // Decline Cookies & Close Modal
  const handleDecline = () => {
    setCookie('userConsent', 'declined', {
      path: '/',
      maxAge: 60 * 60 * 24 * 1,
    }); // 1 day

    window[windowGa] = true; // Optionally set a flag to prevent GA

    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => {}}
      disableEscapeKeyDown
      sx={{ zIndex: 10000000000 }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          paddingX: 4,
          paddingTop: 4,
          paddingBottom: 2,
          borderRadius: 2,
          textAlign: 'center',
          '@media (max-width: 600px)': {
            width: '90%', // Increase width on small screens
          },
          outline: 'none', // Disable focus outline
          '&:focus': {
            outline: 'none', // Remove outline when focused
          },
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
          🍪 Help us improve <strong>rooutie</strong>
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={theme => ({
            fontWeight: 500,
            fontSize: 14,
          })}
        >
          We use cookies only to improve your experience. You can accept or
          decline non-essential cookies.
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button
            variant="text"
            onClick={handleDecline}
            sx={theme => ({
              borderRadius: '20px',
              color: theme.palette.grey[500],
              width: '30%',
              fontWeight: 600,
              fontSize: 16,
              textTransform: 'none',
            })}
          >
            Decline
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAccept}
            sx={theme => ({
              borderRadius: '20px',
              borderColor: theme.palette.grey[300],
              width: '70%',
              fontWeight: 600,
              fontSize: 16,
              textTransform: 'none',
            })}
          >
            Accept
          </Button>
        </Box>
        <Stack
          direction={'row'}
          sx={{
            marginTop: 3,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography
            sx={theme => ({
              color: theme.palette.grey[400],
              textDecoration: 'none',
              marginRight: 0.5,
              fontSize: 14,
            })}
          >
            Learn more about our
          </Typography>
          <Link
            href="/cookies"
            sx={theme => ({
              color: theme.palette.grey[400],
              textDecoration: 'underline',
              fontSize: 14,
            })}
          >
            Cookies Policy
          </Link>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CookieConsentModal;
