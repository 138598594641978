import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import {
  Box,
  Typography,
  Stack,
  Divider,
  Button,
  useMediaQuery,
  useTheme,
  Link,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import {
  Maybe,
  Place,
  Step,
  Image,
  StepPlaceImage,
} from '../../../generated/public_graphql';
import { CategoryIcon } from '../../../theme-components/Icons';
import { H3Title } from '../../../theme-components/Typography';
import {
  formatReviewString,
  isPrerenderBot,
  isPublisher,
  openLinkInBrowserNewTab,
  useUserRoles,
} from '../../../utils/helpers';
import { ActionsStep } from '../../create-trip/edit-trip/ActionsStep';
import { EditImagesModal } from '../../create-trip/manage-trip-modals/EditAddStepImages';
import { LikePlace } from '../../favourite/likePlace';
import { RenderHtmlTest } from '../../RenderHtmlFromResponse';
import { AddToTripButton } from '../AddToTrip';
import PlaceCardCTAsNew from '../place-details/PublicPlaceCardCTANew';
import SocialLinksRow from '../place-details/SocialLinks';
import { TodaysHours } from '../PlaceMoreInfoModal';
import { SideScrollRecommendationsForStep } from '../Recommendation';
import {
  StepPlaceImagesSwipable,
  StepPlaceImagesSwipableHeader,
} from '../StepImages';
import { TripUser } from '../TripItinerary';
import { PlaceCardButtons } from '../../../theme-components/Buttons';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

interface VisibleElement {
  stepId: string;
  distance: number;
}

declare global {
  interface Window {
    visibleElements?: VisibleElement[];
  }
}

interface PlaceCardProps {
  step: Step;
  dayId?: string;
  selected?: boolean;
  tripId?: string;
  showEdit?: boolean;
  collapsedDetails?: boolean;
  tripType: string;
  tripUser: TripUser;
  onCardInView?: (stepId: string) => void;
  highlightedStepId?: string;
  isRenderedOnLockedPublicTrip?: boolean;
}

export const PublicPlaceCardNew: React.FC<PlaceCardProps> = ({
  step,
  dayId,
  selected,
  tripId,
  showEdit,
  collapsedDetails,
  tripType,
  tripUser,
  onCardInView,
  highlightedStepId,
  isRenderedOnLockedPublicTrip = false,
}) => {
  let place: Place | null = null;
  const roles = useUserRoles();

  const cardRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isEditor = window.location.href.includes('editor');
  const [isEditImages, setIsEditImages] = useState(false);

  useEffect(() => {
    if (!cardRef.current || !onCardInView || !step.id) return;
    let isStepHandled = false;

    const checkPosition = () => {
      if (!cardRef.current) return;
      const rect = cardRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportCenter = viewportHeight / 2;
      const elementCenter = rect.top + rect.height / 2;
      const tolerance = rect.height / 2;

      // Check if element center is within tolerance of viewport center
      if (
        Math.abs(elementCenter - viewportCenter) < tolerance &&
        !isStepHandled
      ) {
        isStepHandled = true;
        setTimeout(() => {
          // if (step.id !== highlightedStepId) {
          //   onCardInView(step.id);
          // }
          if (!cardRef.current) return;

          // Recalculate the position to check visibility
          const updatedRect = cardRef.current.getBoundingClientRect();
          const updatedElementCenter = updatedRect.top + updatedRect.height / 2;

          if (Math.abs(updatedElementCenter - viewportCenter) < tolerance) {
            if (step.id !== highlightedStepId) {
              onCardInView(step.id);
            }
          }
        }, 500);
      }
    };

    // Use IntersectionObserver just to trigger position check
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            checkPosition();
          }
        });
      },
      { threshold: [0.5] },
    );

    observer.observe(cardRef.current);
    window.addEventListener('scroll', checkPosition);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', checkPosition);
    };
  }, [step.id, onCardInView]);

  if (step && step.place) {
    place = step.place;
  }
  if (!step) {
    return <></>;
  }

  let customStepImages: Maybe<Image>[] = [];
  if (step.images && step.images.length > 0) {
    customStepImages = step.images;
  }

  let placeImages: Maybe<StepPlaceImage>[] = [];
  if (step && step.placeImages && step.placeImages.length > 0) {
    if (isPublisher(roles) === true) {
      placeImages = step.placeImages.filter(
        image =>
          image?.fromOwner === true ||
          image?.path ||
          image?.previewPath ||
          image?.mediumPath ||
          image?.smallPath,
      );
    } else {
      placeImages = step.placeImages;
    }
  }

  const highlight = highlightedStepId === step.id || selected;

  const scrollToCenter = (element: HTMLElement) => {
    if (!element) return;

    // Calculate the top position of the element relative to the viewport
    const elementTop = element.getBoundingClientRect().top + window.scrollY;
    const middleOfScreen = window.innerHeight / 2;
    const elementCenter = elementTop + element.offsetHeight / 2;
    const scrollPosition = elementCenter - middleOfScreen;

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  let numberOfLines = 5;
  numberOfLines = isSmallScreen ? 6 : 7;

  if (customStepImages.length === 0 && placeImages.length === 0 && !isEditor) {
    numberOfLines = 5;
  }

  const handleCloseModal = () => {
    setIsEditImages(false);
  };

  if (isPrerenderBot()) {
    numberOfLines = 500;
  }

  return (
    <Stack
      ref={cardRef}
      className="step-card"
      direction="column"
      alignItems="left"
      sx={theme => ({
        width: '100%',
        // paddingTop: 1.5,
        // border: 2,
        // borderColor: highlight ? theme.palette.primary.light : '#E4DBFF',
        // borderColor: highlight ? '#E4DBFF' : '#f6f0ff',
        // paddingBottom: 2,
        // paddingLeft: 1.5,
        // paddingRight: 1.5,
        borderRadius: '20px',
        backgroundColor: '#FFFFFF',
        marginBottom: 0,
        boxShadow: highlight
          ? '0px 4px 10px rgba(0, 0, 0, 0.4)'
          : '0px 4px 10px rgba(0, 0, 0, 0.2)',
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          marginBottom: 0,
        },
      })}
    >
      <Box
        sx={{
          '@media (min-width: 1100px)': {
            display: 'none', // Hides the box on screens 1100px and up
          },
        }}
      >
        {customStepImages.length > 0 && (
          <Box
            sx={theme => ({
              // maxWidth: '50%',
              width: '100%',
              height: '260px',
              float: 'right',
              // borderTopLeftRadius: '20px',
              // borderTopRightRadius: '20px',
              // marginLeft: 0.5,
              // marginTop: 1,
              // marginBottom: 1,
              [theme.breakpoints.down('sm')]: {
                // width: '50%',
                height: '260px',
                // marginLeft: 0.5,
                // marginBottom: 0.5,
              },
            })}
          >
            <StepPlaceImagesSwipableHeader
              images={customStepImages}
              step={step}
              tripId={tripId!}
            />
          </Box>
        )}
      </Box>

      <Stack
        sx={theme => ({
          width: '100%',
          paddingTop: 1.5,
          // border: 2,
          // borderColor: highlight ? theme.palette.primary.light : '#E4DBFF',
          // borderColor: highlight ? '#E4DBFF' : '#f6f0ff',
          paddingBottom: 2,
          paddingLeft: 1.5,
          paddingRight: 1.5,

          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
          },
        })}
      >
        <Stack
          direction="row"
          alignItems="left"
          sx={theme => ({
            width: '100%',
            // mb: 0.5,
            // marginBottom: -0.5,
            [theme.breakpoints.down('sm')]: {
              fontSize: 16,
            },
          })}
        >
          <Box
            onClick={() => {
              if (cardRef.current) {
                scrollToCenter(cardRef.current);
              }
            }}
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'flex-start',
              marginRight: 0.75,
              marginLeft: -0.5,
              // marginTop: -0.25,
              [theme.breakpoints.down('sm')]: {
                // marginTop: 0,
                marginTop: 0.5,
                marginRight: 0.75,
              },
            })}
          >
            {/* <Box sx={{ position: 'relative', width: 30, height: 30 }}>
              <FmdGoodIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  color: highlight ? theme.palette.primary.main : '#9E7FEF',
                })}
              /> */}

            <CategoryIcon
              type={place && place.placeType}
              category={place && place.category}
              source={place && place.dataSource}
              width={25}
              height={25}
              // returnFallback={false}
              // sx={theme => ({
              //   // position: 'absolute',
              //   // top: '44%',
              //   // left: '50%',
              //   // background: highlight ? theme.palette.primary.main : '#9E7FEF',
              //   // transform: 'translate(-50%, -50%)',
              //   width: 25,
              //   height: 25,
              //   // color: '#FFFFFF',
              // })}
            />
          </Box>
          {/* </Box> */}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="top"
            sx={{
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {step.title ? (
              <Box
                onClick={() => {
                  if (cardRef.current) {
                    scrollToCenter(cardRef.current);
                  }
                }}
                sx={theme => ({
                  [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    alignItems: 'center',
                  },
                })}
              >
                <H3Title>{step.title}</H3Title>
              </Box>
            ) : (
              <Box />
            )}
            <Stack direction="row" spacing={0.75}>
              {place && <LikePlace placeId={place.id} />}
              {dayId ? (
                <Box>
                  <AddToTripButton
                    stepId={step.id}
                    dayId={dayId}
                    buttonType="insideTitle"
                    buttonCopy="Save"
                    showDropDownIcon={true}
                    isRenderedOnLockedPublicTrip={isRenderedOnLockedPublicTrip}
                  />
                </Box>
              ) : (
                <>
                  {tripId && !collapsedDetails && (
                    <Box>
                      {showEdit && (
                        <ActionsStep
                          copy={'Edit'}
                          step={step}
                          tripId={tripId}
                          tripType={tripType}
                        />
                      )}
                    </Box>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack
          spacing={0.5}
          display={collapsedDetails === true ? 'none' : undefined}
          sx={theme => ({
            width: '100%',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {},
          })}
        >
          <Stack direction={'row'}>
            <Box width={'100%'}>
              {place?.rating && (
                <>
                  <Stack direction="row" alignItems="center">
                    <StarTwoToneIcon
                      sx={theme => ({
                        width: 20,
                        height: 20,
                        marginRight: 1,
                        color: '#9E7FEF',
                      })}
                    />

                    {place.rating && (
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: 14,
                            alignSelf: 'center',
                            color: '#36454F',
                            marginRight: 0.5,
                          }}
                        >
                          {place.rating}{' '}
                        </Typography>
                        {place.noOfReviews && (
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: 14,
                              alignSelf: 'center',
                              color: '#6E7191',
                              marginRight: 0.5,
                            }}
                          >
                            ~{formatReviewString(place.noOfReviews)}
                          </Typography>
                        )}
                      </Stack>
                    )}
                  </Stack>
                </>
              )}
              {/* Operating Hours */}
              {place?.openingHours && place?.openingHours.length > 0 && (
                <>
                  <TodaysHours place={place} />
                </>
              )}
              {place?.checkInTime && (
                <>
                  <Stack direction="row" alignItems="center">
                    <HistoryToggleOffTwoToneIcon
                      sx={theme => ({
                        width: 20,
                        height: 20,
                        marginRight: 1,
                        // color: theme.palette.icon.main,
                        color: '#9E7FEF',

                        // color: '#9E7FEF',
                        // [theme.breakpoints.down('sm')]: {
                        //   marginRight: 0.5,
                        //   width: 20,
                        //   height: 20,
                        // },
                      })}
                    />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 14,
                        alignSelf: 'center',
                        color: '#36454F',
                        marginRight: 0.5,
                      }}
                    >
                      Check In:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        alignSelf: 'center',
                        color: '#6E7191',
                        marginRight: 0.5,
                      }}
                    >
                      {place.checkInTime && place.checkInTime}
                    </Typography>
                  </Stack>
                </>
              )}
              {place?.checkOutTime && (
                <>
                  <Stack direction="row" alignItems="center">
                    <UpdateTwoToneIcon
                      sx={theme => ({
                        width: 20,
                        height: 20,
                        marginRight: 1,
                        color: '#9E7FEF',

                        // color: theme.palette.icon.main,
                        // color: '#9E7FEF',

                        // [theme.breakpoints.down('sm')]: {
                        //   marginRight: 0.5,
                        //   width: 20,
                        //   height: 20,
                        // },
                      })}
                    />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 14,
                        alignSelf: 'center',
                        color: '#36454F',
                        marginRight: 0.5,
                      }}
                    >
                      Check Out:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        alignSelf: 'center',
                        color: '#6E7191',
                        marginRight: 0.5,
                      }}
                    >
                      {place.checkInTime && place.checkOutTime}
                    </Typography>
                  </Stack>
                </>
              )}
              {place && (
                <PriceInfo
                  minPrice={place.minPrice}
                  maxPrice={place.maxPrice}
                  admissionPrice={place.admissionPrice}
                  priceCategory={place.priceCategory}
                  priceCurrency={place.priceCurrency}
                />
              )}
              {place?.bookingLink && (
                <>
                  <Stack direction="row" alignItems="center">
                    <EventTwoToneIcon
                      sx={theme => ({
                        width: 20,
                        height: 20,
                        marginRight: 1,
                        // color: theme.palette.icon.main,
                        color: '#9E7FEF',

                        // [theme.breakpoints.down('sm')]: {
                        //   marginRight: 0.5,
                        //   width: 20,
                        //   height: 20,
                        // },
                      })}
                    />

                    <Button
                      variant="text"
                      sx={theme => ({
                        fontSize: 14,
                        p: 0,
                        m: 0,
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                      })}
                      onClick={() => {
                        if (place && place.bookingLink) {
                          openLinkInBrowserNewTab(place.bookingLink);
                        }
                      }}
                    >
                      Book now
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Stack>

          <SocialLinksRow step={step} displayedInItinerary={true} />

          {/* <PlaceCardCTAsNew
          place={place ? place : undefined}
          lat={step.latitude ? step.latitude : undefined}
          lng={step.longitude ? step.longitude : undefined}
        /> */}

          {/* {step.description && ( */}
          <>
            <Box mb={1}>
              <Box sx={{ mt: 1 }}>
                <Divider />
              </Box>
              {/* <Body1>
                <RenderHtmlFromResponseNoMargin
                  content={step.description}
                  limitToLines={3}
                />
              </Body1> */}
              {!isEditor && (
                <>
                  <Box
                    sx={{
                      '@media (max-width: 1100px)': {
                        display: customStepImages.length > 0 ? 'none' : 'block', // Hides the box on screens 1100px and up
                      },
                    }}
                  >
                    <>
                      <Box
                        sx={theme => ({
                          maxWidth:
                            customStepImages.length > 0 ||
                            placeImages.length > 0
                              ? '50%'
                              : '0%',
                          width: '250px',
                          minHeight:
                            customStepImages.length > 0 ||
                            placeImages.length > 0
                              ? '180px'
                              : '0px',
                          float: 'right',
                          borderRadius: '20px',
                          marginLeft: 0.5,
                          [theme.breakpoints.down('sm')]: {
                            width: '50%',
                            // height: '150px',
                            minHeight:
                              customStepImages.length > 0 ||
                              placeImages.length > 0
                                ? '150px'
                                : '0px',
                            marginLeft: 0.5,
                            marginBottom: 0.5,
                          },
                        })}
                      >
                        {customStepImages.length > 0 && (
                          <StepPlaceImagesSwipable
                            images={customStepImages}
                            step={step}
                            tripId={tripId!}
                          />
                        )}
                        {customStepImages.length === 0 &&
                          placeImages.length > 0 && (
                            <StepPlaceImagesSwipable
                              images={placeImages}
                              step={step}
                              tripId={tripId!}
                            />
                          )}
                      </Box>
                      <Box
                        sx={theme => ({
                          // minHeight: '186px',
                          minHeight:
                            customStepImages.length > 0 ||
                            placeImages.length > 0
                              ? '176px'
                              : '0px',
                          marginTop: 1.5,
                          marginBottom: 1.5,
                          [theme.breakpoints.down('sm')]: {
                            // minHeight: '156px',
                            minHeight:
                              customStepImages.length > 0 ||
                              placeImages.length > 0
                                ? '146px'
                                : '0px',
                          },
                        })}
                      >
                        <RenderHtmlTest
                          content={step.description ? step.description : ''}
                          limitToLines={numberOfLines}
                        />
                      </Box>
                    </>
                    {/* ) : (
                      <Box
                        sx={theme => ({
                          marginTop: 1.5,
                          marginBottom: 1.5,
                        })}
                      >
                        <RenderHtmlTest
                          content={step.description ? step.description : ''}
                          limitToLines={numberOfLines}
                        />
                      </Box> */}
                    {/* )} */}
                  </Box>
                  {customStepImages.length > 0 && (
                    <Box
                      sx={{
                        '@media (min-width: 1100px)': {
                          display: 'none', // Hides the box on screens 1100px and up
                        },
                      }}
                    >
                      <Box
                        sx={theme => ({
                          marginTop: 1.5,
                          marginBottom: 1.5,
                        })}
                      >
                        <RenderHtmlTest
                          content={step.description ? step.description : ''}
                          limitToLines={numberOfLines}
                        />
                      </Box>
                    </Box>
                  )}
                </>
              )}
              {isEditor && (
                <>
                  <Box
                    sx={{
                      '@media (max-width: 1100px)': {
                        display: customStepImages.length > 0 ? 'none' : 'block', // Hides the box on screens 1100px and up
                      },
                    }}
                  >
                    <Box
                      sx={theme => ({
                        // maxWidth: customStepImages.length > 0 ? '0%' : '50%',
                        maxWidth: '50%',

                        width: '250px',
                        height: '180px',
                        float: 'right',
                        borderRadius: '20px',
                        marginLeft: 0.5,
                        marginTop: 1,
                        marginBottom: 1,
                        [theme.breakpoints.down('sm')]: {
                          width: '50%',
                          height: '150px',
                          marginLeft: 0.5,
                          marginBottom: 0.5,
                        },
                      })}
                    >
                      {customStepImages.length > 0 && (
                        <StepPlaceImagesSwipable
                          images={customStepImages}
                          step={step}
                          tripId={tripId!}
                        />
                      )}
                      {customStepImages.length === 0 &&
                        placeImages.length > 0 && (
                          <StepPlaceImagesSwipable
                            images={placeImages}
                            step={step}
                            tripId={tripId!}
                          />
                        )}
                      {!!tripId &&
                        customStepImages.length === 0 &&
                        placeImages.length === 0 && (
                          <>
                            <Box
                              sx={theme => ({
                                // width: '240px',
                                width: '100%',
                                height: '180px',
                                // objectFit: 'cover',
                                // float: 'right',
                                borderRadius: '20px',
                                backgroundColor: '#f0f0f0',

                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                [theme.breakpoints.down('sm')]: {
                                  width: '100%',
                                  height: '150px',
                                },
                              })}
                            >
                              <Button
                                variant="text"
                                color="secondary"
                                onClick={() => {
                                  // if (!isModalDisplayed) {
                                  //   const scrollPosition = window.scrollY;
                                  //   setScrollY(scrollPosition);
                                  //   setIsModalDisplayed(!isModalDisplayed);
                                  // }
                                  setIsEditImages(true);
                                }}
                              >
                                Select Image
                              </Button>
                            </Box>
                          </>
                        )}
                    </Box>
                  </Box>
                  <Box
                    sx={theme => ({
                      minHeight: customStepImages.length > 0 ? '0px' : '186px',
                      marginTop: 1.5,
                      [theme.breakpoints.down('sm')]: {
                        minHeight:
                          customStepImages.length > 0 ? '0px' : '156px',
                      },
                    })}
                  >
                    <RenderHtmlTest
                      content={
                        step.description
                          ? step.description
                          : 'Tap Edit to add notes'
                      }
                      limitToLines={numberOfLines}
                    />
                  </Box>
                </>
              )}
            </Box>
          </>
          {/* )} */}
          <PlaceCardCTAsNew
            place={place ? place : undefined}
            lat={step.latitude ? step.latitude : undefined}
            lng={step.longitude ? step.longitude : undefined}
          />

          {!place && !!step.url && (
            <>
              <Link
                onClick={() => {
                  openLinkInBrowserNewTab(step.url!);
                  gtag('event', 'public-link-navigate-click');
                }}
              >
                <PlaceCardButtons
                  primary={true}
                  mobileCopy="More Info"
                  desktopCopy="More Info"
                  icon={InfoTwoToneIcon}
                />
              </Link>
            </>
          )}

          {step.recommendations &&
            step.recommendations.length > 0 &&
            (step.recommendations[0]?.place ||
              step.recommendations[0]?.activity) && (
              <SideScrollRecommendationsForStep
                recommentations={step.recommendations}
                type="step"
                step={step}
                tripUser={tripUser}
              />
            )}
        </Stack>
        {isEditImages && (
          <EditImagesModal
            step={step}
            tripId={tripId!}
            handleCloseModal={handleCloseModal}
          />
        )}
      </Stack>
    </Stack>
  );
};

interface PriceInfoProps {
  admissionPrice: number | null | undefined;
  minPrice: number | null | undefined;
  maxPrice: number | null | undefined;
  priceCurrency: string | null | undefined;
  priceCategory: string | null | undefined;
  renderSmall?: boolean;
}

export const PriceInfo: React.FC<PriceInfoProps> = ({
  admissionPrice,
  minPrice,
  maxPrice,
  priceCategory,
  priceCurrency,
  renderSmall = false,
}) => {
  // **Condition 1:** Free Entry
  if (admissionPrice === 0) {
    return (
      <Box>
        <Stack direction="row" alignItems="center">
          <LocalActivityTwoToneIcon
            sx={theme => ({
              // width: renderSmall ? 20 : 25,
              // height: renderSmall ? 20 : 25,
              width: 20,
              height: 20,
              marginRight: 1,
              // color: theme.palette.icon?.main || '#9E7FEF',
              color: '#9E7FEF',
            })}
          />
          <Typography
            sx={{
              fontWeight: 600,
              // fontSize: renderSmall ? 14 : 16,
              fontSize: 14,
              color: '#36454F',
              marginRight: 0.5,
            }}
          >
            Free entry
          </Typography>
        </Stack>
      </Box>
    );
  }

  // **Condition 2:** Tickets Available
  if (admissionPrice) {
    return (
      <Box>
        <Stack direction="row" alignItems="center">
          <LocalActivityTwoToneIcon
            sx={theme => ({
              // width: renderSmall ? 20 : 25,
              // height: renderSmall ? 20 : 25,
              width: 20,
              height: 20,
              marginRight: 1,
              color: '#9E7FEF',

              // color: theme.palette.icon?.main || '#9E7FEF',
            })}
          />
          <Typography
            sx={{
              fontWeight: 500,
              // fontSize: renderSmall ? 14 : 16,
              fontSize: 14,

              color: '#6E7191',
              marginRight: 0.5,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 600,
                // fontSize: renderSmall ? 14 : 16,
                fontSize: 14,

                color: '#36454F',
                marginRight: 0.5,
              }}
            >
              Tickets from:
            </Typography>
            {admissionPrice}
            {priceCurrency === 'USD' && '$'} {priceCurrency === 'GBP' && '£'}{' '}
            {priceCategory ? priceCategory : 'per person'}
          </Typography>
        </Stack>
      </Box>
    );
  }

  // **Condition 3:** Price Range
  if (minPrice) {
    return (
      <Box>
        <Stack direction="row" alignItems="flex_start">
          <CreditCardTwoToneIcon
            sx={theme => ({
              // width: renderSmall ? 20 : 25,
              // height: renderSmall ? 20 : 25,
              width: 20,
              height: 20,
              marginRight: 1,
              color: '#9E7FEF',
              // color: theme.palette.icon?.main || '#6E7191',
            })}
          />
          <Typography
            sx={{
              fontWeight: 500,
              // fontSize: renderSmall ? 14 : 16,
              fontSize: 14,

              color: '#6E7191',
              marginRight: 0.5,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 600,
                // fontSize: renderSmall ? 14 : 16,
                fontSize: 14,

                color: '#36454F',
                marginRight: 0.5,
              }}
            >
              From:
            </Typography>
            {minPrice}
            {priceCurrency === 'GBP' && '£'}
            {maxPrice && ` - ${maxPrice}`}
            {maxPrice && priceCurrency === 'GBP' && '£'}{' '}
            {priceCategory ? priceCategory : 'per person'}
          </Typography>
        </Stack>
      </Box>
    );
  }

  // **No Admission Information**
  return null;
};
