import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import WatchLaterTwoToneIcon from '@mui/icons-material/WatchLaterTwoTone';
import {
  Box,
  Typography,
  Link,
  Stack,
  IconButton,
  Grid,
  Button,
  Divider,
  Avatar,
} from '@mui/material';
import { useState } from 'react';

import {
  Activity,
  Maybe,
  Place,
  PlaceHours,
  Step,
} from '../../generated/user_graphql';
import Space from '../../theme-components/Spacing';
import { Body1, TitleH3, TitleH4 } from '../../theme-components/Typography';
import {
  formatReviewString,
  formatSecondsToTime,
  generateGetYourGuideUrl,
} from '../../utils/helpers';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';
import { contributorLink } from '../trip-details/StepImages';

import ButtonsRow, { openEmbeddedLinkInBrowserNewTab } from './ButtonsRow';
import { EmbeddedImageCard } from './EmbeddedImageCard';
import { PriceInfo } from './PriceInfo';

interface PlaceMoreInfoModalProps {
  step?: Maybe<Step>;
  place?: Maybe<Place>;
  activity?: Maybe<Activity>;
}

export const MapMoreInfoCard: React.FC<PlaceMoreInfoModalProps> = ({
  step,
  place,
  activity,
}) => {
  const weekDayOrder = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  let orderedHours: Maybe<PlaceHours>[] = [];
  if (place && place.openingHours && place.openingHours.length > 0) {
    orderedHours = [...place.openingHours];
    orderedHours = orderedHours.sort(
      (a, b) =>
        weekDayOrder.indexOf(a?.weekDay ?? '') -
        weekDayOrder.indexOf(b?.weekDay ?? ''),
    );
  }

  console.log('activity.name', activity);
  console.log('place.title', place);

  console.log('step.title', step);

  console.log('activity.name', activity?.name);
  console.log('place.title', place?.title);

  console.log('step.title', step?.title);

  return (
    <Box sx={{ backgroundColor: '#fffaf5', height: '100%' }}>
      <Stack
        justifyContent="space-between"
        width="100%"
        direction="row"
        sx={{
          position: 'relative',
          top: 0,
          backgroundColor: 'inherit',
          zIndex: 100,
          paddingX: 2,
          paddingTop: 1,
          paddingBottom: 0.5,
          boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
        }}
      >
        <Box>
          {step && !!step.title ? (
            <TitleH4>{step.title}</TitleH4>
          ) : (
            <>
              {place && <TitleH4>{place.title}</TitleH4>}
              {activity && <TitleH4>{activity.name}</TitleH4>}
            </>
          )}
        </Box>
      </Stack>
      <Stack
        direction={'column'}
        spacing={0.5}
        sx={{
          backgroundColor: 'inherit',
          paddingX: 2,
        }}
      >
        <Box>
          {place?.rating && (
            <>
              <Stack direction="row" alignItems="center">
                <StarTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    marginRight: 0.5,

                    // color: 'rgb(250, 175, 0)',
                    color: '#9E7FEF',
                  })}
                />
                {/* </Box> */}
                {place.rating && (
                  <Stack direction="row" spacing={0.5}>
                    <>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 14,
                          alignSelf: 'center',
                          color: '#36454F',
                          marginRight: 0.5,
                        }}
                      >
                        {place.rating}{' '}
                      </Typography>
                      {place.noOfReviews && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: 14,
                            alignSelf: 'center',
                            color: '#6E7191',
                            marginRight: 0.5,
                          }}
                        >
                          ~{formatReviewString(place.noOfReviews)}
                        </Typography>
                      )}
                    </>
                  </Stack>
                )}
              </Stack>
            </>
          )}
          {activity?.rating && (
            <>
              <Stack direction="row" alignItems="center">
                {/* <Box
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#AB88F3',
                    display: 'flex', // Enable flexbox layout
                    alignItems: 'center', // Vertically center content
                    justifyContent: 'center', // Horizontally center content
                    [theme.breakpoints.down('sm')]: {},
                  })}
                > */}
                <StarTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    marginRight: 0.5,

                    // color: 'rgb(250, 175, 0)',
                    color: '#9E7FEF',
                  })}
                />
                {/* </Box> */}
                {activity.rating && (
                  <Stack direction="row" spacing={0.5}>
                    {activity.noOfReviews && (
                      <>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: 14,
                            alignSelf: 'center',
                            color: '#36454F',
                            marginRight: 0.5,
                          }}
                        >
                          {activity.rating}{' '}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: 14,
                            alignSelf: 'center',
                            color: '#6E7191',
                            marginRight: 0.5,
                          }}
                        >
                          ~{formatReviewString(activity.noOfReviews)}
                        </Typography>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            </>
          )}
        </Box>
        {place && (
          <PriceInfo
            minPrice={place.minPrice}
            maxPrice={place.maxPrice}
            admissionPrice={place.admissionPrice}
            priceCategory={place.priceCategory}
            priceCurrency={place.priceCurrency}
          />
        )}
        {activity && !!activity.durationInMinutes && (
          <>
            <Stack direction="row" alignItems="center">
              <UpdateTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Takes:&nbsp;
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    alignSelf: 'center',
                    color: '#6E7191',
                    marginRight: 0.5,
                  }}
                >
                  {formatSecondsToTime(activity.durationInMinutes * 60)}
                </Typography>
              </Typography>
            </Stack>
          </>
        )}
        {activity && (
          <PriceInfo
            minPrice={activity.minPrice}
            maxPrice={activity.maxPrice}
            admissionPrice={null}
            priceCategory={activity.priceCategory}
            priceCurrency={activity.priceCurrency}
          />
        )}
        {activity &&
          !!activity.validForInMinutes &&
          !activity.durationInMinutes && (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
                <UpdateTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    marginRight: 1,
                    // color: theme.palette.icon.main,
                    color: '#9E7FEF',
                  })}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 14,
                    alignSelf: 'center',
                    color: '#36454F',
                    marginRight: 0.5,
                  }}
                >
                  Valid:&nbsp;
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                      alignSelf: 'center',
                      color: '#6E7191',
                      marginRight: 0.5,
                    }}
                  >
                    {formatSecondsToTime(activity.validForInMinutes * 60)}
                  </Typography>
                </Typography>
              </Stack>
            </>
          )}
        {place?.checkInTime && (
          <>
            <Stack direction="row" alignItems="center">
              <HistoryToggleOffTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Check In:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#6E7191',
                  marginRight: 0.5,
                }}
              >
                {place.checkInTime && place.checkInTime}
              </Typography>
            </Stack>
          </>
        )}
        {place?.checkOutTime && (
          <>
            <Stack direction="row" alignItems="center">
              <UpdateTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Check Out:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#6E7191',
                  marginRight: 0.5,
                }}
              >
                {place.checkInTime && place.checkOutTime}
              </Typography>
            </Stack>
          </>
        )}
        {place && place.website && place.website !== '' && (
          <>
            <Stack direction="row" alignItems="center">
              <LanguageIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Box
                onClick={() => {
                  openEmbeddedLinkInBrowserNewTab(place!.website!);
                  gtag('event', 'public-ext-link-website');
                }}
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  textDecoration: 'underline',
                  textDecorationColor: '#B0B0B0', // Lighter color for the underline
                  cursor: 'pointer',
                }}
              >
                Visit website
              </Box>
            </Stack>
          </>
        )}
        {/* Contact Details */}
        {place && place && place.phoneNumber && (
          <>
            <Stack direction="row" alignItems="center">
              <PhoneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Link
                href={`tel:${place && place.phoneNumber}`}
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  textDecoration: 'underline',
                  textDecorationColor: '#B0B0B0',
                }}
              >
                {place.phoneNumber}
              </Link>
            </Stack>
          </>
        )}
        {/* Address */}
        {place && place && place.address && (
          <>
            <Stack direction="row" alignItems="flex-start">
              <LocationOnIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                }}
              >
                {place.address}
              </Typography>
            </Stack>
          </>
        )}
        {place?.bookingLink && (
          <>
            <Stack direction="row" alignItems="center">
              <EventTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Button
                variant="text"
                sx={theme => ({
                  fontSize: 14,
                  fontWeight: 600,

                  color: theme.palette.primary.main,
                })}
                onClick={() => {
                  openEmbeddedLinkInBrowserNewTab(place.bookingLink!);
                }}
              >
                Check availability
              </Button>
            </Stack>
          </>
        )}
        {activity?.bookingLink && (
          <>
            <Stack direction="row" alignItems="center">
              <EventTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />

              <Button
                variant="text"
                sx={theme => ({
                  fontSize: 14,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                })}
                onClick={() => {
                  openEmbeddedLinkInBrowserNewTab(
                    generateGetYourGuideUrl(activity.bookingLink!),
                  );
                }}
              >
                Check availability
              </Button>
            </Stack>
          </>
        )}
        {activity && activity.bookingLink && activity.bookingLink !== '' && (
          <>
            <Stack direction="row" alignItems="center">
              <LanguageIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon.main,
                  color: '#9E7FEF',
                })}
              />
              <Box
                onClick={() => {
                  openEmbeddedLinkInBrowserNewTab(activity!.bookingLink!);
                  gtag('event', 'public-ext-link-website');
                }}
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: 'center',
                  color: '#36454F',
                  textDecoration: 'underline',
                  textDecorationColor: '#B0B0B0', // Lighter color for the underline
                  cursor: 'pointer',
                }}
              >
                Visit website
              </Box>
            </Stack>
          </>
        )}
        {orderedHours && orderedHours.length > 0 && (
          <Box>
            <Stack direction="row">
              <WatchLaterTwoToneIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  marginRight: 0.5,
                  // color: theme.palette.icon?.main,
                  color: '#9E7FEF',
                })}
              />
              <Box>
                {orderedHours &&
                  orderedHours.map((hours, index) => (
                    <Stack direction="row" alignItems="center" key={index}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 14,
                          alignSelf: 'center',
                          color: '#36454F',
                          marginRight: 0.5,
                          minWidth: '100px', // Set a minimum width for the day
                        }}
                      >
                        {hours && hours.weekDay}:
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                          alignSelf: 'center',
                          color: '#6E7191',
                          marginRight: 0.5,
                        }}
                      >
                        {hours && formatAmPmSpacing(hours.hours)}
                      </Typography>
                    </Stack>
                  ))}
              </Box>
            </Stack>
          </Box>
        )}

        <ButtonsRow
          step={step}
          place={place}
          activity={activity}
          displayedInItinerary={false}
        />

        {step && step.images && step.images.length > 0 ? (
          <>
            <Grid container spacing={1}>
              {step.images.slice(0, 6).map((image, index) => (
                <Grid item xs={6} sm={4} key={index}>
                  <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      overflow: 'hidden', // Hide anything outside the box
                      borderRadius: '10px', // Optional: to add rounded corners
                    }}
                  >
                    <EmbeddedImageCard
                      imageUrl={image && image.path ? image.path : ''}
                      path={image && image.path ? image.path : ''}
                      imageId={image && image.id ? image.id : ''}
                      title={'Place image'}
                      index={index}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            {!place?.category?.toLowerCase().includes('car_rental') &&
              !place?.category?.toLowerCase().includes('airport') && (
                <>
                  {place && place.images && place.images.length > 0 && (
                    <Grid container spacing={1}>
                      {place.images.slice(0, 6).map((image, index) => (
                        <>
                          {image && (
                            <>
                              {(!!image.path ||
                                !!image.previewPath ||
                                !!image.mediumPath ||
                                !!image.smallPath ||
                                image.fromOwner === true) && (
                                <Grid item xs={6} sm={6} key={image!.path}>
                                  {' '}
                                  {/* Max 2 images per row */}
                                  <Box
                                    sx={{
                                      position: 'relative',
                                      width: '100%',
                                      overflow: 'hidden',
                                      borderRadius: '10px',
                                    }}
                                  >
                                    <EmbeddedImageCard
                                      imageUrl={image?.imageUrl || ''}
                                      imageId={image?.id || ''}
                                      path={
                                        image?.previewPath ||
                                        image?.mediumPath ||
                                        image?.smallPath ||
                                        image?.path ||
                                        ''
                                      }
                                      title={place?.title || 'Place image'}
                                      index={index}
                                      contributorName={
                                        image && image.title ? image.title : ''
                                      }
                                      contributorUrl={
                                        image && image.contributorId
                                          ? contributorLink(image.contributorId)
                                          : ''
                                      }
                                    />

                                    {/* Attribution Overlay */}
                                    {image?.attribution?.nickName && (
                                      <Box
                                        sx={{
                                          position: 'absolute',
                                          bottom: 0,
                                          right: 0, // Align to the right
                                          width: 'auto', // Make background fit content width
                                          maxWidth: '100%', // Prevent overflow
                                          backdropFilter: 'blur(8px)', // Blurred effect
                                          padding: '4px 8px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'flex-end',
                                          gap: '4px',
                                          color: '#fff', // White text for visibility
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          borderBottomLeftRadius: '10px',
                                          borderTopLeftRadius: '10px',
                                          borderBottomRightRadius: '10px',
                                        }}
                                      >
                                        <Avatar
                                          alt="Profile Picture"
                                          src={
                                            image.attribution
                                              .profilePictureUrl || ''
                                          }
                                          sx={{
                                            width: 18,
                                            height: 18,
                                          }}
                                        >
                                          <PersonIcon fontSize="small" />
                                        </Avatar>

                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontSize: 12,
                                            fontWeight: 500,
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            cursor: 'pointer',
                                            textDecoration: 'none',
                                          }}
                                          onClick={() => {
                                            gtag(
                                              'event',
                                              'click-attribution-map-card',
                                            );
                                            window.location.href = `/profile/${image?.attribution?.nickName}`;
                                          }}
                                        >
                                          @
                                          <span
                                            style={{
                                              textDecoration: 'underline',
                                            }}
                                          >
                                            {image.attribution.nickName}
                                          </span>
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </Grid>
                  )}
                </>
              )}

            {activity && activity.images && activity.images.length > 0 && (
              <Grid container spacing={1}>
                {activity.images.slice(0, 6).map((image, index) => (
                  <Grid item xs={6} sm={6} key={image!.path}>
                    {' '}
                    {/* Max 2 images per row */}
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        overflow: 'hidden',
                        borderRadius: '10px',
                      }}
                    >
                      <EmbeddedImageCard
                        imageUrl={image?.imageUrl || ''}
                        imageId={image?.id || ''}
                        path={
                          image?.previewPath ||
                          image?.mediumPath ||
                          image?.smallPath ||
                          image?.path ||
                          ''
                        }
                        title={place?.title || 'Place image'}
                        index={index}
                        contributorName={
                          image && image.title ? image.title : ''
                        }
                        contributorUrl={
                          image && image.contributorId
                            ? contributorLink(image.contributorId)
                            : ''
                        }
                      />

                      {/* Attribution Overlay */}
                      {image?.attribution?.nickName && (
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0, // Align to the right
                            width: 'auto', // Make background fit content width
                            maxWidth: '100%', // Prevent overflow
                            backdropFilter: 'blur(8px)', // Blurred effect
                            padding: '4px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: '4px',
                            color: '#fff', // White text for visibility
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            borderBottomLeftRadius: '10px',
                            borderTopLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                          }}
                        >
                          <Avatar
                            alt="Profile Picture"
                            src={image.attribution.profilePictureUrl || ''}
                            sx={{
                              width: 18,
                              height: 18,
                            }}
                          >
                            <PersonIcon fontSize="small" />
                          </Avatar>

                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: 12,
                              fontWeight: 500,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              cursor: 'pointer',
                              textDecoration: 'none',
                            }}
                            onClick={() => {
                              gtag('event', 'click-attribution-map-card');
                              window.location.href = `/profile/${image?.attribution?.nickName}`;
                            }}
                          >
                            @
                            <span
                              style={{
                                textDecoration: 'underline',
                              }}
                            >
                              {image.attribution.nickName}
                            </span>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
        {(step?.description ||
          place?.aiDescription ||
          place?.description ||
          activity?.aiDescription ||
          activity?.description) && (
          <>
            <Space size="sm" />
            <Divider sx={{ width: '100%', mx: 'auto' }} />
            <Space size="xs" />
          </>
        )}
        {step && step.description ? (
          <Body1>
            <RenderHtmlFromResponseNoMargin content={step.description} />
          </Body1>
        ) : (
          <>
            {place && place.aiDescription ? (
              <Body1>
                <RenderHtmlFromResponseNoMargin content={place.aiDescription} />
              </Body1>
            ) : (
              <>
                {place && place.description && (
                  <Body1>
                    <RenderHtmlFromResponseNoMargin
                      content={place.description}
                    />
                  </Body1>
                )}
              </>
            )}
            {activity && activity.aiDescription ? (
              <Body1>
                <RenderHtmlFromResponseNoMargin
                  content={activity.aiDescription}
                />
              </Body1>
            ) : (
              <>
                {activity && activity.description && (
                  <Body1>
                    <RenderHtmlFromResponseNoMargin
                      content={activity.description}
                    />
                  </Body1>
                )}
              </>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export const formatAmPmSpacing = (str: string): string => {
  if (!str) return str;

  let formattedStr = str;

  // Insert space before 'am' or 'pm' if directly preceded by a digit
  formattedStr = formattedStr.replace(/(\d)(am|pm)/gi, '$1 $2');

  // Insert space after 'am' or 'pm' if directly followed by a digit
  formattedStr = formattedStr.replace(/(am|pm)(\d)/gi, '$1 $2');

  return formattedStr;
};
