import { Box, ButtonBase, Paper } from '@mui/material';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

// import styles from './Container.module.css';

import { Handle } from './Handle';

export interface ContainerProps {
  children: React.ReactNode;
  label?: string;
  style?: React.CSSProperties;
  handleProps?: React.HTMLAttributes<any>;
  shadow?: boolean;
  onClick?(): void;
  onRemove?(): void;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      handleProps,
      onClick,
      onRemove,
      label,
      style,
      shadow,
      ...props
    },
    ref,
  ) => {
    const Component = onClick ? ButtonBase : Box; // Use ButtonBase for button behavior

    return (
      <Paper
        ref={ref}
        component={Component}
        {...props}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
        elevation={shadow ? 3 : 0} // Adds shadow dynamically
        className={classNames(shadow && 'shadow')}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          boxSizing: 'border-box',
          minWidth: 350,
          minHeight: 0,
          margin: '10px',
          borderRadius: '5px',
          transition: 'background-color 350ms ease',
          backgroundColor: 'rgba(246, 246, 246, 1)',
          border: '1px solid rgba(0, 0, 0, 0.05)',
          fontSize: '1em',
          outline: 'none',
          ...style,
        }}
      >
        {label && (
          <Box
            sx={{
              display: 'flex',
              padding: '5px 20px',
              paddingRight: '8px',
              alignItems: 'center',
              backgroundColor: '#fff',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
            }}
          >
            <Box sx={{ flex: 1, textAlign: 'left' }}>{label}</Box>
            {/* <Box sx={{ display: 'flex' }}>
              {onRemove && <Remove onClick={onRemove} />}
            </Box> */}
            <Box sx={{ marginLeft: '-12px' }}>
              <Handle {...handleProps} />
            </Box>
          </Box>
        )}
        <Box
          component="ul"
          sx={{
            // display: 'grid',
            gap: '10px',
            listStyle: 'none',
            // padding: '20px',
            margin: 0,
            paddingInlineStart: 0,
          }}
        >
          {children}
        </Box>
      </Paper>
    );
  },
);
