import React, { useEffect } from 'react';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import { ListItem, Paper, Box } from '@mui/material';
import { Handle } from './Handle';
import { Maybe, Step } from '../../../../../generated/public_graphql';

export interface Props {
  step?: Maybe<Step>;
  dragOverlay?: boolean;
  disabled?: boolean;
  dragging?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  onRemove?: () => void;
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props['transform'];
    transition: Props['transition'];
    value: Props['value'];
  }): React.ReactElement;
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        step,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay) return;

        document.body.style.cursor = 'grabbing';
        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <ListItem
          ref={ref}
          {...props}
          sx={{
            display: 'flex',
            boxSizing: 'border-box',
            transition: transition || 'all 200ms ease',
            transform: transform
              ? `translate3d(${Math.round(transform.x)}px, ${Math.round(
                  transform.y,
                )}px, 0) scale(${transform.scaleX || 1}, ${
                  transform.scaleY || 1
                })`
              : 'none',
            touchAction: 'manipulation',
            listStyle: 'none',
            opacity: dragging && !dragOverlay ? 0.5 : 1,
            animation: fadeIn ? 'fadeIn 500ms ease' : 'none',
            '--index': index,
            ...wrapperStyle,
          }}
        >
          <Paper
            elevation={dragOverlay ? 4 : 1}
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              padding: '9px 10px',
              backgroundColor: disabled ? '#f1f1f1' : '#fff',
              borderRadius: '4px',
              boxShadow:
                dragging && !dragOverlay
                  ? '0 0 6px rgba(0,0,0,0.1)'
                  : '0 0 0 rgba(63, 63, 68, 0.05)',
              color: disabled ? '#999' : '#333',
              fontWeight: 400,
              fontSize: '1rem',
              whiteSpace: 'nowrap',
              position: 'relative',
              transform: dragOverlay ? 'scale(1.05)' : 'none',
              transition:
                'box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)',
            }}
          >
            {step?.title}
            {/* {value} */}
            <Box sx={{ marginLeft: 'auto' }}>
              {/* {onRemove && (
                <Remove
                  // sx={{
                  //   visibility: 'hidden',
                  //   '&:hover': { visibility: 'visible' },
                  // }}
                  onClick={onRemove}
                />
              )} */}
            </Box>
            <Box sx={{ marginRight: '10px', cursor: 'grab' }}>
              <Handle {...handleProps} {...listeners} />
            </Box>
          </Paper>
        </ListItem>
      );
    },
  ),
);
