import { Box, Modal, styled } from '@mui/material';
import { useEffect } from 'react';

export const ModalTitle = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 22,
  marginBottom: 5,
  color: '#14142B',
  // alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    marginBottom: 5,
  },
}));

export const ModalDescription = styled(Box)(({ theme }) => ({
  marginBottom: 5,
  marginLeft: 0,
  fontWeight: 500,
  fontSize: 18,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    marginBottom: 5,
  },
}));

export const LargeModalPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  width: 700,
  borderRadius: 20,
  maxHeight: '95%',
  maxWidth: '95%',
  overflow: 'scroll',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 3, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 2),
  },
}));

export const WrapModalPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  borderRadius: '20px',
  // maxHeight: '95%',
  // maxWidth: '95%',
  // overflow: 'scroll',
  boxShadow: theme.shadows[5],
  display: 'inline-block', // Make the box wrap its content
  maxHeight: '90vh', // Limit height within viewport
  maxWidth: '90vw', // Limit width within viewport
  overflow: 'auto', // Enable scroll if content overflows
  padding: theme.spacing(2, 3, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 2),
  },
  '& iframe': {
    borderRadius: '20px',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    padding: theme.spacing(2, 3, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 2),
    },
  },
}));

export const MediumModalPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  width: 500,
  borderRadius: 20,
  maxHeight: '95%',
  maxWidth: '95%',
  overflow: 'scroll',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 2),
  },
  zIndex: 100,
}));

export const SmallModalPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  width: 300,
  borderRadius: 20,
  maxHeight: '95%',
  maxWidth: '95%',
  overflow: 'scroll',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 2),
  },
}));
export const ResponsiveModalPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  // width: 'auto', // Set width to auto to wrap around the content
  // width: "100%",
  borderRadius: 20,
  maxHeight: '95%',
  maxWidth: '95%',
  overflow: 'auto', // Ensure overflow behavior is managed when content grows
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 2),
  },
}));

export const ModalPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 3),
  // backgroundColor: theme.palette.background.paper,
  backgroundColor: '#fffaf5',
  // backgroundColor: 'rgba(255, 247, 240, 0.7)',
  borderRadius: '20px',
  boxShadow: theme.shadows[4],
  outline: 'none',
  display: 'flex',
  // display: 'block',
  maxHeight: '80vh',
  maxWidth: 520,
  flexDirection: 'column',
  alignItems: 'center', // This centers the content horizontally
  // justifyContent: 'center', // Optionally centers vertically as well if needed
  textAlign: 'center', // Ensures text is centered within the components
  width: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  // [theme.breakpoints.down('xl')]: {
  //   maxWidth: 550,
  // },
  // [theme.breakpoints.down('lg')]: {
  //   maxWidth: 550,
  // },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 550,
  },
  // [theme.breakpoints.down('sm')]: {
  //   width: '100%',
  // },
}));

export const PaymentPaper = styled(Box)(({ theme }) => ({
  // padding: theme.spacing(4, 3),
  // backgroundColor: theme.palette.background.paper,
  paddingBottom: 20,
  paddingLeft: 8,
  paddingRight: 8,
  backgroundColor: '#fffaf5',
  // backgroundColor: 'rgba(255, 247, 240, 0.7)',
  borderRadius: '20px',
  boxShadow: theme.shadows[4],
  outline: 'none',
  display: 'flex',
  // display: 'block',
  maxHeight: '80vh',
  maxWidth: 520,
  flexDirection: 'column',
  alignItems: 'center', // This centers the content horizontally
  // justifyContent: 'center', // Optionally centers vertically as well if needed
  textAlign: 'center', // Ensures text is centered within the components
  width: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  // [theme.breakpoints.down('xl')]: {
  //   maxWidth: 550,
  // },
  // [theme.breakpoints.down('lg')]: {
  //   maxWidth: 550,
  // },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 550,
  },
  // [theme.breakpoints.down('sm')]: {
  //   width: '100%',
  // },
}));

export const MoreDetailsModalPaper = styled(Box)(({ theme }) => ({
  // padding: theme.spacing(2, 3),
  backgroundColor: '#fffaf5',
  borderRadius: '20px',
  boxShadow: theme.shadows[4],
  outline: 'none',
  display: 'block',
  maxHeight: '80vh',
  maxWidth: 600,
  flexDirection: 'column',
  width: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {
    // padding: theme.spacing(2, 2),
    maxHeight: '75vh',
  },
}));

ModalPaper.defaultProps = {
  onClick: event => {
    event.stopPropagation(); // Prevent the click event from propagating
  },
};

// export const CenteredModal = styled(Modal)(({ theme }) => ({
//   // display: 'flex',
//   // flexDirection: 'column',
//   // alignSelf: 'center',
//   // alignItems: 'center',
//   // justifyContent: 'center',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   // maxHeight: '95%',

//   // maxHeight: '95%',

//   // top: '1%',
// }));

export const CenteredModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh', // Limit height to viewport height
  maxWidth: '100vw', // Limit width to viewport width
  overflowY: 'auto', // Add scroll if content exceeds the modal height
  padding: '0 16px', // Optional padding to avoid edge cuts
  zIndex: 10000000,
}));

CenteredModal.defaultProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => {
    // event.preventDefault();
    event.stopPropagation(); // Prevent the click event from propagating
  },
  onWheel: (event: React.WheelEvent<HTMLElement>) => {
    // event.preventDefault();
    event.stopPropagation(); // Prevent the wheel (scroll) event from propagating
  },
  onTouchMove: (event: React.TouchEvent<HTMLElement>) => {
    // event.preventDefault();
    event.stopPropagation(); // Prevent the touch move (swipe) event from propagating
  },
  disableScrollLock: false,
};
