// // // import React, { useRef, useEffect } from 'react';
// // // import maplibregl from 'maplibre-gl';
// // // import 'maplibre-gl/dist/maplibre-gl.css';
// // // import LocationOnIcon from '@mui/icons-material/LocationOn'; // MUI Icon
// // // import { createRoot } from 'react-dom/client'; // For React-based markers
// // // import { Button } from '@mui/material';

// // // const ExploreMap = () => {
// // //   const mapContainerRef = useRef<HTMLDivElement | null>(null);
// // //   const mapRef = useRef<maplibregl.Map | null>(null);

// // //   useEffect(() => {
// // //     if (mapRef.current || !mapContainerRef.current) return;

// // //     console.log('🗺 Initializing MapLibre...');

// // //     // ✅ Initialize Map
// // //     mapRef.current = new maplibregl.Map({
// // //       container: mapContainerRef.current,
// // //       style: '/map-style.json',
// // //       center: [-77.0353, 38.8895], // Washington Monument
// // //       zoom: 14,
// // //     });

// // //     mapRef.current.addControl(new maplibregl.NavigationControl(), 'top-right');

// // //     mapRef.current.on('load', () => {
// // //       console.log('✅ Map has loaded!');

// // //       // ✅ Create a custom MUI marker
// // //       const markerContainer = document.createElement('div');
// // //       const root = createRoot(markerContainer);
// // //       root.render(
// // //         <LocationOnIcon
// // //           sx={{
// // //             fontSize: 40, // Bigger size
// // //             color: 'red', // Pin color
// // //             filter: 'drop-shadow(0px 0px 5px rgba(0,0,0,0.5))', // Shadow
// // //           }}
// // //         />,
// // //       );

// // //       const popupContainer = document.createElement('div');
// // //       const popupRoot = createRoot(popupContainer);
// // //       popupRoot.render(
// // //         <div style={{ padding: '10px', textAlign: 'center' }}>
// // //           <p>
// // //             <strong>Washington Monument</strong>
// // //           </p>
// // //           <p>Iconic obelisk in Washington, D.C.</p>
// // //           <Button
// // //             variant="contained"
// // //             color="primary"
// // //             onClick={() => alert('More info coming soon!')}
// // //             sx={{ marginTop: '8px' }}
// // //           >
// // //             More Info
// // //           </Button>
// // //         </div>,
// // //       );

// // //       // ✅ Add Marker to Map
// // //       new maplibregl.Marker({ element: markerContainer })
// // //         .setLngLat([-77.0353, 38.8895])
// // //         .setPopup(new maplibregl.Popup().setDOMContent(popupContainer)) // 👈 Use setDOMContent() for React UI

// // //         .addTo(mapRef.current!);
// // //     });

// // //     return () => {
// // //       console.log('🛑 Cleaning up map...');
// // //       mapRef.current?.remove();
// // //       mapRef.current = null;
// // //     };
// // //   }, []);

// // //   return (
// // //     <div ref={mapContainerRef} style={{ width: '100%', height: '400px' }} />
// // //   );
// // // };

// // // export default ExploreMap;
// // import React, { useRef, useEffect } from 'react';
// // import maplibregl from 'maplibre-gl';
// // import 'maplibre-gl/dist/maplibre-gl.css';
// // import LocationOnIcon from '@mui/icons-material/LocationOn'; // MUI Icon
// // import { createRoot } from 'react-dom/client';
// // import { Button } from '@mui/material';
// // import { CountryTrips } from '../../generated/public_graphql';

// // // Mock city coordinates (Replace with API call)
// // const cityCoordinates: Record<string, { lat: number; lng: number }> = {
// //   London: { lat: 51.5074, lng: -0.1278 },
// //   Manchester: { lat: 53.4808, lng: -2.2426 },
// //   Edinburgh: { lat: 55.9533, lng: -3.1883 },
// // };

// // // Mocked data structure
// // const tripData = {
// //   data: {
// //     countriesTrips: [
// //       {
// //         country: { iso2Code: 'GB', name: 'United Kingdom' },
// //         countryTrips: [
// //           {
// //             cities: [
// //               { id: 'city_1', name: 'London' },
// //               { id: 'city_5', name: 'London' },
// //               { id: 'city_6', name: 'London' },
// //               { id: 'city_2', name: 'Manchester' },
// //               { id: 'city_3', name: 'Edinburgh' },
// //               { id: 'city_4', name: 'Liverpool' }, // Will be ignored (max 3 cities)
// //             ],
// //           },
// //         ],
// //       },
// //     ],
// //   },
// // };

// // // Function to slightly offset markers if they overlap
// // const getOffsetCoords = (lat: number, lng: number, index: number) => {
// //   const offset = 0.005 * index; // Slightly move each duplicate
// //   return { lat: lat + offset, lng: lng + offset };
// // };

// // interface ExploreMapProps {
// //   countriesTrips: CountryTrips[];
// // }

// // const ExploreMap = ({ countriesTrips }: ExploreMapProps) => {
// //   const mapContainerRef = useRef<HTMLDivElement | null>(null);
// //   const mapRef = useRef<maplibregl.Map | null>(null);

// //   useEffect(() => {
// //     if (mapRef.current || !mapContainerRef.current) return;

// //     console.log('🗺 Initializing MapLibre...');

// //     // ✅ Initialize Map
// //     mapRef.current = new maplibregl.Map({
// //       container: mapContainerRef.current,
// //       style: '/map-style.json',
// //       center: [-0.1278, 51.5074], // Default center: London
// //       zoom: 5,
// //     });

// //     mapRef.current.addControl(new maplibregl.NavigationControl(), 'top-right');

// //     mapRef.current.on('load', () => {
// //       console.log('✅ Map has loaded!');

// //       // Extract max 3 cities per trip
// //       const trips = tripData.data.countriesTrips.flatMap(countryTrip =>
// //         countryTrip.countryTrips.flatMap(trip => trip.cities.slice(0, 3)),
// //       );

// //       // ✅ Add markers for cities
// //       trips.forEach((city, index) => {
// //         const coords = cityCoordinates[city.name];
// //         if (!coords) return;

// //         const { lat, lng } = getOffsetCoords(coords.lat, coords.lng, index);

// //         // ✅ Create custom MUI marker
// //         const markerContainer = document.createElement('div');
// //         const root = createRoot(markerContainer);
// //         root.render(
// //           <LocationOnIcon
// //             sx={{
// //               fontSize: 40,
// //               color: 'red',
// //               filter: 'drop-shadow(0px 0px 5px rgba(0,0,0,0.5))',
// //             }}
// //           />,
// //         );

// //         // ✅ Create popup with MUI button
// //         const popupContainer = document.createElement('div');
// //         const popupRoot = createRoot(popupContainer);
// //         popupRoot.render(
// //           <div style={{ padding: '10px', textAlign: 'center' }}>
// //             <p>
// //               <strong>{city.name}</strong>
// //             </p>
// //             <Button
// //               variant="contained"
// //               color="primary"
// //               onClick={() => alert(`Exploring ${city.name}!`)}
// //               sx={{ marginTop: '8px' }}
// //             >
// //               More Info
// //             </Button>
// //           </div>,
// //         );

// //         // ✅ Add Marker to Map
// //         new maplibregl.Marker({ element: markerContainer })
// //           .setLngLat([lng, lat])
// //           .setPopup(new maplibregl.Popup().setDOMContent(popupContainer))
// //           .addTo(mapRef.current!);
// //       });
// //     });

// //     return () => {
// //       console.log('🛑 Cleaning up map...');
// //       mapRef.current?.remove();
// //       mapRef.current = null;
// //     };
// //   }, []);

// //   return (
// //     <div ref={mapContainerRef} style={{ width: '100%', height: '500px' }} />
// //   );
// // };

// // export default ExploreMap;
// import React, { useRef, useEffect } from 'react';
// import maplibregl from 'maplibre-gl';
// import 'maplibre-gl/dist/maplibre-gl.css';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import { createRoot } from 'react-dom/client';
// import { Button } from '@mui/material';
// import { CountryTrips } from '../../generated/public_graphql';

// // interface City {
// //   id: string;
// //   name: string;
// //   latitude: number;
// //   longitude: number;
// // }

// // interface Trip {
// //   cities: City[];
// // }

// // interface CountryTrips {
// //   countryTrips: Trip[];
// // }

// interface ExploreMapProps {
//   countriesTrips: CountryTrips[];
// }

// const ExploreMap: React.FC<ExploreMapProps> = ({ countriesTrips }) => {
//   const mapContainerRef = useRef<HTMLDivElement | null>(null);
//   const mapRef = useRef<maplibregl.Map | null>(null);

//   useEffect(() => {
//     if (mapRef.current || !mapContainerRef.current) return;

//     console.log('🗺 Initializing MapLibre...');

//     mapRef.current = new maplibregl.Map({
//       container: mapContainerRef.current,
//       style: '/map-style.json',
//       center: [-0.1278, 51.5074], // Default: London
//       zoom: 5,
//     });

//     mapRef.current.addControl(new maplibregl.NavigationControl(), 'top-right');

//     mapRef.current.on('load', () => {
//       console.log('✅ Map has loaded!');

//       // Extract max 3 cities per trip and prevent duplicates
//       const cityMarkers = new Map<string, { lat: number; lng: number }>();
//       const trips = countriesTrips.flatMap(
//         countryTrip =>
//           countryTrip.countryTrips?.flatMap(
//             trip => trip?.cities?.slice(0, 3) ?? [],
//           ) ?? [],
//       );

//       trips.forEach((city, index) => {
//         if (
//           !city?.id ||
//           !city?.latitude ||
//           !city?.longitude ||
//           cityMarkers.has(city.id)
//         )
//           return;

//         cityMarkers.set(city.id, { lat: city.latitude, lng: city.longitude });

//         // Spread markers slightly if they overlap
//         const { lat, lng } = getOffsetCoords(
//           city.latitude,
//           city.longitude,
//           index,
//         );

//         // ✅ Create custom MUI marker
//         const markerContainer = document.createElement('div');
//         const root = createRoot(markerContainer);
//         root.render(
//           <LocationOnIcon
//             sx={{
//               fontSize: 40,
//               color: 'red',
//               filter: 'drop-shadow(0px 0px 5px rgba(0,0,0,0.5))',
//             }}
//           />,
//         );

//         // ✅ Create popup with MUI button
//         const popupContainer = document.createElement('div');
//         const popupRoot = createRoot(popupContainer);
//         popupRoot.render(
//           <div style={{ padding: '10px', textAlign: 'center' }}>
//             <p>
//               <strong>{city.name}</strong>
//             </p>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => alert(`Exploring ${city.name}!`)}
//               sx={{ marginTop: '8px' }}
//             >
//               More Info
//             </Button>
//           </div>,
//         );

//         // ✅ Add Marker to Map
//         new maplibregl.Marker({ element: markerContainer })
//           .setLngLat([lng, lat])
//           .setPopup(new maplibregl.Popup().setDOMContent(popupContainer))
//           .addTo(mapRef.current!);
//       });
//     });

//     return () => {
//       console.log('🛑 Cleaning up map...');
//       mapRef.current?.remove();
//       mapRef.current = null;
//     };
//   }, [countriesTrips]);

//   return (
//     <div ref={mapContainerRef} style={{ width: '100%', height: '500px' }} />
//   );
// };

// // ✅ Utility to spread overlapping markers slightly
// const getOffsetCoords = (lat: number, lng: number, index: number) => {
//   const offset = 0.01 * (index % 3); // Slight shift
//   return { lat: lat + offset, lng: lng + offset };
// };

// export default ExploreMap;
import React, { useRef, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { createRoot } from 'react-dom/client';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { CountryTrips } from '../../generated/public_graphql';
import { ContainedUserProfile } from '../trip-details/TripDetails';
import { Provider } from 'react-redux';
import ErrorModal from '../Error';
import { store } from '../..';
import ErrorBoundary from '../ErrorBoundary';
import { AuthProvider } from '../auth/AuthWrapper';
import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { AppWrapper } from '../AppWrapper';
import { useNavigate } from 'react-router-dom';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';

import { BrowserRouter as Router } from 'react-router-dom';
import { City, Country, Trip } from '../../generated/user_graphql';
import CircleIcon from '@mui/icons-material/Circle';

interface ExploreMapProps {
  countriesTrips: CountryTrips[];
}

const ExploreMap: React.FC<ExploreMapProps> = ({ countriesTrips }) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<maplibregl.Map | null>(null);

  useEffect(() => {
    if (mapRef.current || !mapContainerRef.current) return;

    console.log('🗺 Initializing MapLibre...');

    mapRef.current = new maplibregl.Map({
      container: mapContainerRef.current,
      style: '/map-style.json',
      center: [12.689272076483228, 24.841175269041337], // Default: London
      zoom: 1.5,
    });

    mapRef.current.addControl(new maplibregl.NavigationControl(), 'top-right');

    mapRef.current.on('load', () => {
      const grouped = getUniqueCountryTripPairs(countriesTrips);
      grouped.forEach(({ country, trips, coordinates }, index) => {
        if (!coordinates?.latitude || !coordinates?.latitude) return;

        // ✅ Create custom MUI marker
        const markerContainer = document.createElement('div');
        const root = createRoot(markerContainer);
        root.render(
          <CircleIcon
            sx={{
              fontSize: 20,
              color: 'red',
              filter: 'drop-shadow(0px 0px 5px rgba(0,0,0,0.5))',
            }}
          />,
        );

        const popupContainer = document.createElement('div');

        // Apply styles to the container (max height and scroll)
        popupContainer.style.maxHeight = '300px'; // Set the max height as needed
        popupContainer.style.overflowY = 'auto';
        // const popupContainer = document.createElement('div');
        const popupRoot = createRoot(popupContainer);
        popupRoot.render(
          <Stack direction={'column'} spacing={1}>
            {trips.map(trip => (
              <Box
                key={trip.id}
                sx={{
                  border: '1px solid #E0E0E0',
                  borderRadius: 2,
                  padding: 1,
                }}
                onClick={() => {
                  window.location.href = `/trip/${trip.id}`;
                }}
              >
                <Provider store={store}>
                  <ErrorModal />
                  <ErrorBoundary>
                    <Router>
                      <AuthProvider>
                        <AuthorizedApolloProvider>
                          <AppWrapper>
                            <Typography
                              sx={{
                                marginBottom: 0.5,
                                fontSize: 14,
                                fontWeight: 600,
                                color: '#001B30',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                              }}
                            >
                              {trip && trip.name}
                            </Typography>
                            {trip && trip.userDetails && (
                              <ContainedUserProfile
                                tripUser={trip.userDetails}
                                renderSmaller={true}
                              />
                            )}
                            {/* <Typography variant="subtitle1" fontWeight="bold">
                          {trip.tripType && trip.tripType === 'guide'
                            ? 'Guide'
                            : 'itinerary'}
                        </Typography> */}
                            {trip &&
                            trip.tripType &&
                            trip.tripType === 'guide' ? (
                              <></>
                            ) : (
                              <Stack direction={'row'} alignItems={'center'}>
                                <HistoryToggleOffTwoToneIcon
                                  sx={theme => ({
                                    width: 16,
                                    height: 16,
                                    marginRight: 0.5,
                                    color: '#F39C6A',
                                    [theme.breakpoints.down('sm')]: {},
                                  })}
                                />
                                {trip && trip.length && trip.length > 0 && (
                                  <Typography
                                    sx={theme => ({
                                      fontWeight: 500,
                                      fontSize: 12,
                                      color: '#36454F',
                                      [theme.breakpoints.down('sm')]: {
                                        fontSize: 12,
                                      },
                                    })}
                                  >
                                    {trip.length}
                                    {trip.length === 1 ? ' day ' : ' days '}
                                    itinerary
                                  </Typography>
                                )}
                              </Stack>
                            )}
                            {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            (window.location.href = `/trip/${trip.id}`)
                          }
                          sx={{
                            mt: 1, // Margin top for spacing
                            borderRadius: 2, // Rounded button corners
                            textTransform: 'none', // Optional: Prevent uppercase text
                          }}
                        >
                          More Info
                        </Button> */}
                            <Link
                              onClick={() => {
                                // openLinkInBrowserNewTab(bookingLink!);
                                gtag('event', 'public-link-navigate-click');
                                window.location.href = `/trip/${trip.id}`;
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={theme => ({
                                  width: '100%',

                                  fontWeight: 600,
                                  fontSize: 14,
                                  borderRadius: '20px',
                                  paddingY: 0,
                                  paddingX: 2,
                                  alignSelf: 'center',
                                  // color: '#001B2F',
                                  // backgroundColor: '#E1D9FA',
                                  backgroundColor: theme.palette.primary.main,
                                  whiteSpace: 'nowrap',
                                  marginTop: 1,
                                  marginRight: 1,
                                })}
                              >
                                {trip.tripType && trip.tripType === 'guide'
                                  ? 'Open Guide'
                                  : 'Open Itinerary'}
                              </Button>
                            </Link>
                          </AppWrapper>
                        </AuthorizedApolloProvider>
                      </AuthProvider>
                    </Router>
                  </ErrorBoundary>
                </Provider>
              </Box>
            ))}
          </Stack>,
        );

        new maplibregl.Marker({ element: markerContainer })
          .setLngLat([coordinates.longitude, coordinates.latitude])
          .setPopup(
            new maplibregl.Popup({
              offset: 15, // Offset to avoid overlap with marker
              closeButton: false, // Optional: Disable the close button in the popup
              closeOnClick: true, // Optional: Close popup on map click
              className: 'custom-popup', // Apply custom CSS class
            }).setDOMContent(popupContainer),
          )
          .addTo(mapRef.current!);
      });
    });

    return () => {
      mapRef.current?.remove();
      mapRef.current = null;
    };
  }, [countriesTrips]);

  return (
    <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
  );
};

// ✅ Utility to spread overlapping markers slightly
const getOffsetCoords = (lat: number, lng: number, index: number) => {
  const angle = index * 137.5; // Golden angle for even spreading
  const radius = 0.0005 * Math.sqrt(index); // Increase radius as index grows

  const offsetLat = lat + radius * Math.cos(angle);
  const offsetLng = lng + radius * Math.sin(angle);

  return { lat: offsetLat, lng: offsetLng };
};

// const getFarthestCities = (
//   cities: { city: City; trip: Trip; lat: number; lng: number }[],
//   count = 3,
// ) => {
//   if (cities.length <= count) return cities; // If we have 3 or fewer cities, return all

//   const selectedCities: typeof cities = [cities[0]]; // Start with the first city

//   for (let i = 1; i < count; i++) {
//     let farthestCity = null;
//     let maxDistance = -1;

//     for (const city of cities) {
//       if (selectedCities.some(selected => selected.id === city.id)) continue;

//       // Calculate min distance to already selected cities
//       const minDist = Math.min(
//         ...selectedCities.map(selected => haversineDistance(selected, city)),
//       );

//       if (minDist > maxDistance) {
//         maxDistance = minDist;
//         farthestCity = city;
//       }
//     }

//     if (farthestCity) selectedCities.push(farthestCity);
//   }

//   return selectedCities;
// };

// const getFarthestCities = (
//   cities: { city: City; trips: Trip[]; lat: number; lng: number }[],
//   count = 3,
// ) => {
//   if (cities.length <= count) return cities; // If we have 3 or fewer cities, return all

//   const selectedCities: typeof cities = [cities[0]]; // Start with the first city-trip pair

//   for (let i = 1; i < count; i++) {
//     let farthestCity: (typeof cities)[number] | null = null;
//     let maxDistance = -1;

//     for (const cityPair of cities) {
//       if (
//         selectedCities.some(selected => selected.city.id === cityPair.city.id)
//       )
//         continue;

//       // Calculate min distance to already selected cities
//       const minDist = Math.min(
//         ...selectedCities.map(selected =>
//           haversineDistance(selected, cityPair),
//         ),
//       );

//       if (minDist > maxDistance) {
//         maxDistance = minDist;
//         farthestCity = cityPair;
//       }
//     }

//     if (farthestCity) selectedCities.push(farthestCity);
//   }

//   return selectedCities;
// };

// // Haversine formula to calculate distance between two coordinates
// interface ExploreMapProps {
//   countriesTrips: CountryTrips[];
// }

// type CountryTrips = {
//   country?: Country | null;
//   countryTrips?: (Trip | null)[] | null;
// };

// type Trip = {
//   id: string;
//   name: string;
//   cities?: (City | null)[] | null;
// };

// type City = {
//   id: string;
//   name: string;
//   latitude: number;
//   longitude: number;
// };

// 🏆 Function to get cleaned city-trip pairs
const getUniqueCityTripPairs = (countriesTrips: CountryTrips[]) => {
  const cityTripPairs: { city: City; trip: Trip }[] = [];

  // 1️⃣ Collect all (city, trip) pairs
  countriesTrips.forEach(countryTrip => {
    countryTrip.countryTrips?.forEach(trip => {
      trip?.cities?.forEach(city => {
        if (city && city.id && city.latitude && city.longitude) {
          cityTripPairs.push({ city, trip });
        }
      });
    });
  });

  // 2️⃣ Remove duplicate city-trip pairs (same trip, same city)
  const uniquePairs = new Map<string, { city: City; trip: Trip }>();
  cityTripPairs.forEach(pair => {
    const key = `${pair.trip.id}-${pair.city.id}`;
    uniquePairs.set(key, pair);
  });

  // 3️⃣ Group by trip and pick up to 3 farthest cities
  const tripCityMap = new Map<string, { city: City; trip: Trip }[]>();

  Array.from(uniquePairs.values()).forEach(({ city, trip }) => {
    if (!tripCityMap.has(trip.id)) {
      tripCityMap.set(trip.id, []);
    }
    tripCityMap.get(trip.id)!.push({ city, trip });
  });

  const cleanedPairs: { city: City; trip: Trip }[] = [];

  tripCityMap.forEach(cityPairs => {
    if (cityPairs.length <= 3) {
      // If 3 or fewer, keep them all
      cleanedPairs.push(...cityPairs);
    } else {
      // Pick 3 farthest cities
      const selectedCities = getFarthestCities(cityPairs, 3);
      cleanedPairs.push(...selectedCities);
    }
  });

  return cleanedPairs;
};

// 📌 Function to pick the 3 farthest cities
const getFarthestCities = (cities: { city: City; trip: Trip }[], count = 3) => {
  if (cities.length <= count) return cities;

  const selectedCities: typeof cities = [];

  // Step 1: Find the first farthest city from the first one
  const firstCity = cities[0];
  let secondCity: typeof firstCity | null = null;
  let maxDistance = -1;

  for (const cityPair of cities) {
    const dist = haversineDistance(firstCity.city, cityPair.city);
    if (dist > maxDistance) {
      maxDistance = dist;
      secondCity = cityPair;
    }
  }

  if (secondCity) {
    selectedCities.push(firstCity, secondCity);
  } else {
    return [firstCity];
  }

  // Step 2: Find a third city that is farthest from the first two
  let thirdCity: typeof firstCity | null = null;
  maxDistance = -1;

  for (const cityPair of cities) {
    if (
      cityPair.city.id === firstCity.city.id ||
      cityPair.city.id === secondCity?.city.id
    )
      continue;

    const dist = Math.min(
      haversineDistance(firstCity.city, cityPair.city),
      haversineDistance(secondCity!.city, cityPair.city),
    );

    if (dist > maxDistance) {
      maxDistance = dist;
      thirdCity = cityPair;
    }
  }

  if (thirdCity) {
    selectedCities.push(thirdCity);
  }

  return selectedCities;
};

// 📌 Function to calculate Haversine distance between two cities
const haversineDistance = (city1: City, city2: City): number => {
  const toRad = (deg: number) => (deg * Math.PI) / 180;
  const R = 6371; // Earth radius in km
  const dLat = toRad(city2.latitude! - city1.latitude!);
  const dLng = toRad(city2.longitude! - city1.longitude!);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(city1.latitude!)) *
      Math.cos(toRad(city2.latitude!)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in km
};

export default ExploreMap;

// 🏆 Function to get country-trip pairs with the farthest coordinates based on all trips within the country
const getUniqueCountryTripPairs = (countriesTrips: CountryTrips[]) => {
  const countryTripPairs: {
    country: Country;
    trip: Trip;
    coordinates: { latitude: number; longitude: number }[];
  }[] = [];

  // 1️⃣ Collect all (country, trip) pairs with coordinates
  countriesTrips.forEach(countryTrip => {
    countryTrip.countryTrips?.forEach(trip => {
      const cityCoordinates: { latitude: number; longitude: number }[] = [];

      // Collect coordinates from all cities in the trip
      trip?.cities?.forEach(city => {
        if (city && city.latitude && city.longitude) {
          cityCoordinates.push({
            latitude: city.latitude,
            longitude: city.longitude,
          });
        }
      });

      // Add the country-trip pair with coordinates
      if (cityCoordinates.length > 0 && countryTrip.country && trip) {
        countryTripPairs.push({
          country: countryTrip.country as Country,
          trip: trip as Trip,
          coordinates: cityCoordinates,
        });
      }
    });
  });

  // 2️⃣ Remove duplicate country-trip pairs (same trip, same country)
  const uniqueCountryTrips = new Map<
    string,
    {
      country: Country;
      trip: Trip;
      coordinates: { latitude: number; longitude: number }[];
    }
  >();

  countryTripPairs.forEach(pair => {
    const key = `${pair.trip.id}-${pair.country.id}`;
    uniqueCountryTrips.set(key, pair);
  });

  // 3️⃣ Group by country and gather all coordinates for all trips in the country
  const countryCoordinatesMap: Map<
    string,
    {
      country: Country;
      trip: Trip;
      coordinates: { latitude: number; longitude: number }[];
    }[]
  > = new Map();

  Array.from(uniqueCountryTrips.values()).forEach(
    ({ country, trip, coordinates }) => {
      if (!countryCoordinatesMap.has(country.id)) {
        countryCoordinatesMap.set(country.id, []);
      }

      // Add coordinates for all trips of the country
      countryCoordinatesMap.get(country.id)!.push({
        country,
        trip,
        coordinates,
      });
    },
  );

  const finalCountryTripPairs: {
    country: Country;
    trips: Trip[];
    coordinates: { latitude: number; longitude: number };
  }[] = [];

  countryCoordinatesMap.forEach(countryTrips => {
    // Flatten all coordinates of all trips in the country into a single array
    const allCoordinates: { latitude: number; longitude: number }[] = [];

    countryTrips.forEach(pair => {
      allCoordinates.push(...pair.coordinates);
    });

    // Get the 3 farthest coordinates (if available)
    const farthestCoordinates = getFarthestCoordinates(allCoordinates, 3);

    // For each farthest coordinate, group all trips together under that coordinate
    farthestCoordinates.forEach(farthestCoord => {
      finalCountryTripPairs.push({
        country: countryTrips[0].country, // Use the country from the first trip entry (all trips belong to the same country)
        trips: countryTrips.map(pair => pair.trip), // Group all trips together
        coordinates: farthestCoord,
      });
    });
  });

  return finalCountryTripPairs;
};

// Helper function to calculate the farthest coordinates
const getFarthestCoordinates = (
  coordinates: { latitude: number; longitude: number }[],
  maxCoordinates: number,
): { latitude: number; longitude: number }[] => {
  const farthestCoordinates: { latitude: number; longitude: number }[] = [];

  // If there are fewer than maxCoordinates, return all coordinates
  if (coordinates.length <= maxCoordinates) {
    return coordinates;
  }

  // Sort by distance and pick the 3 farthest points
  const distanceCalculator = (
    a: { latitude: number; longitude: number },
    b: { latitude: number; longitude: number },
  ) => {
    // Haversine formula to calculate the distance between two points on Earth
    const R = 6371; // Earth radius in km
    const lat1 = a.latitude * (Math.PI / 180);
    const lon1 = a.longitude * (Math.PI / 180);
    const lat2 = b.latitude * (Math.PI / 180);
    const lon2 = b.longitude * (Math.PI / 180);

    const dlat = lat2 - lat1;
    const dlon = lon2 - lon1;
    const aVal =
      Math.sin(dlat / 2) * Math.sin(dlat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) * Math.sin(dlon / 2);
    const c = 2 * Math.atan2(Math.sqrt(aVal), Math.sqrt(1 - aVal));

    return R * c; // Return distance in km
  };

  // Sort coordinates based on distance and select the farthest ones
  const sortedCoordinates = coordinates.sort((a, b) => {
    let totalDistance = 0;
    coordinates.forEach(coord => {
      totalDistance +=
        distanceCalculator(a, coord) - distanceCalculator(b, coord);
    });
    return totalDistance;
  });

  // Pick the 3 farthest points
  return sortedCoordinates.slice(0, maxCoordinates);
};
