import { useQuery } from '@apollo/client';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import TourTwoToneIcon from '@mui/icons-material/TourTwoTone';
import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { Trip } from '../../generated/public_graphql';
import { errorsSelector } from '../../store/ErrorSlice';
import { CardLabel } from '../../theme-components/Lables';
import { isAdmin, isPublisher, useUserRoles } from '../../utils/helpers';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { Header } from '../AppWrapper';
import CreateTripModal from '../create-trip/manage-trip-modals/CreateTripModal';
import ErrorModal from '../Error';
import { LikeTrip } from '../favourite/likeTrip';
import { QUERY_GET_USER_SHARED_TRIPS } from '../gql-user/userSharedTripsQuery';
import { QUERY_GET_USER_TRIPS } from '../gql-user/userTripsQuery';
import { TravelSvg, TravelSvgWithBackground } from '../illustrations/Travel';

import ShareTripModal from './ShareTripModal';
import UnpublishTripModal from './UnpublishTripModal';
import { ContainedUserProfile } from '../trip-details/TripDetails';

interface PropsTripsList {
  readonly trips: Maybe<Trip>[];
}

export function UserTrips() {
  const { loading, error, data } = useQuery(QUERY_GET_USER_TRIPS, {
    fetchPolicy: 'network-only',
  });
  const {
    loading: loadingShared,
    error: errorShared,
    data: dataShared,
  } = useQuery(QUERY_GET_USER_SHARED_TRIPS, {
    fetchPolicy: 'network-only',
  });

  const { errorModalOpen } = useSelector(errorsSelector);

  if (loading || loadingShared) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingLeft: 0.5,
        paddingRight: 0.5,
        [theme.breakpoints.down('sm')]: {
          paddingTop: 1,
        },
      })}
    >
      {errorModalOpen && <ErrorModal />}
      <Stack
        direction="column"
        width="100%"
        sx={theme => ({
          borderRadius: '20px',
          // background: 'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
          border: '2px solid rgba(211, 211, 211, 0.4)',
          // paddingRight: 1,
          paddingY: 1,
          // marginTop: 1.5,
          paddingX: 2,
          marginBottom: 1.5,
          backgroundColor: '#FFF8EE',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
          [theme.breakpoints.down('sm')]: {
            paddingRight: 1.5,
            paddingY: 1,
            marginTop: 1.5,
            paddingX: 1.5,
            marginBottom: 1.5,
            // marginLeft: 1,
            // marginRight: 1,
          },
        })}
      >
        {errorShared || !dataShared ? (
          <div></div>
        ) : (
          <>
            {data &&
              data.userTripsList &&
              data.userTripsList.length === 0 &&
              dataShared.userSharedTripsList.length > 0 && (
                <>
                  <Header>Shared with me</Header>
                  {/* <Divider /> */}
                  <SharedTripsWithUser
                    trips={dataShared.userSharedTripsList}
                    publicTrip={false}
                  />
                </>
              )}
          </>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
        >
          <Header>My trips</Header>
          {data && data.userTripsList && data.userTripsList.length > 3 && (
            <Stack direction={'row'} spacing={2}>
              <CreateTripModal buttonType="outlined" />
              {/* <CreateTripModal type="guide" buttonType="text" /> */}
            </Stack>
          )}
        </Box>

        {error || !data ? (
          <div>
            Error loading your trips, reload or contact help@rooutie.com
          </div>
        ) : (
          <>
            {data &&
            data.userTripsList &&
            data.userTripsList.length === 0 &&
            dataShared.userSharedTripsList.length > 0 ? (
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={'center'}
                alignItems="center"
                pt={2}
                pb={2}
                width={'100%'}
              >
                <CreateTripModal buttonType="outlined" />
              </Stack>
            ) : (
              <CreatedTrips trips={data.userTripsList} />
            )}
          </>
        )}

        {errorShared || !dataShared ? (
          <div>
            Error loading shared trips reload or contact help@rooutie.com
          </div>
        ) : (
          <>
            {data &&
              data.userTripsList &&
              data.userTripsList.length > 0 &&
              dataShared.userSharedTripsList.length > 0 && (
                <>
                  <Header>Shared with me</Header>
                  {/* <Divider /> */}
                  <SharedTripsWithUser
                    trips={dataShared.userSharedTripsList}
                    publicTrip={false}
                  />
                </>
              )}
          </>
        )}
      </Stack>
    </Box>
  );
}

function CreatedTrips(props: PropsTripsList) {
  const navigate = useNavigate();
  const roles = useUserRoles();
  const [needsUnpublishing, setNeedsUnpublishing] = useState(false);
  const [selectedTripId, setSelectedTripId] = useState('');

  return (
    // This is to counter the offset of individual itmes right padding
    <Box
      mb={2}
      sx={theme => ({
        width: 'calc(100% + 16px)',
        // [theme.breakpoints.down('sm')]: {
        //   width: '100%',
        // },
      })}
    >
      {props.trips && props.trips.length > 0 ? (
        <>
          <Grid container sx={{ flexGrow: 1, display: 'flex', width: '100%' }}>
            {props.trips.map((trip, index) => (
              <React.Fragment key={index}>
                {trip && (
                  <Box
                    sx={theme => ({
                      cursor: 'pointer',
                      marginTop: 2,
                      marginBottom: 1,
                      paddingRight: 2,
                      width: '33%',

                      [theme.breakpoints.down('xl')]: {
                        width: '33.3%',
                      },
                      [theme.breakpoints.down('lg')]: {
                        width: '50%',
                      },
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      },
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                      },
                    })}
                  >
                    <Stack
                      key={index + trip.id}
                      direction="column"
                      sx={theme => ({
                        cursor: 'pointer',
                        height: '100%',
                        borderRadius: '20px',
                        backgroundColor: 'rgba(250, 250, 250, 0.6)',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                      })}
                    >
                      <Box
                        onClick={() => {
                          if (trip.published === false) {
                            navigate('/editor/' + trip.id);
                          } else if (isAdmin(roles) || isPublisher(roles)) {
                            navigate('/editor/' + trip.id);
                          } else {
                            setNeedsUnpublishing(true);
                            setSelectedTripId(trip.id);
                          }
                          gtag('event', 'user-trip-edit-card');
                        }}
                        key={index + trip.id}
                        sx={theme => ({
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 1,
                          // borderRadius: '20px',
                          // borderTopLeftRadius: '20px',
                          // borderTopRightRadius: '20px',
                          // borderBottomLeftRadius: !props.publicTrip ? 0 : '20px',
                          // borderBottomRightRadius: !props.publicTrip ? 0 : '20px',
                        })}
                      >
                        <Box sx={{ height: '230px' }}>
                          {trip.images && trip.images.length > 0 ? (
                            <CardMedia
                              component="img"
                              height="100%" // Increased height to accommodate the card content
                              sx={{
                                // borderRadius: '20px',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                                //     borderBottomLeftRadius: !props.publicTrip
                                //       ? 0
                                //       : '20px',
                                //     borderBottomRightRadius: !props.publicTrip
                                //       ? 0
                                //       : '20px',
                              }}
                              image={
                                trip.images?.find(image => image!.main)
                                  ?.mediumPath ||
                                trip.images?.find(image => image!.main)
                                  ?.previewPath ||
                                trip.images?.find(image => image!.main)?.path ||
                                trip.images?.find(image => image!.main)
                                  ?.smallPath ||
                                trip.images[0]?.mediumPath ||
                                trip.images[0]?.previewPath ||
                                trip.images[0]?.path ||
                                trip.images[0]?.smallPath ||
                                ''
                              }
                            />
                          ) : (
                            <Box
                              sx={theme => ({
                                // borderRadius: '20px',
                                // backgroundColor: '#d3d3d3',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                                // borderBottomLeftRadius: !props.publicTrip
                                //   ? 0
                                //   : '20px',
                                // borderBottomRightRadius: !props.publicTrip
                                //   ? 0
                                //   : '20px',
                                // padding: '25px',
                                // overflow: 'hidden',
                                backgroundColor: '#d3d3d3',
                                // borderRadius: '20px',
                                overflow: 'hidden',
                                // position: 'relative',
                                width: '100%',
                                height: '100%',
                                paddingTop: '15px',
                                paddingBottom: '20px',
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                [theme.breakpoints.down('sm')]: {
                                  paddingTop: '5px',
                                  paddingBottom: '10px',
                                  paddingLeft: '5px',
                                  paddingRight: '5px',
                                },
                              })}
                            >
                              <CardMedia
                                component={TravelSvgWithBackground}
                                sx={{
                                  // width: '100%',
                                  // height: '100%',
                                  objectFit: 'cover',
                                  // borderRadius: 'inherit',
                                  // overflow: 'hidden',
                                  // borderTopLeftRadius: '20px',
                                  // borderTopRightRadius: '20px',
                                  // borderBottomLeftRadius: !props.publicTrip
                                  //   ? 0
                                  //   : '20px',
                                  // borderBottomRightRadius: !props.publicTrip
                                  //   ? 0
                                  //   : '20px',
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                        <CardLabel
                          text={
                            trip && trip.tripType && trip.tripType === 'guide'
                              ? 'Guide'
                              : 'Itinerary'
                          }
                        />
                        {trip && trip.published && (
                          <CardLabel
                            text="Published"
                            right={
                              trip && trip.tripType && trip.tripType === 'guide'
                                ? '65px'
                                : '82px'
                            }
                          />
                        )}
                        {trip && trip.published && (
                          <CardLabel
                            text={trip.publicViews + ' views'}
                            right={
                              trip && trip.tripType && trip.tripType === 'guide'
                                ? '145px'
                                : '162px'
                            }
                          />
                        )}

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            paddingTop: 2,
                            paddingBottom: 1.25,
                            paddingX: 1.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              flexGrow: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                marginBottom: 0.5,
                                fontSize: 18,
                                fontWeight: 700,
                                color: '#001B30',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                              }}
                            >
                              {trip && trip.name}
                            </Typography>
                          </Box>
                          {trip &&
                          trip.tripType &&
                          trip.tripType === 'guide' ? (
                            <></>
                          ) : (
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              mb={0.5}
                            >
                              <HistoryToggleOffTwoToneIcon
                                sx={theme => ({
                                  width: 25,
                                  height: 25,
                                  marginRight: 1,
                                  color: '#F39C6A',
                                  [theme.breakpoints.down('sm')]: {},
                                })}
                              />
                              {trip && trip.length && trip.length > 0 && (
                                <Typography
                                  sx={theme => ({
                                    fontWeight: 600,
                                    fontSize: 16,
                                    color: '#36454F',
                                    [theme.breakpoints.down('sm')]: {
                                      fontSize: 16,
                                    },
                                  })}
                                >
                                  {trip.length}
                                  {trip.length === 1 ? ' day ' : ' days '}
                                  itinerary
                                </Typography>
                              )}
                            </Stack>
                          )}
                          <Stack
                            direction="row"
                            width="100%"
                            alignItems="flex-start" // Center vertically
                            mb={0.5}
                            sx={{
                              display: 'flex',
                            }}
                          >
                            {((trip && trip.cities && trip.cities.length > 0) ||
                              (trip &&
                                trip.itineraryCountries &&
                                trip.itineraryCountries.length > 0)) && (
                              <TourTwoToneIcon
                                sx={theme => ({
                                  width: 25,
                                  height: 25,
                                  marginRight: 1,
                                  color: '#F39C6A',
                                })}
                              />
                            )}
                            {trip &&
                            trip.tripType &&
                            trip.tripType === 'guide' ? (
                              <>
                                {trip &&
                                  trip.cities &&
                                  trip.cities.length > 0 && (
                                    <Typography
                                      sx={{
                                        display: '-webkit-box',
                                        flexWrap: 'wrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitLineClamp: 2, // Limit to 2 lines
                                        WebkitBoxOrient: 'vertical',
                                      }}
                                    >
                                      {trip.cities.map((city, ci) => (
                                        <React.Fragment key={ci}>
                                          <Typography
                                            component="span"
                                            sx={theme => ({
                                              fontWeight: 500,
                                              fontSize: 16,
                                              color: '#36454F',
                                              // color: theme.palette.secondary.main,
                                              [theme.breakpoints.down('sm')]: {
                                                fontSize: 16,
                                              },
                                            })}
                                          >
                                            {city?.name}
                                          </Typography>
                                          {ci !== trip.cities!.length - 1 && (
                                            <Typography
                                              component="span"
                                              sx={{
                                                mx: 1,
                                                fontSize: '1.5rem', // Adjust the size as needed
                                                lineHeight: 1,
                                              }}
                                            >
                                              •
                                            </Typography>
                                          )}
                                        </React.Fragment>
                                      ))}
                                    </Typography>
                                  )}
                              </>
                            ) : (
                              <Typography
                                // component="span"
                                sx={{
                                  maxWidth: '100%',
                                  display: '-webkit-box',
                                  flexWrap: 'wrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2, // Limit to 2 lines
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {trip &&
                                  trip.itineraryCountries &&
                                  trip.itineraryCountries.length > 0 &&
                                  trip.itineraryCountries.map(
                                    (country, coi) => (
                                      <React.Fragment key={coi}>
                                        {country && (
                                          <>
                                            <Typography
                                              component="span"
                                              sx={theme => ({
                                                fontWeight: 500,
                                                fontSize: 16,
                                                color: '#36454F',
                                                // color: theme.palette.secondary.main,
                                                [theme.breakpoints.down('sm')]:
                                                  {
                                                    fontSize: 16,
                                                  },
                                              })}
                                            >
                                              {country.countryName}
                                              {trip.itineraryCountries!.length -
                                                1 !==
                                                coi && (
                                                <Typography
                                                  component="span"
                                                  sx={{
                                                    fontSize: '1.5rem', // Adjust the size as needed
                                                    lineHeight: 1,
                                                  }}
                                                >
                                                  {' '}
                                                  •{' '}
                                                </Typography>
                                              )}
                                            </Typography>
                                          </>
                                        )}
                                      </React.Fragment>
                                    ),
                                  )}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      </Box>
                      <Divider sx={{ width: '95%', alignSelf: 'center' }} />
                      <Stack
                        width="100%"
                        direction="row"
                        sx={theme => ({
                          paddingTop: 1.5,
                          paddingBottom: 1.5,
                          // backgroundColor: '#FAFAFA',
                          // backgroundColor: 'rgba(250, 250, 250, 0.6)',

                          borderBottomLeftRadius: '20px',
                          borderBottomRightRadius: '20px',
                          justifyContent: 'space-around',
                          // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        })}
                        display="flex"
                        alignItems="center"
                      >
                        <Button
                          onClick={() => {
                            navigate('/preview/' + trip.id);
                            gtag('event', 'user-trip-preview-icon');
                          }}
                          sx={{
                            padding: 0,
                            backgroundColor: 'transparent',
                            color: theme => theme.palette.icon.main,
                            display: 'flex',
                            alignItems: 'center',
                            '&:hover': {
                              backgroundColor: theme =>
                                theme.palette.action.hover,
                            },
                          }}
                        >
                          <VisibilityTwoToneIcon
                            sx={{
                              width: 20,
                              height: 20,
                              marginRight: 0.5,
                            }}
                          />
                          <TripButtons>Preview</TripButtons>
                        </Button>

                        <Button
                          onClick={() => {
                            isAdmin(roles);
                            if (trip.published === false) {
                              navigate('/editor/' + trip.id);
                            } else if (isAdmin(roles) || isPublisher(roles)) {
                              navigate('/editor/' + trip.id);
                            } else {
                              setNeedsUnpublishing(true);
                              setSelectedTripId(trip.id);
                            }

                            gtag('event', 'user-trip-edit-icon');
                          }}
                          sx={{
                            padding: 0,
                            backgroundColor: 'transparent',
                            color: theme => theme.palette.icon.main,
                            display: 'flex',
                            alignItems: 'center',
                            '&:hover': {
                              backgroundColor: theme =>
                                theme.palette.action.hover,
                            },
                          }}
                        >
                          <EditTwoToneIcon
                            sx={{
                              width: 20,
                              height: 20,
                              marginRight: 0.5,
                            }}
                          />
                          <TripButtons>Edit</TripButtons>
                        </Button>

                        <ShareTripModal
                          tripId={trip.id}
                          sharableId={trip.sharableId}
                          published={trip.published}
                          shareButton="myTripCard"
                        />
                      </Stack>
                    </Stack>

                    {needsUnpublishing && (
                      <UnpublishTripModal
                        tripId={trip.id}
                        tripType={trip.tripType ? trip.tripType : undefined}
                        handleCloseModal={() => {
                          setNeedsUnpublishing(false);
                        }}
                        open={selectedTripId === trip.id}
                      />
                    )}
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Grid>

          <Box
            mt={3}
            mr={2}
            sx={theme => ({
              // [theme.breakpoints.up('sm')]: {
              //   border: '2px solid',
              //   borderColor: '#d3d3d3',
              //   borderRadius: '20px',
              //   padding: 1,
              //   backgroundColor: '#f0f0f0',
              // },
            })}
          >
            <Stack
              direction="row"
              spacing={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CreateTripModal buttonType="primary" />
            </Stack>
          </Box>
        </>
      ) : (
        <Stack
          direction="column"
          // mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="82vh"
          sx={theme => ({
            // marginTop: '10%',
            [theme.breakpoints.down('sm')]: {
              // marginTop: '40%',
            },
          })}
        >
          <Box
            sx={theme => ({
              width: '50%',
              [theme.breakpoints.down('sm')]: {
                width: '85%',
              },
            })}
          >
            <TravelSvg />
          </Box>
          <Box mt={5} sx={theme => ({})}>
            <Stack
              direction="row"
              display="flex"
              spacing={1}
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CreateTripModal />
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
}

interface PropsSharedTripsWithUserList {
  readonly trips: Maybe<Trip>[];
  readonly publicTrip: boolean;
}

export function SharedTripsWithUser(props: PropsSharedTripsWithUserList) {
  const navigate = useNavigate();

  return (
    <Box mb={2}>
      {props.trips && props.trips.length > 0 && (
        <>
          <Grid container>
            {props.trips.map((trip, index) => (
              <React.Fragment key={index}>
                {trip && (
                  <Box
                    sx={theme => ({
                      cursor: 'pointer',
                      // height: '100%',
                      marginTop: 2,
                      marginBottom: 1,
                      paddingRight: 2,
                      width: '33%',

                      [theme.breakpoints.down('xl')]: {
                        width: '33.3%',
                      },
                      [theme.breakpoints.down('lg')]: {
                        width: '50%',
                      },
                      [theme.breakpoints.down('md')]: {
                        width: '50%',
                      },
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                      },
                    })}
                  >
                    <Stack
                      key={index + trip.id}
                      direction="column"
                      sx={theme => ({
                        cursor: 'pointer',
                        height: '100%',
                        // marginTop: 2,
                        // marginRight: 2,
                        // width: '32%',
                        borderRadius: '20px',
                        // backgroundColor: 'rgba(255, 250, 245, 0.7)',
                        backgroundColor: 'rgba(250, 250, 250, 0.6)',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        // [theme.breakpoints.down('xl')]: {
                        //   width: '31.5%',
                        // },
                        // [theme.breakpoints.down('lg')]: {
                        //   width: '48%',
                        // },
                        // [theme.breakpoints.down('md')]: {
                        //   width: '47%',
                        // },
                        // [theme.breakpoints.down('sm')]: {
                        //   width: '100%',
                        //   marginRight: 0,
                        //   marginLeft: 0,
                        // },
                      })}
                    >
                      <Box
                        onClick={() => {
                          // if (props.publicTrip) {
                          //   navigate('/trip/' + trip.id);
                          //   gtag('event', 'user-trip-edit-card');
                          // } else {
                          navigate('/preview/' + trip.id);
                          gtag('event', 'user-trip-edit-card');
                          // }
                        }}
                        key={index + trip.id}
                        sx={theme => ({
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 1,
                          // borderRadius: '20px',
                          // borderTopLeftRadius: '20px',
                          // borderTopRightRadius: '20px',
                          // borderBottomLeftRadius: !props.publicTrip ? 0 : '20px',
                          // borderBottomRightRadius: !props.publicTrip ? 0 : '20px',
                        })}
                      >
                        <Box sx={{ height: '230px' }}>
                          {trip.images && trip.images.length > 0 ? (
                            <CardMedia
                              component="img"
                              height="100%" // Increased height to accommodate the card content
                              sx={{
                                // borderRadius: '20px',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                                //     borderBottomLeftRadius: !props.publicTrip
                                //       ? 0
                                //       : '20px',
                                //     borderBottomRightRadius: !props.publicTrip
                                //       ? 0
                                //       : '20px',
                              }}
                              image={
                                trip.images?.find(image => image!.main)
                                  ?.mediumPath ||
                                trip.images?.find(image => image!.main)
                                  ?.previewPath ||
                                trip.images?.find(image => image!.main)?.path ||
                                trip.images?.find(image => image!.main)
                                  ?.smallPath ||
                                trip.images[0]?.mediumPath ||
                                trip.images[0]?.previewPath ||
                                trip.images[0]?.path ||
                                trip.images[0]?.smallPath ||
                                ''
                              }
                            />
                          ) : (
                            <Box
                              sx={theme => ({
                                // borderRadius: '20px',
                                // backgroundColor: '#d3d3d3',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                                // borderBottomLeftRadius: !props.publicTrip
                                //   ? 0
                                //   : '20px',
                                // borderBottomRightRadius: !props.publicTrip
                                //   ? 0
                                //   : '20px',
                                // padding: '25px',
                                // overflow: 'hidden',
                                backgroundColor: '#d3d3d3',
                                // borderRadius: '20px',
                                overflow: 'hidden',
                                // position: 'relative',
                                width: '100%',
                                height: '100%',
                                paddingTop: '15px',
                                paddingBottom: '20px',
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                [theme.breakpoints.down('sm')]: {
                                  paddingTop: '5px',
                                  paddingBottom: '10px',
                                  paddingLeft: '5px',
                                  paddingRight: '5px',
                                },
                              })}
                            >
                              <CardMedia
                                component={TravelSvgWithBackground}
                                sx={{
                                  // width: '100%',
                                  // height: '100%',
                                  objectFit: 'cover',
                                  // borderRadius: 'inherit',
                                  // overflow: 'hidden',
                                  // borderTopLeftRadius: '20px',
                                  // borderTopRightRadius: '20px',
                                  // borderBottomLeftRadius: !props.publicTrip
                                  //   ? 0
                                  //   : '20px',
                                  // borderBottomRightRadius: !props.publicTrip
                                  //   ? 0
                                  //   : '20px',
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                        <LikeTrip tripId={trip!.id} />
                        <CardLabel
                          text={
                            trip && trip.tripType && trip.tripType === 'guide'
                              ? 'Guide'
                              : 'Itinerary'
                          }
                        />
                        {trip && trip.published && (
                          <CardLabel
                            text="Published"
                            right={
                              trip && trip.tripType && trip.tripType === 'guide'
                                ? '65px'
                                : '82px'
                            }
                          />
                        )}

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            paddingTop: 2,
                            paddingBottom: 1,
                            paddingX: 1.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              flexGrow: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                marginBottom: 0.5,
                                fontSize: 18,
                                fontWeight: 700,
                                color: '#001B30',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                              }}
                            >
                              {trip && trip.name}
                            </Typography>
                          </Box>
                          {trip && trip.userDetails && (
                            <ContainedUserProfile tripUser={trip.userDetails} />
                          )}
                          {trip &&
                          trip.tripType &&
                          trip.tripType === 'guide' ? (
                            <></>
                          ) : (
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              mb={0.5}
                            >
                              <HistoryToggleOffTwoToneIcon
                                sx={theme => ({
                                  width: 25,
                                  height: 25,
                                  marginRight: 1,
                                  color: '#F39C6A',
                                  [theme.breakpoints.down('sm')]: {},
                                })}
                              />
                              {trip && trip.length && trip.length > 0 && (
                                <Typography
                                  sx={theme => ({
                                    fontWeight: 600,
                                    fontSize: 16,
                                    color: '#36454F',
                                    [theme.breakpoints.down('sm')]: {
                                      fontSize: 16,
                                    },
                                  })}
                                >
                                  {trip.length}
                                  {trip.length === 1 ? ' day ' : ' days '}
                                  itinerary
                                </Typography>
                              )}
                            </Stack>
                          )}
                          <Stack
                            direction="row"
                            width="100%"
                            alignItems="flex-start" // Center vertically
                            mb={0.5}
                            sx={{
                              display: 'flex',
                            }}
                          >
                            {((trip && trip.cities && trip.cities.length > 0) ||
                              (trip &&
                                trip.itineraryCountries &&
                                trip.itineraryCountries.length > 0)) && (
                              <TourTwoToneIcon
                                sx={theme => ({
                                  width: 25,
                                  height: 25,
                                  marginRight: 1,
                                  color: '#F39C6A',
                                })}
                              />
                            )}
                            {trip &&
                            trip.tripType &&
                            trip.tripType === 'guide' ? (
                              <>
                                {trip &&
                                  trip.cities &&
                                  trip.cities.length > 0 && (
                                    <Typography
                                      sx={{
                                        display: '-webkit-box',
                                        flexWrap: 'wrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitLineClamp: 2, // Limit to 2 lines
                                        WebkitBoxOrient: 'vertical',
                                      }}
                                    >
                                      {trip.cities.map((city, ci) => (
                                        <React.Fragment key={ci}>
                                          <Typography
                                            component="span"
                                            sx={theme => ({
                                              fontWeight: 500,
                                              fontSize: 16,
                                              color: '#36454F',
                                              // color: theme.palette.secondary.main,
                                              [theme.breakpoints.down('sm')]: {
                                                fontSize: 16,
                                              },
                                            })}
                                          >
                                            {city?.name}
                                          </Typography>
                                          {ci !== trip.cities!.length - 1 && (
                                            <Typography
                                              component="span"
                                              sx={{
                                                mx: 1,
                                                fontSize: '1.5rem', // Adjust the size as needed
                                                lineHeight: 1,
                                              }}
                                            >
                                              •
                                            </Typography>
                                          )}
                                        </React.Fragment>
                                      ))}
                                    </Typography>
                                  )}
                              </>
                            ) : (
                              <Typography
                                // component="span"
                                sx={{
                                  maxWidth: '100%',
                                  display: '-webkit-box',
                                  flexWrap: 'wrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2, // Limit to 2 lines
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {trip &&
                                  trip.itineraryCountries &&
                                  trip.itineraryCountries.length > 0 &&
                                  trip.itineraryCountries.map(
                                    (country, coi) => (
                                      <React.Fragment key={coi}>
                                        {country && (
                                          <>
                                            <Typography
                                              component="span"
                                              sx={theme => ({
                                                fontWeight: 500,
                                                fontSize: 16,
                                                color: '#36454F',
                                                // color: theme.palette.secondary.main,
                                                [theme.breakpoints.down('sm')]:
                                                  {
                                                    fontSize: 16,
                                                  },
                                              })}
                                            >
                                              {country.countryName}
                                              {trip.itineraryCountries!.length -
                                                1 !==
                                                coi && (
                                                <Typography
                                                  component="span"
                                                  sx={{
                                                    fontSize: '1.5rem', // Adjust the size as needed
                                                    lineHeight: 1,
                                                  }}
                                                >
                                                  {' '}
                                                  •{' '}
                                                </Typography>
                                              )}
                                            </Typography>
                                          </>
                                        )}
                                      </React.Fragment>
                                    ),
                                  )}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      </Box>
                      <Divider sx={{ width: '95%', alignSelf: 'center' }} />

                      {!props.publicTrip && (
                        <Stack
                          width="100%"
                          direction="row"
                          sx={theme => ({
                            paddingTop: 1.5, // Reduced padding to remove extra space
                            paddingBottom: 1.5,
                            justifyContent: 'space-around', // Distribute buttons evenly
                          })}
                          display="flex"
                          alignItems="center"
                        >
                          <Button
                            onClick={() => {
                              navigate('/preview/' + trip.id);
                              gtag('event', 'user-trip-preview-icon');
                            }}
                            sx={{
                              padding: 0,
                              backgroundColor: 'transparent',
                              color: theme => theme.palette.icon.main,
                              display: 'flex',
                              alignItems: 'center',
                              '&:hover': {
                                backgroundColor: theme =>
                                  theme.palette.action.hover,
                              },
                            }}
                          >
                            <VisibilityTwoToneIcon
                              sx={{
                                width: 20,
                                height: 20,
                                marginRight: 0.5,
                              }}
                            />
                            <TripButtons>View</TripButtons>
                          </Button>
                          {trip.tripAccess?.canEdit && (
                            <Button
                              onClick={() => {
                                navigate('/editor/' + trip.id);
                                gtag('event', 'user-trip-edit-icon');
                              }}
                              sx={{
                                padding: 0,
                                backgroundColor: 'transparent',
                                color: theme => theme.palette.icon.main,
                                display: 'flex',
                                alignItems: 'center',
                                '&:hover': {
                                  backgroundColor: theme =>
                                    theme.palette.action.hover,
                                },
                              }}
                            >
                              <EditTwoToneIcon
                                sx={{
                                  width: 20,
                                  height: 20,
                                  marginRight: 0.5,
                                }}
                              />
                              <TripButtons>Edit</TripButtons>
                            </Button>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

export const TripTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  // color: '#36454F',
  // overflow: 'hidden',
  // display: '-webkit-box',
  // WebkitLineClamp: 2, // Limit to 2 lines
  // WebkitBoxOrient: 'vertical',
  // textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}));

export const TripLength = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  color: '#36454F',
  // color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const TripCountry = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  marginRight: 5,
  color: '#36454F',
  // color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const TripButtons = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  marginRight: 5,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));
