import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import DirectionsSubwayFilledTwoToneIcon from '@mui/icons-material/DirectionsSubwayFilledTwoTone';
import FastfoodTwoToneIcon from '@mui/icons-material/FastfoodTwoTone';
import HealingTwoToneIcon from '@mui/icons-material/HealingTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import Grid from '@mui/material/Grid';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Country } from '../../generated/user_graphql';
import { LightButton } from '../../theme-components/Buttons';

import { CountrySidebar } from './CountrySidebar';
import { unauthorisedClient } from '../../utils/auth';
import { QUERY_GET_COUNTRY } from '../gql-public/countryQuery';
import { useQuery } from '@apollo/client';

export function CountryInfoLinks(props: {
  country: Country;
  alignLeft?: boolean;
}) {
  const navigate = useNavigate();
  const [countrySideBarOpenID, setCountrySideBarOpenID] = useState('');

  function onShowSeasons() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-seasons');
    navigate('?modal=seasons');
  }

  function onShowTips() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-tips');
    navigate('?modal=tips');
  }

  function onShowVaccinations() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-health');
    navigate('?modal=health');
  }

  function onShowCountriesDetails() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-details');
    navigate('?modal=country_details');
  }

  function onShowVisas() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-visas');
    navigate('?modal=visas');
  }

  function onShowFood() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-food');
    navigate('?modal=food');
  }

  function onShowTransport() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-transport');
    navigate('?modal=transport');
  }

  let showAboutCountry = false;
  let showCountryHealth = false;
  let showCountryVisa = false;
  let showCountryTips = false;
  let showCountryFood = false;
  let showCountryTransport = false;
  let showCountrySeasons = false;
  let country: Country | null = null;

  if (props.country) {
    country = props.country;

    if (country.name && country.description) {
      showAboutCountry = true;
    }

    if (country.seasons && country.seasons.length > 0) {
      showCountrySeasons = true;
    }

    if (country.recommendations && country.recommendations.length > 0) {
      country.recommendations.forEach(recommendation => {
        if (recommendation!.type === 'RECOMMENDATION_HEALTH') {
          showCountryHealth = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_VISAS') {
          showCountryVisa = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_TIPS') {
          showCountryTips = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_FOOD') {
          showCountryFood = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_TRANSPORT') {
          showCountryTransport = true;
        }
      });
    }
  }

  return (
    <>
      <Box
        sx={theme => ({
          padding: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: props.alignLeft ? 'flex-start' : 'center', // Center items horizontally
          alignItems: 'center', // Center items vertically
          margin: '0 auto', // Center the Box horizontally within its parent
        })}
      >
        {showAboutCountry && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton
              onClick={onShowCountriesDetails}
              sx={theme => ({
                color: theme.palette.primary.main,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              })}
            >
              <LanguageTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                })}
              />
              {'About ' + country!.name}
            </LightButton>
          </Box>
        )}
        {showCountrySeasons && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton
              onClick={onShowSeasons}
              sx={theme => ({
                color: theme.palette.primary.main,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              })}
            >
              <WbSunnyTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                })}
              />
              When To Go
            </LightButton>
          </Box>
        )}
        {showCountryFood && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton
              onClick={onShowFood}
              sx={theme => ({
                color: theme.palette.primary.main,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              })}
            >
              <FastfoodTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                })}
              />
              Food
            </LightButton>
          </Box>
        )}
        {showCountryTips && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton
              onClick={onShowTips}
              sx={theme => ({
                color: theme.palette.primary.main,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              })}
            >
              <TipsAndUpdatesTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                })}
              />
              Usefull Tips
            </LightButton>
          </Box>
        )}
        {showCountryTransport && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton
              onClick={onShowTransport}
              sx={theme => ({
                color: theme.palette.primary.main,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              })}
            >
              <DirectionsSubwayFilledTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                })}
              />
              Transport
            </LightButton>
          </Box>
        )}

        {showCountryHealth && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton
              onClick={onShowVaccinations}
              sx={theme => ({
                color: theme.palette.primary.main,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              })}
            >
              <HealingTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                })}
              />
              Health
            </LightButton>
          </Box>
        )}
        {showCountryVisa && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton
              onClick={onShowVisas}
              sx={theme => ({
                color: theme.palette.primary.main,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              })}
            >
              <LibraryBooksTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                })}
              />
              Visas
            </LightButton>
          </Box>
        )}
      </Box>
      {country?.id === countrySideBarOpenID && (
        <CountrySidebar
          country={country}
          onClose={() => setCountrySideBarOpenID('')}
        />
      )}
    </>
  );
}

export function CountryMenuInfoLinks(props: { country: Country }) {
  const navigate = useNavigate();
  const [countrySideBarOpenID, setCountrySideBarOpenID] = useState('');

  function onShowSeasons() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-seasons');
    navigate('?modal=seasons');
  }

  function onShowTips() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-tips');
    navigate('?modal=tips');
  }

  function onShowVaccinations() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-health');
    navigate('?modal=health');
  }

  function onShowCountriesDetails() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-details');
    navigate('?modal=country_details');
  }
  function onShowVisas() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-visas');
    navigate('?modal=visas');
  }

  function onShowFood() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-food');
    navigate('?modal=food');
  }

  function onShowTransport() {
    setCountrySideBarOpenID(country!.id);
    gtag('event', 'public-country-transport');
    navigate('?modal=transport');
  }

  let showAboutCountry = false;
  let showCountryHealth = false;
  let showCountryVisa = false;
  let showCountryTips = false;
  let showCountryFood = false;
  let showCountryTransport = false;
  let showCountrySeasons = false;
  let country: Country | null = null;

  if (props.country) {
    country = props.country;

    if (country.name && country.description) {
      showAboutCountry = true;
    }

    if (country.seasons && country.seasons.length > 0) {
      showCountrySeasons = true;
    }

    if (country.recommendations && country.recommendations.length > 0) {
      country.recommendations.forEach(recommendation => {
        if (recommendation!.type === 'RECOMMENDATION_HEALTH') {
          showCountryHealth = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_VISAS') {
          showCountryVisa = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_TIPS') {
          showCountryTips = true;
        }
        if (recommendation!.type === 'RECOMMENDATION_FOOD') {
          showCountryFood = true;
        }
        if (recommendation!.type === 'RECOMMENDATION_TRANSPORT') {
          showCountryTransport = true;
        }
      });
    }
  }

  return (
    <>
      <Stack direction="column" alignItems="flex-start">
        {showAboutCountry && (
          <Button
            sx={{ fontSize: 16 }}
            onClick={() => {
              onShowCountriesDetails();
              setCountrySideBarOpenID(country!.id);
            }}
          >
            <LanguageTwoToneIcon
              sx={theme => ({
                width: 20,
                height: 20,
                marginRight: 0.5,
                color: theme.palette.icon.main,
              })}
            />
            About
          </Button>
        )}
        {showCountrySeasons && (
          <Button
            sx={{ fontSize: 16 }}
            onClick={() => {
              onShowSeasons();
            }}
          >
            <WbSunnyTwoToneIcon
              sx={theme => ({
                width: 20,
                height: 20,
                marginRight: 0.5,
                color: theme.palette.icon.main,
              })}
            />
            When To Go
          </Button>
        )}
        {showCountryFood && (
          <Button
            sx={{ fontSize: 16 }}
            onClick={() => {
              onShowFood();
            }}
          >
            <FastfoodTwoToneIcon
              sx={theme => ({
                width: 20,
                height: 20,
                marginRight: 0.5,
                color: theme.palette.icon.main,
              })}
            />
            Food
          </Button>
        )}
        {showCountryTips && (
          <Button
            sx={{ fontSize: 16 }}
            onClick={() => {
              onShowTips();
            }}
          >
            <TipsAndUpdatesTwoToneIcon
              sx={theme => ({
                width: 20,
                height: 20,
                marginRight: 0.5,
                color: theme.palette.icon.main,
              })}
            />
            Usefull Tips
          </Button>
        )}
        {showCountryTransport && (
          <Button
            sx={{ fontSize: 16 }}
            onClick={() => {
              onShowTransport();
            }}
          >
            <DirectionsSubwayFilledTwoToneIcon
              sx={theme => ({
                width: 20,
                height: 20,
                marginRight: 0.5,
                color: theme.palette.icon.main,
              })}
            />
            Transport
          </Button>
        )}
        {showCountryVisa && (
          <Button
            sx={{ fontSize: 16 }}
            onClick={() => {
              onShowVisas();
            }}
          >
            <LibraryBooksTwoToneIcon
              sx={theme => ({
                width: 20,
                height: 20,
                marginRight: 0.5,
                color: theme.palette.icon.main,
              })}
            />
            Visas
          </Button>
        )}
        {showCountryHealth && (
          <Button
            sx={{ fontSize: 16 }}
            onClick={() => {
              onShowVaccinations();
            }}
          >
            <HealingTwoToneIcon
              sx={theme => ({
                width: 20,
                height: 20,
                marginRight: 0.5,
                color: theme.palette.icon.main,
              })}
            />
            Health
          </Button>
        )}
        <Button
          sx={{ fontSize: 16 }}
          onClick={() => {
            navigate(
              country!.slug
                ? '/country-itineraries/' + country!.slug
                : '/country-itineraries/' + country!.id,
            );
          }}
        >
          <CardTravelTwoToneIcon
            sx={theme => ({
              width: 20,
              height: 20,
              marginRight: 0.5,
              color: theme.palette.icon.main,
            })}
          />
          Related Trips
        </Button>
      </Stack>
      {country?.id === countrySideBarOpenID && (
        <CountrySidebar
          country={country}
          onClose={() => setCountrySideBarOpenID('')}
        />
      )}
    </>
  );
}

export function TripCountryBox(props: { country?: Country }) {
  const navigate = useNavigate();
  const [countrySideBarOpenID, setCountrySideBarOpenID] = useState('');

  function onShowSeasons() {
    setCountrySideBarOpenID(props.country!.id);
    gtag('event', 'public-country-seasons');
    navigate('?modal=seasons');
  }

  function onShowTips() {
    setCountrySideBarOpenID(props.country!.id);
    gtag('event', 'public-country-tips');
    navigate('?modal=tips');
  }

  function onShowVaccinations() {
    setCountrySideBarOpenID(props.country!.id);
    gtag('event', 'public-country-health');
    navigate('?modal=health');
  }

  function onShowCountriesDetails() {
    setCountrySideBarOpenID(props.country!.id);
    gtag('event', 'public-country-details');
    navigate('?modal=country_details');
  }

  function onShowVisas() {
    setCountrySideBarOpenID(props.country!.id);
    gtag('event', 'public-country-visas');
    navigate('?modal=visas');
  }

  function onShowFood() {
    setCountrySideBarOpenID(props.country!.id);
    gtag('event', 'public-country-food');
    navigate('?modal=food');
  }

  function onShowTransport() {
    setCountrySideBarOpenID(props.country!.id);
    gtag('event', 'public-country-transport');
    navigate('?modal=transport');
  }

  let showAboutCountry = false;
  let showCountryVaccination = false;
  let showCountryVisa = false;
  let showCountryTips = false;
  let showCountryTransport = false;
  let showCountryFood = false;
  let showCountrySeasons = false;
  let country: Country | null = null;

  if (props.country) {
    country = props.country;

    if (country.name && country.description) {
      showAboutCountry = true;
    }

    if (country.seasons && country.seasons.length > 0) {
      showCountrySeasons = true;
    }

    if (country.recommendations && country.recommendations.length > 0) {
      country.recommendations.forEach(recommendation => {
        if (recommendation!.type === 'RECOMMENDATION_HEALTH') {
          showCountryVaccination = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_VISAS') {
          showCountryVisa = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_TIPS') {
          showCountryTips = true;
        }
        if (recommendation!.type === 'RECOMMENDATION_FOOD') {
          showCountryFood = true;
        }
        if (recommendation!.type === 'RECOMMENDATION_TRANSPORT') {
          showCountryTransport = true;
        }
      });
    }
  }

  if (
    !showAboutCountry &&
    !showCountrySeasons &&
    !showCountryVaccination &&
    !showCountryVisa &&
    !showCountryTips &&
    !showCountryFood &&
    !showCountryTransport
  ) {
    return null;
  }

  return (
    <>
      <Stack
        direction="column"
        width="100%"
        sx={{
          borderRadius: '20px',
          // border: '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
          paddingRight: 1,
          marginTop: 1.5,
          paddingLeft: 2,
          marginBottom: 1.5,
          paddingY: 2,
          backgroundColor: '#FFFEFD',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Grid container spacing={0}>
          {showAboutCountry && (
            <Grid item xs={6} sm={6} md={6}>
              <Stack direction="row" alignItems="center">
                <LanguageTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#F39C6A',
                  })}
                />
                <Button
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                  onClick={onShowCountriesDetails}
                >
                  About
                </Button>
              </Stack>
            </Grid>
          )}

          {showCountrySeasons && (
            <Grid item xs={6} sm={6} md={6}>
              <Stack direction="row" alignItems="center">
                <WbSunnyTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#F39C6A',
                  })}
                />

                <Button
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                  onClick={onShowSeasons}
                >
                  When To Go
                </Button>
              </Stack>
            </Grid>
          )}
          {showCountryFood && (
            <Grid item xs={6} sm={6} md={6}>
              <Stack direction="row" alignItems="center">
                <FastfoodTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#F39C6A',
                  })}
                />
                <Button
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                  onClick={onShowFood}
                >
                  Food
                </Button>
              </Stack>
            </Grid>
          )}
          {showCountryTips && (
            <Grid item xs={6} sm={6} md={6}>
              <Stack direction="row" alignItems="center">
                <TipsAndUpdatesTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#F39C6A',
                  })}
                />
                <Button
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                  onClick={onShowTips}
                >
                  Usefull Tips
                </Button>
              </Stack>
            </Grid>
          )}
          {showCountryTransport && (
            <Grid item xs={6} sm={6} md={6}>
              <Stack direction="row" alignItems="center">
                <DirectionsSubwayFilledTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#F39C6A',
                  })}
                />
                <Button
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                  onClick={onShowTransport}
                >
                  Transport
                </Button>
              </Stack>
            </Grid>
          )}
          {showCountryVisa && (
            <Grid item xs={6} sm={6} md={6}>
              <Stack direction="row" alignItems="center">
                <LibraryBooksTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#F39C6A',
                  })}
                />
                <Button
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                  onClick={onShowVisas}
                >
                  Visas
                </Button>
              </Stack>
            </Grid>
          )}

          {showCountryVaccination && (
            <Grid item xs={6} sm={6} md={6}>
              <Stack direction="row" alignItems="center">
                <HealingTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 0.5,
                    color: '#F39C6A',
                  })}
                />
                <Button
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                  onClick={onShowVaccinations}
                >
                  Health
                </Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>
      {country?.id === countrySideBarOpenID && (
        <CountrySidebar
          country={country}
          onClose={() => setCountrySideBarOpenID('')}
        />
      )}
    </>
  );
}
