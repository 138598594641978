import {
  Box,
  Theme,
  Typography,
  TypographyProps,
  styled,
  useTheme,
} from '@mui/material';

// Define styled components with MUI v5
export const TitleH1 = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 700,
  fontSize: 40,
  color: '#001B30',
  // marginTop: 20,
  // marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    // marginBottom: 5,
    // marginTop: 10,
    fontSize: 30,
  },
}));

export const TitleH2 = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  fontSize: 30,
  color: '#001B30',
  [theme.breakpoints.down('sm')]: {
    // marginBottom: 5,
    fontSize: 26,
    width: '85%',
  },
}));

export const TitleH3 = styled(Typography)<TypographyProps>(
  ({ theme, color }) => ({
    fontWeight: 700,
    fontSize: 24,
    // color: color || '#14142B',
    color: color || '#001B30',
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  }),
);

export const TitleH4 = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 700,
  fontSize: 18,
  // color: '#14142B',
  color: '#001B30',
  overflow: 'hidden', // Hide overflow content
  textOverflow: 'ellipsis', // Show ellipsis when content overflows
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  //FIXME
  // whiteSpace: 'nowrap', // Prevent text from wrapping to a new line
  // maxWidth: '100%', // Ensure the component does not exceed the width of its container
  boxSizing: 'border-box', // Include padding and border in the element's total width and height
  flexGrow: 1,
  paddingRight: 1,
  [theme.breakpoints.down('lg')]: {
    fontSize: 18,
    // maxWidth: '480px', // Adjust maxWidth for smaller screens if needed
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
    // maxWidth: '480px', // Adjust maxWidth for smaller screens if needed
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    // maxWidth: '320px', // Adjust maxWidth for smaller screens if needed
  },
}));

export const TitleH5 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  // color: '#14142B',
  color: '#001B30',
  // color: '#393745',
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    // marginBottom: 5,
  },
}));

export const Body1 = styled(Box)(({ theme }) => ({
  fontWeight: 450,
  fontSize: 16,
  // color: '#636363',
  color: '#393745',
  // [theme.breakpoints.down('sm')]: {
  //   fontSize: 15,
  // },
  '& h1': {
    fontSize: 18,
    color: '#222222',
    fontWeight: 700,
    margin: 0,
    marginBlockStart: 8,
    marginBlockEnd: 0,
  },
  '& h2': {
    fontSize: 17,
    color: '#222222',
    fontWeight: 700,
    margin: 0,
    marginBlockStart: 8,
    marginBlockEnd: 0,
  },
  '& h3': {
    fontSize: 17,
    color: '#222222',
    fontWeight: 700,
    margin: 0,
    marginBlockStart: 8,
    marginBlockEnd: 0,
  },
  '& h4': {
    fontSize: 17,
    color: '#222222',
    fontWeight: 700,
    margin: 0,
    marginBlockStart: 8,
    marginBlockEnd: 0,
  },
  '& p:first-child': {
    marginTop: '0.5em', // Top margin for the first <p>
    marginBottom: '0em', // Bottom margin for all except the last <p>
  },
  '& p': {
    marginTop: '1em', // Top margin for all other <p> except the first
    marginBottom: '0em', // Bottom margin for all except the last <p>
  },
  '& p:last-child': {
    marginBottom: '0.5em', // Bottom margin for the last nested <p>
  },
}));

export const Body2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  color: theme.palette.secondary.dark,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const Body2Centered = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  textAlign: 'center',
  color: theme.palette.secondary.dark,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const SubHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  color: theme.palette.secondary.dark,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const HelpText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  color: '#636363',
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const BoldText = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
}));

interface TextDescriptionProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'; // Define the possible size options
  children: React.ReactNode; // Text content
  sx?: object; // Optional additional styles
}

interface TextDescriptionProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'; // Define the possible size options
  children: React.ReactNode; // Text content
  sx?: object; // Optional additional styles
  component?: React.ElementType; // Allow the component prop to pass to Typography
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'overline'; // Only allow predefined variants
  align?: 'left' | 'center' | 'right'; // Text alignment
  // Any other Typography props you'd like to forward
}

const TextDescription: React.FC<TextDescriptionProps> = ({
  size,
  children,
  sx,
  component = 'p', // Default to 'p' if no component is passed
  variant = 'body1', // Default variant
  align = 'left', // Default alignment
  ...otherProps // Forward any other props
}) => {
  const theme = useTheme(); // Access the theme for breakpoints

  // Define the font sizes based on the provided size prop
  const getFontSize = (size: TextDescriptionProps['size'], theme: Theme) => {
    switch (size) {
      case 'xs':
        return 12; // xs font size
      case 'sm':
        return 14; // sm font size
      case 'md':
        return 16; // md font size
      case 'lg':
        return 18; // lg font size
      case 'xl':
        return 20; // xl font size
      default:
        return 16; // Default to md
    }
  };

  const fontSize = getFontSize(size, theme);

  return (
    <Typography
      component={component} // Pass component prop
      variant={variant} // Pass variant prop
      align={align} // Pass align prop
      sx={{
        fontWeight: 500,
        fontSize: fontSize, // Apply the calculated font size
        [theme.breakpoints.down('sm')]: {
          fontSize: fontSize - 1, // Slightly smaller on smaller screens
        },
        ...sx, // Allow for additional custom styles
      }}
      {...otherProps} // Forward other props to Typography
    >
      {children}
    </Typography>
  );
};

export default TextDescription;

interface TitleProps {
  color?: string; // Optional color prop
  children: React.ReactNode; // children prop to accept text or JSX elements
}

export const H1Title = ({ color, children }: TitleProps) => {
  return (
    <Typography
      variant="h1"
      sx={theme => ({
        fontWeight: 700,
        fontSize: 24,
        p: 0,
        color: color || '#001B30', // You can pass `color` as a prop or use the default color
        [theme.breakpoints.down('sm')]: {
          fontSize: 22, // Slightly smaller on smaller screens
        },
      })}
    >
      {children}
    </Typography>
  );
};

export const H2Title = ({ color, children }: TitleProps) => {
  return (
    <Typography
      variant="h2"
      sx={theme => ({
        fontWeight: 700,
        fontSize: 24,
        p: 0,
        color: color || '#001B30', // You can pass `color` as a prop or use the default color
        [theme.breakpoints.down('sm')]: {
          fontSize: 22, // Slightly smaller on smaller screens
        },
      })}
    >
      {children}
    </Typography>
  );
};

export const H3Title = ({ color, children }: TitleProps) => {
  return (
    <Typography
      variant="h3"
      sx={theme => ({
        fontWeight: 700,
        fontSize: 20, // Base font size for h3
        color: color || '#001B30', // Default color or passed `color` prop
        overflow: 'hidden', // Hide overflow content
        textOverflow: 'ellipsis', // Show ellipsis when content overflows
        WebkitLineClamp: 2, // Limit to two lines of text
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        boxSizing: 'border-box', // Include padding and border in the element's total width and height
        flexGrow: 1,
        paddingRight: 1,
        // [theme.breakpoints.down('lg')]: {
        //   fontSize: 18, // Smaller font size on large screens
        // },
        // [theme.breakpoints.down('md')]: {
        //   fontSize: 16, // Smaller font size on medium screens
        // },
        // [theme.breakpoints.down('sm')]: {
        //   fontSize: 14, // Even smaller on small screens
        // },
      })}
    >
      {children}
    </Typography>
  );
};
