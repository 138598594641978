import { gql } from '@apollo/client';

export const PLACE_FIELDS = gql`
  fragment PlaceFields on Place {
    id
    lat
    lng
    city
    country
    countryCode
    region
    externalPlaceId
    title
    category
    noOfReviews
    rating
    description
    quickFact
    phoneNumber
    website
    address
    aiDescription
    tags {
      id
      name
    }
    checkInTime
    checkOutTime
    placeType
    dataSource
    dataSource
    bookingLink
    hotelStarRating
    openingHours {
      weekDay
      hours
    }
    images {
      id
      path
      previewPath
      mediumPath
      imageUrl
      smallPath
      fromOwner
      title
      contributorId
      attribution {
        nickName
        profilePictureUrl
      }
    }
    minPrice
    maxPrice
    admissionPrice
    priceCategory
    priceCurrency
    priceIndicator
  }
`;
