import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import HotelIcon from '@mui/icons-material/Hotel';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import PlaceIcon from '@mui/icons-material/Place';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import FastfoodTwoToneIcon from '@mui/icons-material/FastfoodTwoTone';
import DirectionsBusFilledTwoToneIcon from '@mui/icons-material/DirectionsBusFilledTwoTone';
import FlightTakeoffTwoToneIcon from '@mui/icons-material/FlightTakeoffTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import NightlifeTwoToneIcon from '@mui/icons-material/NightlifeTwoTone';
import CoffeeTwoToneIcon from '@mui/icons-material/CoffeeTwoTone';
import BakeryDiningTwoToneIcon from '@mui/icons-material/BakeryDiningTwoTone';
import LocalPizzaTwoToneIcon from '@mui/icons-material/LocalPizzaTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import LocalSeeTwoToneIcon from '@mui/icons-material/LocalSeeTwoTone';
import MuseumTwoToneIcon from '@mui/icons-material/MuseumTwoTone';
import CakeTwoToneIcon from '@mui/icons-material/CakeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import CarRentalTwoToneIcon from '@mui/icons-material/CarRentalTwoTone';
import LocalTaxiTwoToneIcon from '@mui/icons-material/LocalTaxiTwoTone';
import TrainTwoToneIcon from '@mui/icons-material/TrainTwoTone';
import ForestTwoToneIcon from '@mui/icons-material/ForestTwoTone';
import NaturePeopleTwoToneIcon from '@mui/icons-material/NaturePeopleTwoTone';
import HikingTwoToneIcon from '@mui/icons-material/HikingTwoTone';
import TempleHinduTwoToneIcon from '@mui/icons-material/TempleHinduTwoTone';
import TempleBuddhistTwoToneIcon from '@mui/icons-material/TempleBuddhistTwoTone';
import RestaurantTwoToneIcon from '@mui/icons-material/RestaurantTwoTone';
import SportsSoccerTwoToneIcon from '@mui/icons-material/SportsSoccerTwoTone';
import AttractionsIcon from '@mui/icons-material/Attractions';
import { Box } from '@mui/material';
import WaterDropTwoToneIcon from '@mui/icons-material/WaterDropTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';

export const FavoriteIcon: React.FC<SvgIconProps> = props => {
  return <Favorite {...props} />;
};

export const FavoriteBorderIcon: React.FC<SvgIconProps> = props => {
  return <FavoriteBorder {...props} />;
};

export const TikTokIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
    </SvgIcon>
  );
};

export const CustomBookingComWhiteIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#FFFFFF" />{' '}
      {/* White circular background */}
      <text
        x="50%"
        y="55%"
        textAnchor="middle"
        fontFamily="Arial, sans-serif"
        fontSize="16"
        fontWeight="bold"
        fill="#003580"
        dominantBaseline="middle"
      >
        B.
      </text>
    </SvgIcon>
  );
};

export const CustomBookingComBlueIcon = props => {
  return (
    <SvgIcon {...props} height="20" width="20" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#003580" />{' '}
      {/* Blue circular background */}
      <text
        x="50%"
        y="55%"
        textAnchor="middle"
        fontFamily="Arial, sans-serif"
        fontSize="16"
        fontWeight="bold"
        fill="#FFFFFF"
        dominantBaseline="middle"
      >
        B.
      </text>
    </SvgIcon>
  );
};

export const GetYourGuideIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#FF5533" />{' '}
      {/* Blue circular background */}
      <path
        d="M7.49075 13.5C8.06194 13.4998 8.6241 13.3509 9.12647 13.0666C9.62885 12.7824 10.0556 12.3719 10.3683 11.872V13.317H12V8.51286H7.62685V10.1705H9.46196C9.37818 10.6129 9.15081 11.0115 8.81831 11.2988C8.48581 11.586 8.06861 11.7443 7.63732 11.7469C6.62928 11.7469 5.81417 10.893 5.81417 9.64978V8.37055C5.81417 7.15074 6.68611 6.24996 7.90802 6.24996C8.86072 6.24996 9.71023 6.79418 9.9929 7.58862L11.7158 6.96308C11.2387 5.4477 9.69826 4.5 7.91999 4.5C5.67658 4.5 4 6.07481 4 8.37368V9.65291C4.0015 11.872 5.56441 13.5 7.49075 13.5Z"
        fill="white"
        // Center and scale the path
        transform="translate(0, -1.3) scale(1.5)"
      />
    </SvgIcon>
  );
};

export const GetYourGuideIconLetter: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="get-your-guide-icon-title"
      role="img"
    >
      <path
        d="M7.49075 13.5C8.06194 13.4998 8.6241 13.3509 9.12647 13.0666C9.62885 12.7824 10.0556 12.3719 10.3683 11.872V13.317H12V8.51286H7.62685V10.1705H9.46196C9.37818 10.6129 9.15081 11.0115 8.81831 11.2988C8.48581 11.586 8.06861 11.7443 7.63732 11.7469C6.62928 11.7469 5.81417 10.893 5.81417 9.64978V8.37055C5.81417 7.15074 6.68611 6.24996 7.90802 6.24996C8.86072 6.24996 9.71023 6.79418 9.9929 7.58862L11.7158 6.96308C11.2387 5.4477 9.69826 4.5 7.91999 4.5C5.67658 4.5 4 6.07481 4 8.37368V9.65291C4.0015 11.872 5.56441 13.5 7.49075 13.5Z"
        fill="white"
        transform="translate(0, -1.3) scale(1.5)"
      />
    </SvgIcon>
  );
};

export const CategoryIcon = ({
  type,
  category,
  source,
  width,
  height,
  highlighted = true,
}) => {
  const categoryLower = category ? category.toLowerCase() : '';
  const { icon, color } = getIconByCategory(categoryLower, type, source);

  return (
    <Box
      sx={theme => ({
        width: width ? width : 30, // default width
        height: height ? height : 30, // default height
        borderRadius: '50%',
        backgroundColor: highlighted ? color : `${color}99`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
      })}
    >
      {icon}
    </Box>
  );
};

const getIconByCategory = (categoryLower, type, source) => {
  const baseStyle = {
    width: '70%',
    height: '70%',
    color: 'white',
  };

  if (
    categoryLower.includes('playground') ||
    categoryLower.includes('amusement')
  ) {
    return {
      icon: <SportsSoccerTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#FF6347',
    };
  }

  if (categoryLower.includes('airport')) {
    return {
      icon: <FlightTakeoffTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#00BFFF',
    };
  }

  if (categoryLower.includes('transport')) {
    return {
      icon: <DirectionsBusFilledTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#FF7F50',
    };
  }

  if (categoryLower.includes('coffee')) {
    return {
      icon: <CoffeeTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#D2691E',
    };
  }

  if (categoryLower.includes('beach')) {
    return {
      icon: <BeachAccessTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#FFD700',
    };
  }

  if (
    categoryLower.includes('brasserie') ||
    categoryLower.includes('bakery') ||
    categoryLower.includes('pastry') ||
    categoryLower.includes('patisserie')
  ) {
    return {
      icon: <BakeryDiningTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#D2691E',
    };
  }

  if (categoryLower.includes('museum')) {
    return {
      icon: <MuseumTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#8A2BE2',
    };
  }

  // Transport
  if (categoryLower.includes('car_rental')) {
    return {
      icon: <CarRentalTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#FF4500',
    };
  }

  if (categoryLower.includes('taxi')) {
    return {
      icon: <LocalTaxiTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#FF4500',
    };
  }

  if (categoryLower.includes('train') || categoryLower.includes('railway')) {
    return {
      icon: <TrainTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#007BFF',
    };
  }

  if (
    categoryLower.includes('bus_stop') ||
    categoryLower.includes('public_transport')
  ) {
    return {
      icon: <DirectionsBusFilledTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#007BFF',
    };
  }

  if (categoryLower.includes('chocolate')) {
    return {
      icon: <CakeTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#6F4E37',
    };
  }

  if (
    categoryLower.includes('pizza') ||
    categoryLower.includes('italian_restaurant')
  ) {
    return {
      icon: <LocalPizzaTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#FF1493',
    };
  }

  if (
    categoryLower.includes('wine') ||
    categoryLower.includes('brewery') ||
    categoryLower.includes('beer') ||
    categoryLower.includes('night_club') ||
    categoryLower.includes('bar_&_grill') ||
    categoryLower.includes('irish_pub') ||
    categoryLower.includes('brewpub') ||
    categoryLower.includes('cocktail')
  ) {
    return {
      icon: <NightlifeTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#8B0000',
    };
  }

  if (
    categoryLower.includes('fast_food') ||
    categoryLower.includes('burger') ||
    categoryLower.includes('fastfood') ||
    categoryLower.includes('hot_dog') ||
    categoryLower.includes('kebab') ||
    categoryLower.includes('keba') ||
    categoryLower.includes('takeaway') ||
    categoryLower.includes('falafel') ||
    categoryLower.includes('shawarma')
  ) {
    return {
      icon: <FastfoodTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#FF1493',
    };
  }

  if (
    categoryLower.includes('restaurant') ||
    categoryLower.includes('bistro') ||
    categoryLower.includes('food') ||
    categoryLower.includes('sandwich') ||
    categoryLower.includes('cafe') ||
    categoryLower.includes('bagel') ||
    categoryLower.includes('deli') ||
    categoryLower.includes('canteen') ||
    categoryLower.includes('steak') ||
    categoryLower.includes('diner') ||
    categoryLower.includes('creperie')
  ) {
    return {
      icon: <RestaurantTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#FF1493',
    };
  }

  if (
    categoryLower.includes('store') ||
    categoryLower.includes('shop') ||
    categoryLower.includes('market')
  ) {
    return {
      icon: <StorefrontTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#4682B4',
    };
  }

  if (categoryLower.includes('peak') || categoryLower.includes('forest')) {
    return {
      icon: <ForestTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#32CD32',
    };
  }

  if (categoryLower.includes('mountain')) {
    return {
      icon: <HikingTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#32CD32',
    };
  }

  if (categoryLower.includes('waterfall')) {
    return {
      icon: <WaterDropTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#00FFFF',
    };
  }

  if (categoryLower.includes('attraction')) {
    return {
      icon: <LocalSeeTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#1E90FF',
    };
  }

  if (categoryLower.includes('hindu') && categoryLower.includes('temple')) {
    return {
      icon: <TempleHinduTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#B8860B',
    };
  }

  if (categoryLower.includes('buddhist') && categoryLower.includes('temple')) {
    return {
      icon: <TempleBuddhistTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#B8860B',
    };
  }

  if (categoryLower.includes('cultural')) {
    return {
      icon: <AccountBalanceTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#B8860B',
    };
  }

  if (
    categoryLower.includes('ecological_park') ||
    categoryLower.includes('city_park') ||
    categoryLower.includes('garden') ||
    categoryLower === 'park'
  ) {
    return {
      icon: <NaturePeopleTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#32CD32',
    };
  }

  if (categoryLower.includes('attraction')) {
    return {
      icon: <LocalActivityTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#FF5533',
    };
  }

  switch (type) {
    case 'CATEGORY_FOOD_DRINK':
      return {
        icon: <FastfoodTwoToneIcon sx={{ ...baseStyle }} />,
        color: '#FF1493',
      };
    case 'CATEGORY_ACCOMMODATION':
      return {
        icon: <HotelTwoToneIcon sx={{ ...baseStyle }} />,
        color: '#013B94',
      };
    case 'CATEGORY_TRANSPORT':
      return {
        icon: <DirectionsBusFilledTwoToneIcon sx={{ ...baseStyle }} />,
        color: '#007BFF',
      };
    case 'CATEGORY_ACTIVITY':
      return {
        icon: <LocalActivityTwoToneIcon sx={{ ...baseStyle }} />,
        color: '#FF5533',
      }; // Example: Adjust as needed
  }

  switch (source) {
    case 'accomodation':
      return {
        icon: <HotelTwoToneIcon sx={{ ...baseStyle }} />,
        color: '#013B94',
      };
    case 'activity':
      return {
        icon: <LocalActivityTwoToneIcon sx={{ ...baseStyle }} />,
        color: '#FF5533',
      };
  }

  if (categoryLower.includes('pub') || categoryLower.includes('bar')) {
    return {
      icon: <NightlifeTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#8B0000',
    };
  }

  if (categoryLower.includes('hotel')) {
    return {
      icon: <HotelTwoToneIcon sx={{ ...baseStyle }} />,
      color: '#013B94',
    };
  }

  // default
  return {
    icon: <LocalSeeTwoToneIcon sx={{ ...baseStyle }} />,
    color: '#FFB347',
  };
};
