import { Box, styled, Typography } from '@mui/material';

import Space from '../../theme-components/Spacing';
import Inspiration from '../illustrations/Insiration';

import { BoxActivity } from './HomeHeader';

const Illustration = styled(Inspiration)(({ theme }) => ({
  width: 350,
  height: 250,
  [theme.breakpoints.down('sm')]: {
    width: 300,
    height: 200,
  },
}));

export function HomeGetInspiredLanding() {
  return (
    // <GradientContainer gradient="linear-gradient(180deg, #F2DDF7 0%, #FFFFFF 100%)">
    <Box
      sx={theme => ({
        paddingX: 3,
        marginTop: 3,
        // paddingY: 3,
        borderRadius: 10,
        background: 'rgba(255, 255, 255, 0.4)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Add subtle shadow
        marginX: 10,
        [theme.breakpoints.down('xl')]: {
          marginX: 10,
        },
        [theme.breakpoints.down('lg')]: {
          marginX: 5,
        },
        [theme.breakpoints.down('md')]: {
          marginX: 5,
          paddingBottom: 6,
        },
        [theme.breakpoints.down('sm')]: {
          marginX: 2,
        },
      })}
    >
      <BoxActivity>
        <Illustration />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={theme => ({
            maxWidth: 550,
            paddingLeft: 5,
            paddingTop: 0,
            [theme.breakpoints.down('xl')]: {
              maxWidth: 550,
            },
            [theme.breakpoints.down('lg')]: {
              maxWidth: 550,
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: 4,
              paddingLeft: 0,
              maxWidth: 350,
            },
            [theme.breakpoints.down('sm')]: {
              maxWidth: 280,
            },
          })}
        >
          <Typography
            component="h3"
            sx={theme => ({
              fontWeight: 800,
              fontSize: 25,
              color: '#5A44EB',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Get Inspired
          </Typography>
          <Typography
            sx={theme => ({
              fontWeight: 600,
              fontSize: 20,
              color: '#3F3D56',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Browse existing trips for ideas
          </Typography>
          <Space size="sm" />
          <Typography
            component="h3"
            sx={theme => ({
              fontWeight: 800,
              fontSize: 25,
              color: '#5A44EB',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Drag & Drop or Save
          </Typography>
          <Typography
            sx={theme => ({
              fontWeight: 600,
              fontSize: 20,
              color: '#3F3D56',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Easily add activities or places into your itinerary
          </Typography>
          <Space size="sm" />
          <Typography
            component="h3"
            sx={theme => ({
              fontWeight: 800,
              fontSize: 25,
              color: '#5A44EB',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Create Your Holiday
          </Typography>
          <Typography
            sx={theme => ({
              fontWeight: 600,
              fontSize: 20,
              color: '#3F3D56',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Personalize it to make it truly unique
          </Typography>
        </Box>
      </BoxActivity>
    </Box>
  );
}
