import {
  Box,
  Button,
  CardMedia,
  Link,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { lazy, Suspense } from 'react';

import { CountryTrips } from '../../generated/public_graphql';
import { TitleH1, TitleH2, TitleH3 } from '../../theme-components/Typography';
import { GradientContainer } from '../home/HomeHeader';
import { SharedTripsWithUser } from '../user-account/UserTrips';

import { CountryFlagNoMargin, SideBarIcon } from './CountrySidebar';
import { PublicTripItem } from '../trips-list/PublicTripItem';
import { useNavigate } from 'react-router-dom';
import { CountryInfoLinks, TripCountryBox } from './CountryInfo';
import { CountryInfoLinksById } from './CountryFeatures';
import LazyLoadComponent from '../../theme-components/LazyLoadComponent';

export function CountriesTrips(props: {
  countryTrips: CountryTrips;
  gradient: string;
}) {
  const navigate = useNavigate();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mainImage = props.countryTrips.country?.images?.find(
    image => image?.main,
  );

  const secondaryImage = props.countryTrips.country?.images?.find(
    image => image?.secondary,
  );

  const tertiaryImage = props.countryTrips.country?.images?.find(
    image => image?.tertiary,
  );

  return (
    <Box
      width="100%"
      sx={theme => ({
        // marginTop: 2,
        // [theme.breakpoints.down('sm')]: {
        //   marginTop: 0,
        // },
      })}
    >
      <Stack
        direction="column"
        sx={theme => ({
          borderRadius: '20px',
          // border: '2px solid rgba(211, 211, 211, 0.8)',
          // backgroundColor: '#FFF8EE',
          backgroundColor: 'rgba(255, 248, 238, 0.9)',
          marginBottom: 3,
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '40px',
          borderBottomLeftRadius: '40px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          [theme.breakpoints.down('sm')]: {
            marginBottom: 1.5,
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '40px',
            borderBottomLeftRadius: '20px',
            // backgroundColor: 'transparent',
          },
        })}
      >
        {props.countryTrips.country && (
          <Box sx={{ position: 'relative' }}>
            <>
              {mainImage && secondaryImage && tertiaryImage ? (
                <Stack
                  direction="row"
                  onClick={() =>
                    props.countryTrips.country?.id &&
                    navigate(
                      props.countryTrips.country.slug
                        ? '/country-itineraries/' +
                            props.countryTrips.country.slug
                        : '/country-itineraries/' +
                            props.countryTrips.country.id,
                    )
                  }
                  sx={theme => ({
                    borderTopRightRadius: '40px',
                    borderBottomLeftRadius: '40px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Add bottom shadow
                    overflow: 'hidden',
                    [theme.breakpoints.down('sm')]: {
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px',
                    },
                    [theme.breakpoints.down('sm')]: {},
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '40px',
                      overflow: 'hidden',
                      width: '64%',
                      pr: 0.5,
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 16,
                        pr: '2px',
                      },
                    })}
                  >
                    <CardMedia
                      component="img"
                      alt={
                        props.countryTrips.country?.name
                          ? 'Image of ' + props.countryTrips.country?.name
                          : 'Country image'
                      }
                      image={
                        mainImage?.mediumPath ||
                        mainImage?.previewPath ||
                        mainImage?.smallPath ||
                        mainImage?.path ||
                        ''
                      }
                      sx={theme => ({
                        height: 400,
                        [theme.breakpoints.down('sm')]: {
                          height: 250,
                        },
                      })}
                    />
                    <Box
                      sx={theme => ({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(10px)',
                        paddingY: 1.5,
                        paddingX: 4,
                        borderRadius: '40px 0 40px 0',
                        borderTopLeftRadius: '0px',
                        borderBottomRightRadius: '20px',
                        [theme.breakpoints.down('sm')]: {
                          paddingY: 0,
                          top: 0,
                          paddingLeft: 1.5,
                          paddingRight: 2,
                        },
                      })}
                    >
                      <Stack direction="row" alignItems="center">
                        {isSmallScreen ? (
                          <CountryFlagNoMargin
                            width={30}
                            countryCode={props.countryTrips.country.iso2Code}
                          />
                        ) : (
                          <CountryFlagNoMargin
                            width={40}
                            countryCode={props.countryTrips.country.iso2Code}
                          />
                        )}

                        <Box
                          component={Link}
                          href={
                            props.countryTrips.country.slug
                              ? '/country-itineraries/' +
                                props.countryTrips.country.slug
                              : '/country-itineraries/' +
                                props.countryTrips.country.id
                          }
                          sx={{ textDecoration: 'none' }}
                        >
                          <Typography
                            component={'h3'}
                            sx={theme => ({
                              fontWeight: 700,
                              marginLeft: 2,
                              fontSize: 30,
                              color: '#001B30',
                              [theme.breakpoints.down('sm')]: {
                                marginLeft: 1,
                                fontSize: 25,
                              },
                            })}
                          >
                            {props.countryTrips.country.name}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                  <Stack direction={'column'} width="36%">
                    <Box
                      sx={theme => ({
                        borderTopRightRadius: '40px',
                        borderBottomLeftRadius: '0px',
                        overflow: 'hidden',
                        pb: 0.5,
                        [theme.breakpoints.down('sm')]: {
                          fontSize: 16,
                          pb: '2px',
                        },
                      })}
                    >
                      <CardMedia
                        component="img"
                        image={
                          secondaryImage?.previewPath ||
                          secondaryImage?.mediumPath ||
                          secondaryImage?.smallPath ||
                          secondaryImage?.path ||
                          ''
                        }
                        alt={
                          props.countryTrips.country?.name
                            ? 'Image of ' + props.countryTrips.country?.name
                            : 'Country image'
                        }
                        sx={theme => ({
                          height: 200,
                          [theme.breakpoints.down('sm')]: {
                            height: 125,
                          },
                        })}
                      />
                    </Box>
                    <Box
                      sx={theme => ({
                        // borderBottomRightRadius: '40px',
                        overflow: 'hidden',
                        [theme.breakpoints.down('sm')]: {
                          // borderRadius: '0px',
                          fontSize: 16,
                        },
                      })}
                    >
                      <CardMedia
                        component="img"
                        image={
                          tertiaryImage?.previewPath ||
                          tertiaryImage?.mediumPath ||
                          tertiaryImage?.smallPath ||
                          tertiaryImage?.path ||
                          ''
                        }
                        alt={
                          props.countryTrips.country?.name
                            ? 'Image of ' + props.countryTrips.country?.name
                            : 'Country image'
                        }
                        sx={theme => ({
                          height: 196,
                          [theme.breakpoints.down('sm')]: {
                            height: 123,
                          },
                        })}
                      />
                    </Box>
                  </Stack>
                </Stack>
              ) : (
                <>
                  {mainImage ? (
                    <Box
                      onClick={() =>
                        props.countryTrips.country?.id &&
                        navigate(
                          props.countryTrips.country.slug
                            ? '/country-itineraries/' +
                                props.countryTrips.country.slug
                            : '/country-itineraries/' +
                                props.countryTrips.country.id,
                        )
                      }
                      sx={theme => ({
                        borderTopLeftRadius: '0px',
                        borderTopRightRadius: '40px',
                        borderBottomLeftRadius: '40px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Add bottom shadow
                        borderBottomRightRadius: '0px',
                        overflow: 'hidden',
                        width: '100%',
                        [theme.breakpoints.down('sm')]: {
                          fontSize: 16,

                          mt: 0,
                        },
                      })}
                    >
                      <CardMedia
                        component="img"
                        image={mainImage.path!}
                        alt={
                          props.countryTrips.country?.name
                            ? 'Image of ' + props.countryTrips.country?.name
                            : 'Country image'
                        }
                        sx={theme => ({
                          height: 500,
                          [theme.breakpoints.down('lg')]: {
                            height: 400,
                          },
                          [theme.breakpoints.down('md')]: {
                            height: 370,
                          },
                          [theme.breakpoints.down('sm')]: {
                            height: 200,
                          },
                        })}
                      />
                      <Box
                        sx={theme => ({
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          backdropFilter: 'blur(10px)',
                          paddingY: 2,
                          paddingX: 4,
                          borderRadius: '0px 0 30px 0',
                          [theme.breakpoints.down('sm')]: {
                            paddingY: 1,
                            paddingLeft: 2,
                            paddingRight: 3,
                          },
                        })}
                      >
                        <Stack direction="row" alignItems="center">
                          {isSmallScreen ? (
                            <CountryFlagNoMargin
                              width={30}
                              countryCode={props.countryTrips.country.iso2Code}
                            />
                          ) : (
                            <CountryFlagNoMargin
                              width={40}
                              countryCode={props.countryTrips.country.iso2Code}
                            />
                          )}
                          <Box
                            component={Link}
                            href={
                              props.countryTrips.country.slug
                                ? '/country-itineraries/' +
                                  props.countryTrips.country.slug
                                : '/country-itineraries/' +
                                  props.countryTrips.country.id
                            }
                            sx={{ textDecoration: 'none' }}
                          >
                            <Typography
                              component={'h3'}
                              sx={theme => ({
                                fontWeight: 700,
                                marginLeft: 2,
                                fontSize: 30,
                                color: '#001B30',
                                [theme.breakpoints.down('sm')]: {
                                  marginLeft: 1,
                                  fontSize: 25,
                                },
                              })}
                            >
                              {props.countryTrips.country.name}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        onClick={() =>
                          props.countryTrips.country?.id &&
                          navigate(
                            props.countryTrips.country.slug
                              ? '/country-itineraries/' +
                                  props.countryTrips.country.slug
                              : '/country-itineraries/' +
                                  props.countryTrips.country.id,
                          )
                        }
                        sx={theme => ({
                          paddingTop: 1.5,
                          paddingLeft: 3,
                          borderTopRightRadius: '40px',
                          backgroundColor: 'rgba(222, 224, 252, 1)',
                          // borderBottom: '2px solid rgba(211, 211, 211, 0.8)',
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Add bottom shadow

                          [theme.breakpoints.down('md')]: {
                            marginTop: 0,
                            paddingY: 0.5,
                            paddingLeft: 1.5,
                          },
                        })}
                      >
                        {isSmallScreen ? (
                          <CountryFlagNoMargin
                            width={35}
                            countryCode={props.countryTrips.country.iso2Code}
                          />
                        ) : (
                          <CountryFlagNoMargin
                            width={50}
                            countryCode={props.countryTrips.country.iso2Code}
                          />
                        )}
                        <Typography
                          sx={theme => ({
                            fontWeight: 700,
                            marginLeft: 2,
                            fontSize: 40,
                            color: '#001B30',
                            [theme.breakpoints.down('sm')]: {
                              marginLeft: 1,
                              fontSize: 25,
                            },
                          })}
                        >
                          {props.countryTrips.country.name}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </>
              )}
            </>
          </Box>
        )}
        {/* </StickyHeader> */}
        {/* {props.countryTrips.country && (
          <>
            {isSmallScreen ? (
              <Box sx={{ paddingX: 1.5, marginTop: 1 }}>
                <TripCountryBox country={props.countryTrips.country} />
              </Box>
            ) : (
              <>
                <CountryInfoLinks
                  country={props.countryTrips.country}
                  alignLeft={true}
                />
              </>
            )}
          </>
        )} */}
        <CountryInfoLinksById countryId={props.countryTrips.country?.id} />
        <Stack
          direction="column"
          // maxWidth="100%"
          sx={theme => ({
            // background: 'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
            paddingRight: 1,
            // paddingTop: 1.5,
            marginTop: 1.5,
            paddingLeft: 3,
            marginBottom: 1.5,
            [theme.breakpoints.down('sm')]: {
              paddingRight: 0.5,
              paddingTop: 0,
              paddingBottom: 1,
              marginTop: 0,
              paddingLeft: 0.5,
              marginBottom: 1.5,
              marginLeft: 1,
              marginRight: 1,
            },
          })}
        >
          <Box
            sx={theme => ({
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              // marginTop: 3,
              // paddingLeft: 2,
              marginBottom: 0,
              [theme.breakpoints.down('sm')]: {
                marginTop: 1.5,
                // marginBottom: 1,
              },
            })}
          >
            <Typography
              sx={theme => ({
                fontWeight: 700,
                fontSize: 30,
                color: '#001B30',
                [theme.breakpoints.down('sm')]: {
                  marginLeft: 0.5,
                  fontSize: 25,
                },
              })}
            >
              Start Exploring Now
            </Typography>
          </Box>
          <PublicTripItem
            trips={
              props.countryTrips.countryTrips
                ? props.countryTrips.countryTrips
                : []
            }
          />
        </Stack>
        {/* </GradientContainer> */}
        <Box
          sx={theme => ({
            width: '100%',
            paddingX: 3,
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
              paddingX: 1.5,
            },
          })}
        >
          <Button
            component={Link}
            href={'/country-itineraries/' + props.countryTrips.country?.id}
            variant="outlined"
            sx={theme => ({
              color: '#5A44EB',
              textDecoration: 'none',
              borderRadius: '40px',
              fontSize: 20,
              fontWeight: 700,
              overflow: 'hidden',
              marginTop: 1.5,
              marginBottom: 4,
              border: '2px solid',
              backgroundColor: 'rgba(250, 250, 250, 0.9)',
              paddingX: 4,
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                fontSize: 18,
                marginTop: 1,
                paddingX: 0,
                marginBottom: 3,
              },
            })}
          >
            Explore {props.countryTrips.country?.name}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
