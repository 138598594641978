import LuggageTwoToneIcon from '@mui/icons-material/LuggageTwoTone';
import {
  Box,
  Typography,
  Grid,
  ContainerProps,
  BoxProps,
  styled,
  CardMedia,
  Button,
  Link,
} from '@mui/material';
import { maxWidth } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import {
  GetTheAppButton,
  ProminentButton,
} from '../../theme-components/Buttons';
import { theme } from '../AppWrapper';
import Journey from '../illustrations/Journey';
import Landing from '../illustrations/Landing';

interface GradientContainerProps extends ContainerProps {
  gradient?: string;
}

interface ColorWordProps extends BoxProps {
  marginRight?: string;
  marginLeft?: string;
}

export const OuterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
    width: '100vw',
  },
  // [theme.breakpoints.down('sm')]: {
  //   width: '100',
  // },

  // backgroundColor: '#FAF7F6', // Light background color for the main area
  // padding: theme.spacing(2), // Less space around the container
}));

export const Description = styled(Box)(({ theme }) => ({
  color: '#001B30',
  fontWeight: 600,
  fontSize: 35,
  [theme.breakpoints.down('md')]: {
    fontSize: 25,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

export const BoxPadding = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(3),
  },
}));

export const BoxActivity = styled(Box)(({ theme }) => ({
  display: 'flex',
  // justifyContent: 'space-around',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    paddingBottom: theme.spacing(0),
  },
  // [theme.breakpoints.down('sm')]: {
  //   flexWrap: 'wrap',
  //   justifyContent: 'center',
  // },
}));

export const GradientContainer = styled(Box)<GradientContainerProps>(
  ({ theme, gradient }) => ({
    width: '100%',
    background: gradient || 'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      borderRadius: 0,
    },
  }),
);

export const ColorWord = styled(Box, {
  shouldForwardProp: prop => prop !== 'marginRight' && prop !== 'marginLeft',
})<ColorWordProps>(({ theme, marginRight = '0', marginLeft = '0' }) => ({
  color: '#624AF2',
  fontWeight: 600,
  fontSize: 35,
  marginRight,
  marginLeft,
  [theme.breakpoints.down('md')]: {
    fontSize: 25,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const HeroTextContainer = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  paddingLeft: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(-5),
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    textAlign: 'center',
  },
}));
const HeroTextContainerLanding = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  width: '100%',
  // paddingLeft: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    // marginTop: theme.spacing(-5),
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    // marginTop: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// const Title = styled(Typography)(({ theme }) => ({
//   color: '#333',
//   fontWeight: 700,
//   fontSize: 50,
//   [theme.breakpoints.down('lg')]: {
//     fontSize: 45,
//   },
//   [theme.breakpoints.down('md')]: {
//     alignSelf: 'center',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 30,
//   },
// }));

const HighlightedText = styled('span')(({ theme }) => ({
  color: '#FF6484',
  textDecoration: 'underline',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 500,
  color: '#555',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const IllustrationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(-3),
  marginBottom: theme.spacing(-3),

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(-3),
  },
}));

const IllustrationContainerLanding = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('md')]: {},
}));

export function HomeHeaderLanding() {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        sx={theme => ({
          marginTop: 10,
          marginBottom: 8,
          height: '100%',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            alignItems: 'flex-end',
            marginBottom: 0,
          },
        })}
      >
        <Grid item xs={12} md={6}>
          <HeroTextContainerLanding>
            <Typography
              component="h1"
              sx={theme => ({
                color: '#333',
                fontWeight: 700,
                fontSize: 50,
                lineHeight: 0.75,
                mb: 4,
                [theme.breakpoints.down('lg')]: {
                  fontSize: 45,
                },
                [theme.breakpoints.down('md')]: {
                  alignSelf: 'center',
                  mb: 1,
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: 30,
                },
              })}
            >
              <Typography
                component="span"
                sx={{
                  // color: theme.palette.primary.main,
                  // color: '#5A44EB',

                  textDecoration: 'underline',
                  textDecorationColor: '#FF4D3E',
                  fontWeight: 'inherit',
                  fontSize: 'inherit',
                }}
                onClick={() => {
                  gtag('event', 'app-bar-create-trip');
                  navigate('/explore');
                }}
              >
                Find
              </Typography>{' '}
              or{' '}
              <Typography
                component="span"
                sx={theme => ({
                  // color: theme.palette.primary.main,
                  // color: '#5A44EB',
                  textDecoration: 'underline',
                  textDecorationColor: '#FF4D3E',
                  fontWeight: 'inherit',
                  fontSize: 'inherit',
                })}
                onClick={() => {
                  gtag('event', 'app-bar-create-trip');
                  navigate('/my-trips');
                }}
              >
                Create
              </Typography>{' '}
              your perfect itinerary
            </Typography>
            <Subtitle>
              Create the perfect travel itinerary with our curated itineraries,
              guides, maps, and expert tips for your next vacation.
            </Subtitle>
            <Box
              sx={theme => ({
                // display: 'flex',
                display: {
                  xs: 'none', // Show on extra-small screens
                  sm: 'none', // Hide on small screens
                  md: 'flex', // Hide on medium screens
                  lg: 'flex', // Show on large screens
                  xl: 'flex', // Show on extra-large screens
                },
                justifyContent: 'center',
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column', // Switch to column for screens smaller than 'md'
                },
              })}
            >
              <Button
                startIcon={<LuggageTwoToneIcon />}
                variant="contained"
                sx={theme => ({
                  borderRadius: '100px',
                  backgroundColor: '#5A44EB',
                  marginTop: 5,
                  alignSelf: 'center',
                  marginRight: 2,
                  fontSize: 18,
                  fontWeight: 600,
                  paddingX: 4,
                  paddingY: 1.5,
                  whiteSpace: 'nowrap',

                  [theme.breakpoints.down('md')]: {
                    marginTop: 2,
                    // width: 'auto',
                  },
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                    width: 'auto',
                    display: 'inline-flex',
                  },
                })}
                onClick={() => {
                  gtag('event', 'app-bar-create-trip');
                  navigate('/my-trips');
                }}
              >
                Create Your Itinerary
              </Button>
              <GetTheAppButton
                to="/download"
                component={Link}
                sx={theme => ({
                  marginTop: 5,
                  marginRight: 2,
                  [theme.breakpoints.down('md')]: {
                    marginTop: 2,
                  },
                })}
                onClick={() => {
                  gtag('event', 'app-bar-create-trip');
                  navigate('/download');
                }}
              >
                Get The App
              </GetTheAppButton>
            </Box>
          </HeroTextContainerLanding>
        </Grid>
        {/* <Grid spacing={{ md: 0, lg: 0 }} item xs={12} md={6}> */}
        <Grid item xs={12} md={6}>
          <IllustrationContainerLanding>
            <Box
              sx={theme => ({
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                width: '100%',
                [theme.breakpoints.down('md')]: {
                  marginTop: 3,
                  width: '60%',
                },
                [theme.breakpoints.down('sm')]: {
                  width: '80%',
                },
              })}
            >
              <Landing
                sx={theme => ({
                  // width: '100%',
                  // maxWidth: '200px',
                  // height: 'auto',
                  // [theme.breakpoints.down('md')]: { maxWidth: '300px' },
                })}
              />
            </Box>
          </IllustrationContainerLanding>
        </Grid>
        <Box
          sx={theme => ({
            // display: 'flex',
            display: {
              xs: 'flex', // Show on extra-small screens
              sm: 'flex', // Hide on small screens
              md: 'none', // Hide on medium screens
              lg: 'none', // Show on large screens
              xl: 'none', // Show on extra-large screens
            },
            width: '100%',
            justifyContent: 'center',
            marginBottom: 3,
            [theme.breakpoints.down('sm')]: {
              marginBottom: 2,
              flexDirection: 'column', // Switch to column for screens smaller than 'md'
            },
          })}
        >
          <Button
            startIcon={<LuggageTwoToneIcon />}
            variant="contained"
            sx={theme => ({
              borderRadius: '100px',
              backgroundColor: '#5A44EB',
              marginTop: 5,
              alignSelf: 'center',
              marginRight: 2,
              fontSize: 18,
              fontWeight: 600,
              paddingX: 4,
              paddingY: 1.5,
              whiteSpace: 'nowrap',
              [theme.breakpoints.down('md')]: {
                marginTop: 2,
                // width: 'auto',
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                width: 'auto',
                display: 'inline-flex',
              },
            })}
            onClick={() => {
              gtag('event', 'app-bar-create-trip');
              navigate('/my-trips');
            }}
          >
            Create Your Itinerary
          </Button>
          <GetTheAppButton
            sx={theme => ({
              marginTop: 5,
              marginRight: 2,
              [theme.breakpoints.down('md')]: {
                marginTop: 2,
              },
            })}
            onClick={() => {
              gtag('event', 'app-bar-create-trip');
              navigate('/download');
            }}
          >
            Get The App
          </GetTheAppButton>
        </Box>
      </Grid>
    </>
  );
}
