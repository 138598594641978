import { useMutation, useQuery } from '@apollo/client';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import LuggageTwoToneIcon from '@mui/icons-material/LuggageTwoTone';
import PersonIcon from '@mui/icons-material/PersonOutlineTwoTone';
import TourTwoToneIcon from '@mui/icons-material/TourTwoTone';
import {
  Stack,
  IconButton,
  Avatar,
  Button,
  Link,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserPublicInfo } from '../../generated/public_graphql';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { setUser, userSelector } from '../../store/UserSlice';
import Space from '../../theme-components/Spacing';
import {
  Body1,
  H1Title,
  H2Title,
  TitleH3,
} from '../../theme-components/Typography';
import { unauthorisedClient } from '../../utils/auth';
import { useAuth } from '../auth/firebase';
import SignUp from '../auth/SignUp';
import { ActionsTrip } from '../create-trip/edit-trip/ActionsTrip';
import EditTripDescriptionModal from '../create-trip/manage-trip-modals/AddEditDescription';
import AddStartDateModal from '../create-trip/manage-trip-modals/AddStartDateModal';
import { QUERY_GET_SIDEBAR_COUNTRIES } from '../gql-public/sideBarQuery';
import { QUERY_GET_USER_SIDEBAR_COUNTRIES } from '../gql-user/sideBarQuery';
import { MUTATION_FOLLOW_USER } from '../gql-user/user';
import {
  RenderHtmlFromResponse,
  RenderHtmlTest,
} from '../RenderHtmlFromResponse';

import CopyTrip from './CopyTrip';
import { SideScrollRecommendations } from './Recommendation';
import { TripUser } from './TripItinerary';
import { LikeTrip } from '../favourite/likeTrip';
import { TripImagesModal } from './TripPhotosModal';
import { isPrerenderBot } from '../../utils/helpers';

type TripDetailsProps = {
  tripUser: TripUser;
  sharableTripId?: string | null;
};

export function TripDetails({ tripUser, sharableTripId }: TripDetailsProps) {
  let isPreview = false;
  const location = useLocation(); // React Router hook (optional)
  const { publicTrip } = useSelector(publicTripsSelector);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);

  const [hasSelectedTrip, setHasSelectedTrip] = useState(false);

  const { trip: userTrip } = useSelector(tripsSelector);

  useEffect(() => {
    // Get the query parameters from the current URL
    const queryParams = new URLSearchParams(location.search); // or window.location.search
    const hasSelectedTripParam = queryParams.has('selected-trip'); // Check if 'selected-trip' exists

    // Set the boolean value based on the presence of 'selected-trip'
    setHasSelectedTrip(hasSelectedTripParam);
  }, [location]);

  const handleClose = () => {
    setIsDescriptionModalOpen(false);
  };

  const trip = tripUser === 'user' ? userTrip : publicTrip;

  if (window.location.href.indexOf('preview') > -1) {
    isPreview = true;
  }

  if (!trip) {
    return <></>;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
      })}
    >
      <Stack
        direction="column"
        width="100%"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '20px',
          paddingY: 1.5,
          paddingX: 1,
          marginTop: 1.5,
          paddingLeft: 1.5,
          marginBottom: 1,
          backgroundColor: '#FFFEFD',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {},
          })}
        >
          <Stack
            direction="row"
            width="100%"
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {},
            })}
          >
            <LuggageTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                marginTop: 0.25,
                marginRight: 1,
                color: '#F39C6A',
                [theme.breakpoints.down('sm')]: {
                  // marginTop: 1,
                },
              })}
            />

            <H1Title color="#001B30">{trip.name}</H1Title>
          </Stack>
          <Box
            sx={{
              mr: 0.75,
            }}
          >
            <LikeTrip
              tripId={trip.id}
              width="30px"
              height="30px"
              postion="inline"
            />
          </Box>
          {trip &&
            tripUser !== 'user' &&
            (!trip.tripType || trip.tripType !== 'guide') && (
              <Stack direction={'row'}>
                <CopyTrip
                  tripId={trip.id}
                  sharableTripId={sharableTripId}
                  tripAccess={trip.tripAccess}
                />
              </Stack>
            )}

          {trip && tripUser === 'user' && <ActionsTrip trip={trip} />}
          {/* </>
          </Box> */}
        </Stack>
        <Space size="xs" />

        {trip &&
          trip.userDetails &&
          trip.userDetails.nickName &&
          tripUser !== 'user' && (
            <Stack
              direction="row"
              width="100%"
              sx={theme => ({
                [theme.breakpoints.down('sm')]: {},
              })}
            >
              <ContainedUserProfile tripUser={trip.userDetails} />
            </Stack>
          )}
        {trip.length && (!trip.tripType || trip.tripType === 'itinerary') && (
          <>
            <Stack
              direction="row"
              width="100%"
              sx={theme => ({
                [theme.breakpoints.down('sm')]: {},
              })}
            >
              <HistoryToggleOffTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: '#F39C6A',
                  [theme.breakpoints.down('sm')]: {},
                })}
              />
              <Typography
                sx={theme => ({
                  fontWeight: 600,
                  fontSize: 16,
                  color: '#2E3C44',
                  whiteSpace: 'nowrap', // Force text to one line
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                  },
                })}
              >
                {trip.length} day{trip.length > 1 ? 's' : ''} itinerary
              </Typography>
            </Stack>
            <Space size="xs" />
          </>
        )}

        {trip &&
          trip.itineraryCountries &&
          trip.itineraryCountries.length > 0 &&
          (!trip.tripType || trip.tripType !== 'guide') && (
            <>
              <Stack direction="row" width="100%">
                <TourTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 1,
                    color: '#F39C6A',
                  })}
                />
                <Typography
                  sx={theme => ({
                    fontWeight: 600,
                    fontSize: 16,
                    color: '#2E3C44',
                    marginRight: 0.5,
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 16,
                    },
                  })}
                >
                  {trip.itineraryCountries.length > 1
                    ? 'Countries '
                    : 'Country '}
                  {trip.itineraryCountries.map((country, index) => (
                    <Typography
                      key={index}
                      component="span"
                      sx={theme => ({
                        fontWeight: 600,
                        fontSize: 16,
                        color: '#6E7191',
                        [theme.breakpoints.down('sm')]: {
                          fontSize: 16,
                        },
                      })}
                    >
                      {country && (
                        <>
                          {country.countryName}
                          {trip.itineraryCountries &&
                            index !== trip.itineraryCountries.length - 1 && (
                              <>{', '}</>
                            )}
                        </>
                      )}
                    </Typography>
                  ))}
                </Typography>
              </Stack>
              <Space size="xs" />
            </>
          )}

        {trip &&
          tripUser === 'user' &&
          (!trip.tripType || trip.tripType !== 'guide') && (
            <>
              {trip.startDate ? (
                <AddStartDateModal
                  tripId={trip.id}
                  date={trip.startDate}
                  add={false}
                />
              ) : (
                <AddStartDateModal
                  tripId={trip!.id}
                  date={new Date()}
                  add={true}
                />
              )}
            </>
          )}
      </Stack>
      {trip.description && trip.description !== '<p></p>' ? (
        <>
          <Box sx={{ paddingLeft: 1 }}>
            <H2Title>Overview</H2Title>
          </Box>
          <Box
            // direction="column"
            alignItems="left"
            sx={theme => ({
              width: '100%',
              marginTop: 1,
              paddingY: 0.5,
              marginBottom: 1,
              // border: 2,
              // borderColor: '#E4DBFF',
              paddingX: 1.5,
              borderRadius: '20px',
              // backgroundColor: '#f6f0ff',
              // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#FFFEFD',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
              },
            })}
          >
            <Body1>
              <RenderHtmlTest
                content={trip.description}
                limitToLines={isPrerenderBot() ? 500 : 7}
              />
            </Body1>
          </Box>
        </>
      ) : (
        <>
          {trip && tripUser === 'user' && (
            <>
              <Box sx={{ paddingLeft: 1 }}>
                <H2Title>Overview</H2Title>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<AddCircleTwoToneIcon />}
                  onClick={e => {
                    gtag('event', 'add-trip-description');
                    setIsDescriptionModalOpen(true);
                  }}
                  sx={{
                    borderRadius: '20px',
                    border: '2px solid',
                    right: 0,
                    fontSize: 14,
                    fontWeight: 600,
                    marginY: 1,
                  }}
                >
                  {trip && trip.tripType && trip.tripType === 'guide' ? (
                    <>{'Add guide overview'}</>
                  ) : (
                    <>{'Add trip overview'}</>
                  )}
                </Button>
              </Box>
            </>
          )}
        </>
      )}
      {trip.recommendations &&
      trip.recommendations.length > 0 &&
      (trip.recommendations[0]?.place || trip.recommendations[0]?.activity) ? (
        <Box mb={2}>
          <SideScrollRecommendations
            recommentations={trip.recommendations}
            type="trip"
            tripUser={tripUser}
          />
        </Box>
      ) : (
        <Box mb={2} />
      )}
      {isDescriptionModalOpen && (
        <EditTripDescriptionModal
          description={trip.description}
          editTripId={trip.id}
          tripType={trip && trip.tripType ? trip.tripType : undefined}
          handleCloseModal={handleClose}
        />
      )}
    </Box>
  );
}

export function ContainedUserProfile(props: {
  tripUser: UserPublicInfo;
  renderSmaller?: boolean;
}) {
  const { isAuthenticated } = useAuth();
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const { myUser } = useSelector(userSelector);

  const [followUser, { data }] = useMutation(MUTATION_FOLLOW_USER, {
    variables: {
      following_user_id: props.tripUser.id,
      follow: !isFollowing,
    },
  });

  useEffect(() => {
    if (myUser) {
      const following =
        myUser.following &&
        myUser.following.some(follower => follower!.id === props.tripUser.id);
      if (following === true || following === false) {
        setIsFollowing(following);
      }
    }
  }, [myUser]);

  useEffect(() => {
    if (data && data.followUser) {
      dispatch(setUser(data.followUser)); // Dispatch the updated user info to the store
    }
  }, [data?.followUser]);

  let userHandle = '';
  if (!!props.tripUser.nickName) {
    userHandle = props.tripUser.nickName;
  } else {
    userHandle = props.tripUser.id;
  }
  return (
    <>
      {props.tripUser && props.tripUser.nickName && (
        <Stack
          direction="row"
          alignItems="center"
          onClick={e => e.stopPropagation()}
        >
          <Avatar
            alt={
              props.tripUser.nickName
                ? props.tripUser.nickName
                : 'Profile Picture'
            }
            src={
              props.tripUser.profilePictureUrl
                ? props.tripUser.profilePictureUrl
                : ''
            }
            sx={theme => ({
              // width: props.tripUser.profilePictureUrl ? 25 : 25,
              // height: props.tripUser.profilePictureUrl ? 25 : 25,
              width: props.renderSmaller ? 16 : 25,
              height: props.renderSmaller ? 16 : 25,
              marginRight: props.renderSmaller ? 0.5 : 1,
              color: '#F39C6A',
              // [theme.breakpoints.down('sm')]: {
              //   marginRight: 1,
              //   width: 25,
              //   height: 25,
              // },
            })}
          >
            <PersonIcon />
          </Avatar>
          <Box
            sx={{
              display: 'inline-block', // Ensures the background applies only to the text
              borderRadius: '4px',
              // padding: 0.5, // Rounded corners
              '&:hover': {
                backgroundColor: 'rgba(255, 165, 0, 0.2)', // Light background color on hover
              },
            }}
          >
            <Typography
              component={Link}
              href={'/profile/' + userHandle}
              variant="body1"
              color="textPrimary"
              sx={{
                fontWeight: props.renderSmaller ? 500 : 600,
                fontSize: props.renderSmaller ? 12 : 16,
                color: '#36454F',
                textDecoration: 'none', // No underline for the entire text
                cursor: 'pointer', // Change cursor to pointer on hover
                // Split the "@" sign from the nickname for styling
              }}
              onClick={() => {
                gtag('event', 'tap-on-user-profile-trip-card');
                // navigate('/profile/' + userHandle);
                // window.location.href = `/profile/${userHandle}`;
              }}
            >
              <span style={{ textDecoration: 'none' }}>@</span>
              <span style={{ textDecoration: 'underline' }}>
                {props.tripUser.nickName}
              </span>
            </Typography>
          </Box>
          <IconButton
            size="small"
            color="error"
            sx={{
              width: props.renderSmaller ? 16 : 25,
              height: props.renderSmaller ? 16 : 25,
              marginLeft: 0.5,
            }}
            onClick={async () => {
              if (isAuthenticated) {
                try {
                  gtag('event', 'follow-user');

                  await followUser();
                } catch (e) {
                  // TODO handle error
                }
              } else {
                gtag('event', 'follow-user-login');
                // loginWithRedirect({
                //   appState: { targetUrl: window.location.pathname },
                // });
                handleOpenSignUp();
              }
            }}
          >
            {isFollowing ? (
              <Favorite
                sx={{
                  width: props.renderSmaller ? 16 : 25,
                  height: props.renderSmaller ? 16 : 25,
                }}
              />
            ) : (
              <FavoriteBorder
                sx={{
                  width: props.renderSmaller ? 16 : 25,
                  height: props.renderSmaller ? 16 : 25,
                }}
              />
            )}
          </IconButton>
        </Stack>
      )}
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </>
  );
}

export function FollowUser(props: { publicUser: UserPublicInfo }) {
  const { isAuthenticated } = useAuth();
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const { myUser } = useSelector(userSelector);

  const [followUser, { data }] = useMutation(MUTATION_FOLLOW_USER, {
    variables: {
      following_user_id: props.publicUser.id,
      follow: !isFollowing,
    },
  });

  useEffect(() => {
    if (myUser) {
      const following =
        myUser.following &&
        myUser.following.some(follower => follower!.id === props.publicUser.id);
      if (following === true || following === false) {
        setIsFollowing(following);
      }
    }
    if (data && data.followUser) {
      dispatch(setUser(data.followUser)); // Dispatch the updated user info to the store
    }
  }, [myUser, data, dispatch]);

  return (
    <>
      {props.publicUser && props.publicUser.nickName && (
        <Stack direction="row" alignItems="center">
          <IconButton
            size="small"
            color="error"
            onClick={async () => {
              if (isAuthenticated) {
                try {
                  gtag('event', 'follow-user');

                  await followUser();
                } catch (e) {
                  // TODO handle error
                }
              } else {
                gtag('event', 'follow-user-login');
                // loginWithRedirect({
                //   appState: { targetUrl: window.location.pathname },
                // });
                handleOpenSignUp();
              }
            }}
          >
            {isFollowing ? <Favorite /> : <FavoriteBorder />}
          </IconButton>
        </Stack>
      )}
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </>
  );
}
