import { Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ContainedUserProfile } from '../trip-details/TripDetails';
import { useState } from 'react';
import { useAuth } from '../auth/firebase';
import { CheckoutModal } from './PaymentCheckoutModal';
import SignUp from '../auth/SignUp';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { TripImagesModal } from '../trip-details/TripPhotosModal';
import PhotoCameraBackTwoToneIcon from '@mui/icons-material/PhotoCameraBackTwoTone';

export const TripPayOverlay = ({ trip }) => {
  const { isAuthenticated } = useAuth();
  const [showAllPhotosModal, setShowAllPhotosModal] = useState(false);

  const [signUpOpened, setSignUpOpened] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '60%',
          left: 0,
          width: '100%',
          height: '40%',
          background: `linear-gradient(
                           rgba(255, 248, 238, 0.1) 0%, 
                           rgba(255, 248, 238, 1) 100%
                         )`,
          pointerEvents: 'none',
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '92%',
          left: '50%',
          width: '80%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10,
          textAlign: 'center',
          pb: 10,
        }}
      >
        <Card
          sx={{
            width: '100%',
            background: 'linear-gradient(135deg, #f0f9ff, #cfeffd)',
            borderRadius: 3,
            boxShadow: 10,
            overflow: 'hidden',
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
              sx={{ mb: 2 }}
            >
              {trip && trip.tripType && trip.tripType === 'guide' ? (
                <>Your Full Guide Awaits 🌍</>
              ) : (
                <>Explore Your Next Adventure 🌍</>
              )}
            </Typography>
            {((trip && !trip.tripType) ||
              (trip && trip.tripType && trip.tripType === 'itinerary')) && (
              <Typography
                sx={{ fontSize: 16, fontWeight: 500, mb: 2 }}
                color="text.primary"
                gutterBottom
              >
                This itinerary has got <strong>{trip?.length} days</strong>. On
                average, you'd spend{' '}
                <strong>{trip?.length} days planning</strong>, but we've done
                the hard work for you!
              </Typography>
            )}

            <Typography
              sx={{ fontSize: 16, fontWeight: 500 }}
              color="text.primary"
              gutterBottom
            >
              {trip && trip.tripType && trip.tripType === 'guide' ? (
                <>
                  Enjoy <strong>{trip?.noOfPlaces} incredible places</strong>,{' '}
                  <strong>{trip?.noOfActivities} activities</strong>, and{' '}
                  <strong>
                    {trip?.noOfFoodAndDrink} top-rated restaurants
                  </strong>
                </>
              ) : (
                <>
                  {trip?.noOfPlaces > 0 &&
                    trip?.noOfActivities > 0 &&
                    trip?.noOfFoodAndDrink > 0 && (
                      <>
                        Enjoy{' '}
                        <strong>{trip.noOfPlaces} incredible places</strong>,{' '}
                        <strong>{trip.noOfActivities} activities</strong>, and{' '}
                        <strong>
                          {trip.noOfFoodAndDrink} top-rated bars and restaurants
                        </strong>
                        .
                      </>
                    )}

                  {trip?.noOfPlaces > 0 &&
                    trip?.noOfActivities > 0 &&
                    (!trip?.noOfFoodAndDrink ||
                      trip.noOfFoodAndDrink === 0) && (
                      <>
                        Discover{' '}
                        <strong>{trip.noOfPlaces} amazing places</strong> and
                        enjoy{' '}
                        <strong>
                          {trip.noOfActivities} thrilling activities
                        </strong>
                        .
                      </>
                    )}

                  {trip?.noOfPlaces > 0 &&
                    trip?.noOfFoodAndDrink > 0 &&
                    (!trip?.noOfActivities || trip.noOfActivities === 0) && (
                      <>
                        Experience{' '}
                        <strong>{trip.noOfPlaces} fantastic places</strong> and
                        savor{' '}
                        <strong>
                          {trip.noOfFoodAndDrink} top-rated food and drink spots
                        </strong>
                        .
                      </>
                    )}

                  {trip?.noOfActivities > 0 &&
                    trip?.noOfFoodAndDrink > 0 &&
                    (!trip?.noOfPlaces || trip.noOfPlaces === 0) && (
                      <>
                        Engage in{' '}
                        <strong>
                          {trip.noOfActivities} exciting activities
                        </strong>{' '}
                        and indulge in{' '}
                        <strong>
                          {trip.noOfFoodAndDrink} top-rated food and drink spots
                        </strong>
                        .
                      </>
                    )}

                  {trip?.noOfPlaces > 0 &&
                    (!trip?.noOfActivities || trip.noOfActivities === 0) &&
                    (!trip?.noOfFoodAndDrink ||
                      trip.noOfFoodAndDrink === 0) && (
                      <>
                        Visit{' '}
                        <strong>
                          {trip.noOfPlaces} remarkable destinations
                        </strong>
                        .
                      </>
                    )}

                  {trip?.noOfActivities > 0 &&
                    (!trip?.noOfPlaces || trip.noOfPlaces === 0) &&
                    (!trip?.noOfFoodAndDrink ||
                      trip.noOfFoodAndDrink === 0) && (
                      <>
                        Enjoy{' '}
                        <strong>
                          {trip.noOfActivities} fantastic activities
                        </strong>
                        .
                      </>
                    )}

                  {trip?.noOfFoodAndDrink > 0 &&
                    (!trip?.noOfPlaces || trip.noOfPlaces === 0) &&
                    (!trip?.noOfActivities || trip.noOfActivities === 0) && (
                      <>
                        Relish{' '}
                        <strong>
                          {trip.noOfFoodAndDrink} top-rated food and drink spots
                        </strong>
                        .
                      </>
                    )}
                </>
              )}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography
              color="text.secondary"
              gutterBottom
              sx={{ fontSize: 16, fontWeight: 500 }}
            >
              All curated to perfection by:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {trip?.userDetails && (
                <ContainedUserProfile tripUser={trip.userDetails} />
              )}
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              mt={4}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<LockOpenTwoToneIcon />}
                onClick={() => {
                  setCheckoutOpen(true);
                  if (!isAuthenticated) {
                    setSignUpOpened(true);
                  }
                }}
                sx={{
                  textTransform: 'none',
                  borderRadius: '30px',
                  fontWeight: 700,
                  width: '240px',
                  px: 6,
                }}
              >
                Get Full Access
              </Button>
              {showAllPhotosModal ? (
                <TripImagesModal
                  images={trip.itineraryImages}
                  onClose={() => {
                    setShowAllPhotosModal(false);
                  }}
                />
              ) : (
                <>
                  {trip.itineraryImages && trip.itineraryImages.length > 0 && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowAllPhotosModal(true);
                      }}
                      startIcon={<PhotoCameraBackTwoToneIcon />}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '30px',
                        fontWeight: 700,
                        border: 2,
                        width: '240px',
                        backgroundColor: 'white',
                        mt: 1,
                        px: 4,
                      }}
                    >
                      See {trip.itineraryImages.length} trip photos
                    </Button>
                  )}
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
      {checkoutOpen && (
        <>
          {isAuthenticated ? (
            <CheckoutModal
              tripId={trip.id}
              handleCloseModal={() => {
                setCheckoutOpen(false);
              }}
            />
          ) : (
            <>
              <SignUp
                showInModal={true}
                loginDefault={false}
                open={signUpOpened}
                onClose={() => {
                  setSignUpOpened(false);
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
