import { Box } from '@mui/material';
import { useEffect } from 'react';

import PublicTrip from '../components/trip-details/Trip';

export default function TripRoute() {
  gtag('event', 'public-trip-page');

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: '60px',
        marginTop: '-60px',
        height: '100%',
        backgroundColor: 'rgba(255, 248, 238, 0.7)',
        paddingBottom: 10,
      })}
    >
      <PublicTrip />
    </Box>
  );
}
