import { ButtonBase } from '@mui/material';
import React, { forwardRef, CSSProperties } from 'react';
import classNames from 'classnames';

// import styles from './Button.module.css';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties['cursor'];
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ active, className, cursor, style, ...props }, ref) => {
    return (
      <ButtonBase
        ref={ref}
        {...props}
        className={classNames(className)}
        sx={{
          display: 'flex',
          width: 12,
          p: 1.5,
          alignItems: 'center',
          justifyContent: 'center',
          flex: '0 0 auto',
          touchAction: 'none',
          cursor: cursor || 'pointer',
          borderRadius: 1,
          border: 'none',
          outline: 'none',
          appearance: 'none',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'var(--action-background, rgba(0, 0, 0, 0.05))',
            '& svg': {
              fill: '#6f7b88',
            },
          },
          '& svg': {
            flex: '0 0 auto',
            margin: 'auto',
            height: '100%',
            overflow: 'visible',
            fill: '#919eab',
          },
          '&:focus-visible': {
            outline: 'none',
            boxShadow:
              '0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px var(--focused-outline-color)',
          },
          '&:active svg': {
            backgroundColor: 'var(--background, rgba(0, 0, 0, 0.05))',
          },
          '--fill': active?.fill,
          '--background': active?.background,
          ...style,
        }}
      />
    );
  },
);
