import { Box, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';
import { useEffect, useMemo, useRef, useState } from 'react';
import { openLinkInBrowserNewTab } from '../utils/helpers';

export function RenderHtmlFromResponse(props: { content: string }) {
  const renderContent = props.content;
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(renderContent),
      }}
    />
  );
}

export function RenderHtmlTest(props: {
  content: string;
  limitToLines?: number;
}) {
  const theme = useTheme();
  const { content, limitToLines = 200 } = props; // Default limit is 3 lines
  const [isExpanded, setIsExpanded] = useState(false);
  const [sanitizedContent, setSanitizedContent] = useState<string>('');
  const [truncatedContent, setTruncatedContent] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Sanitize and update the content when `content` changes
    // const sanitized = DOMPurify.sanitize(content);
    // Sanitize and update the content when `content` changes
    const sanitized = DOMPurify.sanitize(content, { ADD_ATTR: ['target'] });

    // Add target="_blank" and rel="noopener noreferrer" to all links
    // let container = document.createElement('div');
    // container.innerHTML = sanitized;

    // const links = container.querySelectorAll('a');
    // links.forEach(link => {
    //   link.setAttribute('target', '_blank');
    //   link.setAttribute('rel', 'noopener noreferrer');
    // });
    // setSanitizedContent(sanitized);
    let container = document.createElement('div');
    container.innerHTML = sanitized;

    // Find all links and modify their behavior
    const links = container.querySelectorAll('a');
    links.forEach(link => {
      const url = link.getAttribute('href');
      if (url) {
        link.removeAttribute('target'); // Remove target="_blank" to avoid conflicts
        link.addEventListener('click', e => {
          e.preventDefault(); // Prevent default link behavior
          openLinkInBrowserNewTab(url); // Use your custom function
        });
      }
    });

    let contentNeedsTruncation = false;

    if (isExpanded && truncatedContent && truncatedContent !== content) {
      // Append "Less" button when content is expanded
      const container = document.createElement('div');
      container.innerHTML = sanitized;
      const paragraphs = container.querySelectorAll('p');
      if (paragraphs.length > 0) {
        const lastParagraph = paragraphs[paragraphs.length - 1];
        lastParagraph.innerHTML += ` <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:600;">Less</span>`;
        setTruncatedContent(container.innerHTML);
      } else {
        setTruncatedContent(
          sanitized +
            ` <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:600;">Less</span>`,
        );
      }
      return;
    }

    // Truncate content only if it exceeds the limit
    container = document.createElement('div');
    container.innerHTML = sanitized;

    const words = sanitized.split(' ');
    let currentText = sanitized;

    if (containerRef.current) {
      // Reset container to full content
      containerRef.current.innerHTML = sanitized;

      // Adjust content until it fits within the allowed height
      while (
        containerRef.current.scrollHeight > containerRef.current.clientHeight &&
        words.length
      ) {
        words.pop();
        currentText = words.join(' ');
        containerRef.current.innerHTML = currentText + '... More';
        contentNeedsTruncation = true; // Mark that truncation is needed
      }

      if (contentNeedsTruncation) {
        setTruncatedContent(
          currentText +
            `... <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:600;">More</span>`,
        );
      } else {
        setTruncatedContent(null); // No truncation, show full sanitized content
      }
    }
  }, [content, isExpanded, theme.palette.primary.main]);

  const handleClickMoreOrLess = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: isExpanded ? 'block' : '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: isExpanded ? 'none' : limitToLines,
        overflow: isExpanded ? 'none' : 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: isExpanded ? 'normal' : 'initial',
        cursor: 'pointer',
        fontSize: '15px',
        fontWeight: '500',
        '& p': {
          margin: '0', // Remove margin from <p> tags
        },
        '& p + p': {
          marginTop: '1em', // Add margin between subsequent <p> tags
        },
      }}
      dangerouslySetInnerHTML={{
        __html: truncatedContent !== null ? truncatedContent : sanitizedContent,
      }}
      onClick={handleClickMoreOrLess}
    />
  );
}

export function RenderHtmlFromResponseNoMargin(props: {
  content: string;
  limitToLines?: number;
}) {
  const theme = useTheme();
  const { content, limitToLines = 200 } = props; // Default limit is 3 lines
  const [isExpanded, setIsExpanded] = useState(false);
  const [sanitizedContent, setSanitizedContent] = useState<string>('');
  const [truncatedContent, setTruncatedContent] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Sanitize and update the content when `content` changes
    // const sanitized = DOMPurify.sanitize(content);
    // setSanitizedContent(sanitized);

    const sanitized = DOMPurify.sanitize(content, { ADD_ATTR: ['target'] });

    // Add target="_blank" and rel="noopener noreferrer" to all links
    // let container = document.createElement('div');
    // container.innerHTML = sanitized;

    // const links = container.querySelectorAll('a');
    // links.forEach(link => {
    //   link.setAttribute('target', '_blank');
    //   link.setAttribute('rel', 'noopener noreferrer');
    // });
    // setSanitizedContent(sanitized);
    let container = document.createElement('div');
    container.innerHTML = sanitized;

    // Find all links and modify their behavior
    const links = container.querySelectorAll('a');
    links.forEach(link => {
      const url = link.getAttribute('href');
      if (url) {
        link.removeAttribute('target'); // Remove target="_blank" to avoid conflicts
        link.addEventListener('click', e => {
          e.preventDefault(); // Prevent default link behavior
          openLinkInBrowserNewTab(url); // Use your custom function
        });
      }
    });

    let contentNeedsTruncation = false;

    if (isExpanded && truncatedContent && truncatedContent !== content) {
      // Append "Less" button when content is expanded
      setTruncatedContent(
        sanitized +
          ` <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:600;">Less</span>`,
      );
      return;
    }

    // Truncate content only if it exceeds the limit
    container = document.createElement('div');
    container.innerHTML = sanitized;

    const words = sanitized.split(' ');
    let currentText = sanitized;

    if (containerRef.current) {
      // Reset container to full content
      containerRef.current.innerHTML = sanitized;

      // Adjust content until it fits within the allowed height
      while (
        containerRef.current.scrollHeight > containerRef.current.clientHeight &&
        words.length
      ) {
        words.pop();
        currentText = words.join(' ');
        containerRef.current.innerHTML = currentText + '... More';
        contentNeedsTruncation = true; // Mark that truncation is needed
      }

      if (contentNeedsTruncation) {
        setTruncatedContent(
          currentText +
            `... <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:600;">More</span>`,
        );
      } else {
        setTruncatedContent(null); // No truncation, show full sanitized content
      }
    }
  }, [content, isExpanded, theme.palette.primary.main]);

  const handleClickMoreOrLess = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: isExpanded ? 'block' : '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: isExpanded ? 'none' : limitToLines,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: isExpanded ? 'normal' : 'initial',
        cursor: 'pointer',
      }}
      dangerouslySetInnerHTML={{
        __html: truncatedContent !== null ? truncatedContent : sanitizedContent,
      }}
      onClick={handleClickMoreOrLess}
    />
  );
}
