import { useQuery } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { CountriesTrips } from '../components/country/CountriesTrips';
import { CountriesWithTripsListLandingSmaller } from '../components/country/CountriesWithTrips';
import ExploreMap from '../components/explore/ExploreMap';
import { QUERY_COUNTRIES_TRIPS } from '../components/gql-public/countriesTripsQuery';
import { RoutePage } from '../components/route';
import { useUserAndTripData } from '../hooks/useUserData';
import LazyLoadComponent from '../theme-components/LazyLoadComponent';
import { TitleH1 } from '../theme-components/Typography';
import { unauthorisedClient } from '../utils/auth';
import { isRunningStandalone } from '../utils/helpers';

const gradients = [
  'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
  'linear-gradient(180deg, #F2DDF7 0%, #FFFFFF 100%)',
  'linear-gradient(180deg, #F9E7E1 0%, #FFFFFF 100%)',
  'linear-gradient(180deg, #D9F9FB 0%, #FFFFFF 100%)',
];

export default function ExploreRoute() {
  useUserAndTripData();
  gtag('event', 'public-country-page');
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const { loading, error, data } = useQuery(QUERY_COUNTRIES_TRIPS, {
    client: unauthorisedClient,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if (error) {
    return <>Error loading trips</>;
  }

  return (
    <>
      <Helmet>
        <title>Explore Curated Travel Itineraries & Maps Worldwide</title>
        <meta
          name="description"
          content="Discover customizable travel itineraries, detailed maps, and expert guides for destinations worldwide. Explore country-specific trips and create your perfect journey!"
        />
        <meta
          property="og:title"
          content={'Explore Curated Travel Itineraries & Maps Worldwide'}
        />
        <meta
          property="og:description"
          content={
            'Discover customizable travel itineraries, detailed maps, and expert guides for destinations worldwide. Explore country-specific trips and create your perfect journey!'
          }
        />
        <meta property="og:image" content={'/images/default-image.jpg'} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        <meta
          name="keywords"
          content={`explore, itinerary, trip, vacation, travel map, curated itineraries, travel guide, adventure, solo travel, budget travel, luxury travel, discover country`}
        />
        <meta
          name="author"
          content="Rooutie | Free Travel Maps & Curated Itineraries"
        />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
      </Helmet>
      <Box
        sx={theme => ({
          width: '100%',
          marginTop: '-60px',
          height: '100%',
          backgroundImage: `
            radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
            radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
            radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
            radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
            radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
            radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
            linear-gradient(
              0deg,
              rgba(255, 215, 0, 0.15) 0%,
              rgba(255, 223, 223, 0.1) 50%,
              rgba(255, 215, 0, 0.15) 100%
            )
          `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
          [theme.breakpoints.down('lg')]: {
            width: '100%',
            marginTop: '-60px',
            height: '100%',
            backgroundImage: `
            radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
            radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
            radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
            radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
            radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
            radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
            linear-gradient(
              0deg,
              rgba(255, 215, 0, 0.15) 0%,
              rgba(255, 223, 223, 0.1) 50%,
              rgba(255, 215, 0, 0.15) 100%
            )
          `,
            backgroundPosition: 'center',
            backgroundSize: `100% 100vh`,
            backgroundRepeat: 'repeat',
          },
          [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: '-60px',
            paddingTop: '60px',
            height: '100%',
            backgroundImage: `
              radial-gradient(circle at 10% 20%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
              radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
              radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
              radial-gradient(circle at 70% 70%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
              radial-gradient(circle at 90% 70%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
              radial-gradient(circle at 80% 60%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 30%),
              linear-gradient(
                0deg, 
                rgba(255, 215, 0, 0.15) 0%, 
                rgba(255, 223, 223, 0.1) 50%, 
                rgba(255, 215, 0, 0.15) 100%
              )
            `,
            backgroundPosition: 'center',
            backgroundSize: `100% 100vh`,
            backgroundRepeat: 'repeat',
          },
        })}
      >
        <RoutePage>
          {/* <Stack
            direction={'row'}
            sx={theme => ({
              width: '100%',
              display: 'flex',
              overflow: 'hidden',
              // paddingTop: 10,
              [theme.breakpoints.down('lg')]: {
                overflow: 'hidden',
                flexDirection: 'column',
                justifyContent: 'center',
              },
              [theme.breakpoints.down('md')]: {
                height: '100%',
                overflow: 'hidden',
                // paddingTop: 10,
                flexDirection: 'column',
              },
              [theme.breakpoints.down('sm')]: {
                height: '100%',
                overflow: 'hidden',
                // paddingTop: 0,
                flexDirection: 'column',
              },
            })}
          >
            <Box
              sx={theme => ({
                // height: '100%',
                height: '500px',
                display: 'flex',
                width: '50%',
                paddingX: 5,
                paddingTop: 10,
                [theme.breakpoints.down('lg')]: {
                  // paddingX: 8,
                  width: '80%',
                  margin: '0 auto',
                  paddingTop: 5,
                  justifyContent: 'center',
                  // Change direction to column on small screens
                },
                [theme.breakpoints.down('md')]: {
                  height: '350px',
                  paddingX: 8,
                  paddingTop: 0,

                  width: '100%', // Change direction to column on small screens
                },
                [theme.breakpoints.down('sm')]: {
                  height: '350px',
                  paddingX: 0,
                  paddingTop: 0,
                  width: '100%', // Change direction to column on small screens
                },
              })}
            >
              <ExploreIllustration />
            </Box>
            <Box
              sx={theme => ({
                display: 'flex',
                width: '50%',
                alignItems: 'center', // Center vertically
                justifyContent: 'center', // Center horizontally
                [theme.breakpoints.down('lg')]: {
                  display: 'none',
                },
              })}
            >
              <CountriesWithTripsListLandingSmaller showExplore={false} />
            </Box>
          </Stack> */}

          <Box
            sx={theme => ({
              marginTop: '10',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginBottom: 3,
              [theme.breakpoints.down('md')]: {
                marginTop: 2,
              },
              [theme.breakpoints.down('sm')]: {
                marginTop: 2,
              },
            })}
          >
            <TitleH1 component="h1" sx={{ textAlign: 'center' }}>
              Explore the world
            </TitleH1>
          </Box>
          <Box
            sx={theme => ({
              width: '100%',
              height: '500px',
              borderRadius: '20px',
              overflow: 'clip',
              marginBottom: 2,
              [theme.breakpoints.down('sm')]: {
                height: '600px',
              },
            })}
          >
            <ExploreMap countriesTrips={data?.countriesTrips} />
          </Box>
          <Box
            sx={theme => ({
              width: '100%',
              // display: 'none',
              alignItems: 'center', // Center vertically
              justifyContent: 'center', // Center horizontally
              [theme.breakpoints.down('lg')]: {
                // width: '100%', // Change direction to column on small screens
                // display: 'block',
              },
            })}
          >
            <CountriesWithTripsListLandingSmaller showExplore={false} />
          </Box>
          <Box
            sx={theme => ({
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginBottom: 3,
              marginTop: 2,
              [theme.breakpoints.down('md')]: {
                marginTop: 1,
              },
              [theme.breakpoints.down('sm')]: {
                marginTop: 1,
              },
            })}
          >
            <TitleH1 component="h2" sx={{ textAlign: 'center' }}>
              All countries
            </TitleH1>
          </Box>
          {/* {data &&
            data.countriesTrips &&
            data.countriesTrips.map((countryTrip, index) => (
              <LazyLoadComponent>
                <CountriesTrips
                  key={index}
                  countryTrips={countryTrip}
                  gradient={gradients[index % gradients.length]}
                />
              </LazyLoadComponent>
            ))} */}
          {data &&
            data.countriesTrips &&
            data.countriesTrips.map((countryTrip, index) => (
              <LazyLoadComponent
                key={countryTrip.id}
                delay={index * 500} // Dynamic delay (500ms per item)
                shouldLoad={true} // or shouldLoad={index < 10} to limit
              >
                <CountriesTrips
                  countryTrips={countryTrip}
                  gradient={gradients[index % gradients.length]}
                />
              </LazyLoadComponent>
            ))}
          {isStandalone && (
            <Box
              sx={{
                paddingBottom: 'calc(70px + env(safe-area-inset-bottom))',
              }}
            />
          )}
          {/* </OuterContainer> */}
        </RoutePage>
      </Box>
    </>
  );
}
