import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsSubwayFilledTwoToneIcon from '@mui/icons-material/DirectionsSubwayFilledTwoTone';
import FastfoodTwoToneIcon from '@mui/icons-material/FastfoodTwoTone';
import HealingTwoToneIcon from '@mui/icons-material/HealingTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone';
import { Drawer, IconButton, Box, Divider, Link, Button } from '@mui/material';
import * as Flags from 'country-flag-icons/react/3x2';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Country } from '../../generated/public_graphql';
import { openLinkInBrowserNewTab } from '../../utils/helpers';
import { getQueryParam } from '../../utils/queryParams';

import { CountryDetails } from './CountryDetails';
import { CountryFood } from './CountryFood';
import { CountryHealth } from './CountryHealth';
import { CountrySeasons } from './CountrySeasons';
import { CountryTips } from './CountryTips';
import { CountryTransport } from './CountryTransport';
import { CountryVisas } from './CountryVisas';

interface IconeTypeProps {
  readonly $iconType: string;
}

export function CountrySidebar(props: {
  country: Country;
  onClose?: () => void;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalId, setModalId] = useState('');
  const open = [
    'seasons',
    'food',
    'transport',
    'health',
    'country_details',
    'visas',
    'tips',
  ].includes(modalId);

  useEffect(() => {
    const modal = getQueryParam(location.search, 'modal');
    const modalValue = typeof modal === 'string' ? modal : '';
    setModalId(modalValue || '');
  }, [location]);

  function onClose() {
    navigate(-1);

    if (props.onClose) {
      props.onClose();
    }
  }

  return (
    <Drawer
      sx={theme => ({
        // width: 60,
        zIndex: 100000,
        // backgroundColor: '#fffaf5',
        flexShrink: 0, // Ensures the Drawer does not shrink
        '& .MuiDrawer-paper': {
          // Target the Drawer paper to set its width
          width: 700,
          boxSizing: 'border-box', // Ensures width includes padding and border
          [theme.breakpoints.down('md')]: {
            width: 700,
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      })}
      open={open}
      anchor="right"
      variant="temporary"
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={theme => ({
          paddingX: 3,
          paddingBottom: 3,
          backgroundColor: '#fffaf5',
          [theme.breakpoints.down('sm')]: {
            paddingX: 2,
          },
        })}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={theme => ({
            position: 'sticky', // Added to make the box sticky
            top: 16,
            zIndex: 100,
            mb: -8,
            [theme.breakpoints.down('sm')]: {
              mb: -6,
            },
          })}
        >
          <IconButton
            aria-label="closeDrawer"
            color="secondary"
            onClick={() => {
              onClose();
              gtag('event', 'close-side-bar');
            }}
          >
            <CloseIcon sx={{ width: 30, height: 30 }} />
          </IconButton>
        </Box>
        {modalId === 'seasons' && <CountrySeasons country={props.country} />}
        {modalId === 'health' && <CountryHealth country={props.country} />}
        {modalId === 'country_details' && (
          <CountryDetails country={props.country} />
        )}
        {modalId === 'visas' && <CountryVisas country={props.country} />}
        {modalId === 'tips' && <CountryTips country={props.country} />}
        {modalId === 'food' && <CountryFood country={props.country} />}
        {modalId === 'transport' && (
          <CountryTransport country={props.country} />
        )}
      </Box>
    </Drawer>
  );
}

export function SideBarHeader(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 700,
        fontSize: 35,
        alignSelf: 'center',
        color: '#001B30',
        // [theme.breakpoints.down('sm')]: {
        //   fontSize: 35,
        // },
      })}
    >
      {props.children}
    </Box>
  );
}

export function SideBarSubHeader(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 1,
        color: '#001B30',
      })}
    >
      {props.children}
    </Box>
  );
}

export function SideBarSubSubHeader(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 700,
        fontSize: 18,
        marginBottom: 1,
        color: '#001B30',
      })}
    >
      {props.children}
    </Box>
  );
}
export function SideBarDescription(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 400,
        fontSize: 18,
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

export function SideBarTitleDivider() {
  return <Divider sx={theme => ({ marginBottom: 0, marginTop: 1 })} />;
}

export function SideBarLink(props: { url: string; copy: string }) {
  return (
    <Button
      variant="outlined"
      sx={theme => ({
        fontWeight: 700,
        fontSize: 16,
        borderRadius: 20,
        zIndex: 0,
        border: '2px solid',
        padding: '5px 40px',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
        },
      })}
      onClick={() => {
        openLinkInBrowserNewTab(props.url);
        gtag('event', 'click-link-on-side-bar');
      }}
    >
      {props.copy}
    </Button>
  );
}

interface CountryFlagProps {
  countryCode: string;
  width?: number;
  height?: number;
}

export const CountryFlagMenu: React.FC<CountryFlagProps> = ({
  countryCode,
}) => {
  // eslint-disable-next-line import/namespace
  const FlagComponent = Flags[countryCode];

  if (!FlagComponent) {
    return (
      <LanguageTwoToneIcon
        sx={theme => ({
          marginRight: 1,
          width: 25,
          height: 25,
          alignSelf: 'center',
          [theme.breakpoints.down('sm')]: {
            marginRight: 1,
            width: 25,
            height: 25,
          },
        })}
      />
    );
  }

  return (
    <Box
      component={FlagComponent}
      title={countryCode}
      sx={theme => ({
        marginRight: 1,
        width: 25,
        height: 25,
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
          marginRight: 1,
          width: 25,
          height: 25,
        },
      })}
    />
  );
};

export const CountryFlag: React.FC<CountryFlagProps> = ({
  countryCode,
  width = 50,
  height = 50,
}) => {
  // eslint-disable-next-line import/namespace
  const FlagComponent = Flags[countryCode];

  // Calculate responsive sizes
  const responsiveWidth = width;
  const responsiveHeight = height;

  if (!FlagComponent) {
    return (
      <LanguageTwoToneIcon
        sx={theme => ({
          marginRight: 2,
          width: responsiveWidth,
          height: responsiveHeight,
          alignSelf: 'center',
          [theme.breakpoints.down('sm')]: {
            marginRight: 1,
            width: responsiveWidth * 0.75,
            height: responsiveHeight * 0.75,
          },
        })}
      />
    );
  }

  return (
    <Box
      component={FlagComponent}
      title={countryCode}
      sx={theme => ({
        marginRight: 2,
        width: responsiveWidth,
        height: responsiveHeight,
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
          marginRight: 1,
          width: responsiveWidth * 0.75,
          height: responsiveHeight * 0.75,
        },
      })}
    />
  );
};

export const CountryFlagNoMargin: React.FC<CountryFlagProps> = ({
  countryCode,
  width = 50,
  height = 50,
}) => {
  // eslint-disable-next-line import/namespace
  const FlagComponent = Flags[countryCode];

  // Calculate responsive sizes
  const responsiveWidth = width;
  const responsiveHeight = height;

  if (!FlagComponent) {
    return (
      <LanguageTwoToneIcon
        sx={theme => ({
          width: responsiveWidth,
          height: responsiveHeight,
          alignSelf: 'center',
          // [theme.breakpoints.down('sm')]: {
          //   width: responsiveWidth * 0.85,
          //   height: responsiveHeight * 0.85,
          // },
        })}
      />
    );
  }

  return (
    <Box
      component={FlagComponent}
      title={countryCode}
      alt={countryCode}
      sx={theme => ({
        width: responsiveWidth,
        height: responsiveHeight,
        alignSelf: 'center',
        // [theme.breakpoints.down('sm')]: {
        //   width: responsiveWidth * 0.85,
        //   height: responsiveHeight * 0.85,
        // },
      })}
    />
  );
};

// export const CountryFlag = ({ countryCode }) => {
//   // eslint-disable-next-line import/namespace
//   const FlagComponent = Flags[countryCode]; // Dynamically select the flag component

//   if (!FlagComponent) {
//     return (
//       <LanguageTwoToneIcon
//         sx={theme => ({
//           marginRight: 2,
//           width: 60,
//           height: 60,
//           alignSelf: 'center',
//           [theme.breakpoints.down('sm')]: {
//             marginRight: 1,
//             width: 40,
//             height: 40,
//           },
//         })}
//       />
//     );
//   }

//   return (
//     <Box
//       component={FlagComponent}
//       title={countryCode}
//       sx={theme => ({
//         marginRight: 2,
//         width: 60,
//         alignSelf: 'center',
//         [theme.breakpoints.down('sm')]: {
//           marginRight: 1,
//           width: 40,
//         },
//       })}
//     />
//   );
// };

export function SideBarIcon(props: IconeTypeProps) {
  switch (true) {
    case /^[A-Z]{2}$/.test(props.$iconType):
      return <CountryFlag countryCode={props.$iconType} />;
    case props.$iconType.includes('WEATHER'):
      return (
        <WbSunnyTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 50,
            height: 50,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    case props.$iconType.includes('TRANSPORT'):
      return (
        <DirectionsSubwayFilledTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 50,
            height: 50,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    case props.$iconType.includes('FOOD'):
      return (
        <FastfoodTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 50,
            height: 50,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    case props.$iconType.includes('VISAS'):
      return (
        <LibraryBooksTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 50,
            height: 50,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    case props.$iconType.includes('HEALTH'):
      return (
        <HealingTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 50,
            height: 50,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    case props.$iconType.includes('TIPS'):
      return (
        <TipsAndUpdatesTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 50,
            height: 50,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    default:
      return (
        <LanguageTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 50,
            height: 50,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
  }
}
