import { gql } from '@apollo/client';

export const ACTIVITY_FIELDS = gql`
  fragment ActivityFields on Activity {
    id
    name
    noOfReviews
    rating
    lat
    lng
    city
    country
    countryCode
    address
    externalId
    region
    category
    description
    highlights
    aiDescription
    activityType
    dataSource
    bookingLink
    durationInMinutes
    validForInMinutes
    images {
      id
      path
      imageUrl
      previewPath
      mediumPath
      smallPath
      fromOwner
      attribution {
        nickName
        profilePictureUrl
      }
    }
    minPrice
    maxPrice
    priceCategory
    priceCurrency
  }
`;
