import { Helmet } from 'react-helmet-async';
import { RoutePage } from '../components/route';
import { ComponentsPreview } from '../theme-components/ComponentsPreview';

export default function ComponentsPreviewRoute() {
  return (
    <RoutePage>
      <Helmet>
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <ComponentsPreview />
    </RoutePage>
  );
}
