import 'maplibre-gl/dist/maplibre-gl.css';
import { useLazyQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Switch,
  FormControl,
  SwitchProps,
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Feature, LineString, Position } from 'geojson';
import { debounce } from 'lodash';
import maplibregl from 'maplibre-gl';
import {
  useRef,
  useEffect,
  useState,
  useCallback,
  MutableRefObject,
} from 'react';
import ReactDOM from 'react-dom/client';
import { useParams } from 'react-router-dom';

import { Maybe, Step, Trip } from '../../generated/public_graphql';
import {
  Activity,
  ItineraryCoordinates,
  ItineraryRelatedPlacesAndActivities,
  Place,
} from '../../generated/user_graphql';
import { CategoryIcon } from '../../theme-components/Icons';
import { unauthorisedClient } from '../../utils/auth';
import { isAppleDevice } from '../../utils/helpers';
import { QUERY_GET_BOUNDING_BOX_PLACES_ACTIVITIES } from '../gql-public/boundingBoxPlacesAndActivities';
import { QUERY_GET_TRIP } from '../gql-public/tripQuery';

import { MapMoreInfoCard } from './MapMoreInfoCard';
import DaysSidebarEmbeded from './TripSideNavigationEmbeded';
import { QUERY_EMBEDDED_TRIP } from '../gql-public/embeddedTipQuery';

// Define types for the MapLibre format
interface Location {
  id: string;
  title: string;
  coordinates: [number, number]; // [longitude, latitude]
  source?: string; // To track where the pin came from
  step?: Step;
  place?: Place;
  activity?: Activity;
}

const mapToLocations = (
  itineraryCoordinates?: Maybe<ItineraryCoordinates>[],
  itineraryRelatedPlacesAndActivities?: Maybe<ItineraryRelatedPlacesAndActivities>,
  source = 'api',
): Location[] | undefined => {
  // Initialize an empty array to store locations
  const locations: Location[] = [];

  // Process itinerary coordinates if provided
  if (itineraryCoordinates) {
    const itineraryLocations = itineraryCoordinates
      .filter(
        (itinerary): itinerary is ItineraryCoordinates =>
          !!itinerary &&
          itinerary.latitude !== undefined &&
          itinerary.longitude !== undefined &&
          itinerary.id !== undefined &&
          itinerary.title !== undefined,
      )
      .map(itinerary => ({
        id: itinerary.id!,
        title: itinerary.title!,
        coordinates: [itinerary.longitude!, itinerary.latitude!] as [
          number,
          number,
        ], // [lng, lat]
        step: itinerary.step ? itinerary.step : undefined,
        source: source,
      }));

    // Add the itinerary locations to the final list
    locations.push(...itineraryLocations);
  }

  // Process places and activities if provided
  if (itineraryRelatedPlacesAndActivities) {
    // Iterate over each item in the itineraryRelatedPlacesAndActivities array
    // Process places if available
    if (itineraryRelatedPlacesAndActivities.places) {
      const placeLocations = itineraryRelatedPlacesAndActivities.places
        .filter(
          (
            place,
          ): place is {
            lat: number;
            lng: number;
            title: string;
            id: string;
          } =>
            place?.lat !== undefined &&
            place?.lng !== undefined &&
            place?.title !== undefined &&
            place?.id !== undefined,
        )
        .map(place => ({
          id: place.id!,
          title: place.title!,
          coordinates: [place.lng, place.lat] as [number, number], // Explicitly casting to a tuple
          place: place ? place : undefined,
          source: source,
        }));
      locations.push(...placeLocations);
    }

    // Process activities if available
    if (itineraryRelatedPlacesAndActivities.activities) {
      const activityLocations = itineraryRelatedPlacesAndActivities.activities
        .filter(
          (
            activity,
          ): activity is {
            lat: number;
            lng: number;
            name: string;
            id: string;
          } =>
            activity?.lat !== undefined &&
            activity?.lng !== undefined &&
            activity?.name !== undefined &&
            activity?.id !== undefined,
        )
        .map(activity => ({
          id: activity.id!,
          title: activity.name!,
          coordinates: [activity.lng, activity.lat] as [number, number], // Explicitly casting to a tuple
          activity: activity ? activity : undefined,
          source: source,
        }));
      locations.push(...activityLocations);
    }
  }

  // If no locations were added, return undefined
  return locations.length > 0 ? locations : undefined;
};

interface MarkerRef {
  [key: string]: maplibregl.Marker; // String key to support multiple sources
}

// No default locations - start with empty array
const defaultLocations: Location[] = [];

export function EmbeddedTripMap(): JSX.Element {
  const urlParams = new URLSearchParams(window.location.search);
  const mobileFullScreenOption = urlParams.get('mobileFullScreenOption');
  const showNearByPlacesOption = urlParams.get('showNearByPlacesOption');
  const embeddedTripId = urlParams.get('embeddedTripId');

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<maplibregl.Map | null>(null);
  const markersRef = useRef<MarkerRef>({});
  const [apiLocations, setApiLocations] = useState<Location[]>([]);
  const [polylineLocations, setPolylineLocations] = useState<string>('');

  const [secondaryLocations, setSecondaryLocations] = useState<Location[]>([]);
  const [allLocations, setAllLocations] =
    useState<Location[]>(defaultLocations);
  const [isMapLoading, setIsMapLoading] = useState<boolean>(true);
  const [isApiLoading, setIsApiLoading] = useState<boolean>(false);
  const [isRouteLoading, setIsRouteLoading] = useState<boolean>(false);

  const [isSecondaryApiLoading, setIsSecondaryApiLoading] =
    useState<boolean>(false);
  const [hasFetchedApi, setHasFetchedApi] = useState<boolean>(false);
  const [isMapInitialized, setIsMapInitialized] = useState<boolean>(false);
  const [showSecondaryLocations, setShowSecondaryLocations] =
    useState<boolean>(false);

  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );
  const [previousSelectedLocation, setPreviousSelectedLocation] =
    useState<Location | null>(null);
  const [trip, setTrip] = useState<Trip | null>(null);

  // Function to handle pin click - defined outside of the useEffect for clarity
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const handlePinClick = (location: Location) => {
    // Set the selected location

    setSelectedLocation(location);

    if (mapRef.current) {
      const map = mapRef.current;
      const [longitude, latitude] = location.coordinates;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (isMdUp) {
        const offsetXPercentage = 0.2; // 20%
        const offsetXPixels = viewportWidth * offsetXPercentage;
        map.easeTo({
          center: [longitude, latitude],
          offset: [offsetXPixels, 0],
          duration: 1000,
        });

        // Step 2: Zoom in while maintaining offset
        setTimeout(() => {
          if (mapRef.current) {
            const map = mapRef.current;
            const currentZoom = map.getZoom();
            const targetZoom = 13;

            if (currentZoom < targetZoom) {
              map.easeTo({
                center: [longitude, latitude],
                zoom: targetZoom,
                offset: [offsetXPixels, 0],
                duration: 1500,
              });
            }
          }
        }, 1000);
      } else {
        const offsetYPercentage = 0.2; // 20%
        const offsetYPixels = viewportHeight * offsetYPercentage;
        map.easeTo({
          center: [longitude, latitude],
          offset: [0, -offsetYPixels], // Move 200px upwards
          duration: 1000,
        });

        // Step 2: Zoom in while maintaining offset
        setTimeout(() => {
          if (mapRef.current) {
            const map = mapRef.current;
            const currentZoom = map.getZoom();
            const targetZoom = 13;

            if (currentZoom < targetZoom) {
              map.easeTo({
                center: [longitude, latitude],
                zoom: targetZoom,
                offset: [0, -offsetYPixels], // Maintain offset during zoom
                duration: 1500,
              });
            }
          }
        }, 1000);
      }
    }
  };
  // Initialize map when component mounts, but don't show it yet
  useEffect(() => {
    if (mapRef.current) return undefined; // Initialize map only once

    setIsMapLoading(true);

    if (mapContainerRef.current) {
      mapRef.current = new maplibregl.Map({
        container: mapContainerRef.current,
        style: '/map-style.json',
        center: [8.2319, 46.7985], // Center on US
        zoom: 3, // Zoom out to see more of the map
        attributionControl: false,
      });
      mapRef.current.addControl(
        new maplibregl.AttributionControl(),
        'bottom-left',
      );

      // Mark map as initialized when it loads
      mapRef.current.once('load', () => {
        setIsMapInitialized(true);
        setTimeout(() => {
          setIsMapLoading(false);
        }, 300);

        // But keep the loading overlay until API data arrives
        // setIsMapLoading will be set to false after API data loads
      });
    }

    // Clean up on unmount
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, []);

  const { userTripId } = useParams<{ userTripId: string }>();
  const [getTrip, { data }] = useLazyQuery<{ embeddedTrip: Trip }>(
    QUERY_EMBEDDED_TRIP,
    {
      client: unauthorisedClient,
      variables: { trip_id: userTripId, embedded_trip_id: embeddedTripId },
    },
  );

  // API call to get trip data
  useEffect(() => {
    if (hasFetchedApi) return;

    const fetchTripData = async () => {
      setIsApiLoading(true);

      // Simulate API delay
      // await new Promise(resolve => setTimeout(resolve, 2000));
      const response = await getTrip();

      if (response.data?.embeddedTrip?.itinerary?.coordinates) {
        // Transform the mock data using the mapToLocations function
        const transformedLocations = mapToLocations(
          response.data?.embeddedTrip?.itinerary?.coordinates,
          null,
          'api',
        );

        setTrip(response.data?.embeddedTrip);

        // Update with transformed API data
        setApiLocations(transformedLocations ? transformedLocations : []);
        setHasFetchedApi(true);
        setIsApiLoading(false);

        // Now that we have API data, fit the map to show these locations
        fitMapToLocations(transformedLocations ? transformedLocations : []);
      } else {
        // TODO - should we retry?
        setIsApiLoading(false);
      }
    };

    fetchTripData();
  }, [hasFetchedApi, isMapInitialized]);

  const fetchSecondaryLocations = useCallback(
    async (
      west: number,
      east: number,
      north: number,
      south: number,
    ): Promise<void> => {
      setIsSecondaryApiLoading(true);

      // Only proceed if all necessary parameters are provided
      if (west && east && south && north) {
        const minLat = south;
        const minLng = west;
        const maxLat = north;
        const maxLng = east;

        try {
          // Await the API response
          const response = await getBoundingBoxData({
            variables: {
              min_lat: minLat,
              min_lng: minLng,
              max_lat: maxLat,
              max_lng: maxLng,
            },
          });

          if (response?.data) {
            // Transform and set the locations after receiving data
            const transformedLocations = mapToLocations(
              [],
              response.data.placesAndActivitiesForBoundingBox,
              'secondary',
            );
            setSecondaryLocations(transformedLocations || []);
          }
        } catch (error) {
          setIsSecondaryApiLoading(false);
          console.error('Error fetching secondary locations:', error);
        } finally {
          // Ensure loading state is updated in both success and failure cases
          setIsSecondaryApiLoading(false);
        }
      }
      setIsSecondaryApiLoading(false);
    },
    [],
  );

  // Get secondary locations
  const initialLoadRef = useRef(false); // Track initial load

  useEffect(() => {
    if (mapRef.current) {
      const debouncedMoveEndHandler = debounce(() => {
        if (!mapRef.current) return;
        if (showSecondaryLocations) {
          const bounds = mapRef.current.getBounds();
          const west = bounds.getWest();
          const east = bounds.getEast();
          const north = bounds.getNorth();
          const south = bounds.getSouth();

          fetchSecondaryLocations(west, east, north, south);
        }
      }, 300); // Adjust debounce delay as needed

      mapRef.current.on('moveend', debouncedMoveEndHandler);

      if (showSecondaryLocations && !initialLoadRef.current) {
        const bounds = mapRef.current.getBounds();
        const west = bounds.getWest();
        const east = bounds.getEast();
        const north = bounds.getNorth();
        const south = bounds.getSouth();

        fetchSecondaryLocations(west, east, north, south);
        initialLoadRef.current = true; // Mark initial load as done
      }

      return () => {
        if (mapRef.current) {
          mapRef.current.off('moveend', debouncedMoveEndHandler);
        }
      };
    }
  }, [mapRef.current, showSecondaryLocations]);

  // Helper function to fit map to a set of locations
  const fitMapToLocations = (
    locations: Location[],
    padding = 50,
    maxZoom = 14,
    duration = 2000,
  ): void => {
    console.log('111');
    if (!mapRef.current || locations.length === 0) return;
    console.log('222');

    // Create bounds that include all locations
    const bounds = new maplibregl.LngLatBounds();

    // Add each location to the bounds
    locations.forEach(location => {
      bounds.extend(location.coordinates);
    });

    // Fit the map to those bounds with smooth animation
    mapRef.current.fitBounds(bounds, {
      padding,
      maxZoom,
      duration, // Smooth animation duration in ms
    });
  };

  // Update allLocations when any of the location sources change
  useEffect(() => {
    const combined = [
      ...apiLocations,
      ...(showSecondaryLocations ? secondaryLocations : []),
    ];

    // Use a Map to ensure uniqueness by ID
    const uniqueLocationsMap = new Map<string, Location>();
    combined.forEach(location => {
      uniqueLocationsMap.set(location.id, location);
    });

    const uniqueLocations = Array.from(uniqueLocationsMap.values());
    setAllLocations(uniqueLocations);
  }, [apiLocations, secondaryLocations, showSecondaryLocations]);

  const [getBoundingBoxData, { data: boundingBoxData }] = useLazyQuery(
    QUERY_GET_BOUNDING_BOX_PLACES_ACTIVITIES,
    {
      client: unauthorisedClient,
    },
  );

  // Update markers and lines when locations change
  useEffect(() => {
    if (!mapRef.current || !isMapInitialized || isMapLoading) return undefined;

    // Wait for map to load before adding markers
    const updateMarkers = (): void => {
      if (!mapRef.current) return;

      // Clear existing markers that are no longer in the locations list
      Object.entries(markersRef.current).forEach(([id, marker]) => {
        const locationExists = allLocations.some(loc => `${loc.id}` === id);
        if (!locationExists) {
          marker.remove();
          delete markersRef.current[id];
        }
      });

      // Add new markers for locations that don't have one yet
      allLocations.forEach(location => {
        const markerId = `${location.id}`;
        const isSelected = selectedLocation?.id === location.id;
        const isPrevious = previousSelectedLocation?.id === location.id;

        if (isPrevious) {
          if (markersRef.current[markerId]) {
            markersRef.current[markerId].remove(); // Remove existing
            delete markersRef.current[markerId]; // Clear reference
          }
        }

        // Skip rendering if marker already exists and not selected/previously selected
        if (!isSelected && !isPrevious && markersRef.current[markerId]) {
          return;
        }

        // Create marker element
        const el = document.createElement('div');
        el.className = 'marker';
        el.setAttribute('data-id', markerId);
        el.setAttribute('data-title', location.title);

        // Add pointer cursor on hover
        el.addEventListener('mouseenter', () => {
          el.style.cursor = 'pointer';
        });

        el.addEventListener('mouseleave', () => {
          el.style.cursor = '';
        });

        const root = ReactDOM.createRoot(el);
        let zIndexValue = 1; // Default zIndex for secondary

        if (location.source === 'secondary') {
          if (
            location.place &&
            trip?.itinerary &&
            location.place.lat &&
            location.place.lng
          ) {
            const doesPlaceExistInItinerary = (
              externalId: string | null | undefined,
              placeId: string,
              lat: number,
              lng: number,
              coordinates: Maybe<Maybe<ItineraryCoordinates>[]> | undefined,
            ): boolean => {
              return !!coordinates?.some(
                coord =>
                  coord?.step?.place &&
                  (coord.step.place.id === placeId ||
                    coord.step.place.externalPlaceId === externalId ||
                    coord.step.place.lat === lat ||
                    coord.step.place.lng === lng),
              );
            };

            if (
              doesPlaceExistInItinerary(
                location.place.externalPlaceId,
                location.place.id,
                location.place.lat,
                location.place.lng,
                trip.itinerary.coordinates,
              )
            ) {
              return null;
            }
          }

          if (
            location.activity &&
            trip?.itinerary &&
            location.activity.lat &&
            location.activity.lng
          ) {
            const doesActivityExistInItinerary = (
              externalId: string | null | undefined,
              activityId: string,
              lat: number,
              lng: number,
              coordinates: Maybe<Maybe<ItineraryCoordinates>[]> | undefined,
            ): boolean => {
              return !!coordinates?.some(
                coord =>
                  coord?.step?.activity &&
                  (coord.step.activity.id === activityId ||
                    coord.step.activity.externalId === externalId ||
                    coord.step.activity.lat === lat ||
                    coord.step.activity.lng === lng),
              );
            };

            if (
              doesActivityExistInItinerary(
                location.activity.externalId,
                location.activity.id,
                location.activity.lat,
                location.activity.lng,
                trip.itinerary.coordinates,
              )
            ) {
              return null;
            }
          }

          root.render(
            <>
              {selectedLocation && location.id === selectedLocation.id ? (
                <Box style={{ position: 'relative' }}>
                  {setPreviousSelectedLocation(selectedLocation)}
                  <CategoryIcon
                    type={
                      location.place?.placeType ||
                      location.activity?.activityType ||
                      ''
                    }
                    category={
                      location.place?.category ||
                      location.place?.placeType ||
                      location.activity?.category ||
                      location.activity?.activityType
                    }
                    source={location.place?.dataSource}
                    width={25}
                    height={25}
                    highlighted={false}
                  />
                  <Box
                    style={{
                      position: 'absolute', // Position absolutely
                      top: 0,
                      left: 0,
                      backgroundColor: 'white',
                      border: '2px solid black',
                      borderRadius: '100%',
                      height: '25px',
                      width: '25px',
                      zIndex: -1, // Place behind
                    }}
                  />
                </Box>
              ) : (
                <Box style={{ position: 'relative' }}>
                  <CategoryIcon
                    type={
                      location.place?.placeType ||
                      location.activity?.activityType ||
                      ''
                    }
                    category={
                      location.place?.category ||
                      location.place?.placeType ||
                      location.activity?.category ||
                      location.activity?.activityType
                    }
                    source={location.place?.dataSource}
                    width={25}
                    height={25}
                    highlighted={false}
                  />
                  <Box
                    style={{
                      position: 'absolute', // Position absolutely
                      top: 0,
                      left: 0,
                      backgroundColor: 'white',
                      borderRadius: '100%',
                      height: '25px',
                      width: '25px',
                      zIndex: -1, // Place behind
                    }}
                  />
                </Box>
              )}
            </>,
          );
        }

        // Different styles based on source
        if (location.source === 'api') {
          zIndexValue = 10; // API icons on top

          root.render(
            <>
              {selectedLocation && location.id === selectedLocation.id ? (
                <Box style={{ position: 'relative' }}>
                  {setPreviousSelectedLocation(selectedLocation)}
                  <CategoryIcon
                    type={location.step?.type}
                    category={
                      location.step?.place?.category ||
                      location.step?.place?.placeType ||
                      ''
                    }
                    source={
                      location.step?.place?.dataSource ||
                      location.step?.activity?.dataSource ||
                      ''
                    }
                    width={25}
                    height={25}
                    highlighted={true}
                  />
                  <Box
                    style={{
                      position: 'absolute', // Position absolutely
                      top: -2,
                      left: -2,
                      backgroundColor: 'white',
                      border: '2px solid black',
                      borderRadius: '100%',
                      height: '29px',
                      width: '29px',
                      zIndex: -1, // Place behind
                    }}
                  />
                </Box>
              ) : (
                <CategoryIcon
                  type={location.step?.type}
                  category={
                    location.step?.place?.category ||
                    location.step?.place?.placeType ||
                    ''
                  }
                  source={
                    location.step?.place?.dataSource ||
                    location.step?.activity?.dataSource ||
                    ''
                  }
                  width={25}
                  height={25}
                  highlighted={true}
                />
              )}
            </>,
          );
        }

        // Add click event listener directly to the element
        el.onclick = () => {
          handlePinClick(location);
        };

        // Create and add the marker to the map WITHOUT popup
        if (mapRef.current) {
          const marker = new maplibregl.Marker({
            element: el,
            clickTolerance: 3,
          })
            .setLngLat(location.coordinates)
            .addTo(mapRef.current);

          // Set the z-index directly on the marker's element
          marker.getElement().style.zIndex = zIndexValue.toString();
          // Store the marker reference
          markersRef.current[markerId] = marker;
        }
      });
    };

    updateMarkers();
    return undefined;
  }, [allLocations, isMapInitialized, isMapLoading, selectedLocation]);

  // We can improve map by allowing users to click any POI
  // then laod info from maps SERP API
  // Show it to user and allw them to add to itinerary
  // useEffect(() => {
  //   if (mapRef.current) {
  //     setupOSMPOIInteraction(mapRef.current, mapRef);
  //   }
  // }, [mapRef.current]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        isMapInitialized &&
        mapRef.current &&
        polylineLocations &&
        polylineLocations !== ''
      ) {
        setIsRouteLoading(true);
        const apiUrl = `https://router.project-osrm.org/trip/v1/walking/${polylineLocations}?geometries=geojson`;
        await displayRouteOnMap(mapRef.current, apiUrl);
        setIsRouteLoading(false);
      }
    };

    fetchData();
  }, [isMapInitialized, polylineLocations]);

  // Full screen map option
  const [fullScreenMap, setFullScreenMap] = useState(false);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const toggleDrawer = () => {
  //   setIsDrawerOpen(!isDrawerOpen);
  // };

  const handleMenuDayClick = dayId => {
    if (trip?.itinerary?.days) {
      const selectedDay = trip.itinerary.days.find(day => day?.id === dayId);

      if (selectedDay && selectedDay.steps && selectedDay.steps.length > 1) {
        const validSteps = selectedDay.steps.filter(
          step =>
            step &&
            step.longitude !== undefined &&
            step.latitude !== undefined &&
            step.longitude !== null &&
            step.latitude !== null &&
            step.longitude !== 0 &&
            step.latitude !== 0,
        );

        if (validSteps.length > 0) {
          const formattedCoordinates = validSteps
            .map(step => `${step!.longitude},${step!.latitude}`)
            .join(';');

          setPolylineLocations(formattedCoordinates);
        } else {
          setPolylineLocations(''); // No valid steps, clear polylineLocations
        }
      } else {
        setPolylineLocations(''); // No matching day or invalid steps, clear polylineLocations
      }
    } else {
      setPolylineLocations(''); // No itinerary or days, clear polylineLocations
    }
  };

  const handleMenuStepClick = (stepId: string) => {
    // Find the location within the filtered array
    const selected = allLocations.find(location =>
      location.id.includes(stepId),
    );

    if (selected) {
      handlePinClick(selected);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          flex: 1,
          height: '100vh',
          width: '100vw',
        }}
      >
        {/* Ensure the map container has a high enough z-index */}
        <Box
          ref={mapContainerRef}
          sx={{
            height: '100vh',
            width: '100vw',
            position: 'relative',
            boxSizing: 'border-box',
            '& .maplibregl-canvas-container': {
              height: '100%',
              width: '100%',
            },
            zIndex: 1000000, // Ensure the map has a base z-index
          }}
        >
          {mobileFullScreenOption === 'enabled' && (
            <Box
              onClick={() => {
                setFullScreenMap(!fullScreenMap);
                if (!fullScreenMap) {
                  window.parent.postMessage(
                    {
                      type: 'resize-iframe',
                      height: '100vh',
                      width: '100vw',
                      borderRadius: '0',
                      zIndex: '1000000',
                      position: 'fixed',
                      top: '0',
                      left: '0',
                      margin: '0',
                    },
                    '*',
                  );
                } else {
                  window.parent.postMessage({ type: 'reset-iframe' }, '*');
                }
              }}
              sx={{
                position: 'fixed',
                bottom: '10px',
                right: '10px',
                // left: 'auto',
                width: '30px',
                height: '30px',
                // zIndex: 1000,
                margin: 0,
                padding: '5px',
                zIndex: 1000000,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                // color: '#fff',
                borderRadius: '50%', // Circular shape
                // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)', // Darker on hover
                },
                display: { xs: 'block', sm: 'none' },
              }}
            >
              {!fullScreenMap ? (
                <FullscreenIcon sx={{ width: '20px', height: '20px' }} />
              ) : (
                <FullscreenExitIcon sx={{ width: '20px', height: '20px' }} />
              )}
            </Box>
          )}

          {isMapLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 10,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Loading Map...
              </Typography>
              <CircularProgress size={36} />
            </Box>
          )}

          {!isMapLoading &&
            !isRouteLoading &&
            (isApiLoading || isSecondaryApiLoading) && (
              <Box
                sx={{
                  position: 'absolute',
                  top: showNearByPlacesOption === 'enabled' ? 60 : 10,
                  left: 10,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  // padding: 1,
                  // borderRadius: '20px',
                  zIndex: 5,
                  borderRadius: '12px',
                  padding: '2px 8px 2px 8px',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ color: 'rgba(0, 0, 0, .75)' }}
                >
                  Loading places
                </Typography>
                <CircularProgress size={10} sx={{ mr: 1 }} />
              </Box>
            )}
          {!isMapLoading && isRouteLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: showNearByPlacesOption === 'enabled' ? 60 : 10,
                left: 10,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                // padding: 1,
                // borderRadius: '20px',
                zIndex: 5,
                borderRadius: '12px',
                padding: '2px 8px 2px 8px',
              }}
            >
              <Typography
                variant="caption"
                sx={{ color: 'rgba(0, 0, 0, .75)' }}
              >
                Loading route
              </Typography>
              <CircularProgress size={10} sx={{ mr: 1 }} />
            </Box>
          )}

          {!isMapLoading && showNearByPlacesOption === 'enabled' && (
            <Box
              sx={{
                position: 'absolute',

                zIndex: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                borderRadius={20}
                bgcolor="#f5f5f5"
                sx={theme => ({
                  paddingY: 1,
                  paddingX: 2,
                  top: 10,
                  left: 10,
                  // position: 'absolute',
                  // top: 10,
                  display: 'inline-flex',
                  // left: 10,
                  zIndex: 100,
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '20px',
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.50)',
                  [theme.breakpoints.down('sm')]: {
                    position: 'fixed',
                    top: 5,
                    left: 0,
                    borderTopRightRadius: '20px', // Larger radius for a smoother corner
                    borderBottomRightRadius: '20px', // Same as above
                    borderTopLeftRadius: 0, // Larger radius for a smoother corner
                    borderBottomLeftRadius: 0,
                  },
                })}
              >
                <Typography
                  sx={theme => ({
                    marginRight: 0.5,
                    whiteSpace: 'nowrap',
                    color: '#36454F',
                    fontWeight: 700,
                    // [theme.breakpoints.down('sm')]: {
                    //   marginLeft: 0.5,
                    //   fontWeight: 600,
                    //   color: '#1C3A57',
                    //   height: '40px',
                    //   display: 'flex',
                    //   alignItems: 'center',
                    // },
                  })}
                >
                  Nearby Places
                </Typography>
                {/* Where Others Go */}
                {isAppleDevice() ? (
                  <IOSSwitch
                    sx={{ ml: 1 }}
                    // checked={isSelected}
                    // onClick={handleDayChange}
                    checked={showSecondaryLocations}
                    onChange={e => setShowSecondaryLocations(e.target.checked)}
                  />
                ) : (
                  <>
                    <Switch
                      sx={{ mr: 1 }}
                      checked={showSecondaryLocations}
                      onChange={e =>
                        setShowSecondaryLocations(e.target.checked)
                      }
                      // color="primary"
                      // size="small"
                    />
                  </>
                )}

                <FormControl fullWidth></FormControl>
              </Box>
            </Box>
          )}

          {/* Selected location info card */}
          {selectedLocation && (
            <>
              <Box
                className="location-card"
                sx={theme => ({
                  position: 'absolute',
                  bottom: 10,
                  left: 10,
                  right: 10,
                  height: '40%',
                  zIndex: 999, // Very high z-index to ensure visibility
                  maxWidth: 'calc(100% - 20px)',
                  // boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                  borderRadius: 2,
                  overflow: 'hidden',
                  overflowY: 'auto',
                  backgroundColor: 'transparent',
                  width: '100%',
                  // border: '1px solid rgba(0,0,0,0.1)',
                  [theme.breakpoints.up('md')]: {
                    top: 10,
                    bottom: 10,
                    left: 10,
                    right: 'auto',
                    height: 'calc(100% - 20px)',
                    maxWidth: '400px',
                    width: '400px',
                  },
                })}
              >
                <MapPlaceCard
                  step={selectedLocation.step}
                  activity={selectedLocation.activity}
                  place={selectedLocation.place}
                />
              </Box>
              <Box
                sx={theme => ({
                  position: 'absolute', // Absolute positioning
                  bottom: 'calc(40% - 25px)',
                  right: 15,
                  zIndex: 100030,
                  display: 'flex',
                  justifyContent: 'flex-end', // Align to right
                  [theme.breakpoints.up('md')]: {
                    bottom: 'auto',
                    top: 15,
                    left: 375,
                    right: 'auto',
                  },
                })}
              >
                <IconButton
                  onClick={() => setSelectedLocation(null)}
                  style={{
                    // backgroundColor: 'rgba(54, 69, 79, 0.9)',
                    borderRadius: '50%',
                    // color: '#ffffff',
                    width: 30,
                    height: 30,
                  }}
                >
                  <CloseIcon sx={{ color: 'rgba(54, 69, 79, 0.9)' }} />
                </IconButton>
              </Box>
            </>
          )}
          {data?.embeddedTrip?.itinerary?.days && (
            <>
              <DaysSidebarEmbeded
                days={data?.embeddedTrip?.itinerary.days}
                onDayClick={handleMenuDayClick}
                onStepClick={handleMenuStepClick}
                tripType={
                  data?.embeddedTrip?.tripType
                    ? data.embeddedTrip.tripType
                    : 'itinerary'
                }
                trip={data?.embeddedTrip}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

interface Coordinate {
  0: number; // Longitude
  1: number; // Latitude
}

interface Geometry {
  coordinates: Coordinate[];
  type: string;
}

interface TripRoute {
  geometry: Geometry;
}

interface ApiResponse {
  code: string;
  trips: TripRoute[];
}

async function getRouteLine(url: string): Promise<Coordinate[]> {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: ApiResponse = await response.json();

    if (data.code === 'Ok' && data.trips && data.trips.length > 0) {
      return data.trips[0].geometry.coordinates;
    } else {
      console.error('API returned an error or no trips found.');
      return [];
    }
  } catch (error) {
    console.error('Error fetching route:', error);
    return [];
  }
}

async function displayRouteOnMap(
  map: maplibregl.Map,
  url: string,
): Promise<void> {
  const coordinates = await getRouteLine(url);

  if (map.getSource('route')) {
    map.removeLayer('route');
    map.removeLayer('route-arrows');
    map.removeSource('route');
  }
  if (coordinates.length > 0) {
    const positions: Position[] = coordinates.map(
      coord => [coord[0], coord[1]], // Explicitly create [number, number]
    );

    const geojsonData: Feature<LineString> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: positions,
      },
    };

    map.addSource('route', {
      type: 'geojson',
      data: geojsonData,
    });

    map.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        // 'line-color': 'rgba(158, 127, 239, 0.7)', // Equivalent to #9E7FEF with 0.7 opacity
        // 'line-width': 3,
        'line-color': '#9E7FEF',
        'line-width': 3,
        'line-opacity': 0.7,
      },
    });

    // Load and add the arrow iconf
    // Load and add the arrow icon using async/await
    try {
      const arrowIconUrl =
        '/assets/icons/right-arrow-purple-semi-transparent.png'; // Replace with your image path
      const imageResponse = await map.loadImage(arrowIconUrl); // Get the GetResourceResponse
      if (!map.hasImage('arrow-icon')) {
        map.addImage('arrow-icon', imageResponse.data);
      }
      // Add the arrow layer
      map.addLayer({
        id: 'route-arrows',
        type: 'symbol',
        source: 'route',
        layout: {
          'symbol-placement': 'line',
          'symbol-spacing': 50,
          'icon-image': 'arrow-icon',
          'icon-size': 0.7,
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': false,
        },
      });
    } catch (error) {
      console.error('Error loading arrow icon:', error);
    }

    // Optionally fit the map to the route
    const bounds = coordinates.reduce(
      (bounds, coord) => bounds.extend(coord as [number, number]),
      new maplibregl.LngLatBounds(
        coordinates[0] as [number, number],
        coordinates[0] as [number, number],
      ),
    );
    map.fitBounds(bounds, { padding: 20 });
  } else {
    console.log('No route to display.');
  }
}

function formatCoordinates(locations: Location[]): string {
  const limitedLocations = locations.slice(0, 6); // Limit to the first 20 locations

  return limitedLocations
    .map(location => `${location.coordinates[0]},${location.coordinates[1]}`)
    .join(';');
}

interface PlaceCardProps {
  step?: Maybe<Step>;
  activity?: Maybe<Activity>;
  place?: Maybe<Place>;
}

const MapPlaceCard: React.FC<PlaceCardProps> = ({ step, activity, place }) => {
  return (
    <>
      {place && !activity && <MapMoreInfoCard place={place} step={step} />}
      {activity && !place && (
        <MapMoreInfoCard activity={activity} step={step} />
      )}
      {!activity && !place && step && <MapMoreInfoCard step={step} />}
    </>
  );
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40, // Slightly wider for more space
  height: 24, // Slightly taller for better alignment
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 2, // Provide consistent padding
    margin: 0, // Remove extra margin
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)', // Adjusted position
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    backgroundColor: '#6E7191',
    opacity: 0.5,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

// Define a minimal GeoJSON Point type
interface GeoJSONPoint {
  type: 'Point';
  coordinates: [number, number];
}

function displayOSMPOIInfo(
  point: GeoJSONPoint,
  properties: any,
  mapRef: MutableRefObject<maplibregl.Map | null> | null,
) {
  console.log('displayOSMPOIInfo called with:', { point, properties, mapRef });

  if (point && point.coordinates && mapRef && mapRef.current) {
    console.log('Valid GeoJSON Point and mapRef, creating popup.');

    const popup = new maplibregl.Popup()
      .setLngLat(point.coordinates)
      .setHTML(
        `
        <h3>${properties.name || properties.Name || 'Name not available'}</h3>
        <p>Coordinates: ${point.coordinates[0]}, ${point.coordinates[1]}</p>
        <button id="add-poi-button">Add</button>
      `,
      )
      .addTo(mapRef.current);

    // Add event listener to the "Add" button
    document.getElementById('add-poi-button')?.addEventListener('click', () => {
      console.log('Add button clicked for:', properties, point.coordinates);
      // Add your logic here to handle the "Add" action
      // For example:
      // addToFavorites(properties, point.coordinates);
    });

    console.log('Popup created and added:', popup);
  } else {
    console.warn('GeoJSON Point is not valid or mapRef is null.');
  }
}

function setupOSMPOIInteraction(
  map: maplibregl.Map,
  mapRef: MutableRefObject<maplibregl.Map | null> | null,
) {
  console.log('setupOSMPOIInteraction called.');

  map.on('click', e => {
    console.log('Map click event triggered:', e);

    const layers = map.getStyle().layers.map(layer => layer.id);
    const poiLayers = layers.filter(layerId => layerId.startsWith('poi_'));
    poiLayers.forEach(layerId => {
      map.on('mouseenter', layerId, () => {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', layerId, () => {
        map.getCanvas().style.cursor = '';
      });
    });

    console.log('POI layers to query:', poiLayers);

    const features = map.queryRenderedFeatures(e.point, { layers: poiLayers });

    console.log('Queryed features:', features);

    if (features.length > 0) {
      const feature = features[0];
      const properties = feature.properties;

      console.log('Feature properties:', properties);

      if (feature.geometry.type === 'Point') {
        displayOSMPOIInfo(feature.geometry as GeoJSONPoint, properties, mapRef);
      } else {
        console.warn('Geometry type not supported for popup display.');
      }
    }
  });

  // Add pointer cursor on hover
  map.on('mouseenter', 'poi_r20', () => {
    //replace poi_r20 with the layer you want the hover effect on.
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'poi_r20', () => {
    map.getCanvas().style.cursor = '';
  });
}
