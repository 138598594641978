import DirectionsIcon from '@mui/icons-material/Directions';
import { Box, Stack, styled, Typography } from '@mui/material';

import { HorizontalFlex } from '../helpers/flex';
import Navigation from '../illustrations/Navigation';

import {
  BoxActivity,
  BoxPadding,
  ColorWord,
  Description,
  GradientContainer,
  OuterContainer,
} from './HomeHeader';

const Illustration = styled(Navigation)(({ theme }) => ({
  width: 350,
  height: 300,
  // merginTop: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    width: 300,
    height: 200,
  },
}));

const NavigationIcon = styled(DirectionsIcon)(({ theme }) => ({
  height: 35,
  width: 35,
  alignSelf: 'center',
  marginRight: 5,
  marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    height: 28,
    width: 28,
  },
  [theme.breakpoints.down('sm')]: {
    height: 20,
    width: 20,
  },
}));

const NavigationText = styled(Box)(({ theme }) => ({
  color: '#6E7191',
  fontWeight: 600,
  fontSize: 30,
  marginBottom: 10,
  [theme.breakpoints.down('md')]: {
    fontSize: 25,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

export function HomeLocationInfo() {
  return (
    <GradientContainer gradient="linear-gradient(180deg, #D9F9FB 0%, #FFFFFF 100%)">
      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <BoxActivity>
          <Illustration />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <HorizontalFlex>
              <NavigationIcon />
              <NavigationText>
                Take me there
                <br />
              </NavigationText>
            </HorizontalFlex>
            <Description>Add exact location to your</Description>
            <HorizontalFlex>
              <Description>activities so you can</Description>
              <ColorWord marginLeft="8px">navigate</ColorWord>
            </HorizontalFlex>
            <HorizontalFlex>
              <ColorWord marginRight="8px">by one tap </ColorWord>
              <Description>when on the go.</Description>
            </HorizontalFlex>
          </Box>
        </BoxActivity>
      </Box>
      <Box sx={{ display: { sm: 'block', md: 'none' } }}>
        <BoxActivity>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <HorizontalFlex>
              <NavigationIcon />
              <NavigationText>
                Take me there <br />
              </NavigationText>
            </HorizontalFlex>
            <Description>Add exact location to your</Description>
            <HorizontalFlex>
              <Description>activities so you can</Description>
              <ColorWord marginLeft="8px">navigate</ColorWord>
            </HorizontalFlex>
            <HorizontalFlex>
              <ColorWord marginRight="8px">by one tap</ColorWord>
              <Description>when on the go.</Description>
            </HorizontalFlex>
            <BoxPadding />
          </Box>
          <Illustration />
        </BoxActivity>
      </Box>
    </GradientContainer>
  );
}

export function HomeLocationInfoLanding() {
  return (
    <Box
      sx={theme => ({
        paddingX: 3,
        borderRadius: 10,
        background: 'rgba(255, 255, 255, 0.4)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Add subtle shadow
        marginX: 10,
        [theme.breakpoints.down('xl')]: {
          marginX: 10,
        },
        [theme.breakpoints.down('lg')]: {
          marginX: 5,
        },
        [theme.breakpoints.down('md')]: {
          marginX: 5,
          paddingBottom: 6,
        },
        [theme.breakpoints.down('sm')]: {
          marginX: 2,
        },
      })}
    >
      <BoxActivity>
        <Illustration />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={theme => ({
            maxWidth: 550,
            paddingLeft: 5,
            paddingTop: 0,
            [theme.breakpoints.down('xl')]: {
              maxWidth: 550,
            },
            [theme.breakpoints.down('lg')]: {
              maxWidth: 550,
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: 4,
              paddingLeft: 0,
              maxWidth: 350,
            },
            [theme.breakpoints.down('sm')]: {
              maxWidth: 280,
            },
          })}
        >
          <Stack direction={'row'}>
            <NavigationIcon />
            <NavigationText>
              Take me there
              <br />
            </NavigationText>
          </Stack>
          <Typography
            component="h3"
            sx={theme => ({
              fontWeight: 800,
              fontSize: 25,
              color: '#5A44EB',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Always know the exact location
          </Typography>
          <Typography
            sx={theme => ({
              fontWeight: 600,
              fontSize: 20,
              color: '#3F3D56',
              maxWidth: '300px',
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            No more searching – find your next destination effortlessly.
          </Typography>
        </Box>
      </BoxActivity>
    </Box>
  );
}
