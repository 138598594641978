import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  GetTheAppButtonSmall,
  ProminentButtonThin,
} from '../theme-components/Buttons';

import { useAuth } from './auth/firebase';
import { ModernLogo } from './logo/Logo1';
import { RoutePage } from './route';

const LinkButtonOld = styled(Box)(({ theme }) => ({
  // marginLeft: 1,
  fontWeight: 600,
  fontSize: 16,
  padding: 2,

  color: '#3F3D56',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 4,
    fontSize: 24,
    // marginLeft: 5,
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    width: 30,
    height: 30,
  },
}));

const BoxIconName = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const BoxName = styled(Box)(({ theme }) => ({
  fontFamily: `"Prosto One", cursive`,
  marginLeft: 5,
  fontSize: 40,
  fontWeight: 600,
  color: '#3F3D56',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
    marginLeft: 10,
  },
}));

export const LogoName = styled(Box)(({ theme }) => ({
  fontFamily: `"Prosto One", cursive`,
  fontSize: 40,
  fontWeight: 600,
  color: '#3F3D56',
  alignSelf: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  // color: '#3F3D56',
  opacity: 1,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export function AppBarNew() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    // <div style={{ position: isMobile ? 'relative' : 'sticky', zIndex: 5000 }}>
    <header
      style={{
        position: isMobile ? 'relative' : 'sticky',
        top: 0,
        zIndex: 5000,
      }}
    >
      <Box
        sx={theme => ({
          background: `
      linear-gradient(
        to top,
        rgba(255, 250, 245, 0) 0%,
        rgba(255, 250, 245, 1) 100%
      )
    `,
          backdropFilter: 'blur(10px)',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '60px',
          overflow: 'visible',
          [theme.breakpoints.down('sm')]: {
            height: '50px',
          },
        })}
      >
        <RoutePage>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={isMobile ? 'center' : 'space-between'}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              // flexGrow={isStandalone ? 0 : 1}
              component={Link}
              href="/"
              sx={{ textDecoration: 'none' }}
            >
              <BoxIconName
                onClick={() => {
                  gtag('event', 'app-bar-rooutie-logo');
                }}
              >
                <Logo>
                  <ModernLogo />
                </Logo>

                <BoxName>rooutie</BoxName>
              </BoxIconName>
            </Box>
            {/* {!isStandalone && ( */}
            <>
              <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <Stack direction="row">
                  <CustomButton
                    disableRipple
                    disableFocusRipple
                    href="#"
                    onClick={() => {
                      gtag('event', 'app-bar-create-trip');
                      navigate('/my-trips');
                    }}
                  >
                    {/* <RightBox border={1} borderColor="#484848"> */}

                    <Box
                      sx={theme => ({
                        fontWeight: 600,
                        fontSize: 16,
                        // padding: 2,

                        color: theme.palette.primary.main,
                        [theme.breakpoints.down('sm')]: {
                          paddingBottom: 4,
                          fontSize: 24,
                        },
                      })}
                    >
                      Create Trip
                    </Box>
                    {/* <Box sx={{ marginTop: -2, marginLeft: -2 }}> */}
                    {/* <NewLabel>NEW</NewLabel> */}

                    {/* </Box> */}
                    {/* </RightBox> */}
                  </CustomButton>
                  <Divider
                    orientation="vertical"
                    sx={theme => ({
                      padding: 0,
                      width: '2px',
                      // display: 'flex',
                      backgroundColor: '#D7D6E6',
                      color: '#D7D6E6',
                      margin: 'auto',
                      position: 'relative',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '15px',
                      border: 'none',
                      '& .MuiDivider-wrapper': {
                        padding: 0,
                        margin: 0,
                      },
                    })}
                    textAlign="center"
                  />
                  <CustomButton
                    disableRipple
                    disableFocusRipple
                    href="#"
                    onClick={() => {
                      gtag('event', 'app-bar-create-trip');
                      navigate('/explore');
                    }}
                  >
                    <Link
                      href="/explore"
                      sx={theme => ({
                        fontWeight: 600,
                        fontSize: 16,
                        padding: 2,
                        textDecoration: 'none',
                        color: '#3F3D56',
                        [theme.breakpoints.down('sm')]: {
                          paddingBottom: 4,
                          fontSize: 24,
                        },
                      })}
                    >
                      Explore Countries
                    </Link>
                  </CustomButton>
                </Stack>
              </Box>

              <Stack direction="row" alignItems="center">
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  <Stack direction="row">
                    <CustomButton
                      disableRipple
                      disableFocusRipple
                      href="#"
                      onClick={() => {
                        gtag('event', 'app-bar-favourite');
                        navigate('/favourite');
                      }}
                    >
                      {isAuthenticated && (
                        <LinkButtonOld>My Favourite</LinkButtonOld>
                      )}
                    </CustomButton>
                    <Divider
                      orientation="vertical"
                      sx={theme => ({
                        padding: 0,
                        width: '2px',
                        // display: 'flex',
                        backgroundColor: '#D7D6E6',
                        color: '#D7D6E6',
                        margin: 'auto',
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '15px',
                        border: 'none',
                        '& .MuiDivider-wrapper': {
                          padding: 0,
                          margin: 0,
                        },
                      })}
                      textAlign="center"
                    />
                  </Stack>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  <Stack direction="row">
                    <CustomButton
                      disableRipple
                      disableFocusRipple
                      href="#"
                      onClick={() => {
                        gtag('event', 'app-bar-create-trip');
                        navigate('/my-trips');
                      }}
                    >
                      {isAuthenticated ? (
                        <LinkButtonOld>My Trips</LinkButtonOld>
                      ) : (
                        <LinkButtonOld>Login</LinkButtonOld>
                      )}
                    </CustomButton>
                    <Divider
                      orientation="vertical"
                      sx={theme => ({
                        padding: 0,
                        width: '2px',
                        // display: 'flex',
                        backgroundColor: '#D7D6E6',
                        color: '#D7D6E6',
                        margin: 'auto',
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '15px',
                        border: 'none',
                        '& .MuiDivider-wrapper': {
                          padding: 0,
                          margin: 0,
                        },
                      })}
                      textAlign="center"
                    />
                  </Stack>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  {isAuthenticated ? (
                    <IconButton
                      onClick={() => {
                        gtag('event', 'app-bar-create-trip');
                        navigate('/account');
                      }}
                    >
                      <PersonOutlineTwoToneIcon />
                    </IconButton>
                  ) : (
                    <CustomButton
                      disableRipple
                      disableFocusRipple
                      href="#"
                      onClick={() => {
                        gtag('event', 'app-bar-create-trip');
                        navigate('/account');
                      }}
                    >
                      <ProminentButtonThin>Sign Up </ProminentButtonThin>
                    </CustomButton>
                  )}
                </Box>
              </Stack>
            </>
          </Box>
        </RoutePage>
      </Box>
    </header>
    // </div>
  );
}
