import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import {
  CardMedia,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Maybe, Image, StepPlaceImage } from '../../generated/public_graphql';
import { PlaceImage, Step } from '../../generated/user_graphql';
import { EditImagesModal } from '../create-trip/manage-trip-modals/EditAddStepImages';
import { isPrerenderBot } from '../../utils/helpers';
import InfoIcon from './images/ImageAttribution';

const hasContributorId = (
  img: StepPlaceImage | Image | PlaceImage | null,
): img is StepPlaceImage | PlaceImage => {
  return (img as StepPlaceImage).contributorId !== undefined;
};

const hasTitle = (
  img: StepPlaceImage | Image | PlaceImage | null,
): img is StepPlaceImage | PlaceImage => {
  return (img as StepPlaceImage).title !== undefined;
};

export const contributorLink = (inputString: string): string => {
  return 'https://www.google.com/maps/contrib/' + inputString;
};

export function StepPlaceImages(props: {
  images: Maybe<StepPlaceImage>[] | Maybe<Image>[] | Maybe<PlaceImage>[];
}) {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null); // Make sure ref is typed

  useEffect(() => {
    if (!props.images || props.images.length === 0) return;
    // If it's a Prerender.io request, immediately set visibility as true
    if (isPrerenderBot()) {
      setIsVisible(true);
      return; // Skip the IntersectionObserver logic for Prerender.io
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          setIsVisible(true); // Set visibility state to true
          observer.unobserve(entry.target); // Stop observing once visible
        }
      },
      {
        root: null, // Viewport is root
        rootMargin: '400px', // Trigger when 300px below the viewport
        threshold: 0.1, // Trigger when 10% of the element is visible
      },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    } else {
    }

    return () => {
      if (componentRef.current) {
        observer.disconnect();
      }
    };
  }, [props.images, isVisible]);

  return (
    <Box ref={componentRef}>
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container spacing={1} direction="row" wrap="nowrap">
              {/* Render only visible images */}
              {props.images.map((image, index) => {
                let imagePath = '';
                if (
                  image?.previewPath !== null &&
                  image?.previewPath !== undefined &&
                  image?.previewPath !== ''
                ) {
                  imagePath = image.previewPath;
                } else if (
                  image?.mediumPath !== null &&
                  image?.mediumPath !== undefined &&
                  image?.mediumPath !== ''
                ) {
                  imagePath = image.mediumPath;
                } else if (
                  image?.smallPath !== null &&
                  image?.smallPath !== undefined &&
                  image?.smallPath !== ''
                ) {
                  imagePath = image.smallPath;
                } else if (
                  image?.path !== null &&
                  image?.path !== undefined &&
                  image?.path !== ''
                ) {
                  imagePath = image.path;
                }
                return (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                      >
                        {isVisible ? (
                          <ImageCard
                            imageUrl={
                              image && image.imageUrl ? image.imageUrl : ''
                            }
                            imageId={image && image.id ? image.id : ''}
                            path={imagePath}
                            title={image && image.title ? image.title : ''}
                            contributorName={
                              image && image.title ? image.title : ''
                            }
                            contributorUrl={
                              image && image.contributorId
                                ? contributorLink(image.contributorId)
                                : ''
                            }
                            index={index}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            sx={theme => ({
                              height: 150,
                              width: '100%',
                              objectFit: 'cover',
                              [theme.breakpoints.down('sm')]: {
                                height: 130,
                              },
                            })}
                          />
                        )}
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export function PlaceSingleImage(props: {
  images: Maybe<StepPlaceImage>[] | Maybe<Image>[] | Maybe<PlaceImage>[];
}) {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!props.images || props.images.length === 0) return;
    // If it's a Prerender.io request, immediately set visibility as true
    if (isPrerenderBot()) {
      setIsVisible(true);
      return; // Skip the IntersectionObserver logic for Prerender.io
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '400px',
        threshold: 0.1,
      },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    } else {
    }

    return () => {
      if (componentRef.current) {
        observer.disconnect();
      }
    };
  }, [props.images, isVisible]);

  return (
    <Box ref={componentRef}>
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 350,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <ImageCard
                      imageUrl={image && image.imageUrl ? image.imageUrl : ''}
                      path={
                        image?.previewPath ||
                        image?.mediumPath ||
                        image?.smallPath ||
                        image?.path ||
                        ''
                      }
                      imageId={image && image.id ? image.id : ''}
                      title={image && image.title ? image.title : ''}
                      index={index}
                      contributorName={image && image.title ? image.title : ''}
                      contributorUrl={
                        image && image.contributorId
                          ? contributorLink(image.contributorId)
                          : ''
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export function PlaceImages(props: { images: Maybe<PlaceImage>[] }) {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!props.images || props.images.length === 0) return;

    // If it's a Prerender.io request, immediately set visibility as true
    if (isPrerenderBot()) {
      setIsVisible(true);
      return; // Skip the IntersectionObserver logic for Prerender.io
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '400px',
        threshold: 0.1,
      },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    } else {
    }

    return () => {
      if (componentRef.current) {
        observer.disconnect();
      }
    };
  }, [props.images, isVisible]);

  return (
    <Box ref={componentRef}>
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard
                          imageUrl={
                            image && image.imageUrl ? image.imageUrl : ''
                          }
                          imageId={image && image.id ? image.id : ''}
                          path={
                            image?.previewPath ||
                            image?.mediumPath ||
                            image?.smallPath ||
                            image?.path ||
                            ''
                          }
                          title={image && image.title ? image.title : ''}
                          index={index}
                          contributorName={
                            image && image.title ? image.title : ''
                          }
                          contributorUrl={
                            image && image.contributorId
                              ? contributorLink(image.contributorId)
                              : ''
                          }
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard
                          imageUrl={
                            image && image.imageUrl ? image.imageUrl : ''
                          }
                          imageId={image && image.id ? image.id : ''}
                          path={image && image.path ? image.path : ''}
                          title={image && image.title ? image.title : ''}
                          index={index}
                          contributorName={
                            image && image.title ? image.title : ''
                          }
                          contributorUrl={
                            image && image.contributorId
                              ? contributorLink(image.contributorId)
                              : ''
                          }
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

interface ImageCardProps {
  imageUrl: string;
  imageId: string;
  path: string;
  title: string;
  index: number;
  step?: Step;
  tripId?: string;
  contributorName?: string | null;
  contributorUrl?: string | null;
}

export const ImageCard: React.FC<ImageCardProps> = React.memo(
  ({
    path,
    title,
    index,
    imageId,
    imageUrl,
    contributorName,
    contributorUrl,
  }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [height, setHeight] = useState<number | null>(null);
    const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const [imageSrc, setImageSrc] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);

    // const [getImage] = useLazyQuery(FETCH_IMAGE_URL, {
    //   client: unauthorisedClient,
    // });

    useEffect(() => {
      // if (imageUrl) {
      //   setImageSrc(imageUrl);
      // } else
      if (path === null || path === '') {
        setImageSrc(
          `${process.env.REACT_APP_CONFIG_URL}/getFreeImage/${imageId}`,
        );
      } else {
        if (path !== '' && path !== null && path !== undefined) {
          setImageSrc(path);
        }
      }
    }, [path, imageFailedToLoad, imageId]);

    // const [getImage] = useLazyQuery(FETCH_IMAGE_BY_ID, {
    //   client: unauthorisedClient,
    // });

    // useEffect(() => {
    //   let isMounted = true;

    //   const fetchImage = async () => {
    //     if (path === null || path === '') {
    //       try {
    //         const response = await getImage({
    //           variables: { image_id: imageId },
    //         });

    //         // if (response.data && response.data.fetchImage.imagePath) {
    //         //   // const base64Data = `data:image/jpeg;base64,${response.data.fetchImage.imageData}`;

    //         //   if (isMounted) {
    //         //     // setImageSrc(base64Data);
    //         //     setImageSrc(response.data.fetchImage.imagePath);
    //         //     setImageLoaded(true);
    //         //     // saveImageToSessionStorage(imageUrl, base64Data);
    //         //   }
    //         // }
    //         if (
    //           response.data &&
    //           response.data.fetchFreeImage &&
    //           response.data.fetchFreeImage.imageData
    //         ) {
    //           const base64Data = `data:image/jpeg;base64,${response.data.fetchFreeImage.imageData}`;

    //           if (isMounted) {
    //             setImageSrc(base64Data);
    //             // setImageSrc(response.data.fetchImage.imagePath);
    //             setImageLoaded(true);
    //             // saveImageToSessionStorage(imageUrl, base64Data);
    //           } else {
    //             console.error('Error fetching image 4');

    //             return <></>;
    //           }
    //         } else {
    //           console.error('Error fetching image 5');

    //           return <></>;
    //         }
    //       } catch (err) {
    //         console.error('Error fetching image:', err);
    //         return <></>;
    //       }
    //     } else {
    //       if (isMounted && path !== '') {
    //         setImageLoaded(true);
    //         setImageSrc(path);
    //         // saveImageToSessionStorage(path, path);
    //       }
    //     }
    //   };

    //   fetchImage();

    //   return () => {
    //     isMounted = false;
    //   };
    // }, [imageUrl, path]);

    useEffect(() => {
      // If it's a Prerender.io request, immediately set visibility as true
      if (isPrerenderBot()) {
        setIsVisible(true);
        return; // Skip the IntersectionObserver logic for Prerender.io
      }

      let timeout: NodeJS.Timeout;
      const lastState = { isIntersecting: false };
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Clear any pending timeout
          if (timeout) {
            clearTimeout(timeout);
          }

          // Only update if state actually changed
          if (lastState.isIntersecting !== entry.isIntersecting) {
            timeout = setTimeout(() => {
              setIsVisible(entry.isIntersecting);
              lastState.isIntersecting = entry.isIntersecting;
            }, 150); // Debounce time
          }
        },
        {
          root: null,
          rootMargin: '400px',
          threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        },
      );

      if (elementRef.current) {
        observer.observe(elementRef.current);
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
      };
    }, []);

    if (imageFailedToLoad) {
      return <></>;
    }

    return (
      <Box
        ref={elementRef}
        sx={theme => ({
          height: 150,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: {
            height: 130,
          },
        })}
      >
        {!imageFailedToLoad && imageSrc && (
          <>
            <CardMedia
              component="img"
              src={imageSrc}
              alt={title ? 'Image of ' + title : 'Place image'}
              title={title ? 'Image of ' + title : 'Place image'}
              onLoad={() => {
                setImageLoaded(true);
                setImageFailedToLoad(false);
              }}
              onError={() => {
                console.log('error loading image', imageId);

                setImageFailedToLoad(true);
              }} // Set imageLoaded to true once the image is loaded
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                display: imageLoaded ? 'block' : 'none',
              }}
            />{' '}
            {contributorUrl && contributorName && (
              <InfoIcon userName={contributorName} link={contributorUrl} />
            )}
          </>
        )}
        {!imageLoaded && !imageFailedToLoad && (
          <Skeleton
            variant="rectangular"
            sx={{
              height: 150,
              width: '100%',
            }}
          />
        )}
      </Box>
    );
  },
);

export const ImageCardForSwipable: React.FC<ImageCardProps> = React.memo(
  ({
    path,
    title,
    index,
    imageId,
    step,
    tripId,
    contributorName,
    contributorUrl,
  }) => {
    // const [isVisible, setIsVisible] = useState(false);
    const [isEditImages, setIsEditImages] = useState(false);
    const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [imageSrc, setImageSrc] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);
    const isEditor = window.location.href.includes('editor');

    useEffect(() => {
      if (path === null || path === '') {
        setImageSrc(
          `${process.env.REACT_APP_CONFIG_URL}/getFreeImage/${imageId}`,
        );
      } else {
        if (path !== '' && path !== null && path !== undefined) {
          setImageSrc(path);
        }
      }
    }, [path, imageFailedToLoad, imageId]);

    const handleCloseModal = () => {
      setIsEditImages(false);
    };

    return (
      <Box
        key={index}
        ref={elementRef}
        sx={theme => ({
          width: '250px',
          height: '180px',
          objectFit: 'fill',
          float: 'right',
          borderRadius: '20px',
          [theme.breakpoints.down('sm')]: {
            height: '150px',
          },
        })}
      >
        {!imageLoaded && !imageFailedToLoad && (
          <Skeleton
            variant="rectangular"
            sx={theme => ({
              width: '100%',
              height: '180px',
              borderRadius: '20px',
              '&&': {
                borderRadius: '20px',
              },
              [theme.breakpoints.down('sm')]: {
                height: '150px',
              },
            })}
          />
        )}
        {imageFailedToLoad && (
          <Box
            width="100%"
            sx={theme => ({
              flexDirection: 'column',
              height: '180px',
              borderRadius: '20px',
              backgroundColor: theme => theme.palette.grey[300],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              [theme.breakpoints.down('sm')]: {
                height: '150px',
              },
            })}
            onClick={() => {
              setImageFailedToLoad(false);
            }}
          >
            <Typography
              sx={{
                color: theme => theme.palette.grey[600],
                fontSize: 14,
                fontWeight: 500,
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              Tap to reload.
            </Typography>
            {isEditor && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '80%',
                    marginTop: 1,
                  }}
                >
                  <Box
                    sx={{
                      height: '1px',
                      // backgroundColor: 'white',
                      flexGrow: 1,
                      backgroundColor: theme => theme.palette.grey[500],
                      marginRight: 1,
                    }}
                  />
                  <Typography
                    sx={{
                      color: theme => theme.palette.grey[500],
                      textAlign: 'center',
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    or
                  </Typography>
                  <Box
                    sx={{
                      height: '1px',
                      backgroundColor: theme => theme.palette.grey[500],
                      flexGrow: 1,
                      marginLeft: 1,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: theme => theme.palette.grey[600],
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginTop: 1,
                  }}
                  onClick={() => {
                    setIsEditImages(true);
                  }}
                >
                  Manage images
                </Typography>
                {isEditImages && step && tripId && (
                  <EditImagesModal
                    step={step}
                    tripId={tripId}
                    handleCloseModal={handleCloseModal}
                  />
                )}
              </>
            )}
          </Box>
        )}

        {!imageFailedToLoad && !!imageSrc && (
          // <Box
          //   sx={theme => ({
          //     // position: 'relative',
          //     // width: '250px',
          //     // height: '180px',
          //     // objectFit: 'fill',
          //     // float: 'right',
          //     // borderRadius: '20px',
          //     // [theme.breakpoints.down('sm')]: {
          //     //   height: '150px',
          //     // },
          //   })}
          // >
          <>
            <CardMedia
              component="img"
              image={imageSrc}
              alt={step?.title ? 'Image of ' + step.title : 'Place image'}
              title={title ? 'Image of ' + title : 'Place image'}
              onLoad={() => {
                setImageLoaded(true);
                setImageFailedToLoad(false);
              }}
              onError={() => {
                setImageFailedToLoad(true);
              }}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '20px',
                display: !imageLoaded ? 'none' : 'block',
              }}
            />
            {contributorUrl && contributorName && (
              <InfoIcon userName={contributorName} link={contributorUrl} />
            )}
          </>
        )}
      </Box>
    );
  },
);

export function StepPlaceImagesSwipable(props: {
  images: Maybe<StepPlaceImage>[] | Maybe<Image>[] | Maybe<PlaceImage>[];
  step: Step;
  tripId: string;
}) {
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!props.images || props.images.length === 0) return;

    // If it's a Prerender.io request, immediately set visibility as true
    if (isPrerenderBot()) {
      setIsComponentVisible(true);
      return; // Skip the IntersectionObserver logic for Prerender.io
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsComponentVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '500px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    );

    const currentElement = componentRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
      observer.disconnect();
    };
  }, [props.images]);

  const handleNext = useCallback(() => {
    if (props.images.length > 0) {
      const nextIndex =
        currentIndex === props.images.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(nextIndex);
    }
  }, [currentIndex, props.images]);

  const handlePrev = useCallback(() => {
    if (props.images.length > 0) {
      const prevIndex =
        currentIndex === 0 ? props.images.length - 1 : currentIndex - 1;
      setCurrentIndex(prevIndex);
    }
  }, [currentIndex, props.images]);

  useEffect(() => {
    const handleSwipe = (direction: 'left' | 'right') => {
      if (direction === 'left') {
        handleNext();
      } else if (direction === 'right') {
        handlePrev();
      }
    };

    const handleTouchStart = (e: TouchEvent) => {
      const touchStartX = e.touches[0].clientX;
      const handleTouchMove = (e: TouchEvent) => {
        const touchEndX = e.changedTouches[0].clientX;
        const deltaX = touchStartX - touchEndX;
        if (Math.abs(deltaX) > 50) {
          handleSwipe(deltaX > 0 ? 'left' : 'right');
          document.removeEventListener('touchmove', handleTouchMove);
        }
      };
      document.addEventListener('touchmove', handleTouchMove);
    };

    const componentElement = componentRef.current;
    if (componentElement) {
      componentElement.addEventListener('touchstart', handleTouchStart);
    }

    return () => {
      if (componentElement) {
        componentElement.removeEventListener('touchstart', handleTouchStart);
      }
    };
  }, [handleNext, handlePrev]);

  const hasImages = props.images && props.images.length > 0;
  const currentImage = hasImages ? props.images[currentIndex] : null;
  const imageId = currentImage?.id ?? '';

  let imageTitle: string | null | undefined;
  if (hasContributorId(currentImage)) {
    imageTitle = currentImage.title; // Safe to access
  }

  let contributorUrl: string | null | undefined;
  if (hasTitle(currentImage) && currentImage.contributorId) {
    contributorUrl = contributorLink(currentImage.contributorId); // Safe to access
  }

  const imageUrl =
    currentImage && 'imageUrl' in currentImage && currentImage.imageUrl
      ? currentImage.imageUrl
      : '';
  let imagePath = '';
  if (
    currentImage?.previewPath !== null &&
    currentImage?.previewPath !== undefined &&
    currentImage?.previewPath !== ''
  ) {
    imagePath = currentImage.previewPath;
  } else if (
    currentImage?.mediumPath !== null &&
    currentImage?.mediumPath !== undefined &&
    currentImage?.mediumPath !== ''
  ) {
    imagePath = currentImage.mediumPath;
  } else if (
    currentImage?.smallPath !== null &&
    currentImage?.smallPath !== undefined &&
    currentImage?.smallPath !== ''
  ) {
    imagePath = currentImage.smallPath;
  } else if (
    currentImage?.path !== null &&
    currentImage?.path !== undefined &&
    currentImage?.path !== ''
  ) {
    imagePath = currentImage.path;
  }

  if (!hasImages) {
    return null;
  }

  return (
    <Box
      ref={componentRef}
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {props.images.length > 1 && !isSmallScreen && (
        <IconButton
          onClick={handlePrev}
          sx={{
            position: 'absolute',
            left: 10,
            color: 'white',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
            zIndex: 2,
          }}
        >
          <ArrowBackIos sx={{ marginLeft: 0.5, marginRight: -0.5 }} />
        </IconButton>
      )}

      <Box
        sx={theme => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '20px',
          // marginLeft: 0.5,
          [theme.breakpoints.down('sm')]: {
            marginBottom: 0.5,
          },
        })}
      >
        {isComponentVisible ? (
          <ImageCardForSwipable
            key={imageId} // Add key to force remount
            imageUrl={imageUrl}
            imageId={imageId}
            path={imagePath}
            title={props.step.title ?? 'Place image'}
            index={currentIndex}
            step={props.step}
            tripId={props.tripId}
            contributorUrl={contributorUrl}
            contributorName={imageTitle}
          />
        ) : (
          <Box
            sx={theme => ({
              width: '250px',
              height: '180px',
              objectFit: 'fill',
              float: 'right',
              borderRadius: '20px',
              [theme.breakpoints.down('sm')]: {
                height: '150px',
              },
            })}
          />
        )}
      </Box>

      {props.images.length > 1 && !isSmallScreen && (
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: 5,
            color: 'white',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
            zIndex: 2,
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      )}
      <Box
        sx={{
          position: 'absolute',
          bottom: 15,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Render dots */}
        {Array.from({ length: props.images.length }).map((_, index) => (
          <Box
            key={index}
            sx={{
              width: '7px',
              height: '7px',
              borderRadius: '50%',
              backgroundColor:
                currentIndex === index ? 'white' : 'rgba(255, 255, 255, 0.5)',
              margin: '0 5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

export function StepPlaceImagesSwipableHeader(props: {
  images: Maybe<StepPlaceImage>[] | Maybe<Image>[] | Maybe<PlaceImage>[];
  step: Step;
  tripId: string;
}) {
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!props.images || props.images.length === 0) return;

    // If it's a Prerender.io request, immediately set visibility as true
    if (isPrerenderBot()) {
      setIsComponentVisible(true);
      return; // Skip the IntersectionObserver logic for Prerender.io
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsComponentVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '500px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    );

    const currentElement = componentRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
      observer.disconnect();
    };
  }, [props.images]);

  const handleNext = useCallback(() => {
    if (props.images.length > 0) {
      const nextIndex =
        currentIndex === props.images.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(nextIndex);
    }
  }, [currentIndex, props.images]);

  const handlePrev = useCallback(() => {
    if (props.images.length > 0) {
      const prevIndex =
        currentIndex === 0 ? props.images.length - 1 : currentIndex - 1;
      setCurrentIndex(prevIndex);
    }
  }, [currentIndex, props.images]);

  useEffect(() => {
    const handleSwipe = (direction: 'left' | 'right') => {
      if (direction === 'left') {
        handleNext();
      } else if (direction === 'right') {
        handlePrev();
      }
    };

    const handleTouchStart = (e: TouchEvent) => {
      const touchStartX = e.touches[0].clientX;
      const handleTouchMove = (e: TouchEvent) => {
        const touchEndX = e.changedTouches[0].clientX;
        const deltaX = touchStartX - touchEndX;
        if (Math.abs(deltaX) > 50) {
          handleSwipe(deltaX > 0 ? 'left' : 'right');
          document.removeEventListener('touchmove', handleTouchMove);
        }
      };
      document.addEventListener('touchmove', handleTouchMove);
    };

    const componentElement = componentRef.current;
    if (componentElement) {
      componentElement.addEventListener('touchstart', handleTouchStart);
    }

    return () => {
      if (componentElement) {
        componentElement.removeEventListener('touchstart', handleTouchStart);
      }
    };
  }, [handleNext, handlePrev]);

  const hasImages = props.images && props.images.length > 0;
  const currentImage = hasImages ? props.images[currentIndex] : null;
  const imageId = currentImage?.id ?? '';
  const imageUrl =
    currentImage && 'imageUrl' in currentImage && currentImage.imageUrl
      ? currentImage.imageUrl
      : '';

  let imageTitle: string | null | undefined;
  if (hasContributorId(currentImage)) {
    imageTitle = currentImage.title; // Safe to access
  }

  let contributorUrl: string | null | undefined;
  if (hasTitle(currentImage) && currentImage.contributorId) {
    contributorUrl = contributorLink(currentImage.contributorId); // Safe to access
  }
  let imagePath = '';
  if (
    currentImage?.mediumPath !== null &&
    currentImage?.mediumPath !== undefined &&
    currentImage?.mediumPath !== ''
  ) {
    imagePath = currentImage.mediumPath;
  } else if (
    currentImage?.previewPath !== null &&
    currentImage?.previewPath !== undefined &&
    currentImage?.previewPath !== ''
  ) {
    imagePath = currentImage.previewPath;
  } else if (
    currentImage?.smallPath !== null &&
    currentImage?.smallPath !== undefined &&
    currentImage?.smallPath !== ''
  ) {
    imagePath = currentImage.smallPath;
  } else if (
    currentImage?.path !== null &&
    currentImage?.path !== undefined &&
    currentImage?.path !== ''
  ) {
    imagePath = currentImage.path;
  }

  if (!hasImages) {
    return null;
  }

  return (
    <Box
      ref={componentRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        // overflow: 'hidden',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
      }}
    >
      {props.images.length > 1 && !isSmallScreen && (
        <IconButton
          onClick={handlePrev}
          sx={{
            position: 'absolute',
            left: 10,
            top: '45%',
            color: 'white',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
            zIndex: 2,
          }}
        >
          <ArrowBackIos sx={{ marginLeft: 0.5, marginRight: -0.5 }} />
        </IconButton>
      )}

      <Box
        sx={theme => ({
          width: '100%',
          height: '100%',
          // display: 'flex',
          // justifyContent: 'center',
          // borderTopLeftRadius: '20px',
          // borderTopRightRadius: '20px',
          // marginLeft: 0.5,
          // marginTop: 1,
          // marginBottom: 1,
          [theme.breakpoints.down('sm')]: {
            // marginBottom: 0.5,
          },
        })}
      >
        {isComponentVisible ? (
          <ImageCardForSwipableHeader
            key={imageId} // Add key to force remount
            imageUrl={imageUrl}
            imageId={imageId}
            path={imagePath}
            title={props.step.title ?? 'Place image'}
            index={currentIndex}
            step={props.step}
            tripId={props.tripId}
            contributorName={imageTitle}
            contributorUrl={contributorUrl}
          />
        ) : (
          <Box
            sx={theme => ({
              width: '100%',
              height: '100%',
              objectFit: 'fill',
              float: 'right',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              [theme.breakpoints.down('sm')]: {
                height: '100%',
              },
            })}
          />
        )}
      </Box>

      {props.images.length > 1 && !isSmallScreen && (
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            top: '45%',
            right: 5,
            color: 'white',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
            zIndex: 2,
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      )}
      <Box
        sx={{
          position: 'absolute',
          bottom: 15,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Render dots */}
        {Array.from({ length: props.images.length }).map((_, index) => (
          <Box
            key={index}
            sx={{
              width: '7px',
              height: '7px',
              borderRadius: '50%',
              backgroundColor:
                currentIndex === index ? 'white' : 'rgba(255, 255, 255, 0.5)',
              margin: '0 5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

export const ImageCardForSwipableHeader: React.FC<ImageCardProps> = React.memo(
  ({
    path,
    title,
    index,
    imageId,
    step,
    tripId,
    contributorName,
    contributorUrl,
  }) => {
    // const [isVisible, setIsVisible] = useState(false);
    const [isEditImages, setIsEditImages] = useState(false);
    const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [imageSrc, setImageSrc] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);
    const isEditor = window.location.href.includes('editor');

    useEffect(() => {
      if (path === null || path === '') {
        setImageSrc(
          `${process.env.REACT_APP_CONFIG_URL}/getFreeImage/${imageId}`,
        );
      } else {
        if (path !== '' && path !== null && path !== undefined) {
          setImageSrc(path);
        }
      }
    }, [path, imageFailedToLoad, imageId]);

    // useEffect(() => {
    //   let timeout: NodeJS.Timeout;
    //   const lastState = { isIntersecting: false };
    //   const observer = new IntersectionObserver(
    //     ([entry]) => {
    //       // Clear any pending timeout
    //       if (timeout) {
    //         clearTimeout(timeout);
    //       }

    //       // Only update if state actually changed
    //       if (lastState.isIntersecting !== entry.isIntersecting) {
    //         timeout = setTimeout(() => {
    //           setIsVisible(entry.isIntersecting);
    //           lastState.isIntersecting = entry.isIntersecting;
    //         }, 150); // Debounce time
    //       }
    //     },
    //     {
    //       root: null,
    //       rootMargin: '400px',
    //       threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    //     },
    //   );

    //   if (elementRef.current) {
    //     observer.observe(elementRef.current);
    //   }

    //   return () => {
    //     if (timeout) {
    //       clearTimeout(timeout);
    //     }
    //     if (elementRef.current) {
    //       observer.unobserve(elementRef.current);
    //     }
    //   };
    // }, []);

    const handleCloseModal = () => {
      setIsEditImages(false);
    };

    return (
      <Box
        key={index}
        ref={elementRef}
        sx={theme => ({
          width: '100%',
          height: '100%',
          objectFit: 'fill',
          float: 'right',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          [theme.breakpoints.down('sm')]: {
            height: '100%',
          },
        })}
      >
        {!imageLoaded && !imageFailedToLoad && (
          <Skeleton
            variant="rectangular"
            sx={theme => ({
              width: '100%',
              height: '100%',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              '&&': {
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              },
              [theme.breakpoints.down('sm')]: {
                height: '100%',
              },
            })}
          />
        )}
        {imageFailedToLoad && (
          <Box
            width="100%"
            sx={theme => ({
              flexDirection: 'column',
              height: '100%',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              backgroundColor: theme => theme.palette.grey[300],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              [theme.breakpoints.down('sm')]: {
                height: '100%',
              },
            })}
            onClick={() => {
              setImageFailedToLoad(false);
            }}
          >
            <Typography
              sx={{
                color: theme => theme.palette.grey[600],
                fontSize: 14,
                fontWeight: 500,
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              Tap to reload.
            </Typography>
            {isEditor && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '80%',
                    marginTop: 1,
                  }}
                >
                  <Box
                    sx={{
                      height: '1px',
                      // backgroundColor: 'white',
                      flexGrow: 1,
                      backgroundColor: theme => theme.palette.grey[500],
                      marginRight: 1,
                    }}
                  />
                  <Typography
                    sx={{
                      color: theme => theme.palette.grey[500],
                      textAlign: 'center',
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    or
                  </Typography>
                  <Box
                    sx={{
                      height: '1px',
                      backgroundColor: theme => theme.palette.grey[500],
                      flexGrow: 1,
                      marginLeft: 1,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: theme => theme.palette.grey[600],
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginTop: 1,
                  }}
                  onClick={() => {
                    setIsEditImages(true);
                  }}
                >
                  Manage images
                </Typography>
                {isEditImages && step && tripId && (
                  <EditImagesModal
                    step={step}
                    tripId={tripId}
                    handleCloseModal={handleCloseModal}
                  />
                )}
              </>
            )}
          </Box>
        )}

        {!imageFailedToLoad && !!imageSrc && (
          <>
            <CardMedia
              component="img"
              image={imageSrc}
              alt={step?.title ? 'Image of ' + step.title : 'Place image'}
              title={step?.title ? 'Image of ' + step.title : 'Place image'}
              onLoad={() => {
                setImageLoaded(true);
                setImageFailedToLoad(false);
              }}
              onError={() => {
                setImageFailedToLoad(true);
              }}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                display: !imageLoaded ? 'none' : 'block',
              }}
            />
            {contributorUrl && contributorName && (
              <InfoIcon userName={contributorName} link={contributorUrl} />
            )}
          </>
        )}
      </Box>
    );
  },
);
