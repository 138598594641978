import { useQuery } from '@apollo/client';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import StarRateTwoToneIcon from '@mui/icons-material/StarRateTwoTone';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Activity,
  Maybe,
  Place,
  PlaceImage,
} from '../../generated/user_graphql';
import { useUserAndTripData } from '../../hooks/useUserData';
import { errorsSelector } from '../../store/ErrorSlice';
import { setLikedPlaces } from '../../store/LikedPlacesSlice';
import { ProminentOrangeButton } from '../../theme-components/Buttons';
import Space from '../../theme-components/Spacing';
import { Body1, TitleH4 } from '../../theme-components/Typography';
import {
  formatReviewString,
  getPlaceMapUrls,
  isPrerenderBot,
} from '../../utils/helpers';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { Header } from '../AppWrapper';
import ErrorModal from '../Error';
import { LikeActivity } from '../favourite/LikeActivity';
import { LikePlace } from '../favourite/likePlace';
import { QUERY_GET_USER_FAVOURITE_ACTIVITIES } from '../gql-user/activity';
import { QUERY_GET_USER_FAVOURITE_PLACES } from '../gql-user/place';
import { QUERY_GET_USER_FAVOURITE_TRIPS } from '../gql-user/userTripsQuery';
import { LikeSvg } from '../illustrations/Like';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';
import { AddPlaceToDayButton } from '../trip-details/AddPlaceToDay';
import {
  PlaceOrActivityMoreInfoModal,
  TodaysHours,
} from '../trip-details/PlaceMoreInfoModal';
import ActivityCardCTAs from '../trip-details/PublicActivityCardCTA';
import PlaceCardCTAs from '../trip-details/PublicPlaceCardCTA';
import { PlaceImages, PlaceSingleImage } from '../trip-details/StepImages';
import { PublicTripItem } from '../trips-list/PublicTripItem';

export function UserFavouriteTrips() {
  useUserAndTripData();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, error, data } = useQuery(QUERY_GET_USER_FAVOURITE_TRIPS, {
    fetchPolicy: 'network-only',
  });
  const {
    loading: loadingPlaces,
    error: errorPlaces,
    data: dataPlaces,
  } = useQuery(QUERY_GET_USER_FAVOURITE_PLACES, {
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingActivities,
    error: errorActivities,
    data: dataActivities,
  } = useQuery(QUERY_GET_USER_FAVOURITE_ACTIVITIES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (
      dataPlaces &&
      dataPlaces.userLikedPlaces &&
      dataPlaces.userLikedPlaces.length > 0
    ) {
      dispatch(setLikedPlaces(dataPlaces.userLikedPlaces));
    }
  }, [dataPlaces, dispatch]); // dependencies for useEffect

  const { errorModalOpen } = useSelector(errorsSelector);

  if (loading || loadingPlaces || loadingActivities) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: 3,
        [theme.breakpoints.down('sm')]: { paddingTop: 0 },
      })}
    >
      {errorModalOpen && <ErrorModal />}
      {/* <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        
      </Box> */}
      {error || !data ? (
        <div>
          Error loading your favourite trips, refresh to try agian or contact
          help@rooutie.com
        </div>
      ) : (
        <>
          {data.userLikedTrips.length > 0 && (
            <>
              <Stack
                direction="column"
                width="100%"
                sx={theme => ({
                  borderRadius: '20px',
                  border: '2px solid rgba(211, 211, 211, 0.4)',
                  paddingTop: 1.5,
                  paddingBottom: 3,
                  paddingLeft: 2,
                  backgroundColor: '#FFF8EE',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
                  [theme.breakpoints.down('sm')]: {
                    paddingX: 1.5,
                    paddingY: 1,
                    marginTop: 1.5,
                  },
                })}
              >
                <Header>Favourite trips</Header>
                <PublicTripItem trips={data.userLikedTrips} route="/preview/" />
              </Stack>
              <Space size="lg" />
            </>
          )}
        </>
      )}
      {errorPlaces || !dataPlaces ? (
        <div>
          Error loading your favourite places, refresh to try agian or contact
          help@rooutie.com
        </div>
      ) : (
        <>
          {dataPlaces.userLikedPlaces.length > 0 && (
            <>
              <Stack
                direction="column"
                width="100%"
                sx={theme => ({
                  borderRadius: '20px',
                  // background: 'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
                  border: '2px solid rgba(211, 211, 211, 0.4)',
                  // paddingRight: 1,
                  paddingTop: 1.5,
                  paddingBottom: 2,
                  // marginTop: 1.5,
                  paddingLeft: 2,
                  marginBottom: 1.5,
                  backgroundColor: '#FFF8EE',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
                  [theme.breakpoints.down('sm')]: {
                    paddingY: 1,
                    marginTop: 1.5,
                    paddingX: 1.5,
                    marginBottom: 1.5,
                    // marginLeft: 1,
                    // marginRight: 1,
                  },
                })}
              >
                <Box mb={1}>
                  <Header>Favourite places</Header>
                </Box>
                <FavouritePlaces places={dataPlaces.userLikedPlaces} />
              </Stack>
              <Space size="lg" />
            </>
          )}
        </>
      )}
      {errorActivities || !dataActivities ? (
        <div>
          Error loading your favourite activities, refresh to try agian or
          contact help@rooutie.com
        </div>
      ) : (
        <>
          {dataActivities.userLikedActivities.length > 0 && (
            <>
              <Stack
                direction="column"
                width="100%"
                sx={theme => ({
                  borderRadius: '20px',
                  // background: 'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
                  border: '2px solid rgba(211, 211, 211, 0.4)',
                  // paddingRight: 1,
                  paddingTop: 1.5,
                  paddingBottom: 2,
                  // marginTop: 1.5,
                  paddingLeft: 2,
                  marginBottom: 1.5,
                  backgroundColor: '#FFF8EE',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
                  [theme.breakpoints.down('sm')]: {
                    // paddingRight: 1.5,
                    paddingY: 1,
                    marginTop: 1.5,
                    paddingX: 1.5,
                    marginBottom: 1.5,
                    // marginLeft: 1,
                    // marginRight: 1,
                  },
                })}
              >
                <Box mb={1}>
                  <Header>Favourite Activities</Header>
                </Box>
                <FavouriteActivities
                  activities={dataActivities.userLikedActivities}
                />
              </Stack>
            </>
          )}
        </>
      )}
      {dataActivities.userLikedActivities.length < 1 &&
      dataPlaces.userLikedPlaces.length < 1 &&
      data.userLikedTrips.length < 1 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Horizontal alignment
            justifyContent: 'center', // Vertical alignment
            textAlign: 'center',
            gap: 2, // Space between text and button
          }}
        >
          <Box
            sx={theme => ({
              marginTop: '10%',
              width: '40%',
              [theme.breakpoints.down('sm')]: {
                width: '75%',
                marginTop: '35%',
              },
              [theme.breakpoints.down('sm')]: {
                width: '85%',
                marginTop: '35%',
              },
            })}
          >
            <LikeSvg />
          </Box>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: 'text.secondary', // Softer color to complement primary button
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
              mb: 1, // Adds slight bottom margin
            }}
          >
            You haven’t liked any trips yet!
          </Typography>

          <ProminentOrangeButton
            sx={{
              marginRight: 1,
              marginBottom: 1,
              paddingX: 3,
              fontWeight: 'bold',
              boxShadow: '0px 4px 10px rgba(255, 87, 34, 0.4)', // Subtle shadow effect
            }}
            onClick={() => {
              gtag('event', 'explore-all-countries');
              navigate('/explore');
            }}
          >
            Explore All Countries
          </ProminentOrangeButton>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

interface PropsPlacesList {
  readonly places: Maybe<Place>[];
}

export function FavouritePlaces(props: PropsPlacesList) {
  return (
    <Box>
      {props.places && props.places.length > 0 && (
        <>
          <Grid container>
            {props.places.map((place, index) => (
              <React.Fragment key={index}>
                {place && <FavouritePlaceCard place={place} />}
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

interface PropsActivitiesList {
  readonly activities: Maybe<Activity>[];
}

export function FavouriteActivities(props: PropsActivitiesList) {
  return (
    <Box>
      {props.activities && props.activities.length > 0 && (
        <>
          <Grid container>
            {props.activities.map((activity, index) => (
              <React.Fragment key={index}>
                {activity && <FavouriteActivityCard activity={activity} />}
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

interface PlaceCardProps {
  place: Place;
  dayId?: string;
  selected?: boolean;
  tripId?: string;
  showEdit?: boolean;
  collapsedDetails?: boolean;
}

const FavouritePlaceCard: React.FC<PlaceCardProps> = ({ place }) => {
  const dispatch = useDispatch();
  const [moreInfoOpened, setMoreInfoOpened] = useState(false);
  const closeModal = () => {
    setMoreInfoOpened(false);
  };

  if (!place) {
    return <></>;
  }

  let placeImages: Maybe<PlaceImage>[] = [];
  if (place && place.images && place.images.length > 0) {
    placeImages = place.images;
  }

  const { takeMeThereUrl, openGoogleMapsUrl } = getPlaceMapUrls(place);

  let moreInfoForPlace = false;
  if (
    place &&
    ((place.openingHours && place.openingHours.length > 0) ||
      place.website ||
      place.address ||
      place.phoneNumber)
  ) {
    moreInfoForPlace = true;
  }

  return (
    <Box
      sx={theme => ({
        cursor: 'pointer',
        marginTop: 2,
        marginBottom: 1,
        paddingRight: 2,
        width: '33%',

        [theme.breakpoints.down('xl')]: {
          width: '33.3%',
        },
        [theme.breakpoints.down('lg')]: {
          width: '50%',
        },
        [theme.breakpoints.down('md')]: {
          width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          paddingRight: 0,
        },
      })}
    >
      <Stack
        direction="column"
        alignItems="left"
        sx={theme => ({
          // width: '100%',
          display: 'flex',
          flexGrow: 1,
          cursor: 'pointer',
          height: '100%',
          borderRadius: '20px',
          // backgroundColor: 'rgba(250, 250, 250, 0.6)',
          backgroundColor: '#f6f0ff',
          border: '2px solid #CBB8FF',

          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

          paddingTop: 1.25,
          paddingBottom: 1.5,
          paddingLeft: 1.5,
          paddingRight: 1.5,
        })}
      >
        <Stack
          direction="row"
          // alignItems="left"

          sx={theme => ({
            width: '100%',

            [theme.breakpoints.down('sm')]: {
              fontSize: 16,
              maxWidth: '99%',
            },
          })}
        >
          <Box
            sx={theme => ({
              display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
              alignItems: 'flex-start',
              // alignSelf: 'center',
              marginRight: 1,
              [theme.breakpoints.down('sm')]: {
                marginRight: 0.5,
              },
            })}
          >
            <PlaceTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                color: theme.palette.icon.main,
              })}
            />
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            // alignItems="center"

            sx={{
              alignItems: 'flex-start',

              maxWidth: '100%',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {place.title ? <TitleH4>{place.title}</TitleH4> : <Box />}
            {place && <LikePlace placeId={place.id} />}
            {place && (
              <AddPlaceToDayButton
                placeId={place.id}
                country={place.country ? place.country : undefined}
              />
            )}
          </Stack>
        </Stack>

        <Box
          // display={collapsedDetails === true ? 'none' : undefined}
          display="flex"
          flexDirection={'column'}
          sx={theme => ({
            // paddingLeft: 1,
            height: '100%',
            width: '100%',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
              // paddingLeft: 3,
            },
          })}
        >
          {place && (place.rating || place.noOfReviews) && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center', // Vertically center content
                justifyContent: 'space-between', // Horizontally center content
              }}
            >
              <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
                <StarRateTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 1,
                    color: 'rgb(250, 175, 0)',
                    // color: '#9E7FEF',
                    // [theme.breakpoints.down('sm')]: {
                    //   marginRight: 0.5,
                    //   width: 20,
                    //   height: 20,
                    // },
                  })}
                />

                {place.noOfReviews && (
                  <>
                    {place.rating && (
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 16,
                          alignSelf: 'center',
                          color: '#36454F',
                          marginRight: 0.5,
                        }}
                      >
                        {place.rating}{' '}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        alignSelf: 'center',
                        color: '#6E7191',
                        marginRight: 0.5,
                      }}
                    >
                      ~{formatReviewString(place.noOfReviews)}
                    </Typography>
                  </>
                )}
              </Stack>
            </Stack>
          )}
          {/* Operating Hours */}
          {place && place.openingHours && place.openingHours.length > 0 && (
            <TodaysHours place={place} />
          )}
          <Box
            sx={{
              flexGrow: 1, // This box will expand to fill the space
            }}
          />
          <Space size="sm" />

          <PlaceCardCTAs place={place} renderingOnFavourite={true} />

          {place.aiDescription && (
            <Box>
              <Body1>
                <RenderHtmlFromResponseNoMargin
                  content={place.aiDescription}
                  limitToLines={isPrerenderBot() ? 500 : 3}
                />
              </Body1>
            </Box>
          )}

          <Box>
            {placeImages.length > 1 ? (
              <PlaceImages images={placeImages} />
            ) : (
              <PlaceSingleImage images={placeImages} />
            )}

            {moreInfoOpened && (
              <PlaceOrActivityMoreInfoModal
                place={place}
                onClose={closeModal}
              />
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

interface ActivityCardProps {
  activity: Activity;
}

const FavouriteActivityCard: React.FC<ActivityCardProps> = ({ activity }) => {
  if (!activity) {
    return <></>;
  }

  let placeImages: Maybe<PlaceImage>[] = [];
  if (activity && activity.images && activity.images.length > 0) {
    placeImages = activity.images;
  }

  return (
    <Box
      sx={theme => ({
        cursor: 'pointer',
        marginTop: 2,
        marginBottom: 1,
        paddingRight: 2,
        width: '33%',

        [theme.breakpoints.down('xl')]: {
          width: '33.3%',
        },
        [theme.breakpoints.down('lg')]: {
          width: '50%',
        },
        [theme.breakpoints.down('md')]: {
          width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          paddingRight: 0,
        },
      })}
    >
      <Stack
        direction="column"
        alignItems="left"
        sx={theme => ({
          // width: '100%',
          display: 'flex',
          flexGrow: 1,
          cursor: 'pointer',
          height: '100%',
          borderRadius: '20px',
          // backgroundColor: 'rgba(250, 250, 250, 0.6)',
          backgroundColor: '#f6f0ff',
          border: '2px solid #CBB8FF',

          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

          paddingTop: 1.25,
          paddingBottom: 1.5,
          paddingLeft: 1.5,
          paddingRight: 1.5,
        })}
      >
        <Stack
          direction="row"
          alignItems="left"
          sx={theme => ({
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              fontSize: 16,
              maxWidth: '99%',
            },
          })}
        >
          <Box
            sx={theme => ({
              display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
              // alignSelf: 'center',
              alignItems: 'flex-start',

              marginRight: 1,
              [theme.breakpoints.down('sm')]: {
                marginRight: 0.5,
              },
            })}
          >
            <PlaceTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                color: theme.palette.icon.main,
              })}
            />
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            // alignItems="center"

            sx={{
              alignItems: 'flex-start',

              maxWidth: '100%',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {activity.name ? <TitleH4>{activity.name}</TitleH4> : <Box />}
            {activity && <LikeActivity activityId={activity.id} />}
            {activity && (
              <AddPlaceToDayButton
                activityId={activity.id}
                country={activity.country ? activity.country : undefined}
              />
            )}
          </Stack>
        </Stack>

        <Box
          display="flex"
          flexDirection={'column'}
          sx={theme => ({
            // paddingLeft: 1,
            height: '100%',
            width: '100%',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
              // paddingLeft: 3,
            },
          })}
        >
          {activity && (activity.rating || activity.noOfReviews) && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center', // Vertically center content
                justifyContent: 'space-between', // Horizontally center content
              }}
            >
              <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
                <StarRateTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 1,
                    color: 'rgb(250, 175, 0)',
                  })}
                />

                {activity.rating && (
                  <Stack direction="row" spacing={0.5}>
                    {activity.noOfReviews && (
                      <>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: 16,
                            alignSelf: 'center',
                            color: '#36454F',
                            marginRight: 0.5,
                          }}
                        >
                          {activity.rating}{' '}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: 16,
                            alignSelf: 'center',
                            color: '#6E7191',
                            marginRight: 0.5,
                          }}
                        >
                          ~{formatReviewString(activity.noOfReviews)}
                        </Typography>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
          )}
          <Box
            sx={{
              flexGrow: 1,
            }}
          />
          <Space size="sm" />
          <ActivityCardCTAs activity={activity} renderingOnFavourite={true} />

          {activity.aiDescription && (
            <Box>
              <Body1>
                <RenderHtmlFromResponseNoMargin
                  content={activity.aiDescription}
                  limitToLines={isPrerenderBot() ? 500 : 3}
                />
              </Body1>
            </Box>
          )}

          <Box>
            {placeImages.length > 1 ? (
              <PlaceImages images={placeImages} />
            ) : (
              <PlaceSingleImage images={placeImages} />
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
