import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import AddLocationTwoToneIcon from '@mui/icons-material/AddLocationTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DirectionsSubwayFilledTwoToneIcon from '@mui/icons-material/DirectionsSubwayFilledTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import FastfoodTwoToneIcon from '@mui/icons-material/FastfoodTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import { Button, Box, Stack, Typography, Divider } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  setAddStepModalOpen,
  setStepDayId,
  setStepType,
} from '../../../store/StepSlice';
import { returnFormattedDate } from '../../../utils/helpers';
import { AddRecommendationModal } from '../manage-trip-modals/add-step/AddRecommendationModal';
import { AddStepToDay } from '../manage-trip-modals/add-step/AddStepToDayModal';
import { DeleteDayModal } from '../manage-trip-modals/DeleteDayModal';
import { EditDayModal } from '../manage-trip-modals/EditDayModal';

interface ActionsDayProps {
  dayId: string;
  dayNumber: number;
  iconsOnly: boolean;
  tripType: string;
  dayDate?: Date | undefined | null;
  marginLeft?: boolean;
  outlinedAddButton?: boolean;
  stepNumber?: number;
  dayDescription?: string | null;
}

interface AnchorPosition {
  top: number;
  left: number;
}

export const ActionsDay: React.FC<ActionsDayProps> = ({
  dayId,
  dayNumber,
  iconsOnly,
  tripType,
  dayDate,
  marginLeft,
  outlinedAddButton = false,
  stepNumber,
  dayDescription,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fixedAnchorPosition, setFixedAnchorPosition] =
    useState<AnchorPosition | null>(null);
  const [scrollY, setScrollY] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(null);
  const handleMenuItemClick = category => {
    if (!currentCategory) {
      const scrollPosition = window.scrollY;
      setScrollY(scrollPosition);
      setCurrentCategory(category);
      dispatch(setStepType(category)); // Set step type
    }
  };

  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);
  const [editDay, setEditDay] = useState(false);

  const [addRecommendation, setAddRecommendation] = useState(false);

  const handleClick = event => {
    document.body.style.top = `-${scrollY}px`;
    setAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    setFixedAnchorPosition({ top: rect.top, left: rect.left });
  };

  const handleClose = () => {
    setAnchorEl(null);
    enableScroll();
  };

  const handleCloseModal = () => {
    setCurrentCategory(null);
    setAddRecommendation(false);
    setEditDay(false);
    setAnchorEl(null);
    setIsDelete(false);
    setTimeout(() => {
      enableScroll();
    }, 200);
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden'; // Disable scroll
    document.body.style.position = 'fixed'; // Prevent page jump
    document.body.style.top = `-${scrollY}px`; // Move body to the saved scroll position
  };

  const enableScroll = () => {
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
    // document.body.style.top = ''; // Reset top
    window.scrollTo(0, scrollY); // Restore previous scroll position
  };

  useEffect(() => {
    if (currentCategory) {
      disableScroll(); // Lock scrolling when modal is displayed
    }

    return () => {
      // setIsModalDisplayed(false);
      // there was enable scroll but that caused unexpected screen scrolls to top of the screen
    };
  }, [currentCategory]);

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu && !currentCategory) {
      const handleScroll = e => {
        handleClose(); // Close menu on scroll
      };

      const handleTouchMove = e => {
        handleClose(); // Close menu on touchmove
      };

      // Listen to scroll and touchmove events
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listeners when the menu is closed
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu, currentCategory]);

  return (
    <Box
    // sx={theme => ({
    //   width: '100%',
    //   flexShrink: 0, // Prevent the button from shrinking
    // })}
    >
      {!iconsOnly ? (
        <>
          {!outlinedAddButton ? (
            <Button
              variant="contained"
              endIcon={
                <ArrowDropDownIcon
                  sx={theme => ({
                    height: 17,
                    width: 17,
                    pointerEvents: 'none',
                  })}
                />
              }
              onClick={e => {
                const scrollPosition = window.scrollY;
                setScrollY(scrollPosition);
                handleClick(e);
              }}
              sx={theme => ({
                fontSize: 13,
                fontWeight: 700,
                paddingY: 0.65,
                paddingX: 1.75,
                backgroundColor: '#36454F',
                borderRadius: 20,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                boxShadow: 'none', // Disable shadow
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: '#4A5D6F',
                },
                '& .MuiButton-endIcon': {
                  marginLeft: '2px', // Decrease this value as needed
                },
                '& .MuiButton-startIcon': {
                  marginRight: '4px', // Decrease this value as needed
                },
              })}
            >
              {tripType && tripType === 'guide' ? (
                <>Add to guide</>
              ) : (
                <>Add to Day {dayNumber}</>
              )}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<AddCircleTwoToneIcon />}
              onClick={e => {
                const scrollPosition = window.scrollY;
                setScrollY(scrollPosition);
                handleClick(e);
              }}
              sx={{
                position: 'absolute',
                borderRadius: '20px',
                border: '2px solid',
                right: 0,
                fontSize: 14,
                fontWeight: 600,
                backgroundColor: '#FFFFFF',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              Add here
            </Button>
          )}
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            disableScrollLock={true}
            anchorReference="anchorPosition"
            anchorPosition={
              fixedAnchorPosition
                ? {
                    top: fixedAnchorPosition.top,
                    left: fixedAnchorPosition.left,
                  }
                : undefined
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_PLACE');
                dispatch(setStepDayId(dayId));
              }}
            >
              <ListItemIcon>
                <AddLocationTwoToneIcon />
              </ListItemIcon>
              Add Place
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_FOOD_DRINK');
                dispatch(setStepDayId(dayId));
                dispatch(setAddStepModalOpen(true));
              }}
            >
              <ListItemIcon>
                <FastfoodTwoToneIcon />
              </ListItemIcon>
              Add Food & Drink
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_ACCOMMODATION');
                dispatch(setStepDayId(dayId));
                dispatch(setAddStepModalOpen(true));
              }}
            >
              <ListItemIcon>
                <HotelTwoToneIcon />
              </ListItemIcon>
              Add Stay
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_ACTIVITY');
                dispatch(setStepDayId(dayId));
                dispatch(setAddStepModalOpen(true));
              }}
            >
              <ListItemIcon>
                <LocalActivityTwoToneIcon />
              </ListItemIcon>
              Add Activity
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_TRANSPORT');
                dispatch(setStepDayId(dayId));
                dispatch(setAddStepModalOpen(true));
              }}
            >
              <ListItemIcon>
                <DirectionsSubwayFilledTwoToneIcon />
              </ListItemIcon>
              Add Transport
            </MenuItem>

            {!outlinedAddButton && (
              <>
                <Box sx={{ paddingX: 1.5 }}>
                  <Divider />
                </Box>
                <MenuItem
                  onClick={() => {
                    dispatch(setAddStepModalOpen(false));
                    dispatch(setStepDayId(dayId));
                    setAddRecommendation(true);
                  }}
                >
                  <ListItemIcon>
                    <LocalActivityTwoToneIcon />
                  </ListItemIcon>
                  Add related activity
                </MenuItem>
                <Box sx={{ paddingX: 1.5 }}>
                  <Divider />
                </Box>

                <MenuItem
                  onClick={() => {
                    dispatch(setAddStepModalOpen(false));
                    setEditDay(true);
                  }}
                >
                  <ListItemIcon>
                    <EditTwoToneIcon />
                  </ListItemIcon>
                  Edit description
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(setAddStepModalOpen(false));
                    setIsDelete(true);
                  }}
                >
                  <ListItemIcon>
                    <DeleteTwoToneIcon />
                  </ListItemIcon>
                  Delete day
                </MenuItem>
              </>
            )}

            {/* </span>
            </Tooltip> */}
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            fontSize: 18,
            fontWeight: 600,
            marginLeft: 0,
            // paddingX: 2,
            paddingBottom: 2,
            paddingTop: 1,
            // backgroundColor: '#F0F0F0',
            borderRadius: '20px',
            marginBottom: 1,
            // marginTop: marginLeft ? 2 : 0,
            // marginBottom: marginLeft ? 2 : 0,
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            width="100%"
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: '#555',
              // padding: '8px 16px',
              backgroundColor: '#FFFFFF',
              borderRadius: '20px',
              // border: '2px solid #5428D4',

              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Box
              sx={{
                fontSize: 16,
                fontWeight: 700,
                color: '#333',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {tripType && tripType === 'guide' ? (
                <Box sx={{ marginBottom: 1, marginTop: 0 }}>Add to guide</Box>
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    position: 'relative',
                    height: 20,
                    marginTop: 1,
                    marginBottom: 1,
                  }} // Make the Stack position relative
                >
                  {/* Centered Box */}
                  <Box
                    sx={{
                      position: 'absolute', // Use absolute positioning
                      left: '50%', // Start from the middle of the parent
                      transform: 'translateX(-50%)', // Move it back half of its width
                      whiteSpace: 'nowrap', // Prevent wrapping
                      zIndex: 1, // Bring it in front if necessary
                    }}
                  >
                    Add to Day {dayNumber} (above)
                  </Box>
                  {dayDate && (
                    <Box sx={{ position: 'absolute', right: 8 }}>
                      {/* Use pl={2} for spacing */}
                      <Typography
                        sx={theme => ({
                          fontWeight: 600,
                          fontSize: 14,
                          color: '#4E4B66',
                          [theme.breakpoints.down('sm')]: {
                            fontSize: 14,
                          },
                        })}
                      >
                        {returnFormattedDate(dayDate, dayNumber - 1, false)}
                      </Typography>
                    </Box>
                  )}
                  {/* Second child - aligned normally */}
                </Stack>
              )}
            </Box>
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              spacing={2}
              rowGap={1}
              sx={{
                fontSize: 14,
                fontWeight: 600,
                // color: '#555',
                padding: '8px 16px',
                // backgroundColor: '#FFFFFF',
                // borderRadius: '20px',
                // border: '2px solid #5428D4',

                // boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
              }}
            >
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }
                  handleMenuItemClick('CATEGORY_PLACE');
                  // dispatch(setStepType('CATEGORY_PLACE'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={0}
                  sx={{ color: '#5428D4' }}
                >
                  <AddLocationTwoToneIcon />
                  <Box>{'Place'}</Box>
                </Stack>
              </Box>
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }
                  handleMenuItemClick('CATEGORY_FOOD_DRINK');
                  // dispatch(setStepType('CATEGORY_FOOD_DRINK'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={2}
                  sx={{ color: '#5428D4' }}
                >
                  <FastfoodTwoToneIcon />
                  <Box>{'Eat & Drink'}</Box>
                </Stack>
              </Box>
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }
                  handleMenuItemClick('CATEGORY_ACCOMMODATION');
                  // dispatch(setStepType('CATEGORY_ACCOMMODATION'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={1}
                  sx={{ color: '#5428D4' }}
                >
                  <HotelTwoToneIcon />
                  <Box>{'Stay'}</Box>
                </Stack>
              </Box>
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }

                  handleMenuItemClick('CATEGORY_TRANSPORT');
                  // dispatch(setStepType('CATEGORY_TRANSPORT'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={3}
                  sx={{ color: '#5428D4' }}
                >
                  <DirectionsSubwayFilledTwoToneIcon />
                  <Box>{'Transport'}</Box>
                </Stack>
              </Box>
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }
                  handleMenuItemClick('CATEGORY_ACTIVITY');
                  // dispatch(setStepType('CATEGORY_ACTIVITY'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={4}
                  sx={{ color: '#5428D4' }}
                >
                  <LocalActivityTwoToneIcon />
                  <Box>{'Activity'}</Box>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}
      {currentCategory && (
        <AddStepToDay
          dayId={dayId}
          tripType={tripType}
          category={currentCategory}
          handleCloseModal={handleCloseModal}
          stepNumber={stepNumber}
        />
      )}
      {isDelete && (
        <DeleteDayModal dayId={dayId} handleCloseModal={handleCloseModal} />
      )}
      {addRecommendation && (
        <AddRecommendationModal
          type="day"
          dayId={dayId}
          handleCloseModal={handleCloseModal}
        />
      )}
      {editDay && (
        <EditDayModal
          dayId={dayId}
          dayDescription={dayDescription}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Box>
  );
};
