import { gql } from 'graphql-tag';

export const QUERY_TRIPS = gql`
  query tripsList {
    tripsList {
      id
      name
      slug
      tripType
      length
      userDetails {
        id
        nickName
        tikTokUrl
        instagramUrl
        youTubeUrl
        blogUrl
        profilePictureUrl
      }
      images {
        id
        path
        previewPath
        mediumPath
        smallPath
        name
        header
        main
        secondary
        tertiary
      }
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      cities {
        id
        name
        latitude
        longitude
      }
      itineraryCountries {
        iso3Code
        countryName
        itineraryId
      }
    }
  }
`;

export const QUERY_PUBLIC_TRIPS_BY_IDS = gql`
  query limitedItinerariesList($ids: [String!]!) {
    limitedItinerariesList(ids: $ids) {
      id
      name
      slug
      tripType
      length
      userDetails {
        id
        nickName
        tikTokUrl
        instagramUrl
        youTubeUrl
        blogUrl
        profilePictureUrl
      }
      images {
        id
        path
        previewPath
        mediumPath
        smallPath
        name
        header
        main
        secondary
        tertiary
      }
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      cities {
        id
        name
        latitude
        longitude
      }
      itineraryCountries {
        iso3Code
        countryName
        itineraryId
      }
    }
  }
`;
