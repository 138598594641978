import { Box, Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { Header } from '../components/AppWrapper';
import { useAuth } from '../components/auth/firebase';
import { Profile } from '../components/auth/Profile';
import SignUp from '../components/auth/SignUp';
import { VerticalFlex } from '../components/helpers/flex';
import LoginIllustration from '../components/illustrations/Login';
import { isRunningStandalone } from '../utils/helpers';
import { RoutePage } from '../components/route';
import { LoaderFullScreen } from '../theme-components/Loader';
import UnauthorizedApolloProvider from '../UnAuthorizedApolloProvider';
import { Helmet } from 'react-helmet-async';

export default function MyAccountRoute() {
  gtag('event', 'user-account-page');
  const { isAuthenticated, isLoading } = useAuth();
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: '60px',
        marginTop: '-60px',
        height: '100%',
        backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
        backgroundPosition: 'center',
        backgroundSize: `100% 100vh`,
        backgroundRepeat: 'repeat',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
          marginTop: '-60px',
          height: '100%',
          backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
        },
        [theme.breakpoints.down('md')]: {
          width: '100%',
          marginTop: '-60px',
          paddingTop: '60px',
          height: '100%',
          backgroundImage: `
                  radial-gradient(circle at 10% 20%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                  radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                  radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                  radial-gradient(circle at 70% 70%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                  radial-gradient(circle at 90% 70%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                  radial-gradient(circle at 80% 60%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 30%),
                  linear-gradient(
                    0deg, 
                    rgba(255, 215, 0, 0.15) 0%, 
                    rgba(255, 223, 223, 0.1) 50%, 
                    rgba(255, 215, 0, 0.15) 100%
                  )
                `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
        },
      })}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <RoutePage>
        <Box sx={{ minHeight: '100vh', paddingBottom: 10, width: '100%' }}>
          {!isAuthenticated ? (
            <SignUp showInModal={false} loginDefault={false} />
          ) : (
            <Stack
              direction="column"
              width="100%"
              pl={1}
              pr={1}
              alignItems="center"
            >
              <Box
                sx={theme => ({
                  width: 500,
                  [theme.breakpoints.down('sm')]: {
                    maxWidth: '100%',
                    width: '100%',
                  },
                })}
              >
                <Box
                  mt={2}
                  sx={{
                    maxWidth: '100%',
                    width: '100%',
                  }}
                >
                  <Profile />
                </Box>
              </Box>
            </Stack>
          )}
        </Box>
        {isStandalone && (
          <Box
            sx={{ paddingBottom: 'calc(70px + env(safe-area-inset-bottom))' }}
          />
        )}
      </RoutePage>
    </Box>
  );
}
