import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_UPDATE_USER_TRIP = gql`
  mutation updateUserTrip(
    $description: String
    $name: String
    $slug: String
    $trip_id: String!
    $startDate: DateTime
    $country_id: String
  ) {
    updateUserTrip(
      name: $name
      slug: $slug
      description: $description
      trip_id: $trip_id
      startDate: $startDate
      country_id: $country_id
    ) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
