import { useLazyQuery } from '@apollo/client';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { useAuth } from '../components/auth/firebase';
import { QUERY_USER_TRIP } from '../components/gql-user/userTrip';
import { TripPayOverlay } from '../components/payment/PayOverlay';
import { RouteContent, RoutePage, RouteTitle } from '../components/route';
import CopyTripBox from '../components/trip-details/CopyTripBox';
import { MapDrawer } from '../components/trip-details/MapDrawer';
import { TripDetails } from '../components/trip-details/TripDetails';
import { TripHeaderImages } from '../components/trip-details/TripHeader';
import { TripItinerary } from '../components/trip-details/TripItinerary';
import { TripMap } from '../components/trip-details/TripMap';
import DaysSidebar from '../components/trip-details/TripSideNavigation';
import ShareTripModal from '../components/user-account/ShareTripModal';
import { useUserAndTripData } from '../hooks/useUserData';
import { setPublicTrip } from '../store/PublicTripSlice';
import { LoaderFullScreen } from '../theme-components/Loader';
import { getSafeAreaInsetTop, isRunningStandalone } from '../utils/helpers';
import { Helmet } from 'react-helmet-async';

export default function TripPreviewRoute() {
  useUserAndTripData();
  const { isLoading, isAuthenticated } = useAuth();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sharableId = params.get('shared_trip_id');
  gtag('event', 'trip-preview-page');
  const dispatch = useDispatch();
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedDayId, setSelectedDayId] = useState('');
  const [stepInView, setStepInView] = useState('');

  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true
  const stepRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const dayRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const fullDayRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const scrollPositionRef = useRef<number>(0); // Ref to hold the scroll position
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  const [getUserTrip, { data, error, loading }] = useLazyQuery(
    QUERY_USER_TRIP,
    {
      variables: { trip_id: id, sharable_trip_id: sharableId },
    },
  );

  useEffect(() => {
    if (id && !isLoading && isAuthenticated) {
      getUserTrip();
    }
  }, [id, isLoading, isAuthenticated]);

  useEffect(() => {
    if (data && data.userTrip) {
      dispatch(setPublicTrip(data.userTrip));
    }
  }, [data, dispatch]); // dependencies for useEffect

  const scrollToStep = (stepId: string) => {
    if (stepId) {
      const stepRef = stepRefs.current[stepId];
      if (stepRef) {
        stepRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setTimeout(() => {
          stepRef.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 1000);
      }
    }
  };

  const scrollToDay = (dayId: string) => {
    if (dayId) {
      const dayRef = dayRefs.current[dayId];
      const safeAreaTop = getSafeAreaInsetTop();
      if (dayRef) {
        if (isSmallScreen) {
          dayRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setTimeout(() => {
            dayRef.scrollIntoView({
              behavior: 'auto',
              block: 'start',
            });
          }, 1000);
          setTimeout(() => {
            window.scrollBy({
              top: -(60 + safeAreaTop),
              left: 0,
              behavior: 'auto',
            });
          }, 1100);
        } else {
          dayRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setTimeout(() => {
            dayRef.scrollIntoView({
              behavior: 'auto',
              block: 'start',
            });
          }, 1000);
          setTimeout(() => {
            window.scrollBy({
              top: -75, // Scroll down 300px
              left: 0, // No horizontal scrolling
              behavior: 'auto', // Optional: 'auto' for instant scrolling or 'smooth' for smooth scrolling
            });
          }, 1100);
        }
      }
    }
  };

  // useEffect(() => {
  //   // Restore the scroll position if it's stored
  //   if (scrollPositionRef.current && selectedStepId === null) {
  //     window.scrollTo(0, scrollPositionRef.current);
  //   }
  // }, [selectedStepId]);
  useEffect(() => {
    // Restore the scroll position if it's stored
    if (
      scrollPositionRef.current &&
      (selectedStepId === null || selectedDayId === null)
    ) {
      window.scrollTo(0, scrollPositionRef.current);
    }
  }, [selectedStepId, selectedDayId]);

  const handleMarkerClick = stepId => {
    setSelectedStepId(stepId);
    scrollToStep(stepId);
  };

  const handleCloseClick = () => {
    scrollPositionRef.current = window.scrollY;
    setSelectedStepId(null);
  };

  const handleMenuDayClick = dayId => {
    setSelectedDayId(dayId);
    scrollToDay(dayId);
  };
  const handleMenuStepClick = stepId => {
    setSelectedStepId(stepId);
    scrollToStep(stepId);
  };

  const handleCardInView = (stepId: string) => {
    setStepInView(stepId);
  };

  const handleDayInView = (dayId: string) => {
    setSelectedDayId(dayId);
  };

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if ((!id || error || !data) && !loading) {
    return (
      <RoutePage>
        <Helmet>
          <meta name="robots" content="noindex, nofollow"></meta>
        </Helmet>
        <RouteTitle>Trip</RouteTitle>
        <RouteContent>Trip id not found</RouteContent>
      </RoutePage>
    );
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: '60px',
        marginTop: '-60px',
        height: '100%',
        backgroundColor: 'rgba(255, 248, 238, 0.7)',
      })}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <Box sx={{ minHeight: '100vh', paddingBottom: 10, width: '100%' }}>
        <MapDrawer
          tripOwner="public"
          onMarkerClick={handleMarkerClick}
          onCloseClick={handleCloseClick}
          onDayClick={handleMenuDayClick}
          clickedDayId={selectedDayId}
          stepInView={stepInView}
          mapId="preview"
        />
        {data?.userTrip && (
          <ShareTripModal
            tripId={data?.userTrip.id}
            sharableId={data?.userTrip.sharableId}
            published={data?.userTrip.published}
            shareButton="floating"
          />
        )}

        <Stack
          direction="row"
          alignItems="left"
          sx={{
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="left"
            sx={{
              width: 'calc(100vw - 232px)',
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
          >
            {!isSmallScreen && (
              <Box
                sx={theme => ({
                  display: 'block',
                  width: '50%',
                  [theme.breakpoints.down(1040)]: {
                    width: '44%',
                  },
                  [theme.breakpoints.down('md')]: {
                    width: '47%',
                  },
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                })}
              >
                <TripMap
                  tripOwner="public"
                  onMarkerClick={handleMarkerClick}
                  onCloseClick={handleCloseClick}
                  onDayClick={handleMenuDayClick}
                  clickedDayId={selectedDayId}
                  stepInView={stepInView}
                />
              </Box>
            )}
            <Box
              sx={theme => ({
                marginTop: 2,
                display: 'block',
                width: '50%',
                [theme.breakpoints.down(1040)]: {
                  width: '56%',
                },
                [theme.breakpoints.down('md')]: {
                  width: '53%',
                  paddingRight: 1.5,
                },
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  marginTop: 0,
                  paddingRight: 0,
                },
              })}
            >
              <TripHeaderImages tripType="public" />
              <Box
                sx={{
                  width: '100%',
                  marginTop: 0.5,
                  paddingLeft: 1.5,
                  [theme.breakpoints.down('sm')]: {
                    paddingRight: 1.5,
                  },
                }}
              >
                <CopyTripBox tripUser="public" />
              </Box>

              <Stack
                direction="row"
                alignItems="left"
                sx={{
                  width: '100%',
                  paddingLeft: 1.5,
                  [theme.breakpoints.down('sm')]: {
                    paddingRight: 1.5,
                  },
                }}
              >
                <Stack
                  direction="column"
                  alignItems="top"
                  sx={theme => ({
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                    },
                  })}
                >
                  <TripDetails tripUser="public" sharableTripId={sharableId} />
                  <Box position="relative">
                    <TripItinerary
                      tripUser="public"
                      selectedStepId={selectedStepId}
                      stepRefs={stepRefs}
                      dayRefs={dayRefs}
                      onCardInView={handleCardInView}
                      onDayInView={handleDayInView}
                    />
                    {data?.userTrip?.tripAccess?.fullViewGranted !== true &&
                      data?.userTrip?.priceId && (
                        <>
                          <TripPayOverlay trip={data?.userTrip} />
                        </>
                      )}
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          {data?.userTrip?.itinerary?.days &&
            data?.userTrip?.itinerary?.days.length > 0 && (
              <DaysSidebar
                days={data.userTrip.itinerary.days}
                onDayClick={handleMenuDayClick}
                onStepClick={handleMenuStepClick}
                tripType={
                  data?.userTrip?.tripType
                    ? data.userTrip.tripType
                    : 'itinerary'
                }
                tripOwner="user"
                trip={data?.userTrip}
                currentDayId={selectedDayId}
                stepInView={stepInView}
              />
            )}
        </Stack>
        {isStandalone && (
          <Box
            sx={{
              paddingBottom: 'calc(70px + env(safe-area-inset-bottom))',
            }}
          />
        )}
      </Box>
    </Box>
  );
}
