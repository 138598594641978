import { useLazyQuery, useMutation } from '@apollo/client';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ShareIcon from '@mui/icons-material/Share';
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import imageCompression from 'browser-image-compression';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Image, Maybe, Place } from '../../../generated/public_graphql';
import {
  PlaceImage,
  Recommendation,
  Step,
  StepPlaceImage,
} from '../../../generated/user_graphql';
import { setDays } from '../../../store/DaySlice';
import { setTrip } from '../../../store/TripSlice';
import { SecondaryButton } from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';
import TextDescription, { TitleH4 } from '../../../theme-components/Typography';
import { unauthorizedClient } from '../../../UnAuthorizedApolloProvider';
import { useAuth } from '../../auth/firebase';
import { LinkPlaceImage, SetMainImage } from '../../favourite/mainImage';
import { FETCH_IMAGE_BY_ID } from '../../gql-public/getPlaceImage';
import {
  MUTATION_DELETE_STEP_PLACE_IMAGE,
  MUTATION_DELETE_USER_IMAGE,
} from '../../gql-user/images';
import { QUERY_USER_TRIP } from '../../gql-user/userTrip';
import { CenteredModal, ModalPaper } from '../../styling/modal';
import { isAdmin, isPublisher, useUserRoles } from '../../../utils/helpers';

export const EditRecommendationImagesModal: React.FC<{
  recommendation: Recommendation;
  tripId: string;
  handleCloseModal: () => void;
}> = ({ recommendation, tripId, handleCloseModal }) => {
  const closeModal = () => {
    handleCloseModal();
  };

  useEffect(() => {}, [recommendation]);

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        <ManageRecommendationImages
          tripId={tripId}
          recommendation={recommendation}
        />
      </ModalPaper>
    </CenteredModal>
  );
};

function ManageRecommendationImages(props: {
  recommendation: Recommendation;
  tripId: string;
}) {
  const dispatch = useDispatch();
  const roles = useUserRoles();
  const { idToken } = useAuth();
  const [imagePreview, setImagePreview] = useState('');
  const [imageLoading, setImageLoading] = useState(false);

  // const [compressedFile, setCompressedFile] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  const addImage = async (e: any) => {
    if (e.target.files.length) {
      setImageLoading(true);

      for (let i = 0; i < e.target.files.length; i++) {
        const formData = new FormData();
        gtag('event', 'upload-image');
        formData.append('recommendationId', props.recommendation.id!);
        const file = e.target.files[i];
        const preview = URL.createObjectURL(file);
        setImagePreview(preview);
        await handleUpload(file.type, preview, formData);
      }

      setImageLoading(false);
      setImagePreview('');
    }
  };

  const [getUserTrip] = useLazyQuery(QUERY_USER_TRIP, {
    fetchPolicy: 'no-cache',
    variables: { trip_id: props.tripId },
  });

  const handleUpload = async (
    imageType: string,
    preview: string,
    formData: FormData,
  ) => {
    const blob = await fetch(preview).then(r => r.blob());

    const file = new File([blob], 'image', {
      type: imageType,
    });

    const imageFile = file;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };

    if (user != null) {
      try {
        // const compressedFile = await imageCompression(imageFile, options);
        formData.append('image', imageFile);

        await fetch(process.env.REACT_APP_CONFIG_URL_LOCKED + '/upload', {
          credentials: 'same-origin',
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        const { data } = await getUserTrip();
        if (data && data.userTrip) {
          dispatch(setTrip(data.userTrip));
          dispatch(setDays(data.userTrip.itinerary.days));
        }
      } catch (error) {
        // TODO
        console.log(error);
      }
    }
  };

  //NEW
  let image: string | null = null;
  if (imagePreview) {
    image = imagePreview;
  }

  return (
    <>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 1.5,
          color: 'primary.main',
        }}
      >
        Capture Your Travel Memories 📸
      </Typography>

      <label htmlFor="upload-image">
        <Box
          onClick={() => document.getElementById('upload-image')?.click()}
          sx={{
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            // width: '90%',
            // maxWidth: 500,
            backgroundColor: 'white',
            // borderRadius: 3,
            borderRadius: '20px',

            boxShadow: 12,
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!!image && (
            <CardMedia
              component="img"
              src={image}
              alt={'Place Image'}
              sx={{
                borderTopRightRadius: '20px',
                borderTopLeftRadius: '20px',
              }}
            />
          )}
          <Box sx={{ paddingX: 1, paddingY: 2 }}>
            <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                marginBottom: 2,
                color: 'text.secondary',
              }}
            >
              Add your <strong>your photos as you travel</strong> and create a
              beautiful, personalized itinerary to keep your{' '}
              <strong>memories forever</strong>! 🌍✨
            </Typography>
            {/* <Typography
        variant="body2"
        sx={{
          textAlign: 'center',
          marginBottom: 2,
          color: 'text.secondary',
        }}
      >
        You can choose to share your personalized itinerary with friends,
        publish it or keep it private, It's entirely up to you! 🎉
      </Typography> */}
            <Box>
              <Box
                sx={theme => ({
                  alignSelf: 'center',
                  fontWeight: 500,
                  fontSize: 16,
                  color: theme.palette.primary.main,
                })}
              >
                <Button
                  variant="text"
                  color="primary"
                  startIcon={
                    imageLoading ? (
                      <Loader />
                    ) : (
                      <CameraAltTwoToneIcon
                        sx={{ width: '25px', height: '25px' }}
                      />
                    )
                  }
                  sx={{
                    width: '100%',
                    borderRadius: '20px',
                    paddingX: '30px',
                    fontWeight: 600,
                    fontSize: 18,
                    textTransform: 'none',
                  }}
                >
                  Add Your Photos
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </label>
      <input
        type="file"
        id="upload-image"
        style={{ display: 'none' }}
        onChange={addImage}
        multiple
      />

      <Box width={'100%'} sx={{ textAlign: 'left' }}>
        {props.recommendation.images &&
          props.recommendation.images.length > 0 && (
            <>
              <TitleH4>My photos</TitleH4>
              <RenderImages
                tripId={props.tripId}
                // stepId={props.step.id}
                images={props.recommendation.images}
                userImages={true}
                placeImages={false}
                tripImages={false}
              />
            </>
          )}
        {props.recommendation.linkedImages &&
          props.recommendation.linkedImages.length > 0 && (
            <>
              <TitleH4>Place images</TitleH4>
              <RenderImages
                tripId={props.tripId}
                images={props.recommendation.linkedImages}
                userImages={false}
                placeImages={true}
                tripImages={false}
              />
            </>
          )}

        {isAdmin(roles) || isPublisher(roles) ? (
          <>
            {props.recommendation.place &&
              props.recommendation.place.images &&
              props.recommendation.place.images.length > 0 &&
              props.recommendation.place.images.some(
                image =>
                  image?.fromOwner === true ||
                  image?.path ||
                  image?.previewPath ||
                  image?.mediumPath ||
                  image?.smallPath,
              ) && (
                <>
                  <TitleH4>Suggested images</TitleH4>
                  <Typography
                    sx={theme => ({
                      fontSize: 14,
                      fontWeight: 600,
                      color: theme.palette.grey[600],
                    })}
                  >
                    Pick an image
                  </Typography>
                  <RenderSuggestedImages
                    tripId={props.tripId}
                    recommendationId={props.recommendation.id!}
                    placeImages={props.recommendation.place.images.filter(
                      image =>
                        image?.fromOwner === true ||
                        image?.path ||
                        image?.previewPath ||
                        image?.mediumPath ||
                        image?.smallPath,
                    )}
                  />
                </>
              )}
          </>
        ) : (
          <>
            {(!props.recommendation.linkedImages ||
              (props.recommendation.linkedImages &&
                props.recommendation.linkedImages.length === 0)) &&
              props.recommendation.place &&
              props.recommendation.place.images &&
              props.recommendation.place.images.length > 0 && (
                <>
                  <TitleH4>Pick an image</TitleH4>
                  <Typography
                    sx={theme => ({
                      fontSize: 14,
                      fontWeight: 600,
                      color: theme.palette.grey[500],
                    })}
                  >
                    Pick an image
                  </Typography>
                  <RenderSuggestedImages
                    tripId={props.tripId}
                    recommendationId={props.recommendation.id!}
                    placeImages={props.recommendation.place.images}
                  />
                </>
              )}
          </>
        )}
      </Box>
    </>
  );
}

export function RenderImages(props: {
  tripId: string;
  stepId?: string;
  images: Maybe<Image>[] | Maybe<StepPlaceImage>[];
  userImages: boolean;
  placeImages: boolean;
  tripImages: boolean;
}) {
  //
  return (
    <>
      {props.images && props.images.length > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            // wrap="nowrap"
            sx={{ justifyContent: 'left', paddingTop: 2 }}
          >
            {props.images.map((image, index) => (
              <Grid
                item
                xs={4} // 12/4 = 3 items per row on extra-small screens and above
                sm={4} // 3 items per row on small screens and above
                md={4} // 3 items per row on medium screens and above
                lg={4} // 3 items per row on large screens and above
                xl={4} // 3 items per row on extra-large screens and above
                key={index}
              >
                {/* TODO specify 
                 contributorName={
                              image && image.title ? image.title : ''
                            }
                            contributorUrl={
                              image && image.contributorId
                                ? contributorLink(image.contributorId)
                                : ''
                            }
                */}
                <ImageCard
                  tripId={props.tripId}
                  stepId={props.stepId}
                  image={image}
                  index={index}
                  userImages={props.userImages}
                  placeImages={props.placeImages}
                  tripImages={props.tripImages}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}

export function RenderSuggestedImages(props: {
  recommendationId: string;
  tripId: string;
  placeImages: Maybe<PlaceImage>[];
}) {
  return (
    <>
      {props.placeImages && props.placeImages.length > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            // wrap="nowrap"
            sx={{ justifyContent: 'left', paddingTop: 2 }}
          >
            {props.placeImages.map((image, index) => (
              <Grid
                item
                xs={4} // 12/4 = 3 items per row on extra-small screens and above
                sm={4} // 3 items per row on small screens and above
                md={4} // 3 items per row on medium screens and above
                lg={4} // 3 items per row on large screens and above
                xl={4} // 3 items per row on extra-large screens and above
                key={index}
              >
                <SuggestedImageCard
                  recommendationId={props.recommendationId}
                  image={image}
                  tripId={props.tripId}
                  index={index}
                  imageAlreadyAdded={false}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}

function ImageCard(props: {
  image;
  index: number;
  userImages: boolean;
  placeImages: boolean;
  tripImages: boolean;
  tripId: string;
  stepId?: string;
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {}, [dispatch]);

  useEffect(() => {
    // if (props.image.imageUrl) {
    //   setImageSrc(props.image.imageUrl);
    // } else
    if (
      (props.image.path === null || props.image.path === '') &&
      (props.image.previewPath === null || props.image.previewPath === '')
    ) {
      setImageSrc(
        `${process.env.REACT_APP_CONFIG_URL}/getFreeImage/${props.image.id}`,
      );
    } else {
      if (
        props.image.previewPath !== '' &&
        props.image.previewPath !== null &&
        props.image.previewPath !== undefined
      ) {
        setImageSrc(props.image.previewPath);
      } else if (
        props.image.path !== '' &&
        props.image.path !== null &&
        props.image.path !== undefined
      ) {
        setImageSrc(props.image.path);
      }
    }
  }, [props.image]);

  const [deleteUserImage, { loading }] = useMutation(
    MUTATION_DELETE_USER_IMAGE,
    {
      variables: {
        id: props.image.id,
      },
    },
  );
  const [deleteStepPlaceImage, { loading: loadingPlaceImage }] = useMutation(
    MUTATION_DELETE_STEP_PLACE_IMAGE,
    {
      variables: {
        step_place_image_id: props.image.id,
      },
    },
  );

  const [getUserTrip] = useLazyQuery(QUERY_USER_TRIP, {
    fetchPolicy: 'no-cache',
    variables: { trip_id: props.tripId },
  });

  // let imageUrl = '';
  // if (props.userImages || props.tripImages) {
  //   imageUrl = props.image.previewPath
  //     ? props.image.previewPath
  //     : props.image.path;
  // }

  // if (props.placeImages) {
  //   imageUrl = imageSrc;
  // }

  const handleDeleteImage = async () => {
    try {
      if (props.placeImages) {
        await deleteStepPlaceImage();
      } else if (props.userImages || props.tripImages) {
        await deleteUserImage();
      }

      const { data } = await getUserTrip();
      if (data && data.userTrip) {
        dispatch(setTrip(data.userTrip));
        if (data.userTrip.itinerary && data.userTrip.itinerary.days) {
          dispatch(setDays(data.userTrip.itinerary.days));
        }
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  console.log('imageFailedToLoad', imageFailedToLoad);
  console.log('imageSrc', imageSrc);

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {!imageLoaded && !imageFailedToLoad && (
          <Skeleton
            variant="rectangular"
            width="100%"
            sx={{
              height: 150,
              objectFit: 'cover',
            }}
          />
        )}

        {!imageFailedToLoad && !!imageSrc && (
          <CardMedia
            component="img"
            src={imageSrc}
            alt={props.image?.title || 'Place Image'}
            onLoad={() => {
              setImageLoaded(true);
              setImageFailedToLoad(false);
            }}
            onError={() => {
              setImageFailedToLoad(true);
            }}
            sx={{
              height: 150,
              objectFit: 'cover',
              display: imageLoaded ? 'block' : 'none',
            }}
          />
        )}

        {imageFailedToLoad && !!imageSrc && (
          <Box
            width="100%"
            sx={{
              height: 150,
              borderRadius: '5px',
              backgroundColor: theme => theme.palette.grey[600],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setImageFailedToLoad(false);
              console.log('ddd');
            }} // Reset the failed state to retry loading
          >
            <Typography
              variant="body2"
              sx={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              Failed to load. <br />
              Tap to reload.
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            position: 'absolute',
            bottom: 4,
            right: 4,
            width: 39,
            height: 39,
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            borderRadius: 20,
            zIndex: 2,
          }}
        >
          <IconButton
            aria-label="delete"
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              zIndex: 3,
              color: '#FFFFFF',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
            disabled={loading || loadingPlaceImage}
            onClick={handleDeleteImage}
          >
            {loading || loadingPlaceImage ? (
              <CircularProgress size={15} sx={{ color: '#FFFFFF' }} />
            ) : (
              <DeleteIcon
                sx={{
                  height: 15,
                  width: 15,
                }}
              />
            )}
          </IconButton>
        </Box>
        {(props.tripImages || props.stepId) && (
          <SetMainImage
            tripId={props.tripId}
            stepId={props.stepId}
            imageId={props.image.id}
            isMain={props.image.main}
            bottom="45px"
            right="4px"
          />
        )}
      </Box>
    </Card>
  );
}

function SuggestedImageCard(props: {
  image;
  tripId;
  index: number;
  imageAlreadyAdded: boolean;
  recommendationId: string;
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {}, [dispatch]);

  // useEffect(() => {
  //   if (
  //     (props.image.path === null || props.image.path === '') &&
  //     (props.image.previewPath === null || props.image.previewPath === '')
  //   ) {
  //     const fetchImage = async () => {
  //       try {
  //         // const response = await unauthorizedClient.mutate({
  //         //   mutation: FETCH_IMAGE_URL,
  //         //   variables: { image_url: props.image.imageUrl },
  //         // });
  //         // const response = await unauthorizedClient.query({
  //         //   query: FETCH_IMAGE_URL,
  //         //   variables: { image_url: props.image.imageUrl },
  //         // });
  //         const response = await unauthorizedClient.query({
  //           query: FETCH_IMAGE_BY_ID,
  //           fetchPolicy: imageFailedToLoad ? 'no-cache' : 'cache-first',
  //           variables: { image_id: props.image.id },
  //         });

  //         if (
  //           response.data &&
  //           response.data.fetchFreeImage &&
  //           response.data.fetchFreeImage.imageData
  //         ) {
  //           // Ensure the response contains base64 data in the correct format
  //           const base64Data = `data:image/jpeg;base64,${response.data.fetchFreeImage.imageData}`; // Adjust MIME type as needed
  //           setImageSrc(base64Data);
  //           setImageLoaded(true);
  //         } else {
  //           setImageFailedToLoad(true);
  //           return null;
  //         }
  //       } catch (err) {
  //         setImageFailedToLoad(true);
  //         return null;
  //       }
  //     };

  //     fetchImage();
  //   } else {
  //     setImageSrc(
  //       props.image.previewPath ? props.image.previewPath : props.image.path,
  //     );
  //   }
  // }, [props.image, imageFailedToLoad]);

  useEffect(() => {
    // if (props.image.imageUrl) {
    //   setImageSrc(props.image.imageUrl);
    // } else
    if (
      (props.image.path === null || props.image.path === '') &&
      (props.image.previewPath === null || props.image.previewPath === '')
    ) {
      setImageSrc(
        `${process.env.REACT_APP_CONFIG_URL}/getFreeImage/${props.image.id}`,
      );
    } else {
      if (
        props.image.previewPath !== '' &&
        props.image.previewPath !== null &&
        props.image.previewPath !== undefined
      ) {
        setImageSrc(props.image.previewPath);
      } else if (
        props.image.path !== '' &&
        props.image.path !== null &&
        props.image.path !== undefined
      ) {
        setImageSrc(props.image.path);
      }
    }
  }, [props.image, imageFailedToLoad]);

  // let imageUrl = '';
  // if (imageSrc) {
  //   imageUrl = imageSrc;
  // }

  if (imageFailedToLoad) {
    return <></>;
  }
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {!imageLoaded && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{
              maxHeight: 100,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        <CardMedia
          component="img"
          src={imageSrc}
          alt={props.image?.title || 'Place Image'}
          onLoad={() => {
            setImageLoaded(true);
            setImageFailedToLoad(false);
          }}
          onError={() => {
            setImageFailedToLoad(true);
          }}
          sx={{
            maxHeight: 150,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: imageLoaded ? 'block' : 'none',
          }}
        />
        {/* {props.tripImages && ( */}
        <LinkPlaceImage
          imageId={props.image.id}
          tripId={props.tripId}
          recommendationId={props.recommendationId}
          top="5px"
          right="4px"
        />
        {/* )} */}
      </Box>
    </Card>
  );
}
