import { useQuery } from '@apollo/client';
import {
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardMedia,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { ReactNode, useEffect, useState } from 'react';
import { Img } from 'react-image';
import { useNavigate } from 'react-router-dom';

import { LoaderCentred } from '../../theme-components/Loader';
import { TitleH1 } from '../../theme-components/Typography';
import { unauthorisedClient } from '../../utils/auth';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { QUERY_GET_COUNTRY } from '../gql-public/countryQuery';

import {
  CountryInfoLinks,
  CountryMenuInfoLinks,
  TripCountryBox,
} from './CountryInfo';
import {
  CountryFlagMenu,
  CountryFlagNoMargin,
  CountrySidebar,
  SideBarIcon,
} from './CountrySidebar';
import { Country } from '../../generated/user_graphql';
import { Helmet } from 'react-helmet-async';
import { RouteContent, RoutePage, RouteTitle } from '../route';

export function CountryPageHeader(props: { countryId: string }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, error, loading } = useQuery(QUERY_GET_COUNTRY, {
    client: unauthorisedClient,
    variables: { country_id: props.countryId },
  });

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if (!data || !data.country || error) {
    return (
      <>
        <RoutePage>
          <Helmet>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          <RouteTitle>Country</RouteTitle>
          <RouteContent>Country not found</RouteContent>
        </RoutePage>
      </>
    );
  }

  const mainImage = data.country.images?.find(image => image?.main);

  const secondaryImage = data.country.images?.find(image => image.secondary);

  const tertiaryImage = data.country.images?.find(image => image.tertiary);

  return (
    <Stack
      sx={theme => ({
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          width: '100vw',
          // ofestting RootPage
          marginLeft: -1,
        },
      })}
    >
      <Helmet>
        <title>{`${data.country.name} | Travel Itineraries, Guides & Tips`}</title>
        <meta
          name="description"
          content={`Discover curated itineraries & travel maps for ${data.country.name}. Plan your perfect trip with expert recommendations & personalized itineraries in minutes!`}
        />

        <meta
          property="og:title"
          content={`${data.country.name} | Travel Itineraries, Guides & Tips`}
        />
        <meta
          property="og:description"
          content={`Discover curated itineraries & travel maps for ${data.country.name}. Plan your perfect trip with expert recommendations & personalized itineraries in minutes!`}
        />
        <meta property="og:image" content={'/images/default-image.jpg'} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        <meta
          name="keywords"
          content={`${data.country.name} travel guide, curated itineraries, trip planning, vacation ideas, travel map, solo travel, budget travel, luxury travel, adventure in ${data.country.name}`}
        />
        <meta
          name="author"
          content="Rooutie | Free Travel Maps & Curated Itineraries"
        />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
      </Helmet>
      <Box sx={{ position: 'relative' }}>
        <>
          {mainImage && secondaryImage && tertiaryImage ? (
            <Stack
              direction="row"
              sx={theme => ({
                mt: 2,
                [theme.breakpoints.down('sm')]: {
                  mt: 0,
                },
              })}
            >
              <Box
                sx={theme => ({
                  borderTopLeftRadius: '40px',
                  borderBottomLeftRadius: '40px',
                  overflow: 'hidden',
                  width: '70%',
                  pr: 0.5,
                  [theme.breakpoints.down('sm')]: {
                    borderRadius: '0px',
                    fontSize: 16,
                    pr: '2px',
                  },
                })}
              >
                <CardMedia
                  component="img"
                  image={mainImage.path}
                  alt={
                    data.country.name
                      ? 'Image for ' + data.country.name
                      : 'Country Image'
                  }
                  sx={theme => ({
                    height: 400,
                    [theme.breakpoints.down('sm')]: {
                      height: 250,
                    },
                  })}
                />
                <Box
                  sx={theme => ({
                    position: 'absolute',
                    top: 16,
                    left: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    backdropFilter: 'blur(10px)',
                    paddingY: 1.5,
                    paddingX: 4,
                    borderRadius: '40px 0 40px 0',
                    [theme.breakpoints.down('sm')]: {
                      paddingY: 0,
                      top: 0,
                      paddingLeft: 1.5,
                      paddingRight: 2,
                      borderRadius: '0px 0 20px 0',
                    },
                  })}
                >
                  <Stack direction="row" alignItems="center">
                    {isSmallScreen ? (
                      <CountryFlagNoMargin
                        width={30}
                        countryCode={data.country.iso2Code}
                      />
                    ) : (
                      <CountryFlagNoMargin
                        width={40}
                        countryCode={data.country.iso2Code}
                      />
                    )}

                    <Typography
                      component={'h1'}
                      sx={theme => ({
                        fontWeight: 700,
                        marginLeft: 2,
                        fontSize: 30,
                        color: '#001B30',
                        [theme.breakpoints.down('sm')]: {
                          marginLeft: 1,
                          fontSize: 20,
                        },
                      })}
                    >
                      {data.country.name}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
              <Stack direction={'column'} width="30%">
                <Box
                  sx={theme => ({
                    borderTopRightRadius: '40px',
                    overflow: 'hidden',
                    pb: 0.5,
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: '0px',
                      fontSize: 16,
                      pb: '2px',
                    },
                  })}
                >
                  <CardMedia
                    component="img"
                    image={secondaryImage.path}
                    alt={
                      data.country.name
                        ? 'Image for ' + data.country.name
                        : 'Country Image'
                    }
                    sx={theme => ({
                      height: 200,
                      [theme.breakpoints.down('sm')]: {
                        height: 125,
                      },
                    })}
                  />
                </Box>
                <Box
                  sx={theme => ({
                    borderBottomRightRadius: '40px',
                    overflow: 'hidden',
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: '0px',
                      fontSize: 16,
                    },
                  })}
                >
                  <CardMedia
                    component="img"
                    image={tertiaryImage.path}
                    alt={
                      data.country.name
                        ? 'Image for ' + data.country.name
                        : 'Country Image'
                    }
                    sx={theme => ({
                      height: 196,
                      [theme.breakpoints.down('sm')]: {
                        height: 123,
                      },
                    })}
                  />
                </Box>
              </Stack>
            </Stack>
          ) : (
            <>
              {mainImage ? (
                <Box
                  sx={theme => ({
                    borderRadius: '40px',
                    overflow: 'hidden',
                    width: '100%',
                    mt: 2,
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: '0px',
                      fontSize: 16,
                      mt: 0,
                    },
                  })}
                >
                  <CardMedia
                    component="img"
                    image={mainImage.path}
                    alt={
                      data.country.name
                        ? 'Image for ' + data.country.name
                        : 'Country Image'
                    }
                    sx={theme => ({
                      height: 500,
                      [theme.breakpoints.down('lg')]: {
                        height: 400,
                      },
                      [theme.breakpoints.down('md')]: {
                        height: 370,
                      },
                      [theme.breakpoints.down('sm')]: {
                        height: 200,
                      },
                    })}
                  />
                  <Box
                    sx={theme => ({
                      position: 'absolute',
                      top: 16,
                      left: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      backdropFilter: 'blur(10px)',
                      paddingY: 2,
                      paddingX: 4,
                      borderRadius: '40px 0 40px 0',
                      [theme.breakpoints.down('sm')]: {
                        paddingY: 1,
                        top: 0,
                        paddingLeft: 2,
                        paddingRight: 3,
                        borderRadius: '0px 0 30px 0',
                      },
                    })}
                  >
                    <Stack direction="row" alignItems="center">
                      {isSmallScreen ? (
                        <CountryFlagNoMargin
                          width={30}
                          countryCode={data.country.iso2Code}
                        />
                      ) : (
                        <CountryFlagNoMargin
                          width={40}
                          countryCode={data.country.iso2Code}
                        />
                      )}
                      <Typography
                        component={'h1'}
                        sx={theme => ({
                          fontWeight: 700,
                          marginLeft: 2,
                          fontSize: 30,
                          color: '#001B30',
                          [theme.breakpoints.down('sm')]: {
                            marginLeft: 1,
                            fontSize: 20,
                          },
                        })}
                      >
                        {data.country.name}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              ) : (
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={theme => ({
                      marginTop: 2,
                      [theme.breakpoints.down('sm')]: {
                        marginTop: 1,
                        paddingLeft: 1.5,
                      },
                    })}
                  >
                    {isSmallScreen ? (
                      <CountryFlagNoMargin
                        width={40}
                        countryCode={data.country.iso2Code}
                      />
                    ) : (
                      <CountryFlagNoMargin
                        width={50}
                        countryCode={data.country.iso2Code}
                      />
                    )}
                    <Typography
                      component={'h1'}
                      sx={theme => ({
                        fontWeight: 700,
                        marginLeft: 2,
                        fontSize: 40,
                        color: '#001B30',
                        [theme.breakpoints.down('sm')]: {
                          marginLeft: 1,
                          fontSize: 30,
                        },
                      })}
                    >
                      {data.country.name}
                    </Typography>
                  </Stack>
                </>
              )}
            </>
          )}
        </>
      </Box>
      <Box sx={{ width: '100%' }}>
        {loading && <LoaderCentred />}
        {!loading && !error && (
          <>
            <Box sx={{ width: '100%', display: { xs: 'none', sm: 'block' } }}>
              <CountryInfoLinks country={data.country} />
            </Box>

            <Box
              sx={{ display: { xs: 'block', sm: 'none' }, mt: 1, pl: 1, pr: 1 }}
            >
              <TripCountryBox country={data.country} />
            </Box>
          </>
        )}
      </Box>
    </Stack>
  );
}

export function CountryInfoLinksById(props: { countryId }) {
  const { data, error, loading } = useQuery(QUERY_GET_COUNTRY, {
    client: unauthorisedClient,
    variables: { country_id: props.countryId },
  });

  return (
    <Box sx={{ width: '100%' }}>
      {loading && <LoaderCentred />}
      {!loading && !error && (
        <>
          <Box sx={{ width: '100%', display: { xs: 'none', sm: 'block' } }}>
            <CountryInfoLinks country={data.country} alignLeft={true} />
          </Box>

          <Box
            sx={{ display: { xs: 'block', sm: 'none' }, mt: 1, pl: 1, pr: 1 }}
          >
            <TripCountryBox country={data.country} />
          </Box>
        </>
      )}
    </Box>
  );
}

export function CountryMenu(props: {
  countryId: string;
  numberOfHiddenDays?: number | null;
}) {
  const [isItineraryOpen, setIsItineraryOpen] = useState(
    props.numberOfHiddenDays ? false : true,
  );
  const [countrySideBarOpenID, setCountrySideBarOpenID] = useState('');

  const { data, error, loading } = useQuery(QUERY_GET_COUNTRY, {
    client: unauthorisedClient,
    variables: { country_id: props.countryId },
  });

  if (!data || !data.country || error || loading) {
    return null;
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <Stack
        direction={'row'}
        onClick={() => {
          setIsItineraryOpen(!isItineraryOpen);
          setCountrySideBarOpenID(data.country.id);
        }}
      >
        <CountryFlagMenu countryCode={data.country.iso2Code} />
        <Typography
          sx={theme => ({
            fontWeight: 600,
            fontSize: 16,
            color: props.numberOfHiddenDays ? '#A3AAB6' : '#36454F',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            '&:hover': {
              color: theme.palette.primary.light,
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: 16,
            },
          })}
        >
          {data.country.name}
        </Typography>
        {isItineraryOpen ? (
          <ExpandLess
            sx={{ color: props.numberOfHiddenDays ? '#A3AAB6' : '#36454F' }}
          />
        ) : (
          <ExpandMore
            sx={{ color: props.numberOfHiddenDays ? '#A3AAB6' : '#36454F' }}
          />
        )}
      </Stack>
      {isItineraryOpen && (
        <Stack direction="row">
          <Box sx={{ width: 20 }} />
          <CountryMenuInfoLinks country={data.country} />
        </Stack>
      )}
    </Stack>
  );
}
