import { gql } from 'graphql-tag';

export const MUTATION_NEWSLETTER_SUBSCRIBE = gql`
  mutation newsletterSubscribe($email: String!) {
    newsletterSubscribe(email: $email) {
      success
    }
  }
`;

export const MUTATION_NEWSLETTER_UN_SUBSCRIBE = gql`
  mutation newsletterUnSubscribe($email: String!) {
    newsletterUnSubscribe(email: $email) {
      success
    }
  }
`;
