import { gql } from 'graphql-tag';

import { PUBLIC_TRIP_FIELDS } from './schema';

export const QUERY_EMBEDDED_TRIP = gql`
  query embeddedTrip($embedded_trip_id: String!, $trip_id: String!) {
    embeddedTrip(embedded_trip_id: $embedded_trip_id, trip_id: $trip_id) {
      ...PublicTripFields
    }
  }
  ${PUBLIC_TRIP_FIELDS}
`;
