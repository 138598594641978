import { useEffect, useRef, useState } from 'react';
import { isPrerenderBot } from '../utils/helpers';

function LazyLoadComponent({ children, delay = 500, shouldLoad = true }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (isPrerenderBot() || !shouldLoad) {
      setIsLoaded(true);
      return;
    }

    const loadWithDelay = () => {
      setTimeout(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
              setIsLoaded(true);
              observer.unobserve(entry.target);
            }
          },
          {
            root: null,
            rootMargin: '400px',
            threshold: 0.1,
          },
        );

        if (ref.current) {
          observer.observe(ref.current);
        }

        return () => {
          if (ref.current) {
            observer.disconnect();
          }
        };
      }, delay);
    };

    loadWithDelay();

    return () => {
      // Cleanup is handled by the observer's disconnect
    };
  }, [delay, shouldLoad]);

  return (
    <div style={{ width: '100%' }} ref={ref}>
      {isLoaded || isPrerenderBot() ? children : null}
    </div>
  );
}

export default LazyLoadComponent;
