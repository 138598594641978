import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import { Box, Stack, Typography } from '@mui/material';

interface PriceInfoProps {
  admissionPrice: number | null | undefined;
  minPrice: number | null | undefined;
  maxPrice: number | null | undefined;
  priceCurrency: string | null | undefined;
  priceCategory: string | null | undefined;
  renderSmall?: boolean;
}

export const PriceInfo: React.FC<PriceInfoProps> = ({
  admissionPrice,
  minPrice,
  maxPrice,
  priceCategory,
  priceCurrency,
  renderSmall = false,
}) => {
  // **Condition 1:** Free Entry
  if (admissionPrice === 0) {
    return (
      <Box>
        <Stack direction="row" alignItems="center">
          <LocalActivityTwoToneIcon
            sx={theme => ({
              width: renderSmall ? 20 : 20,
              height: renderSmall ? 20 : 20,
              marginRight: 0.5,
              // color: theme.palette.icon?.main || '#6E7191',
              color: '#9E7FEF',
            })}
          />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: renderSmall ? 14 : 14,
              color: '#36454F',
              marginRight: 0.5,
            }}
          >
            Free entry
          </Typography>
        </Stack>
      </Box>
    );
  }

  // **Condition 2:** Tickets Available
  if (admissionPrice) {
    return (
      <Box>
        <Stack direction="row" alignItems="center">
          <LocalActivityTwoToneIcon
            sx={theme => ({
              width: renderSmall ? 20 : 20,
              height: renderSmall ? 20 : 20,
              marginRight: 0.5,
              color: '#9E7FEF',
            })}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: renderSmall ? 14 : 14,
              color: '#6E7191',
              marginRight: 0.5,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 600,
                fontSize: renderSmall ? 14 : 14,
                color: '#36454F',
                marginRight: 0.5,
              }}
            >
              Tickets from:
            </Typography>
            {admissionPrice}
            {priceCurrency === 'USD' && '$'} {priceCurrency === 'GBP' && '£'}{' '}
            {priceCategory ? priceCategory : 'per person'}
          </Typography>
        </Stack>
      </Box>
    );
  }

  // **Condition 3:** Price Range
  if (minPrice) {
    return (
      <Box>
        <Stack direction="row" alignItems="flex_start">
          <CreditCardTwoToneIcon
            sx={theme => ({
              width: renderSmall ? 20 : 20,
              height: renderSmall ? 20 : 20,
              marginRight: 0.5,
              color: '#9E7FEF',
            })}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: renderSmall ? 14 : 14,
              color: '#6E7191',
              marginRight: 0.5,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 600,
                fontSize: renderSmall ? 14 : 14,
                color: '#36454F',
                marginRight: 0.5,
              }}
            >
              From:
            </Typography>
            {minPrice}
            {priceCurrency === 'GBP' && '£'}
            {maxPrice && ` - ${maxPrice}`}
            {maxPrice && priceCurrency === 'GBP' && '£'}{' '}
            {priceCategory ? priceCategory : 'per person'}
          </Typography>
        </Stack>
      </Box>
    );
  }

  // **No Admission Information**
  return null;
};
