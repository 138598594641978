import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CountryTrips } from '../components/country/CountryTrips';
import {
  GradientContainer,
  OuterContainer,
} from '../components/home/HomeHeader';
import { RouteContent, RoutePage, RouteTitle } from '../components/route';
import { isRunningStandalone } from '../utils/helpers';
import { CountryPageHeader } from '../components/country/CountryFeatures';
import { TitleH2, TitleH3, TitleH4 } from '../theme-components/Typography';
import { Helmet } from 'react-helmet-async';

export default function CountryRoute() {
  gtag('event', 'public-country-page');
  const { id = '' } = useParams<{ id: string }>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  return (
    <>
      <Box
        sx={theme => ({
          paddingTop: '60px',
          width: '100%',
          marginTop: '-60px',
          height: '100%',
          backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
          backgroundPosition: 'center',
          backgroundSize: `100% 100vh`,
          backgroundRepeat: 'repeat',
          [theme.breakpoints.down('lg')]: {
            width: '100%',
            marginTop: '-60px',
            height: '100%',
            backgroundImage: `
                radial-gradient(circle at 30% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                radial-gradient(circle at 60% 60%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                radial-gradient(circle at 90% 50%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                radial-gradient(circle at 10% 50%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                linear-gradient(
                  0deg,
                  rgba(255, 215, 0, 0.15) 0%,
                  rgba(255, 223, 223, 0.1) 50%,
                  rgba(255, 215, 0, 0.15) 100%
                )
              `,
            backgroundPosition: 'center',
            backgroundSize: `100% 100vh`,
            backgroundRepeat: 'repeat',
          },
          [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: '-60px',
            paddingTop: '60px',
            height: '100%',
            backgroundImage: `
                  radial-gradient(circle at 10% 20%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 20%),
                  radial-gradient(circle at 30% 30%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 25%),
                  radial-gradient(circle at 50% 50%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 20%),
                  radial-gradient(circle at 70% 70%, rgba(84, 40, 212, 0.2) 0%, rgba(84, 40, 212, 0) 30%),
                  radial-gradient(circle at 90% 70%, rgba(100, 180, 255, 0.2) 0%, rgba(100, 180, 255, 0) 35%),
                  radial-gradient(circle at 80% 60%, rgba(152, 251, 152, 0.2) 0%, rgba(152, 251, 152, 0) 30%),
                  linear-gradient(
                    0deg, 
                    rgba(255, 215, 0, 0.15) 0%, 
                    rgba(255, 223, 223, 0.1) 50%, 
                    rgba(255, 215, 0, 0.15) 100%
                  )
                `,
            backgroundPosition: 'center',
            backgroundSize: `100% 100vh`,
            backgroundRepeat: 'repeat',
          },
        })}
      >
        <RoutePage>
          <Box sx={{ minHeight: '100vh', paddingBottom: 10, width: '100%' }}>
            <CountryPageHeader countryId={id} />
            <Stack
              direction="column"
              width="100%"
              sx={theme => ({
                borderRadius: '20px',
                border: '2px solid rgba(211, 211, 211, 0.4)',
                paddingRight: 1,
                paddingY: 1.5,
                marginTop: 1.5,
                paddingLeft: 3,
                marginBottom: 1.5,
                backgroundColor: '#FFF8EE',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
                [theme.breakpoints.down('sm')]: {
                  paddingRight: 1.5,
                  paddingY: 1,
                  marginTop: 1.5,
                  paddingLeft: 1.5,
                  marginBottom: 1.5,
                  marginLeft: 0,
                  marginRight: 0,
                },
              })}
            >
              {id && (
                <>
                  {isSmallScreen ? (
                    <TitleH3 component={'h2'}>
                      Your Next Adventure Awaits
                    </TitleH3>
                  ) : (
                    <TitleH3 component={'h2'}>
                      Your Next Adventure Awaits
                    </TitleH3>
                  )}

                  <CountryTrips countryId={id} />
                </>
              )}
            </Stack>
          </Box>
        </RoutePage>
      </Box>
    </>
  );
}
