import { useMutation } from '@apollo/client';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector, setTrip } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import { CenteredModal, ModalPaper } from '../../styling/modal';

import { CustomFormControl } from './Styles';

export default function EditTripSlugModal(props: {
  slug?: string;
  editTripId: string;
  tripType: string;
  handleCloseModal: () => void;
}) {
  // const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [slugName, setSlugName] = useState(props.slug);

  const [editTrip, { loading }] = useMutation(MUTATION_UPDATE_USER_TRIP, {
    variables: {
      slug: slugName && slugName.replace(/^-+|-+$/g, ''),
      trip_id: props.editTripId,
    },
  });

  const handleClose = () => {
    props.handleCloseModal();
  };

  const handleSlugChange = e => {
    let value = e.target.value;
    // Replace spaces with hyphens
    value = value.replace(/[\s_]+/g, '-');

    // Convert to lowercase
    value = value.toLowerCase();

    // Normalize and remove diacritics (accents)
    value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Remove non-ASCII characters & special symbols
    value = value.replace(/[^a-z0-9-]/g, '');

    // Prevent multiple consecutive hyphens
    value = value.replace(/-+/g, '-');

    // Update state
    setSlugName(value);
  };

  return (
    <>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={true}
        onClose={() => handleClose()}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader
            title={
              props.tripType && props.tripType === 'guide'
                ? 'Change guide slug'
                : 'Change trip slug'
            }
            description={
              <>
                <strong>Enter best slug for SEO</strong>
              </>
            }
          />
          <CustomFormControl>
            <TextField
              id="standard-basic"
              label="Slug"
              name="slug"
              value={slugName}
              fullWidth={true}
              variant="outlined"
              onChange={handleSlugChange}
              sx={{ backgroundColor: '#fafafa' }}
            />
          </CustomFormControl>
          <Space size="md" />
          <PrimaryButton
            disabled={loading}
            onClick={async () => {
              gtag('event', 'edit-trip-name-save');
              try {
                const response = await editTrip();
                if (
                  response.data.updateUserTrip &&
                  response.data.updateUserTrip.description
                ) {
                }

                if (response.data.updateUserTrip) {
                  dispatch(setTrip(response.data.updateUserTrip));
                }
                handleClose();
              } catch (e) {
                gtag('event', 'error-edit-trip-slug');
                dispatch(setErrorTitle('Error saving trip slug'));
                dispatch(setErrorModalOpen(true));
              }
            }}
          >
            {loading ? <Loader /> : 'Save slug'}
          </PrimaryButton>
          <Space size="md" />
          <SecondaryButton
            disabled={loading}
            onClick={async () => {
              gtag('event', 'publish-trip-close');
              // setOpen(false);
              handleClose();
            }}
          >
            Close
          </SecondaryButton>
        </ModalPaper>
      </CenteredModal>
    </>
  );
}
