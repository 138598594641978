import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import {
  Box,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
  Stack,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextButton } from '../theme-components/Buttons';
import { isRunningStandalone } from '../utils/helpers';
import { RoutePage } from './route';
import { LogoName } from './AppBar';
import Space from '../theme-components/Spacing';
import { Spa } from '@mui/icons-material';
import { useAuth } from './auth/firebase';
import SignUp from './auth/SignUp';
import { useMutation } from '@apollo/client';
import { MUTATION_NEWSLETTER_SUBSCRIBE } from './gql-public/newsletter';
import { unauthorisedClient } from '../utils/auth';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function AppFooter() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md')); // Detect if the screen size is mobile
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen size is mobile

  return (
    <footer>
      {isTablet ? (
        <>
          <BottomTabBar />

          <FooterContent />
        </>
      ) : (
        <FooterContent />
      )}
    </footer>
  );
}

const FooterContent = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md')); // Detect if the screen size is mobile

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Divider
          sx={{
            width: '100%',
          }}
        />
        <BetaTestersLinks />
        <NewsletterSignup />
        <RoutePage>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingBottom: 2,
            }}
          >
            <Box
              sx={theme => ({
                // display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                fontSize: '14px',
                color: '#595959',
                maxWidth: '60%',
                display: {
                  xs: 'none', // Hidden on extra small screens
                  sm: 'none', // Hidden on small screens
                  md: 'flex', // Visible on medium screens and up
                },
              })}
            >
              <Box component={Link} href="/" sx={{ textDecoration: 'none' }}>
                <LogoName>rooutie</LogoName>
              </Box>
              <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                71-75, Shelton Street <br />
                Covent Garden, WC2H 9JQ <br />
                London
              </Typography>
              <Space size="xs" />
            </Box>
            <Box
              sx={theme => ({
                // display: 'flex',
                textAlign: 'left',
                fontSize: '14px',
                color: '#595959',
                maxWidth: '60%',
                display: {
                  xs: 'flex', // Hidden on extra small screens
                  sm: 'flex', // Hidden on small screens
                  md: 'none', // Visible on medium screens and up
                },
              })}
            />

            <Stack direction="row" spacing={3}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                }}
              >
                <Space size="xs" />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#3F3D56',
                  }}
                >
                  About
                </Typography>

                <Link
                  href="mailto:help@rooutie.com"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Contact Us
                </Link>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                }}
              >
                <Space size="xs" />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#3F3D56',
                  }}
                >
                  Follow Us
                </Typography>

                <Stack direction={'row'} spacing={0.5}>
                  <FacebookOutlinedIcon
                    sx={{ width: '20px', height: '20px' }}
                  />
                  <Link
                    href="https://www.facebook.com/profile.php?id=100064556029478"
                    sx={{
                      textDecoration: 'none',
                      color: '#595959',
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    Facebook
                  </Link>
                </Stack>
                <Stack direction={'row'} spacing={0.5}>
                  <InstagramIcon sx={{ width: '20px', height: '20px' }} />
                  <Link
                    href="https://www.instagram.com/rooutie/"
                    sx={{
                      textDecoration: 'none',
                      color: '#595959',
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    Instagram
                  </Link>
                </Stack>
                <Stack direction={'row'} spacing={0.5}>
                  <PinterestIcon sx={{ width: '20px', height: '20px' }} />
                  <Link
                    href="https://uk.pinterest.com/rooutie/"
                    sx={{
                      textDecoration: 'none',
                      color: '#595959',
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    Pinterest
                  </Link>
                </Stack>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                }}
              >
                <Space size="xs" />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#3F3D56',
                  }}
                >
                  Legal
                </Typography>
                <Link
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Terms of Use
                </Link>
                <Space size="xs" />
                <Link
                  href="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Privacy Policy
                </Link>
                <Space size="xs" />
                <Link
                  href="/cookies"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Cookies Policy
                </Link>
                <Space size="xs" />
                <Link
                  href="/disclaimer"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Disclaimer
                </Link>
              </Box>
            </Stack>
          </Box>
          <Copyright />
          {isTablet && <Box height={100} />}
        </RoutePage>
      </Box>
    </>
  );
};

const BottomTabBar = () => {
  const [value, setValue] = useState(0); // State to manage active tab
  const navigate = useNavigate(); // React Router navigation hook
  const theme = useTheme();
  const { isLoading, isAuthenticated } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen size is mobile
  const isEditor = window.location.href.includes('/editor/');
  const isTrip = window.location.href.includes('/trip/');
  const isPreview = window.location.href.includes('/preview/');
  const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false);

  const isStandalone = isRunningStandalone();
  // Handle tab change and navigation
  const handleChange = (event, newValue) => {
    if (isAuthenticated) {
      setValue(newValue);
      if (newValue === 0) {
        navigate('/explore'); // Navigate to "My Account" (Home)
      } else if (newValue === 1) {
        navigate('/favourite'); // Navigate to "Help"
      } else if (newValue === 2) {
        navigate('/my-trips'); // Navigate to "Main" (Explore)
      } else if (newValue === 3) {
        navigate('/account'); // Navigate to "Help"
      }
    } else if (!isLoading) {
      if (newValue === 0) {
        navigate('/explore'); // Navigate to "My Account" (Home)
      } else {
        setShowSignUpModal(true); // Navigate to "Help"
      }
    }
  };

  let width = '100%';

  if ((isEditor || isTrip || isPreview) && !isMobile) {
    width = '53%';
  }
  return (
    <footer>
      <Box
        sx={{
          width: width,
          position: 'fixed',
          bottom: 0,
          right: 0,
          zIndex: 960,
          paddingTop: 1,
          backgroundColor: '#fffaf5',
          transform: 'translateZ(0)', // Fix for iOS
          willChange: 'transform', // Helps with rendering stability
          overflow: 'visible',
          borderTop: '1px solid', // Add top border
          borderColor: 'rgba(0, 0, 0, 0.12)', // Customize the color of the border
          paddingBottom: isStandalone
            ? 'calc(env(safe-area-inset-bottom, 20px))' // Fallback to 0px
            : '10px',
        }}
      >
        <BottomNavigation
          value={value}
          onChange={handleChange}
          // sx={{
          //   height: isStandaone
          //     ? 'calc(60px + env(safe-area-inset-bottom))'
          //     : '60px ',
          //   backgroundColor: '#fffaf5',
          //   paddingBottom: isStandaone
          //     ? 'calc(env(safe-area-inset-bottom))'
          //     : '10px ',
          //   // paddingTop: '10px',
          // }}
          sx={{
            height: isStandalone
              ? 'calc(60px + env(safe-area-inset-bottom, 70px))' // Fallback to 0px
              : '60px',
            backgroundColor: '#fffaf5',
          }}
        >
          <BottomNavigationAction
            label="Explore"
            showLabel={true}
            sx={theme => ({
              fontWeight: 700,
              color:
                value === 0
                  ? theme.palette.primary.main
                  : theme.palette.icon.main,
            })}
            icon={
              <TravelExploreTwoToneIcon
                sx={theme => ({
                  height: 30,
                  width: 30,
                  color:
                    value === 0
                      ? theme.palette.primary.main
                      : theme.palette.icon.main,
                })}
              />
            }
          />

          <BottomNavigationAction
            label="Favourite"
            showLabel={true}
            sx={theme => ({
              fontWeight: 700,
              color:
                value === 1
                  ? theme.palette.primary.main
                  : theme.palette.icon.main,
            })}
            icon={
              <FavoriteTwoToneIcon
                sx={theme => ({
                  height: 30,
                  width: 30,
                  color:
                    value === 1
                      ? theme.palette.primary.main
                      : theme.palette.icon.main,
                })}
              />
            }
          />
          <BottomNavigationAction
            label="My trips"
            showLabel={true}
            sx={theme => ({
              fontWeight: 700,
              color:
                value === 2
                  ? theme.palette.primary.main
                  : theme.palette.icon.main,
            })}
            icon={
              <CardTravelTwoToneIcon
                sx={theme => ({
                  height: 30,
                  width: 30,
                  color:
                    value === 2
                      ? theme.palette.primary.main
                      : theme.palette.icon.main,
                })}
              />
            }
          />
          <BottomNavigationAction
            label="Account"
            showLabel={true}
            sx={theme => ({
              fontWeight: 700,
              color:
                value === 3
                  ? theme.palette.primary.main
                  : theme.palette.icon.main,
            })}
            icon={
              <AccountCircleTwoToneIcon
                sx={theme => ({
                  height: 30,
                  width: 30,
                  color:
                    value === 3
                      ? theme.palette.primary.main
                      : theme.palette.icon.main,
                })}
              />
            }
          />
        </BottomNavigation>
        <SignUp
          showInModal={true}
          open={showSignUpModal}
          loginDefault={false}
          onClose={() => setShowSignUpModal(false)}
        />
      </Box>
    </footer>
  );
};

export function NewsletterSignup() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const [newsletterSubscribe, { loading: sending }] = useMutation(
    MUTATION_NEWSLETTER_SUBSCRIBE,
    {
      client: unauthorisedClient,
      variables: { email: email },
    },
  );

  const isValidEmail = email => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setError('');
    await newsletterSubscribe();
    setSuccess(true);
    setEmail('');
  };

  return (
    <Box
      sx={theme => ({
        paddingX: 2,
        paddingBottom: 2,
        [theme.breakpoints.down('sm')]: {},
      })}
    >
      <Box
        sx={theme => ({
          bgcolor: '#1E1E2F',
          p: 5,
          borderRadius: '20px',
          boxShadow: '0 6px 15px rgba(0,0,0,0.2)',
          textAlign: 'center',
          maxWidth: 850,
          margin: 'auto',
          transition: 'all 0.3s ease-in-out',
        })}
      >
        <Typography
          variant="h4"
          sx={{ color: '#fff', fontWeight: '600', mb: 1, letterSpacing: '1px' }}
        >
          Join Our Exclusive Community
        </Typography>
        <Typography variant="body1" sx={{ color: '#d6d6d6', mb: 3 }}>
          Be the first to receive exciting travel updates, guides, and exclusive
          offers.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              justifyContent: 'center',
            }}
          >
            <TextField
              label="Your Email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              fullWidth
              sx={theme => ({
                [theme.breakpoints.down('sm')]: { maxWidth: 550 },
                maxWidth: 350,
                input: { color: '#fff' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    // Target the fieldset
                    borderColor: '#aaa', // lighter border for the input field
                    borderRadius: '20px',
                  },
                },
                '&:hover .MuiOutlinedInput-root': {
                  '& fieldset': {
                    // Target the fieldset on hover
                    borderColor: '#fff', // border on hover
                  },
                },
                '& .MuiFormLabel-root': { color: '#d6d6d6' },
              })}
              error={!!error}
              helperText={error}
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={sending}
              sx={{
                minWidth: 120,
                borderRadius: '20px',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                backgroundColor: '#ff4081', // Better contrast color for the button
                '&:hover': {
                  backgroundColor: '#ff3366', // Hover state with a slightly darker shade
                },
                transition: '0.3s ease',
              }}
            >
              {sending ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Subscribe'
              )}
            </Button>
          </Box>
        </form>

        {success && (
          <Typography
            variant="body2"
            sx={{
              color: '#5dff66',
              fontWeight: '500',
              mt: 2,
              fontSize: '1rem',
              letterSpacing: '0.5px',
            }}
          >
            🎉 You’re in! Thanks for subscribing!
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export function BetaTestersLinks() {
  return (
    <Box
      sx={theme => ({
        paddingX: 2,
        paddingY: 2,
        [theme.breakpoints.down('sm')]: {},
      })}
    >
      <Box
        sx={theme => ({
          // bgcolor: '#3D1E5F',
          background: 'linear-gradient(135deg, #2A2A4F 0%, #1A8CFF 100%)',
          p: 3,
          borderRadius: '20px',
          boxShadow: '0 6px 15px rgba(0,0,0,0.2)',
          textAlign: 'center',
          maxWidth: 850,
          margin: 'auto',
          transition: 'all 0.3s ease-in-out',
          justifyContent: 'center',
          justifyItems: 'center',
        })}
      >
        <Box
          sx={{
            maxWidth: '600px',
            justifyContent: 'center',
            justifyItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#fff',
              fontWeight: '600',
              mb: 1,
              letterSpacing: '1px',
            }}
          >
            Help us shape rooutie
          </Typography>
          <Typography variant="body1" sx={{ color: 'white', mb: 3 }}>
            Join our beta community, get early access to new features, and help
            shape travel’s future with your feedback.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            <Stack direction={'row'}>
              <WhatsAppIcon sx={{ color: 'white', marginRight: 1 }} />
              <Link
                href="https://chat.whatsapp.com/HyyR3f4w8eh7InzSewAA17"
                sx={{ color: 'white', textDecoration: 'none', fontWeight: 600 }}
              >
                WhatsApp Group
              </Link>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Copyright() {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 1, // Adds top padding
        paddingBottom: 1, // Adds bottom padding
      }}
    >
      <Typography sx={{ fontSize: 12, fontWeight: 500, textAlign: 'center' }}>
        All Rights Reserved © {currentYear} rooutie LTD
      </Typography>
    </Box>
  );
}
