import { useState } from 'react';
import { Box, Typography, Link } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const InfoIcon = ({ userName, link }) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    if (window.innerWidth < 768) {
      setExpanded(!expanded); // Toggle expansion on mobile
    }
  };

  const handleUsernameClick = e => {
    e.stopPropagation(); // Prevent the whole box from capturing the click
    setExpanded(false); // Collapse after clicking the link
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 4,
        right: 4,
        display: 'flex',
        alignItems: 'center',
        bgcolor: expanded ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.0)',
        color: 'white',
        paddingY: '4px',
        paddingLeft: '12px',
        zIndex: 100,
        paddingRight: '4px',
        borderRadius: '20px',
        cursor: 'pointer',
        transition: 'all 0.5s',
      }}
      onClick={handleClick}
      onMouseEnter={() => window.innerWidth >= 768 && setExpanded(true)}
      onMouseLeave={() => window.innerWidth >= 768 && setExpanded(false)}
    >
      {expanded && (
        <Typography
          component={Link}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontSize: 10,
            fontWeight: 600,
            mr: 1,
            color: 'white',
            textDecoration: 'underline',
            '&:hover': { color: 'lightgray' },
          }}
          onClick={handleUsernameClick}
        >
          {userName}
        </Typography>
      )}
      <InfoOutlinedIcon sx={{ width: 17, height: 17, color: 'lightgray' }} />
    </Box>
  );
};

export default InfoIcon;
