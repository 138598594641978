import { Google } from '@mui/icons-material';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import CarRentalTwoToneIcon from '@mui/icons-material/CarRentalTwoTone';
import DirectionsTwoToneIcon from '@mui/icons-material/DirectionsTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import { Stack, Button, Link } from '@mui/material';
import { useState } from 'react';

import { Activity, Maybe, Place, Step } from '../../generated/public_graphql';
import {
  generateBookingComUrl,
  generateCarRentalUrl,
  generateFlightUrl,
  generateGetYourGuideUrl,
  getPlaceMapUrls,
} from '../../utils/helpers';
import InstagramEmbedModal, {
  TikTokEmbedModal,
  YouTubeEmbedModal,
} from '../trip-details/InstagramEmbeded';

interface SocialLinksRowProps {
  step?: Maybe<Step>;
  place?: Maybe<Place>;
  activity?: Maybe<Activity>;
  displayedInItinerary: boolean;
}

export default function ButtonsRow({
  step,
  place,
  activity,
  displayedInItinerary,
}: SocialLinksRowProps) {
  let instagramLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const instagramItem = step.socialLinks.find(
      item => item?.socialType === 'instagram',
    );
    if (instagramItem) {
      instagramLink = instagramItem.urlLink!;
    }
  }

  let tikTokLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const tikTokItem = step.socialLinks.find(
      item => item?.socialType === 'tiktok',
    );
    if (tikTokItem) {
      tikTokLink = tikTokItem.urlLink!;
    }
  }

  let youTubeLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const youTubeItem = step.socialLinks.find(
      item => item?.socialType === 'youtube',
    );
    if (youTubeItem) {
      youTubeLink = youTubeItem.urlLink!;
    }
  }

  let mapUrls: {
    takeMeThereUrl: string | null;
    openGoogleMapsUrl: string | null;
  } = { takeMeThereUrl: null, openGoogleMapsUrl: null };
  if (step?.place) {
    mapUrls = getPlaceMapUrls(step.place);
  } else if (place) {
    mapUrls = getPlaceMapUrls(place);
  }
  const { takeMeThereUrl, openGoogleMapsUrl } = mapUrls;

  const [moreInfoOpened, setMoreInfoOpened] = useState(false);
  const closeModal = () => {
    setMoreInfoOpened(false);
  };

  let bookingLink: string | null = null;
  let carRentalLink: string | null = null;
  let bookFlightLink: string | null = null;
  let activityBookingLink: string | null = null;
  if (activity?.bookingLink) {
    const affiliateLink = generateGetYourGuideUrl(activity.bookingLink);
    activityBookingLink = affiliateLink;
  }

  if (
    place?.placeType === 'CATEGORY_ACCOMMODATION' ||
    place?.category === 'hotel'
  ) {
    bookingLink = generateBookingComUrl(
      place.lat ?? undefined,
      place.lng ?? undefined,
      place.city ?? undefined,
      place.title ?? undefined,
    );
  }

  if (
    step?.place?.placeType === 'CATEGORY_ACCOMMODATION' ||
    step?.place?.category === 'hotel'
  ) {
    bookingLink = generateBookingComUrl(
      step.place.lat ?? undefined,
      step.place.lng ?? undefined,
      step.place.city ?? undefined,
      step.place.title ?? undefined,
    );
  }

  if (
    place?.dataSource === 'accomodation' &&
    place?.bookingLink != null &&
    place?.bookingLink.startsWith('https://')
  ) {
    bookingLink = place.bookingLink;
  }

  if (
    step?.place?.dataSource === 'accomodation' &&
    step.place?.bookingLink != null &&
    step.place?.bookingLink.startsWith('https://')
  ) {
    bookingLink = step.place.bookingLink;
  }

  if (place?.category?.toLowerCase().includes('car_rental')) {
    carRentalLink = generateCarRentalUrl(
      place.city ?? undefined,
      place.country ?? undefined,
    );
  }

  if (step?.place?.category?.toLowerCase().includes('car_rental')) {
    carRentalLink = generateCarRentalUrl(
      step.place.city ?? undefined,
      step.place.country ?? undefined,
    );
  }

  if (place?.category?.toLowerCase().includes('airport')) {
    bookFlightLink = generateFlightUrl();
  }

  if (step?.place?.category?.toLowerCase().includes('airport')) {
    bookFlightLink = generateFlightUrl();
  }

  let hideMoreInfo = true;

  if (step && step.place) {
    hideMoreInfo = false;
  }

  if (
    step &&
    step.place !== null &&
    step.place?.dataSource !== null &&
    step.place?.dataSource === 'accomodation'
  ) {
    hideMoreInfo = true;
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          flexWrap: 'wrap',
          rowGap: 0.5,
        }}
      >
        {!displayedInItinerary && (
          <>
            {activityBookingLink !== null && activityBookingLink !== '' && (
              <>
                <Link
                  onClick={() => {
                    openEmbeddedLinkInBrowserNewTab(activityBookingLink!);
                    gtag('event', 'public-link-navigate-click');
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={
                      <LocalActivityTwoToneIcon
                        sx={theme => ({
                          width: 20,
                          height: 20,
                          // color: theme.palette.primary.light,
                        })}
                      />
                    }
                    sx={theme => ({
                      fontWeight: 600,
                      fontSize: 14,
                      borderRadius: '20px',
                      paddingY: 0.2,
                      paddingX: 2,
                      alignSelf: 'center',
                      // color: '#001B2F',
                      // backgroundColor: '#E1D9FA',
                      backgroundColor: theme.palette.primary.main,
                      whiteSpace: 'nowrap',
                      marginTop: 0.5,
                      marginRight: 1,
                    })}
                  >
                    Book Now
                  </Button>
                </Link>
              </>
            )}
            {bookingLink !== null && bookingLink !== '' && (
              <>
                <Link
                  onClick={() => {
                    openEmbeddedLinkInBrowserNewTab(bookingLink!);
                    gtag('event', 'public-link-navigate-click');
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={
                      <HotelTwoToneIcon
                        sx={theme => ({
                          width: 20,
                          height: 20,
                          // color: theme.palette.primary.light,
                        })}
                      />
                    }
                    sx={theme => ({
                      fontWeight: 600,
                      fontSize: 14,
                      borderRadius: '20px',
                      paddingY: 0.2,
                      paddingX: 2,
                      alignSelf: 'center',
                      // color: '#001B2F',
                      // backgroundColor: '#E1D9FA',
                      backgroundColor: theme.palette.primary.main,
                      whiteSpace: 'nowrap',
                      marginTop: 0.5,
                      marginRight: 1,
                    })}
                  >
                    Book stay
                  </Button>
                </Link>
              </>
            )}
            {carRentalLink !== null && carRentalLink !== '' && (
              <>
                <Link
                  onClick={() => {
                    openEmbeddedLinkInBrowserNewTab(carRentalLink!);
                    gtag('event', 'public-link-navigate-click');
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={
                      <CarRentalTwoToneIcon
                        sx={theme => ({
                          width: 20,
                          height: 20,
                          // color: theme.palette.primary.light,
                        })}
                      />
                    }
                    sx={theme => ({
                      fontWeight: 600,
                      fontSize: 14,
                      borderRadius: '20px',
                      paddingY: 0.2,
                      paddingX: 2,
                      alignSelf: 'center',
                      // color: '#001B2F',
                      // backgroundColor: '#E1D9FA',
                      backgroundColor: theme.palette.primary.main,
                      whiteSpace: 'nowrap',
                      marginTop: 0.5,
                      marginRight: 1,
                    })}
                  >
                    Rent a car
                  </Button>
                </Link>
              </>
            )}
            {bookFlightLink !== null && bookFlightLink !== '' && (
              <>
                <Link
                  onClick={() => {
                    openEmbeddedLinkInBrowserNewTab(bookFlightLink!);
                    gtag('event', 'public-link-navigate-click');
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={
                      <AirplaneTicketIcon
                        sx={theme => ({
                          width: 20,
                          height: 20,
                          // color: theme.palette.primary.light,
                        })}
                      />
                    }
                    sx={theme => ({
                      fontWeight: 600,
                      fontSize: 14,
                      borderRadius: '20px',
                      paddingY: 0.2,
                      paddingX: 2,
                      alignSelf: 'center',
                      // color: '#001B2F',
                      // backgroundColor: '#E1D9FA',
                      backgroundColor: theme.palette.primary.main,
                      whiteSpace: 'nowrap',
                      marginTop: 0.5,
                      marginRight: 1,
                    })}
                  >
                    Seach for flight
                  </Button>
                </Link>
              </>
            )}
          </>
        )}
        {!hideMoreInfo && displayedInItinerary && (
          <>
            <Link
              onClick={() => {
                gtag('event', 'public-place-more-info-click');
                setMoreInfoOpened(true);
              }}
            >
              <Button
                variant="contained"
                startIcon={
                  <InfoTwoToneIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      color: theme.palette.primary.light,
                    })}
                  />
                }
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  borderRadius: '20px',
                  paddingY: 0.2,
                  paddingX: 2,
                  alignSelf: 'center',
                  color: '#001B30',
                  backgroundColor: '#E1D9FA',
                  whiteSpace: 'nowrap',
                  marginTop: 0.5,
                  marginRight: 1,
                }}
              >
                More info
              </Button>
            </Link>
          </>
        )}

        {instagramLink && <InstagramEmbedModal url={instagramLink} />}
        {tikTokLink && <TikTokEmbedModal url={tikTokLink} />}
        {youTubeLink && <YouTubeEmbedModal url={youTubeLink} />}
        {((step &&
          step.place &&
          step.place.dataSource &&
          step.place.dataSource === 'place') ||
          (place && place.dataSource && place.dataSource === 'place')) &&
          !!takeMeThereUrl && (
            <Link
              onClick={() => {
                openEmbeddedLinkInBrowserNewTab(takeMeThereUrl);
              }}
            >
              <Button
                variant="contained"
                startIcon={
                  <Google
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      color: theme.palette.primary.light,
                    })}
                  />
                }
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  borderRadius: '20px',
                  paddingY: 0.2,
                  paddingX: 2,
                  alignSelf: 'center',
                  color: '#001B2F',
                  backgroundColor: '#E1D9FA',
                  whiteSpace: 'nowrap',
                  marginTop: 0.5,
                  marginRight: 1,
                }}
              >
                Photos
              </Button>
            </Link>
          )}
        {!displayedInItinerary && takeMeThereUrl && (
          <Link
            onClick={() => {
              openEmbeddedLinkInBrowserNewTab(takeMeThereUrl);
              gtag('event', 'public-link-directions-click');
            }}
          >
            <Button
              variant="contained"
              startIcon={
                <DirectionsTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    color: theme.palette.primary.light,
                  })}
                />
              }
              sx={{
                fontWeight: 600,
                fontSize: 14,
                borderRadius: '20px',
                paddingY: 0.2,
                paddingX: 2,
                alignSelf: 'center',
                color: '#001B2F',
                backgroundColor: '#E1D9FA',
                whiteSpace: 'nowrap',
                marginTop: 0.5,
                marginRight: 1,
              }}
            >
              Directions
            </Button>
          </Link>
        )}
        {!displayedInItinerary && takeMeThereUrl && (
          <Link
            onClick={() => {
              openEmbeddedLinkInBrowserNewTab(takeMeThereUrl);
              gtag('event', 'public-link-googlre-maps-click');
            }}
          >
            <Button
              variant="contained"
              startIcon={
                <MapTwoToneIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    color: theme.palette.primary.light,
                  })}
                />
              }
              sx={{
                fontWeight: 600,
                fontSize: 14,
                borderRadius: '20px',
                paddingY: 0.2,
                paddingX: 2,
                alignSelf: 'center',
                color: '#001B2F',
                backgroundColor: '#E1D9FA',
                whiteSpace: 'nowrap',
                marginTop: 0.5,
                marginRight: 1,
              }}
            >
              Google Maps
            </Button>
          </Link>
        )}
      </Stack>
    </Stack>
  );
}

export function openEmbeddedLinkInBrowserNewTab(url: string): void {
  if (!url) return;

  // Only modify the URL if it contains "booking.com"
  if (url.includes('booking.com')) {
    const encodedRedirectLink = encodeURIComponent(url);
    const cjRootDomain = 'www.dpbolvw.net';
    const pid = '101303179';
    const advertiserLinkId = '12099511';
    // Construct the CJ formatted link
    url = `https://${cjRootDomain}/click-${pid}-${advertiserLinkId}?url=${encodedRedirectLink}`;
  }

  // Format the URL to force HTTPS if necessary
  const formattedUrl = url.replace(/^http?:\/\//, 'https://');

  const redirectUrl = `/redirect?url=${encodeURIComponent(formattedUrl)}`;
  window.open(formattedUrl, '_blank', 'noopener,noreferrer');

  // window.parent.postMessage(
  //   { type: 'openLinkInNewTab', url: formattedUrl },
  //   '*', // Or specify your parent window's origin for better security
  // );
}
