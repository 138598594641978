import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, CardMedia, Link, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { Step } from '../../generated/public_graphql';
import { openEmbeddedLinkInBrowserNewTab } from './ButtonsRow';

interface ImageCardProps {
  imageUrl: string;
  imageId: string;
  path: string;
  title: string;
  index: number;
  step?: Step;
  tripId?: string;
  contributorName?: string | null;
  contributorUrl?: string | null;
}

export const EmbeddedImageCard: React.FC<ImageCardProps> = React.memo(
  ({
    path,
    title,
    index,
    imageId,
    imageUrl,
    contributorName,
    contributorUrl,
  }) => {
    const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [imageSrc, setImageSrc] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
      if (path === null || path === '') {
        setImageSrc(
          `${process.env.REACT_APP_CONFIG_URL}/getFreeImage/${imageId}`,
        );
      } else {
        if (path !== '' && path !== null && path !== undefined) {
          setImageSrc(path);
        }
      }
    }, [path, imageFailedToLoad, imageId]);

    useEffect(() => {
      let timeout: NodeJS.Timeout;
      const lastState = { isIntersecting: false };
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Clear any pending timeout
          if (timeout) {
            clearTimeout(timeout);
          }

          // Only update if state actually changed
          if (lastState.isIntersecting !== entry.isIntersecting) {
            timeout = setTimeout(() => {
              lastState.isIntersecting = entry.isIntersecting;
            }, 150); // Debounce time
          }
        },
        {
          root: null,
          rootMargin: '400px',
          threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        },
      );

      if (elementRef.current) {
        observer.observe(elementRef.current);
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
      };
    }, []);

    if (imageFailedToLoad) {
      return <></>;
    }

    return (
      <Box
        ref={elementRef}
        sx={theme => ({
          height: 150,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: {
            height: 130,
          },
        })}
      >
        {!imageFailedToLoad && imageSrc && (
          <>
            <CardMedia
              component="img"
              src={imageSrc}
              alt={title ? 'Image of ' + title : 'Place image'}
              title={title ? 'Image of ' + title : 'Place image'}
              onLoad={() => {
                setImageLoaded(true);
                setImageFailedToLoad(false);
              }}
              onError={() => {
                console.log('error loading image', imageId);

                setImageFailedToLoad(true);
              }} // Set imageLoaded to true once the image is loaded
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                display: imageLoaded ? 'block' : 'none',
              }}
            />
            {contributorUrl && contributorName && (
              <InfoIcon userName={contributorName} link={contributorUrl} />
            )}
          </>
        )}
        {!imageLoaded && !imageFailedToLoad && (
          <Skeleton
            variant="rectangular"
            sx={{
              height: 150,
              width: '100%',
            }}
          />
        )}
      </Box>
    );
  },
);

const InfoIcon = ({ userName, link }) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    if (window.innerWidth < 768) {
      setExpanded(!expanded); // Toggle expansion on mobile
    }
  };

  const handleUsernameClick = e => {
    e.stopPropagation(); // Prevent the whole box from capturing the click
    setExpanded(false); // Collapse after clicking the link
    openEmbeddedLinkInBrowserNewTab(link);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 4,
        right: 4,
        display: 'flex',
        alignItems: 'center',
        bgcolor: expanded ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.0)',
        color: 'white',
        paddingY: '4px',
        paddingLeft: '12px',
        zIndex: 100,
        paddingRight: '4px',
        borderRadius: '20px',
        cursor: 'pointer',
        transition: 'all 0.5s',
      }}
      onClick={handleClick}
      onMouseEnter={() => window.innerWidth >= 768 && setExpanded(true)}
      onMouseLeave={() => window.innerWidth >= 768 && setExpanded(false)}
    >
      {expanded && (
        <Typography
          sx={{
            fontSize: 10,
            fontWeight: 600,
            mr: 1,
            color: 'white',
            textDecoration: 'underline',
            '&:hover': { color: 'lightgray' },
          }}
          onClick={handleUsernameClick}
        >
          {userName}
        </Typography>
      )}
      <InfoOutlinedIcon sx={{ width: 17, height: 17, color: 'lightgray' }} />
    </Box>
  );
};
