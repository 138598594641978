import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import DirectionsIcon from '@mui/icons-material/Directions';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon
import LanguageIcon from '@mui/icons-material/Language';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import WatchLaterTwoToneIcon from '@mui/icons-material/WatchLaterTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import PersonIcon from '@mui/icons-material/Person';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Typography,
  Link,
  Stack,
  styled,
  Tooltip,
  IconButton,
  Grid,
  Button,
  Divider,
  Rating,
  Avatar,
  Skeleton,
  CardMedia,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import zIndex from '@mui/material/styles/zIndex';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Activity,
  ItineraryImage,
  Maybe,
  Place,
  PlaceHours,
  Step,
} from '../../generated/user_graphql';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
  stepsSelector,
} from '../../store/StepSlice';
import { LinkButton, ResponsiveChip } from '../../theme-components/Buttons';
import {
  CustomBookingComBlueIcon,
  GetYourGuideIcon,
} from '../../theme-components/Icons';
import Space from '../../theme-components/Spacing';
import TextDescription, {
  Body1,
  TitleH3,
  TitleH4,
} from '../../theme-components/Typography';
import {
  formatReviewString,
  formatSecondsToTime,
  openLinkInBrowserNewTab,
} from '../../utils/helpers';
import GoogleIcon from '../icons/GoogleIcon';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';
import { CenteredModal, MoreDetailsModalPaper } from '../styling/modal';

import { PriceInfo } from './PublicPlaceCard';
import PlaceCardCTAs from './PublicPlaceCardCTA';
import { ImageCard } from './StepImages';
import ActivityCardCTAs from './PublicActivityCardCTA';
import SocialLinksRow from './place-details/SocialLinks';

interface TripImagesModalProps {
  images?: Maybe<Maybe<ItineraryImage>[]>;
  onClose?: () => void;
}

export const TripImagesModal: React.FC<TripImagesModalProps> = ({
  images,
  onClose,
}) => {
  if (!images) {
    return <></>;
  }

  return (
    <CenteredModal
      open={true}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <Box
        sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: -45,
            right: 0,
            zIndex: 1500,
            color: '#fff',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        <MoreDetailsModalPaper>
          <Box p={2} sx={{ width: '100%' }}>
            <ImageGrid images={images} />
          </Box>
        </MoreDetailsModalPaper>
      </Box>
    </CenteredModal>
  );
};

interface ImageGridProps {
  images: Maybe<Maybe<ItineraryImage>[]>;
}

const ImageGrid: React.FC<ImageGridProps> = ({ images }) => {
  return (
    <Grid container spacing={2}>
      {images?.map(image => (
        <Grid item xs={12} sm={6} key={image?.id} sx={{ display: 'flex' }}>
          <LazyImage image={image} />
        </Grid>
      ))}
    </Grid>
  );
};

const LazyImage = ({ image }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  useEffect(() => {
    let timeout;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (timeout) clearTimeout(timeout);

        // Start loading as soon as image enters the viewport
        timeout = setTimeout(() => {
          setIsVisible(entry.isIntersecting);
        }, 150); // Debounce to prevent rapid state changes
      },
      {
        root: null,
        rootMargin: '300px', // Load images slightly before they enter view
        threshold: 0, // Trigger when the image enters the viewport (even 1px)
      },
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
      if (elementRef.current) observer.unobserve(elementRef.current);
    };
  }, []);

  return (
    <Box
      ref={elementRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '250px',
        borderRadius: '20px',
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Title at the Top */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          padding: 1,
          paddingLeft: 2,
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          background: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(8px)',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Box sx={{ fontWeight: 'bold', color: '#F39C6A', flexShrink: 0 }}>
          {`Day ${image?.dayNumber}`}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            paddingLeft: '4px',
          }}
        >
          {image?.stepName ? (
            `: ${image.stepName}`
          ) : (
            <LockTwoToneIcon
              sx={{
                ml: 0.5,
                mt: 0.5,
                alignSelf: 'center',
                width: '16px',
                height: '16px',
              }}
            />
          )}
        </Box>
      </Box>

      {/* Image / Skeleton Loader */}
      {isVisible && !imageFailedToLoad ? (
        <CardMedia
          component="img"
          src={image?.previewPath || image?.mediumPath || image?.path || ''}
          title={image?.stepName || 'Locked'}
          onLoad={() => {
            setImageLoaded(true);
            setImageFailedToLoad(false);
          }}
          onError={() => setImageFailedToLoad(true)}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: imageLoaded ? 'block' : 'none',
          }}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{ height: '100%', width: '100%' }}
        />
      )}
    </Box>
  );
};
