import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
import { Link, Route, Routes } from 'react-router-dom';

import ComponentsPreviewRoute from '../routes/ComponentsRoute';
import CountryRoute from '../routes/CountryRoute';
import CreateTripRoute from '../routes/CreateTripRoute';
import ExploreRoute from '../routes/ExploreRoute';
import GetTheApp from '../routes/GetTheApp';
import LandingPage from '../routes/Landing';
import MyAccountRoute from '../routes/MyAccountRoute';
import MyFavouritesRoute from '../routes/MyFavouritesRoute';
import MyTripsRoute from '../routes/MyTripsRoute';
import SharedTripPreviewRoute from '../routes/SharedTripPreviewRoute';
import TripPreviewRoute from '../routes/TripPreviewRoute';
import TripRoute from '../routes/TripRoute';
import UserPublicTrips from '../routes/UserPublicTripsRoute';

import { FullScreenBusAnimation } from './animations/BusAnimation';
import { AppBarNew } from './AppBar';
import AppFooter from './AppFooter';
import { Return } from './payment/PaymentCheckoutModal';
import { HelmetProvider } from 'react-helmet-async';
import EditorV2 from '../routes/EditorV2';
import MuiCookieComponent from './Cookies';

const NotFoundRoute = lazy(() => import('../routes/NotFoundRoute'));

export function App() {
  const isEmbedded = window.self !== window.top; // Check if the page is inside an iframe

  return (
    <HelmetProvider>
      {/* <AppBarNew /> */}
      {!isEmbedded && <AppBarNew />}
      {!isEmbedded && <MuiCookieComponent />}

      <main>
        <Suspense fallback={<FullScreenBusAnimation copy="Almost there!" />}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/terms"
              element={
                <iframe
                  title="Terms and Conditions"
                  src="/terms.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route
              path="/privacy"
              element={
                <iframe
                  title="Privacy Policy"
                  src="/privacy.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route
              path="/cookies"
              element={
                <iframe
                  title="Cookies Policy"
                  src="/cookies.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route
              path="/disclaimer"
              element={
                <iframe
                  title="Disclaimer"
                  src="/disclaimer.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route path="/explore" element={<ExploreRoute />} />
            <Route path="/trip/:shared_id/:trip_id" element={<TripRoute />} />
            <Route path="/itinerary/:id" element={<TripRoute />} />
            <Route path="/trip/:id" element={<TripRoute />} />
            <Route path="/guide/:id" element={<TripRoute />} />
            <Route path="/preview/:id" element={<TripPreviewRoute />} />
            <Route
              path="/components_preview"
              element={<ComponentsPreviewRoute />}
            />
            <Route path="/my-trips" element={<MyTripsRoute />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/favourite" element={<MyFavouritesRoute />} />
            <Route path="/account" element={<MyAccountRoute />} />
            <Route path="/profile/:id" element={<UserPublicTrips />} />
            <Route
              path="/shared-link/:shared_id/:trip_id"
              element={<SharedTripPreviewRoute />}
            />
            <Route
              path="/shared-trip/:shared_id/:trip_id"
              element={<SharedTripPreviewRoute />}
            />
            {/* <Route path="/finish-sign-up" element={<FinishSignUp />} /> */}
            <Route path="/editor/:userTripId" element={<CreateTripRoute />} />

            <Route path="/editor-v2/:userTripId" element={<EditorV2 />} />

            <Route path="/country-itineraries/:id" element={<CountryRoute />} />
            <Route path="/country/:id" element={<CountryRoute />} />
            <Route path="/download" element={<GetTheApp />} />
            {/* <Route path="/checkout" element={<CheckoutModal />} /> */}
            <Route path="/return" element={<Return />} />
            <Route path="/u/:id" element={<UserPublicTrips />} />
            <Route path="*" element={<NotFoundRoute />} />
          </Routes>
        </Suspense>
      </main>
      {!isEmbedded && <AppFooter />}
    </HelmetProvider>
  );
}
