import createCache from '@emotion/cache';
import { CacheProvider, css, Global } from '@emotion/react';
import { configureStore } from '@reduxjs/toolkit';
import { initializeApp } from 'firebase/app';
import ReactDOM from 'react-dom/client';
import { MapProvider } from 'react-map-gl';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

import AuthorizedApolloProvider from './AuthorizedApolloProvider';
import { App } from './components/App';
import { AppWrapper } from './components/AppWrapper';
import { AuthProvider } from './components/auth/AuthWrapper';
import { firebaseConfig } from './components/auth/firebase';
import ErrorModal from './components/Error';
import ErrorBoundary from './components/ErrorBoundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import daysSliceReducer from './store/DaySlice';
import errorSliceReducer from './store/ErrorSlice';
import { likedPlacesSliceReducer } from './store/LikedPlacesSlice';
import { publicTripSliceReducer } from './store/PublicTripSlice';
import stepsSliceReducer from './store/StepSlice';
import transportSliceReducer from './store/TransportSlice';
import { tripSliceReducer } from './store/TripSlice';
import { userSliceReducer } from './store/UserSlice';
import { CookiesProvider } from 'react-cookie';
import MuiCookieComponent from './components/Cookies';
import { EmbeddedTripMap } from './components/embedded-map/EmbeddedTripMap';

export const store = configureStore({
  reducer: {
    publicTrip: publicTripSliceReducer,
    userLikedPlaces: likedPlacesSliceReducer,
    userTrip: tripSliceReducer,
    userTripDays: daysSliceReducer,
    userAddingStep: stepsSliceReducer,
    errorModal: errorSliceReducer,
    user: userSliceReducer,
    transport: transportSliceReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

// initializeApp(firebaseConfig);

const speedy = navigator.userAgent.toLowerCase().indexOf('prerender') === -1;

const emotionCache = createCache({
  key: 'emotion-cache',
  speedy: speedy,
});

const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);

const globalStyles = css`
  body,
  html {
    height: fit-content;
  }
`;

function AppRoutes() {
  // Only initialise if not embedded route
  if (window.location.pathname !== '/embedded-map1/:userTripId') {
    serviceWorkerRegistration.register();
    initializeApp(firebaseConfig);
  }

  const routes = useRoutes([
    {
      path: '/embedded-map/:userTripId',
      element: (
        // <Provider store={store}>
        <AppWrapper>
          <EmbeddedTripMap />
        </AppWrapper>
        // </Provider>
      ),
    },
    {
      path: '/redirect',
      element: <RedirectToUrl />,
    },
    {
      path: '*',
      element: (
        <CookiesProvider>
          <CacheProvider value={emotionCache}>
            <Global styles={globalStyles} />
            <Provider store={store}>
              <ErrorModal />
              <ErrorBoundary>
                <AuthProvider>
                  <AuthorizedApolloProvider>
                    <AppWrapper>
                      <MapProvider>
                        <App />
                      </MapProvider>
                    </AppWrapper>
                  </AuthorizedApolloProvider>
                </AuthProvider>
              </ErrorBoundary>
            </Provider>
          </CacheProvider>
        </CookiesProvider>
      ),
    },
  ]);

  return routes;
}

root.render(
  <Router>
    <AppRoutes />
  </Router>,
);
// Register the service worker
// serviceWorkerRegistration.register();

const RedirectToUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get('url');

  if (redirectUrl) {
    window.location.replace(redirectUrl);
    return null; // Prevents React from rendering anything further
  } else {
    // Handle the case where the 'url' parameter is missing
    return <div>Invalid redirect URL.</div>;
  }
};
