import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useAuth } from '../components/auth/firebase';
import { CreateUserTripV2 } from '../components/create-trip/CreateUserTripV2';
import { LoaderFullScreen } from '../theme-components/Loader';
import { isRunningStandalone } from '../utils/helpers';

export default function EditorV2() {
  const { isLoading } = useAuth();
  const [isStandalone, setIsStandalone] = useState<boolean>(false);

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingTop: '60px',
        marginTop: '-60px',
        height: '100%',
        backgroundColor: 'rgba(255, 248, 238, 0.7)',
      })}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <Box sx={{ minHeight: '100vh', paddingBottom: 10, width: '100%' }}>
        <CreateUserTripV2 />
        {isStandalone && (
          <Box
            sx={{ paddingBottom: 'calc(130px + env(safe-area-inset-bottom))' }}
          />
        )}
      </Box>
    </Box>
  );
}
