import React from 'react';
import { useSelector } from 'react-redux';

import { daysSelector } from '../../../store/DaySlice';

import { MultipleContainers } from './dnd/Components/MultipleContainers';

export function DraggableDroppableDaysV2() {
  const { days } = useSelector(daysSelector);

  return (
    <>
      <MultipleContainers
        vertical
        days={days}
        containerStyle={{ width: '100%' }}
      />
    </>
  );
}
