import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone';
import {
  Box,
  Drawer,
  List,
  ListItem,
  Typography,
  Collapse,
  useMediaQuery,
  useTheme,
  Stack,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Day, Maybe, Trip } from '../../generated/user_graphql';
import { CategoryIcon } from '../../theme-components/Icons';
import CloseIcon from '@mui/icons-material/Close';

interface DaysSidebarProps {
  days: Day[] | Maybe<Day>[];
  onDayClick?: (dayId: string) => void;
  onStepClick?: (stepId: string) => void;
  tripType: string;
  tripOwner?: string;
  numberOfHiddenDays?: number;
  itineraryId?: string;
  trip?: Trip;
  currentDayId?: string;
  stepInView?: string;
}

const DaysSidebarEmbeded: React.FC<DaysSidebarProps> = ({
  days,
  onDayClick,
  onStepClick,
  tripType,
  numberOfHiddenDays,
  currentDayId,
  stepInView,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [expandedDay, setExpandedDay] = useState<string | null | undefined>(
    currentDayId,
  );
  const [selectedStep, setSelectedStep] = useState<string | null | undefined>(
    stepInView,
  );

  useEffect(() => {
    if (currentDayId) {
      setExpandedDay(currentDayId);
    }
  }, [currentDayId]);

  useEffect(() => {
    if (stepInView) {
      setSelectedStep(stepInView);
    }
  }, [stepInView]);

  const handleDayClick = (dayId: string) => {
    if (onDayClick) {
      onDayClick(dayId);
    }
  };

  const handleStepClick = (stepId: string) => {
    if (onStepClick) {
      onStepClick(stepId);
    }
    setIsDrawerOpen(false);
  };

  const handleDayExpand = (dayId: string) => {
    setExpandedDay(prevDayId => (prevDayId === dayId ? null : dayId));
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      // Prevent toggle on tab or shift key presses
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsDrawerOpen(open);
    };

  const renderSidebarContent = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: 200,
        height: '100%',
        paddingX: 1,
        paddingTop: 0,
        zIndex: 100000,
      }}
    >
      <Box
        sx={theme => ({
          position: 'absolute', // Absolute positioning
          right: 15,
          left: 'auto',
          zIndex: 100030,
          display: 'flex',
        })}
      >
        <IconButton
          onClick={() => {
            setIsDrawerOpen(!isDrawerOpen);
          }}
          style={{
            borderRadius: '50%',
            width: 30,
            height: 30,
          }}
        >
          <CloseIcon sx={{ color: 'rgba(54, 69, 79, 0.9)' }} />
        </IconButton>
      </Box>
      <Typography
        sx={theme => ({
          marginTop: 0,
          marginLeft: 1,
          fontWeight: 700,
          fontSize: 18,
          color: '#001B30',
          whiteSpace: 'nowrap',
          [theme.breakpoints.down('sm')]: {
            fontSize: 18,
          },
        })}
      >
        Itinerary
      </Typography>
      <List component="div" disablePadding>
        {days.map((day, index) => (
          <Box
            key={index}
            sx={{
              // backgroundColor: '#fff',
              borderRadius: 1,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor:
                  expandedDay === day.id ? 'transparent' : '#f6f0ff',
              },
              paddingLeft: 1,
            }}
            role="button"
            tabIndex={0}
          >
            <ListItem
              onClick={() => {
                handleDayClick(day.id);
                handleDayExpand(day.id);
              }}
              sx={{
                paddingY: 0.75,
                margin: 0,
                paddingLeft: 0,
                borderRadius: 1,
                cursor: 'pointer',
                // '&:hover': {
                //   backgroundColor: '#f6f0ff',
                // },
              }}
              role="button"
              tabIndex={0}
            >
              <Typography
                sx={theme => ({
                  fontWeight: 600,
                  fontSize: 16,
                  color: '#36454F',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                  },
                })}
              >
                {tripType === 'itinerary' ? `Day ${day.dayNumber}` : `Guide`}
              </Typography>
              {expandedDay === day.id ? (
                <ExpandLess sx={{ paddingLeft: 0.5 }} />
              ) : (
                <ExpandMore sx={{ paddingLeft: 0.5 }} />
              )}
            </ListItem>
            <Collapse
              in={expandedDay === day.id || !!numberOfHiddenDays}
              timeout="auto"
              unmountOnExit
            >
              <Box>
                {day.steps &&
                  day.steps.length > 0 &&
                  day.steps.map((step, idx) => (
                    <Stack
                      key={idx}
                      direction="row"
                      onClick={() => {
                        handleStepClick(step.id);
                      }}
                      sx={theme => ({
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '5px',
                        marginLeft: step.id === selectedStep ? -0.5 : 0,
                        paddingLeft: step.id === selectedStep ? 0.5 : 0,
                        backgroundColor:
                          step.id === selectedStep
                            ? theme.palette.grey[200]
                            : 'transparent',
                        '&:hover': {
                          marginLeft: -0.5,
                          paddingLeft: 0.5,
                          backgroundColor: theme.palette.grey[200],
                          '& .hover-text': {
                            color: theme.palette.primary.light, // Set font color on hover
                          },
                        },
                      })}
                    >
                      <Box mr={0.5}>
                        <CategoryIcon
                          type={step?.type}
                          category={step?.placeCategory}
                          source={step?.placeCategory}
                          width={20}
                          height={20}
                        />
                      </Box>

                      <Typography
                        key={idx}
                        sx={theme => ({
                          fontWeight: 500,
                          fontSize: 14,
                          color:
                            step.id === selectedStep
                              ? theme.palette.primary.light
                              : '#6E7191',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',

                          textOverflow: 'ellipsis',
                        })}
                      >
                        {step?.title}
                      </Typography>
                    </Stack>
                  ))}
              </Box>
            </Collapse>
          </Box>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ zIndex: 100000000 }}>
      <>
        <Box
          onClick={toggleDrawer(!isDrawerOpen)}
          display="flex"
          alignItems="center"
          borderRadius={20}
          bgcolor="#f5f5f5"
          sx={theme => ({
            paddingY: 1,
            paddingX: 2,
            position: 'absolute',
            top: 10,
            right: 10,
            left: 'auto',
            zIndex: 5,
            paddingLeft: 2,
            display: 'inline-flex',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(5px)',
            borderRadius: '20px',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.50)',
            [theme.breakpoints.down('sm')]: {
              position: 'fixed',
              top: 5,
              right: 0,
              left: 'auto',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: '20px',
              borderBottomLeftRadius: '20px',
            },
          })}
        >
          <Stack direction="row" spacing={1}>
            <MenuOpenTwoToneIcon sx={{ color: '#F39C6A' }} />

            <Typography
              sx={theme => ({
                marginRight: 0.5,
                whiteSpace: 'nowrap',
                color: '#36454F',
                fontWeight: 700,
              })}
            >
              Itinerary
            </Typography>
          </Stack>
        </Box>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          // onClose={() => {
          //   setIsDrawerOpen(!isDrawerOpen);
          // }}
          onClose={() => {
            setIsDrawerOpen(!isDrawerOpen);
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: 1000000,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 200,
            },
            '& .MuiBackdrop-root': {
              // Target the backdrop
              backgroundColor: 'rgba(0, 0, 0, 0.1)', // Adjust the alpha (last value) for transparency
            },
          }}
        >
          {renderSidebarContent()}
        </Drawer>
      </>
    </Box>
  );
};
export default DaysSidebarEmbeded;
